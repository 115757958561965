section.hero{
  margin-bottom: 40px;
  .hero_grid{
    width: 100%;
    display: flex;
    grid-gap: 16px;
    min-height: 400px;
    @media(max-width: 1024px){
      flex-direction: column;
      gap: 30px;
    }
    .hero_banner{
      flex: 1;
      width: calc(24% - 32px);
      border-radius: 12px;
      overflow: hidden;
      @media(max-width: 1024px){
        width: 100%;
        height: max-content;
      }
      figure{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .banner_content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 15px;
        height: 100%;
        @media(max-width: 1024px){
          padding: 15px;
        }
        .text:not(.banner_button){
          color: $cwhite;
          font-size: 19px;
          span{
            font-size: 27px;
          }
          &.title{
            font-size: 34px;
            line-height: 34px;
            @media(max-width: 1024px){
              font-size: 26px;
              line-height: 26px;
            }
          }
        }
        .banner_button{
          display: flex;
          justify-content: center;
          a{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $cwhite;
            color: $cdark;
            border-radius: 5px;
            padding: 2px 10px;
            font-size: 15px;
            transition: 0.4s;
            @media(max-width: 1024px){
              padding: 2px 4px;
              width: max-content;
              height: max-content;
            }
            &:hover{
              color: $cwhite;
              background-color: $cbase;
            }
          }
          &.text{
            width: 100%;
            a{
              justify-content: space-between;
              width: 100%;
              background-color: transparent;
              color: $cwhite;
              padding: 0;
              @media(max-width: 1024px){
                justify-content: flex-end;
                gap: 10px;
                font-size: 13px;
              }
              &:hover{
                color: $cbase;
                &::after{
                  color: $cbase;
                }
              }
              &:after{
                content: "\ea6e";
                font-family: $ffremix;
                color: $cwhite;
                font-size: 15px;
                transition: 0.4s;
                @media(max-width: 1024px){
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
      &.left{
        .banner_content{
          @media(max-width: 1024px){
            display: flex;
            gap: 15px;
            align-items: center;
            flex-direction: row;
          }
          .text.title{
            flex: 1;
          }
          .wrap{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media(max-width: 1024px){
              gap: 10px;
              align-items: flex-end;
            }
          }
          .text:not(.banner_button):not(.title){
            @media(max-width: 1024px){
              display: inline-flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              flex-direction: row;
              font-size: 11px;
              background-color: #005BFF;
              color: $cwhite;
              border-radius: 5px;
              text-transform: uppercase;
              padding: 1px 4px;
              br{
                display: none;
              }
            }
            span{
              @media(max-width: 1024px){
                font-size: 12px;
              }
            }
          }
          .text:not(.banner_button).title{
            @media(max-width: 1024px){
              font-size: 21px;
              line-height: 21px;
            }
          }
        }
      }
      &.right{
        .banner_content{
          padding-bottom: 65px;
          @media(max-width: 1024px){
            padding-bottom: 15px;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
          }
        }
      }
    }
    .hero_banners_slider_wrap{
      flex: 1;
      min-width: calc(52% - 32px);
      @media(max-width: 1024px){
        width: 100%;
      }
      .hero_banners_slider{
        height: 100%;
        .owl-wrapper-outer{
          height: 100%;
          .owl-wrapper{
            height: 100%;
            .owl-item{
              height: 100%;
            }
          }
        }
        .owl-controls{
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 40px;
          width: 100%;
          @media(max-width: 1024px){
            bottom: 30px;
          }
          .owl-pagination{
            bottom: 0;
            .owl-page{
              span{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background: $ccorp;
                border-radius: 50%;
                opacity: 0.7;
              }
              &.active{
                span{
                  opacity: 1;
                }
              }
            }
          }
        }
        .hero_slider_item{
          width: 100%;
          border-radius: 12px;
          height: 100%;
          overflow: hidden;
          padding: 25px 20px 100px;
          @media(max-width: 1024px){
            padding: 15px 15px 80px;
          }
          figure{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .banner_content{
            .banner_title{
              display: flex;
              flex-direction: column;
              font-size: 45px;
              gap: 25px;
              margin-bottom: 50px;
              color: $cwhite;
              font-weight: 700;
              line-height: 34px;
              @media(max-width: 1024px){
                font-size: 30px;
                margin-bottom: 20px;
                line-height: 34px;
              }
              span{
                color: $ccorp;
              }
            }
            .banner_subtitle{
              margin-bottom: 20px;
              font-size: 27px;
              color: $cwhite;
              @media(max-width: 1024px){
                font-size: 20px;
                margin-bottom: 40px;
              }
            }
            .banner_points{
              ul{
                display: grid;
                gap: 20px 50px;
                grid-template-columns: repeat(2, max-content);
                margin: 0;
                @media(max-width: 1024px){
                  grid-gap: 12px;
                  grid-template-columns: 1fr;
                }
                li{
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  color: $cwhite;
                  font-size: 17px;
                  @media(max-width: 1024px){
                    font-size: 16px;
                  }
                  img{

                  }
                  span{

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}