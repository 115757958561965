section.benefits{
  margin-bottom: 40px;
  .dl_container{
    background-color: $clightcorp;
    border-radius: 5px;
    .section_title{
      padding-top: 30px;
      @media(max-width: 1024px){
        padding-top: 15px;
      }
    }
    .block_wrap{
      padding: 0 25px 30px;
      .benefits_list{
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
        counter-reset: benefitCounter;
        @media(max-width: 1024px){
          grid-template-columns: 1fr;
        }
        .benefit_item{
          display: flex;
          flex-direction: column;
          padding: 20px;
          background-color: $cwhite;
          border-radius: 5px;
          transition: 0.4s;
          @media(max-width: 1024px){
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            padding: 15px;
            gap: 15px;
          }
          &:hover{
            background-color: #F5F5F5;
          }
          figure{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            @media(max-width: 1024px){
              width: 35px;
              margin-bottom: 0;
              position: static;
            }
            &:after{
              counter-increment: benefitCounter;
              content: "0" counter(benefitCounter);
              color: #929292;
              font-size: 15px;
              font-weight: 700;
              @media(max-width: 1024px){
                position: absolute;
                right: 15px;
                top: 15px;
              }
            }
            img{

            }
          }
          .benefit_title{
            margin-bottom: 5px;
            font-size: 14px;
            @media(max-width: 1024px){
              flex: 1;
              padding-right: 40px;
            }
          }
          .benefit_text{
            font-size: 13px;
            color: $cmain;
            line-height: 24px;
            @media(max-width: 1024px){
              width: 100%;
            }
          }
        }
      }
    }
  }
}