body {
  color: #666666;
  font-family: 'Rubik',Helvetica,sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.6px; }

select:focus {
  outline: none !important; }

h1, h2, h3, h4, h5, h6 {
  color: #333; }

li {
  list-style: none; }

/* default font size */
.fa {
  font-size: 14px; }

.fa-stack {
  width: 12px;
  font-size: 9px;
  height: 11px; }

.fa-stack .fa {
  font-size: 13px; }

i.fa.fa-star-o.fa-stack-2x:before {
  content: "\f005"; }

/* Override the bootstrap defaults */
h1 {
  font-size: 33px; }

h2 {
  font-size: 27px; }

h3 {
  font-size: 21px; }

h4 {
  font-size: 15px; }

h5 {
  font-size: 12px; }

h6 {
  font-size: 10.2px; }

a {
  color: #333333; }

a:hover, a:focus {
  text-decoration: none; }

legend {
  font-size: 18px;
  padding: 7px 0px; }

label {
  font-weight: 500;
  font-size: 14px; }

.top-links-toggle {
  display: none; }

.top-links .links-item:first-child::before {
  display: none; }

.top-links .links-item {
  padding-left: 30px;
  position: relative;
  color: #7a7a7a; }

.top-links .links-item:hover {
  color: #f6d000; }

.top-links .links-item::before {
  content: "\\";
  position: absolute;
  left: 12px;
  top: -2px;
  color: #7a7a7a; }

.input-group .input-group-addon {
  font-size: 13px;
  height: 30px; }

.blog_page .blog_top_content {
  float: left;
  width: 100%; }

.blog_page .blog_top_content .blog-image {
  float: left;
  position: relative; }

.quickview-container #content .col-sm-6.product-right {
  margin: 0px 0;
  width: 50%; }

.mfp-iframe-scaler iframe {
  background: #fff !important; }

#content .col-sm-6.product-right {
  margin-left: 0; }

/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
  display: inline; }

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  color: #000; }

.pagination > li > a, .pagination > li > span {
  border-color: #e8e9eb !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #7a7a7a;
  padding: 7px 12px;
  height: 35px;
  width: 35px; }

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  background: #fbb50d none repeat scroll 0 0 !important;
  border: 1px solid #fbb50d !important;
  color: #ffffff !important; }

div.required .control-label:before {
  content: '* ';
  color: #F00;
  font-weight: bold; }

.banners-slider-carousel .customNavigation {
  top: 11px;
  right: 30px; }

/* counter*/
.product-block:hover .countdown {
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0; }

.countdown {
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  left: 10px;
  padding: 0;
  position: absolute;
  right: 10px;
  text-align: center;
  bottom: 10px;
  vertical-align: top;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s;
  top: auto; }

.countbox .timebox {
  margin: 0 4px 0 0;
  display: inline-block;
  position: relative;
  background: #fff;
  height: 40px;
  min-width: 43px;
  overflow: hidden;
  border-radius: 5px;
  text-align: center; }

.countbox .timebox:last-child::after {
  display: none; }

.countbox.hastime {
  width: auto;
  border-radius: 25px;
  padding: 2px 6px; }

.countbox .second .timebox-inner::after {
  display: none; }

.countbox .timebox .time {
  padding: 0;
  color: #777;
  display: block;
  text-align: center;
  margin: -23px 0 0 0;
  font-size: 10px; }

.count-down .countbox strong {
  display: block;
  font-size: 13px;
  padding: 0 0;
  font-weight: 500;
  text-align: center;
  height: 40px;
  line-height: 25px;
  color: #111;
  margin: 0; }

.timebox.second span.timebox-inner strong {
  color: #fbb50d; }

.countbox .timebox:last-child {
  margin: 0; }

.product-content .count-down .countbox strong {
  font-size: 16px; }

.box.special .product-block:hover .product-details .quickbox,
.box.special .product-block:hover .product_hover_block button.compare_button {
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  transform: translateX(0); }

/* end of counter*/
.special-tag {
  font-weight: 400;
  position: absolute;
  top: 20px;
  left: 10px;
  text-transform: inherit;
  padding: 0 8px;
  background: #d32122;
  font-size: 10px;
  color: #fff;
  border-radius: 25px;
  line-height: 20px; }

.stock_status {
  text-transform: uppercase;
  color: #f00;
  position: absolute;
  top: 41%;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  padding: 10px 10px;
  box-shadow: 0 0px 5px 0px #ddd; }

.outstock .img-responsive.hover-image {
  display: none !important; }

.outstock .img-responsive {
  filter: opacity(30%); }

.footer-blocks .col-sm-3 {
  float: right;
  width: 20%; }

.footer-area ul li {
  display: inline-block; }

#block_1 .list-unstyled li:first-child {
  margin: 0 10px 0 0; }

.listing {
  min-height: 798px;
  display: block;
  text-align: center;
  font-size: 20px;
  padding-top: 50%;
  font-weight: 500;
  text-transform: inherit; }

.listing::before {
  content: "\f071";
  position: absolute;
  font-family: fontawesome;
  margin-top: -50px;
  font-size: 35px;
  left: 45%; }

/* mouse grab icon */
.grabbing {
  cursor: url(../stylesheet/grabbing.png) 8 8, move; }

/* OpenCart Code */
#paysocialcmsblock {
  float: right;
  width: 30%; }

.social-block {
  float: left;
  width: 100%; }

.col-sm-3.column.footerleft {
  width: 100%;
  float: left;
  padding: 0; }

.payment-block {
  float: right; }

.social-block ul li {
  float: left; }

.social-block ul li a {
  width: 40px;
  height: 40px;
  float: left;
  background: transparent;
  outline: none;
  color: #fff;
  text-align: center; }

.footer-blocks .footerleft .social-block ul li a .fa {
  color: #999;
  font-size: 18px; }

.social-block ul li a .fa {
  font-size: 17px;
  line-height: 40px;
  float: left;
  height: 100%;
  width: 100%; }

.social-block ul li:last-child a {
  margin: 0; }

.social-block ul li a:hover, .social-block ul li a:active, .social-block ul li a:focus {
  color: #fbb50d; }

#paysocialcmsblock ul {
  padding: 0;
  margin: 0;
  float: right; }

.main-slider .swiper-viewport:hover .swiper-button-next {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  right: 20px; }

.main-slider .swiper-viewport:hover .swiper-button-prev {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  left: 20px; }

#form-currency .currency-select {
  text-align: left;
  width: 100%;
  text-transform: inherit; }

.dropdown-menu-right {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -khtml-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  box-shadow: none; }

.language-menu .btn.btn-link.btn-block.language-select {
  text-transform: inherit; }

#form-language .dropdown-menu {
  border: none;
  box-shadow: none;
  margin: 2px 0 0;
  padding: 10px 10px 12px;
  right: auto;
  top: 19px;
  left: 10px; }

.dropdown-menu.currency-menu {
  border: none;
  box-shadow: none;
  margin: 2px 0 0;
  padding: 10px 10px 12px;
  right: auto;
  top: 19px;
  left: 10px; }

.lang-curr-wrapper .language-title, .lang-curr-wrapper .currency-title {
  font-weight: 600;
  padding: 3px 20px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer; }

.dropdown-menu.currency-menu .fa.fa-angle-down, .dropdown-menu.language-menu .fa.fa-angle-down {
  display: none; }

.productpage .special-price {
  font-size: 40px;
  display: inline-block;
  font-weight: 600;
  line-height: 20px;
  color: #111; }

.productpage .description {
  line-height: 40px;
  font-size: 20px;
  margin-bottom: 10px;
  padding: 15px 5px 20px 0;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea; }

.product-description td span {
  font-weight: 500;
  padding: 2px; }

.description {
  color: #000; }

.description a {
  color: #0089fa; }

.description a:hover {
  color: #75bdf8 !important; }

.product-description td {
  padding: 2px; }

.productpage .description .product-description .description-right {
  padding-left: 50px; }

#form-language .dropdown-menu > li > .btn {
  padding: 5px;
  color: #000; }

#form-currency .dropdown-menu .btn {
  padding: 6px !important;
  color: #333333; }

#form-language .dropdown-menu > li > .btn:hover, #form-currency .dropdown-menu .btn:hover {
  color: #111111;
  background: transparent; }

.box-content-category ul {
  padding: 0;
  margin: 0; }

.content_headercms_top #nav-one a {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase; }

.box-content-category a {
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
  font-weight: 400;
  width: 95%;
  color: #111;
  position: relative;
  padding: 11px 35px 11px 20px; }

.box-content-category ul li a:before {
  content: "";
  background-image: url(../image/webdigify/cat-icon.png);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s; }

.box-content-category ul li.main.menu-1 a:before {
  background-position: 16px 10px; }

.box-content-category ul li.main.menu-1 a:hover::before {
  background-position: 16px -31px; }

.box-content-category ul li.main.menu-2 a::before {
  background-position: 16px -70px; }

.box-content-category ul li.main.menu-2 a:hover::before {
  background-position: 16px -108px; }

.box-content-category ul li.main.menu-3 a::before {
  background-position: 16px -146px; }

.box-content-category ul li.main.menu-3 a:hover::before {
  background-position: 16px -186px; }

.box-content-category ul li.main.menu-4 a::before {
  background-position: 16px -227px; }

.box-content-category ul li.main.menu-4 a:hover::before {
  background-position: 16px -267px; }

.box-content-category ul li.main.menu-5 a::before {
  background-position: 15px -308px; }

.box-content-category ul li.main.menu-5 a:hover::before {
  background-position: 15px -348px; }

.box-content-category ul li.main.menu-6 a::before {
  background-position: 15px -386px; }

.box-content-category ul li.main.menu-6 a:hover::before {
  background-position: 15px -422px; }

.box-content-category ul li.main.menu-7 a::before {
  background-position: 15px -457px; }

.box-content-category ul li.main.menu-7 a:hover::before {
  background-position: 15px -494px; }

.box-content-category ul li.main.menu-8 a::before {
  background-position: 15px -532px; }

.box-content-category ul li.main.menu-8 a:hover::before {
  background-position: 15px -571px; }

.box-content-category ul li.main.menu-9 a::before {
  background-position: 15px -611px; }

.box-content-category ul li.main.menu-9 a:hover::before {
  background-position: 15px -652px; }

.box-content-category ul li.main.menu-10 a::before {
  background-position: 14px -688px; }

.box-content-category ul li.main.menu-10 a:hover::before {
  background-position: 14px -723px; }

.box-content-category a:hover,
.box-content-category a:focus {
  color: #fbb50d; }

.box-content-category li.top_level:after {
  content: "";
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  margin: 0 auto;
  top: auto;
  width: 85%; }

#review-count {
  cursor: pointer; }

.site-map li {
  list-style: inherit;
  line-height: 28px; }

.site-map ul {
  padding: 0 0 0 20px; }

#review-count:hover {
  text-decoration: underline; }

/* #nav-one .dropdown a::after, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown a::after {
	content: "\f105";
	right: 20px;
	position: absolute;
	font-family: fontawesome;
	font-size: 16px;
	display: inline-block;
} */
#nav-one li .megamenu ul.list-unstyled > li.dropdown > a::after, #nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a:after, #nav-one .hiden_menu .dropdown-menu a:after {
  display: none; }

.header_cart .cart-menu .text-left a {
  font-size: 13px;
  font-weight: 500; }

.header_bottom .row {
  position: relative; }

#nav-one > li > .dropdown-menu.megamenu.column1, #nav-one .hiden_menu .dropdown-menu {
  padding: 20px 0 20px 20px;
  width: auto;
  height: auto;
  min-width: 200px;
  top: 0;
  left: 271px;
  border-radius: 0; }

.box-content-category ul li.top_level a {
  text-transform: uppercase; }

.quickview .header-container {
  display: none; }

/* header cms start*/
.content_headercms_top {
  float: left;
  width: 16%;
  margin-top: 5px;
  text-align: left; }

.content_header_topleft {
  padding: 0;
  position: relative;
  text-transform: uppercase;
  font-weight: 500; }

.content_header_top .single-banner {
  margin: 0; }

.static_links {
  list-style: outside none none;
  margin: 0;
  padding: 2px 0 0 0;
  padding-left: 8px; }

.static_links li {
  position: relative;
  padding: 0;
  float: left; }

.static_links .hiden_menu .top_level > a {
  padding: 10px 20px !important;
  margin-right: 0; }

.childs_2 {
  width: auto !important; }

.static_links li .megamenu li.list-unstyled.childs_1 li {
  padding: 0; }

#content .col-sm-6 h2 {
  font-size: 16px; }

.static_links .myaccount ul {
  height: auto;
  border: 1px solid #ebebeb;
  padding: 10px 5px; }

.static_links ul.nav li .megamenu.column1 ul.childs_1 {
  border: medium none; }

.static_links ul.nav li .megamenu.column1 ul.list-unstyled li > a {
  color: #000;
  display: inline-block;
  font-size: 14px;
  padding: 8px 7px !important;
  position: relative;
  text-transform: inherit;
  font-weight: 400; }

.static_links ul.nav li .megamenu.column1 ul.list-unstyled li > a:hover {
  color: #111111; }

.navbar-nav > li > .dropdown-menu {
  padding: 15px 0 15px 15px;
  top: 56px; }

.static_links .dropdown-menu .list-unstyled.childs_1 > li {
  padding-bottom: 0; }

.static_links ul {
  text-align: left; }

.static_links .cart-menu > li {
  padding: 0; }

.header-cart .dropdown-menu {
  border: 1px solid #ebebeb;
  min-width: 280px;
  padding: 10px;
  top: 53px; }

.dropdown-menu-right {
  left: auto;
  right: 0;
  padding: 10px 0 10px 0;
  margin-top: 24px !important; }

.static_links ul li ul {
  display: table-cell;
  float: none; }

.nav .navbar-nav .top-level.dropdown li a:hover {
  display: inline-block; }

.static_links li.hiden_menu div {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  display: none;
  min-width: 155px;
  padding: 15px 0;
  position: absolute;
  text-align: left;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
  -khtml-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  top: 56px; }

.static_links li.hiden_menu:hover div {
  display: block;
  color: #767676; }

.static_links li.hiden_menu div li a::before {
  background: none; }

.static_links li.hiden_menu .top_level a:hover {
  background: none; }

.static_links .dropdown-inner .top_level li a::before {
  background: none; }

.static_links li a::before {
  content: "";
  display: block;
  height: 13px;
  position: absolute;
  right: 7px;
  border-left: 1px solid #ededed; }

.static_links .dropdown.myaccount li a::before {
  background: none; }

.login_acc {
  display: inline-block;
  padding: 0px;
  position: relative;
  width: 100%; }

.lang-curr-wrapper .language-title .fa.fa-caret-down {
  float: right;
  padding: 5px 10px; }

.lang-curr-wrapper .currency-title .fa.fa-caret-down {
  float: right;
  padding: 5px 10px; }

.lang-curr-wrapper .language-title:hover {
  color: #111111; }

.lang-curr-wrapper .currency-title:hover {
  color: #111111; }

.login_acc li {
  padding: 3px 40px;
  position: relative;
  text-align: left; }

.login_acc li::after {
  content: " ";
  position: absolute;
  right: auto;
  top: 10px;
  left: 15px;
  background: rgba(0, 0, 0, 0) url("../image/webdigify/myaccountArrow.png") no-repeat scroll center 0;
  width: 10px;
  height: 10px; }

.login_acc li:hover::after {
  background-position: center -56px; }

.checkout:hover {
  color: #111111; }

.myaccount .account:hover {
  background: none;
  color: #000 !important;
  border: none;
  font-size: 12px;
  margin: 0;
  padding: 0; }

.drop_account {
  color: #000000; }

.myaccount .account {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #111111;
  color: #111111;
  float: left;
  margin: 5px;
  padding: 8px 18px;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  font-weight: 400; }

.static_links .dropdown.myaccount li a:hover {
  background: none;
  color: #111111; }

.static_links li:first-child a::before {
  background: none; }

.static_links li a {
  color: #000;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 10px;
  margin: 1px 11px 0px 0;
  padding: 15px 20px !important;
  position: relative;
  text-transform: inherit;
  z-index: 6; }

.list-unstyled.childs_2 > li {
  padding: 0;
  width: 100%;
  margin: 0 0 0 0; }

.static_links li:last-child a {
  padding-right: 8px; }

.btn-link:hover, .btn-link:focus {
  color: #111111 !important; }

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background: none; }

#nav-one > li.top_level.dropdown .list-unstyled.childs_1 li a::before {
  background: none; }

#top-links li {
  padding: 0;
  list-style: none; }

header .btn-link:hover, header .btn-link:focus, #top-links a:hover, #top-links a:focus {
  color: #fff;
  background: none;
  text-decoration: none; }

#top-links .dropdown-menu a {
  text-shadow: none; }

#top-links .dropdown-menu a:hover, #top-links .dropdown-menu a:focus {
  color: #2196f3; }

#top .btn-link strong {
  font-size: 14px;
  font-weight: normal; }

#top-links {
  padding-top: 0px; }

#top-links a + a {
  margin-left: 15px; }

.dropdown.myaccount:hover a.account::after, .dropdown.myaccount:hover a.account1::after, .dropdown.myaccount:hover a.account_order1::after, .dropdown.myaccount:hover a.account_transaction1::after, .dropdown.myaccount:hover a.account_download1::after, .dropdown.myaccount:hover a.account_logout1::after {
  display: none; }

.swiper-viewport .swiper-button-next {
  left: auto;
  right: 40px;
  width: 60px;
  height: 60px;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

.swiper-viewport .swiper-button-prev {
  right: auto;
  left: 40px;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

#top-links ul.list-inline {
  float: left;
  margin-right: 5px;
  margin-bottom: 0; }

#top-links ul.list-inline li {
  background: transparent;
  font-weight: 300; }

#top-links #form-language li {
  padding: 0; }

.lang-curr {
  margin: 0;
  display: inline-block; }

.container .container-top a {
  color: #000; }

.nav.pull-right {
  display: inline-block;
  padding: 0; }

.container .result-search-autocomplete ul li {
  float: left; }

.right-links ul li {
  float: left;
  margin-left: 10px;
  font-size: 12px; }

.container .container-top a:hover {
  color: #111111; }

.right-myaccount-menu > li #wishlist-total {
  border-right: 1px solid;
  padding: 0 10px 0 0; }

.right-links .right-myaccount-menu ul li {
  margin-right: 10px; }

.content-top {
  float: left;
  width: 100%; }

#form-language .fa-angle-down, #form-currency .fa-angle-down {
  float: none;
  display: inline-block;
  vertical-align: middle;
  color: #111;
  width: auto;
  padding-left: 5px; }

#form-currency:hover .btn-link.dropdown-toggle, #form-language:hover .btn-link.dropdown-toggle {
  margin: 0px; }

#form-currency .btn-link, #form-language .btn-link {
  color: #111;
  font-weight: normal;
  font-size: 13px;
  padding: 0;
  line-height: 22px;
  letter-spacing: 0.6px;
  border: 0px; }

.nav2 {
  float: left;
  width: 100%;
  text-align: center; }

.lang-curr-wrapper {
  float: right; }

#form-language .btn-link.btn-block.language-select,
#form-currency .currency-select {
  color: #666; }

.language-menu li {
  text-align: left;
  padding: 2px 0; }

.wd-currency ul.currency-menu li {
  padding: 2px 0; }

#form-currency .btn-link strong {
  font-weight: normal;
  padding: 0 3px 0 0;
  font-size: 13px; }

.pull-left #form-language .btn-group span img {
  padding: 0px 8px 0 0; }

.dropdown.myaccount {
  float: left;
  position: relative;
  margin: 5px 0 5px 10px;
  width: 40px;
  height: 40px; }

.myaccount > a {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -314px;
  display: block;
  transition: all 600ms ease-in-out 0s;
  -webkit-transition: all 600ms ease-in-out 0s;
  -moz-transition: all 600ms ease-in-out 0s;
  -o-transition: all 600ms ease-in-out 0s;
  -ms-transition: all 600ms ease-in-out 0s; }

.myaccount > a:hover {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg); }

.language-menu,
.currency-menu {
  padding: 10px 15px;
  display: none;
  position: absolute;
  background: #fff;
  border: 1px solid #eaeaea;
  top: 40px;
  left: auto;
  right: 0px;
  min-width: 160px;
  z-index: 1000;
  margin: 0px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

.header_top {
  width: 100%;
  padding: 25px 0;
  float: left; }

.header_bottom {
  width: 100%;
  background: #020202;
  padding: 0px;
  z-index: 1000;
  clear: both; }

.common-home .header_bottom {
  border-bottom: none; }

.content_headercms_bottom {
  clear: both;
  float: left;
  text-align: center;
  width: 100%; }

.header-right .head {
  float: right;
  margin: 5px 5px 0 0;
  padding: 2px; }

.header-center {
  float: left;
  padding: 10px 0 16px 0;
  width: 100%; }

/**************Start Contact Link***********/
.category_list.box .category_img {
  width: 80px;
  float: left; }

#_desktop_contact_link {
  float: left;
  padding: 5px 0px; }

span.service-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #111; }

span.content a.contact-info {
  color: #111111;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0; }

#_desktop_contact_link .wdicon {
  height: 30px;
  width: 30px;
  float: left;
  margin: 0 10px 0 0;
  text-align: center;
  font-size: 0;
  background: rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 0px 0px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#_desktop_contact_link span.content {
  float: left;
  padding: 4px 0px; }

@media (max-width: 991px) {
  .header_top .header_right {
    display: none; }
  #_desktop_contact_link .wdicon {
    display: none; }
  span.content {
    width: 100%; }
  span.service-title {
    color: #111;
    display: inline-block;
    font-weight: 500;
    padding: 10px 0px;
    font-size: 14px; }
  span.content a.contact-info {
    padding: 0px;
    display: inline-block;
    color: #111111;
    font-size: 16px;
    margin: 0 0 0 10px; } }

/*************ENd Contact Link***************/
/* logo */
.header-logo {
  float: left; }

.header-cart {
  float: left; }

.header-main {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff; }

.static_links li:last-child a::before {
  border-left: none; }

.header-left {
  float: left;
  width: auto;
  margin: 0 79px 0 0; }

.static_links li:first-child a {
  padding-left: 5px !important; }

.header-right {
  float: right;
  width: 81%;
  position: relative;
  top: 0;
  transition: top 800ms ease 0s;
  -webkit-transition: top 800ms ease 0s;
  -moz-transition: top 800ms ease 0s;
  -ms-transition: top 800ms ease 0s;
  -o-transition: top 800ms ease 0s; }

header .tags > a {
  font-weight: normal; }

header {
  padding: 0;
  margin: 0;
  clear: both;
  border-radius: 0;
  height: auto; }

@-webkit-keyframes fixedmenu {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

@-moz-keyframes fixedmenu {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

@keyframes fixedmenu {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

.header_bottom.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin-top: -1px;
  animation: 1s ease 0s normal both 1 running fixedmenu; }

#checkout-checkout .text-right.total {
  font-size: 17px;
  font-weight: 600; }

a.banner_text::before, a.banner_text::after {
  background: none !important; }

.col-sm-4.header-logo {
  width: auto;
  padding: 0; }

.header_center {
  float: left;
  width: auto; }

.col-sm-3.sort {
  padding: 0; }

.col-sm-3.sort.category_dropdown span.customSelect {
  height: 38px;
  border-radius: 5px;
  padding: 8px 15px; }

#searchbox .col-sm-3.sort {
  padding: 0 15px; }

.col-sm-3.sort select.form-control {
  padding: 0;
  height: auto;
  text-transform: inherit;
  background-color: #fff;
  color: #333333;
  border: none;
  overflow: hidden;
  left: 0;
  width: 100% !important; }

.head-right-bottom {
  float: right;
  position: relative; }

/*header-cms*/
.quickview {
  padding: 15px 0;
  display: inline-block; }

.quickview .container {
  width: 100%;
  background: #FFFFFF;
  padding: 0 10px; }

.quickview .container .row {
  margin: 0; }

.quickview .top_button {
  display: none; }

.quickview #product2 {
  float: left;
  width: 100%; }

.quickview #product2 h3 {
  width: 100%; }

.productpage-quickview #product2 h3 {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  text-transform: none;
  color: #333333; }

.menu_toggle {
  background: #ffffff none repeat scroll 0 0;
  display: none;
  left: 0;
  position: absolute;
  top: 62px;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px; }

/* search */
.col-sm-5.header_search {
  float: none;
  width: auto;
  display: inline-block;
  vertical-align: top;
  padding: 5px 0; }

#searchbox {
  position: relative;
  float: left;
  width: 100%;
  border-radius: 30px; }

.search_box {
  float: left;
  position: relative; }

.search_box .form-control-select {
  height: 40px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: 1px solid #eaeaea;
  padding: 5px 4px 5px 17px;
  border-radius: 5px 0 0 5px;
  font-size: 13px;
  color: #666;
  line-height: 1.25;
  letter-spacing: 0.6px;
  width: 160px; }

.search_box:after {
  /* content: ""; */
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: -11px;
  top: 0;
  z-index: 1;
  cursor: pointer;
  bottom: 0;
  margin: auto;
  height: 20px;
  width: 20px;
  background: #fbb50d;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 16px;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 8px black; }

.header_search .btn.btn-default.btn-lg .fa.fa-search:before {
  content: "\f002";
  font-size: 14px;
  padding-right: 7px; }

.header_search .form-control.input-lg {
  height: 40px;
  width: 280px;
  padding: 8px 15px 8px 18px;
  font-size: 13px;
  color: #666;
  display: block;
  border-left: 2px solid #eaeaea; }

.header_search .input-group-btn {
  width: auto; }

#searchbox .input-group input {
  font-size: 12px; }

.header_search .btn.btn-default.btn-lg {
  border: none;
  text-transform: inherit;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.6px;
  padding: 11px 24px 11px 24px;
  border-radius: 0 5px 5px 0px;
  height: 40px;
  line-height: 18px;
  background: #fbb50d;
  color: #ffffff; }

/* end of search */
/* end header top block */
/****************Start Cms Top Banner********************/
#wdcmstopbanner {
  float: left;
  width: 100%;
  margin: 0px 0 35px; }

#wdcmstopbanner .wdcmstopbanner {
  margin: 0 -15px; }

#wdcmstopbanner .one-third {
  padding: 0px 15px;
  float: left;
  width: 33.33%;
  position: relative; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  text-transform: inherit;
  color: #111111;
  margin: 0 0 10px;
  font-family: 'Poppins',Helvetica,sans-serif; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  color: #111111;
  margin: 0 0 20px;
  font-family: 'Poppins',Helvetica,sans-serif; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
  background: #020202;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-transform: inherit;
  padding: 0 12px;
  border-radius: 5px;
  margin: 0 0 10px; }

#wdcmstopbanner .wdtopbanner .wdright-banner-content {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
  text-align: left;
  position: absolute;
  left: 30px; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
  display: inline-block;
  font: 500 14px/20px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  background: #fbb50d;
  color: #fff;
  padding: 9px 20px;
  position: relative;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -khtml-border-radius: 25px;
  vertical-align: top;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s;
  z-index: 1;
  overflow: hidden; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:hover {
  color: #ffffff; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:hover:before {
  height: 100%; }

#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:before {
  content: "";
  position: absolute;
  background: #020202;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#wdcmstopbanner .wdtopbanner .wdright-banner-content > a {
  display: block;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#wdcmstopbanner .wdtopbanner-img2.wdtopbanner .wdtopbanner-content .title {
  color: #111; }

#wdcmstopbanner .wdtopbanner-img2.wdtopbanner .wdtopbanner-content .toptitle {
  color: #111; }

#wdcmstopbanner .wdtopbanner:hover .wdright-banner-content > a img {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04); }

#wdcmstopbanner .wdtopbanner .wdright-banner-content > a img {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  max-width: 100%; }

@media (max-width: 1199px) {
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    left: 20px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
    margin: 0 0 5px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 10px; } }

@media (max-width: 991px) {
  #wdcmstopbanner {
    margin: 0px 0 25px; } }

@media (max-width: 991px) {
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    left: 15px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
    font-size: 10px;
    line-height: 18px;
    padding: 0 8px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
    font-size: 12px;
    line-height: 16px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 6px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px; } }

@media (max-width: 767px) {
  #wdcmstopbanner .one-third {
    padding: 0px 10px; } }

@media (max-width: 767px) and (min-width: 544px) {
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    background: none;
    color: #fbb50d;
    text-decoration: underline; } }

@media (max-width: 677px) and (min-width: 544px) {
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
    display: none; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 5px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px; } }

@media (max-width: 543px) {
  #wdcmstopbanner {
    margin: 0px 0 15px; }
  #wdcmstopbanner .one-third.topbanner-part1 {
    padding-top: 0px; }
  #wdcmstopbanner .one-third {
    padding: 15px 10px 0px;
    width: 100%; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
    font-size: 12px;
    line-height: 22px;
    margin: 0 0 8px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 15px; }
  #wdcmstopbanner .wdtopbanner .wdright-banner-content {
    float: none;
    width: auto;
    position: relative;
    display: inline-block;
    text-align: center; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    left: 9%;
    top: 27%; }
  #wdcmstopbanner .wdcmstopbanner {
    text-align: center; } }

@media (max-width: 480px) {
  #wdcmstopbanner {
    margin: 0px 0 0px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    left: 5%;
    top: 23%; } }

@media (max-width: 380px) {
  #wdcmstopbanner .one-third {
    padding: 15px 10px 0px 10px; }
  #wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    top: 17%; } }

/********************End Cms Top Bannner***********************/
.footer-middle {
  background: #f1f4fa;
  float: left;
  width: 100%; }

/********** End Testimonial Cms BLock  **********/
/********************Start Cms Sub Banner**********************/
#wdcmssubbanner {
  float: left;
  width: 100%;
  margin: 35px 0;
  text-align: center; }

#wdcmssubbanner .wdcmssubbanner .title {
  font: 400 22px/28px 'Rubik',Helvetica,sans-serif;
  color: #ffffff;
  margin: 0 0 10px; }

#wdcmssubbanner .wdcmssubbanner .desc {
  font: 500 36px/40px 'Rubik',Helvetica,sans-serif;
  color: #fff;
  margin: 0 0 20px; }

#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content {
  display: flex;
  align-items: center;
  position: relative; }

#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content .subbanner-content {
  position: absolute;
  left: 14%; }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn {
  padding: 0; }

#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content a {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content img {
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  max-width: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#wdcmssubbanner .wdcmssubbanner:hover .wdright-wdsubbanner-content img {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04); }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
  background: #fbb50d;
  color: #fff;
  padding: 9px 20px;
  display: inline-block;
  font: 500 14px/22px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -khtml-border-radius: 25px;
  vertical-align: top;
  z-index: 1; }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover {
  color: #111111; }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:after {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0; }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover:after {
  height: 100%; }

#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:after {
  background: #fff; }

@media (max-width: 1499px) {
  #wdcmssubbanner {
    margin: 0px 0 40px; } }

@media (max-width: 1400px) {
  .header_right {
    display: none; } }

@media (max-width: 991px) {
  #wdcmssubbanner {
    margin: 30px 0 25px; }
  #wdcmssubbanner .wdcmssubbanner .title {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 5px; }
  #wdcmssubbanner .wdcmssubbanner .desc {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 10px; }
  #wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
    font-size: 13px;
    line-height: 16px;
    padding: 6px 12px; } }

@media (max-width: 767px) and (min-width: 544px) {
  #wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    background: none;
    color: #fbb50d;
    text-decoration: underline; } }

@media (max-width: 677px) {
  #wdcmssubbanner {
    padding: 0 0px; }
  #wdcmssubbanner .wdcmssubbanner .title {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px; }
  #wdcmssubbanner .wdcmssubbanner .desc {
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 5px; } }

@media (max-width: 543px) {
  #wdcmssubbanner .wdcmssubbanner .title {
    display: none; }
  #wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content .subbanner-content {
    position: static;
    margin: 15px 0 0px;
    background: #f5f5f5;
    padding: 20px 10px;
    float: left;
    width: 100%; }
  #wdcmssubbanner .wdcmssubbanner .desc {
    font-size: 14px;
    line-height: 18px;
    color: #222; }
  #wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
    background: #020202;
    color: #fff; }
  #wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content {
    display: block; }
  #wdcmssubbanner {
    margin: 10px 0; }
  #wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover {
    background: #fbb50d;
    color: #111; } }

/********************End Cms Sub Banner*****************************/
/***********************Start Cms Bottom Banner*******************/
#wdcmsbottombanner {
  float: left;
  width: 100%;
  margin: 15px 0 40px; }

#wdcmsbottombanner .wdcmsbottombanner {
  margin: 0 -15px; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content {
  display: flex;
  align-items: center;
  position: relative; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content .wdbottombanner-content {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-img1.wdbottombanner .wdbottombanner-content.left {
  right: 15%;
  left: auto; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
  font: 400 16px/20px 'Rubik',Helvetica,sans-serif;
  color: #111;
  margin: 0 0 15px; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
  font: 500 30px/38px 'Rubik',Helvetica,sans-serif;
  color: #111;
  margin: 0 0 20px; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
  display: inline-block;
  font: 500 14px/20px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  background: #020202;
  color: #ffffff;
  vertical-align: top;
  padding: 10px 21px;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s;
  z-index: 1;
  position: relative;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -khtml-border-radius: 25px; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:hover {
  color: #ffffff;
  background: #fbb50d; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:before {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:hover:before {
  height: 100%; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content img {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  max-width: 100%; }

#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content a {
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#wdcmsbottombanner .wdbottombanner:hover .wdbottom-banner-content img {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04); }

#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content .wdbottombanner-content .label {
  font: 500 14px/24px 'Rubik',Helvetica,sans-serif;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  padding: 0 12px;
  text-transform: inherit;
  color: #111;
  margin: 0 0 15px; }

@media (max-width: 991px) {
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
    font-size: 14px;
    line-height: 18px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
    font-size: 26px;
    line-height: 30px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 18px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-img1.wdbottombanner .wdbottombanner-content.left {
    right: 8%;
    left: auto; } }

@media (max-width: 991px) {
  #wdcmsbottombanner {
    margin: 25px 0 20px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 10px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 15px; } }

@media (max-width: 767px) {
  .wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
    width: 50%; }
  #wdcmsbottombanner .wdcmsbottombanner {
    margin: 0px; }
  #wdcmsbottombanner .container {
    padding: 0px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    background: no-repeat;
    color: #fbb50d;
    text-decoration: underline; } }

@media (max-width: 677px) {
  #wdcmssubbanner .container {
    padding: 0 10px; }
  .wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
    padding: 0 10px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 5px; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 10px; } }

@media (max-width: 543px) {
  .wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
    width: 100%; }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content {
    padding: 10px 0px; }
  #wdcmsbottombanner {
    margin: 10px 0 8px; } }

/************************End Cms Bottom Banner*********************/
/********************Start Cms Sub Banner**********************/
.wdcmsbanner {
  float: left;
  width: 100%;
  overflow: hidden; }

.banners {
  position: relative;
  vertical-align: top;
  margin: 30px -15px 0px; }

.banners .container {
  position: relative; }

.owl-wrapper-outer {
  float: left; }

.banners .products .owl-stage-outer {
  padding: 0; }

.banners .brand-image {
  text-align: center;
  float: none;
  width: auto;
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  height: 80px; }

#wdcmsbanner-carousel .item {
  float: left;
  width: 100%;
  padding: 0 15px; }

.offer-desc > div {
  float: left;
  width: 100%; }

.banners #wdcmsbanner-carousel .cmsbanner-title-desc .offer-img a {
  float: left;
  width: 100%;
  overflow: hidden;
  border: 3px solid #ffffff;
  border-radius: 5px; }

.banners #wdcmsbanner-carousel .cmsbanner-inner:hover .cmsbanner-title-desc .offer-img img {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04); }

.banners #wdcmsbanner-carousel .cmsbanner-title-desc .offer-img img {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  overflow: hidden;
  max-width: 100%; }

.banners #wdcmsbanner-carousel .brand-title a:hover {
  text-decoration: underline; }

.banners #wdcmsbanner-carousel .brand-title {
  font: 500 14px/18px 'Rubik',Helvetica,sans-serif;
  margin: 2px 0 15px;
  float: left;
  width: 100%; }

.banners #wdcmsbanner-carousel .cmsbanner-inner {
  float: left;
  width: 100%;
  padding: 12px;
  background: #f1f4fa;
  margin: 0 0 30px; }

.banners #wdcmsbanner-carousel .brand-title a {
  color: #df1612; }

.banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn:hover {
  text-decoration: underline; }

.banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn::before {
  content: '+';
  margin: 0 3px 0 0; }

.banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn {
  padding: 0;
  background: none;
  font: 500 14px/18px 'Rubik',Helvetica,sans-serif;
  color: #111111; }

.banners #wdcmsbanner-carousel .cmsbanner-desc .offer-title {
  font: 400 13px/21px 'Rubik',Helvetica,sans-serif;
  margin: 15px 0 10px;
  color: #111111; }

.banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
  font: 600 20px/28px 'Rubik',Helvetica,sans-serif;
  color: #111111;
  margin: 0 0 12px; }

.banners .products-section-title a {
  color: #111111; }

.banners #wdcmsbanner-carousel {
  float: left;
  width: 100%;
  margin: 0;
  position: relative; }

.banners #wdcmsbanner-carousel {
  float: left;
  text-align: center;
  width: 100% !important; }

.banners #wdcmsbanner-carousel .brand-image img {
  max-width: 100%;
  transition: all 500ms ease 0s;
  -webkit-transition: all 500ms ease 0s;
  -moz-transition: all 500ms ease 0s;
  -o-transition: all 500ms ease 0s;
  -ms-transition: all 500ms ease 0s;
  width: auto; }

.banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc .offer-img {
  padding: 0 0 20px; }

.banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc {
  float: left;
  width: 100%;
  padding: 0px 0 13px; }

@media (max-width: 1199px) {
  .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
    font-size: 20px;
    line-height: 24px; } }

@media (max-width: 991px) {
  .banners {
    margin: 10px 0; }
  .banners #wdcmsbanner-carousel .cmsbanner-inner {
    margin: 0 0 20px; } }

@media (max-width: 630px) {
  .banners #wdcmsbanner-carousel .brand-title {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 10px; }
  .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 5px; }
  .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn {
    font-size: 12px;
    line-height: 16px; }
  .banners #wdcmsbanner-carousel .cmsbanner-inner {
    padding: 8px; }
  .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc {
    padding: 0px 0 7px; }
  .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc .offer-img {
    padding: 0 0 15px; } }

@media (max-width: 543px) {
  .banners #wdcmsbanner-carousel {
    padding: 0 5px; }
  .banners #wdcmsbanner-carousel .cmsbanner-inner {
    margin: 0 0 10px; }
  .banners .row {
    margin: 0 -5px; }
  .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
    font-size: 14px;
    line-height: 18px; }
  .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-title {
    font-size: 12px;
    line-height: 16px; } }

@media (max-width: 479px) {
  #wdcmsbanner-carousel .item {
    padding: 0 5px; } }

/********************End Cms Sub Banner*****************************/
/************************Start Category List Cms********************/
.category_list_carousel.owl-carousel.owl-theme {
  margin: 30px 0px; }

.category_list.box {
  float: left;
  width: 100%; }

.category_list.box .category_img img {
  border-radius: 50%;
  transition: all 600ms ease-in-out 0s;
  -webkit-transition: all 600ms ease-in-out 0s;
  -moz-transition: all 600ms ease-in-out 0s;
  -o-transition: all 600ms ease-in-out 0s;
  -ms-transition: all 600ms ease-in-out 0s;
  margin-bottom: 5px; }

.category_list_inner {
  text-align: left;
  float: left;
  width: 60%;
  margin: 0;
  padding: 20px 0 0 15px; }

.category_list_inner ul.subcategory {
  margin: 0;
  float: left;
  width: 100%;
  position: relative; }

.category_list_inner a {
  font: 600 13px/17px 'Rubik',Helvetica,sans-serif;
  margin: 0;
  position: relative;
  text-transform: inherit;
  padding: 0;
  color: #222;
  float: left;
  width: 100%;
  border: none;
  transition: 400ms ease-in-out all;
  -webkit-transition: 400ms ease-in-out all;
  -moz-transition: 400ms ease-in-out all;
  -o-transition: 400ms ease-in-out all;
  -ms-transition: 400ms ease-in-out all; }

.content-top .owl-controls.clickable {
  display: none; }

.category_list.box:hover .category_img img {
  transform: rotateY(360deg); }

.view a {
  color: #777;
  font: 400 12px/24px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  position: relative;
  padding: 0;
  display: inline-block; }

.view a:hover {
  text-decoration: underline; }

@media (max-width: 1499px) {
  .category_list_inner {
    width: 56%; } }

@media (max-width: 991px) {
  .category_list_inner {
    width: 49%; }
  .category_list.box {
    padding: 0 10px; } }

@media (max-width: 575px) {
  .category_list_inner {
    padding: 0px;
    width: 100%;
    text-align: center; }
  .category_list_carousel.owl-carousel.owl-theme {
    margin: 15px 0px; }
  .category_list.box .category_img {
    width: 100%;
    float: left; }
  .category_list.box {
    float: left;
    width: auto;
    display: block;
    text-align: center; } }

/************************End Category List Cms**********************/
/***********************Homepage Special Product********************/
#content .product-carousel .product-block-inner, #content .product-carousel-custom .product-block-inner, #content .box-product .product-block-inner, .category_tab .tab-pane .product-block-inner {
  text-align: center;
  overflow: visible;
  display: inline-block; }

.product-thumb .image {
  text-align: center;
  position: relative;
  overflow: hidden;
  background: #fff;
  float: left;
  width: 100%; }

.product-grid .product-thumb .image {
  float: none; }

.box.special .product-block .product-details {
  width: 100%;
  float: none;
  text-align: left;
  padding: 15px 0px 20px;
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.tabfeatured_default_width {
  width: 240px; }

.tabbestseller_default_width {
  width: 240px; }

.tablatest_default_width {
  width: 240px; }

.tabspecial_default_width {
  width: 240px; }

.featured_default_width {
  width: 240px; }

.tab-content .tab {
  display: none; }

.static_links ul.nav li .megamenu ul.list-unstyled li .dropdown-menu {
  border: medium none;
  margin: 0;
  padding: 0 0 0 0;
  position: relative; }

.static_links ul.nav li .megamenu ul.list-unstyled li.dropdown.dropdown-menu {
  border: 1px solid #000;
  margin: 0;
  padding: 10px;
  position: absolute;
  left: 188px;
  top: 0; }

.static_links ul.nav li .megamenu ul.list-unstyled li .dropdown-menu {
  display: block; }

.static_links ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
  border: 1px solid #d9d9d9;
  display: none;
  left: 165px;
  padding: 10px;
  position: absolute;
  top: 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px; }

.static_links ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown:hover .dropdown-menu {
  display: block; }

.main-slider .swiper-viewport {
  border: medium none;
  box-shadow: none;
  margin: 0;
  border-radius: 0; }

.swiper-viewport .swiper-button-prev::before, .swiper-viewport .swiper-button-next::before {
  font-size: 25px;
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  text-align: center;
  padding: 6px 0;
  display: inline-block;
  width: 60px;
  height: 60px;
  content: "\f104";
  font-family: "fontawesome";
  font-weight: 400;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #fbb50d;
  color: #ffffff;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

.swiper-viewport .swiper-button-next::before {
  content: "\f105"; }

.swiper-viewport .swiper-button-next:hover::before, .swiper-viewport .swiper-button-prev:hover::before {
  color: #fff;
  background-color: #020202; }

.main-slider .swiper-pagination {
  display: none; }

.main-slider.swiper-button-next, .main-slider.swiper-button-prev {
  width: 70px; }

.header-cart .cart .btn-lg .dropdown-toggle:before {
  content: '\f291';
  font-family: 'FontAwesome';
  font-size: 12px; }

#search .input-group-btn {
  float: right;
  width: auto;
  position: absolute;
  right: 0; }

#search .btn-lg {
  font-size: 12px;
  line-height: 18px;
  background: #7e5cdd;
  /* Old browsers */
  border: none;
  color: #ffffff !important;
  padding: 9px 30px 8px;
  height: 42px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -khtml-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  width: 100%; }

.show-result {
  overflow: hidden; }

.product-search #content > p {
  margin-top: 10px; }

header .dropdown.myaccount a .fa {
  background-color: transparent;
  padding: 10px 8px; }

header .header-cart a .fa {
  background: transparent; }

.header-container {
  height: auto;
  width: 100%;
  z-index: 99;
  background: #232f3e none repeat scroll 0 0; }

.header-container.smaller {
  z-index: 999; }

.header-logo img {
  display: inline-block;
  max-width: 100%; }

.content_header_top .box.webdigifycategory {
  left: 0;
  position: absolute;
  top: 0;
  width: 277px;
  z-index: 9; }

/*end webdigify category */
.header_right {
  width: auto;
  float: right;
  cursor: pointer;
  padding: 9px 0px; }

/* header top cms block*/
.offer-title {
  float: left;
  text-transform: inherit;
  color: #7a7a7a; }

.offer-content {
  display: flex;
  text-align: center; }

.offer-inner {
  text-align: center;
  padding: 12px 0; }

.offer_title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 18px;
  padding: 0 0 0 10px; }

/*-------------------------- Start Home page Service CMS Block -------------------------------*/
#wdservicecmsblock {
  clear: both;
  margin: 0;
  padding: 40px 0;
  float: left;
  width: 100%; }

#wdservicecmsblock .service_container {
  color: #FFF;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px auto; }

#wdservicecmsblock .service_container .service-area-carousel {
  padding: 0px;
  float: left;
  width: 100%; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth {
  float: left;
  width: 100%;
  line-height: 20px;
  color: #333333;
  padding: 0px 15px;
  position: relative; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 {
  padding-left: 0px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
  position: relative;
  background: url(../image/webdigify/service-icon.png) no-repeat scroll;
  float: left;
  margin-top: 0px;
  height: 60px;
  width: 65px;
  margin-right: 10px;
  -webkit-transition: all 600ms ease 0s;
  -moz-transition: all 600ms ease 0s;
  -o-transition: all 600ms ease 0s;
  transition: all 600ms ease 0s; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 .icon1 {
  background-position: -24px -24px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1:hover .icon1 {
  background-position: -133px -24px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 .icon2 {
  background-position: -24px -137px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2:hover .icon2 {
  background-position: -132px -137px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 .icon3 {
  background-position: -24px -244px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3:hover .icon3 {
  background-position: -131px -244px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 .icon4 {
  background-position: -24px -365px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4:hover .icon4 {
  background-position: -131px -365px; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111; }

#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-description {
  color: #777;
  margin-top: 0px;
  font-size: 14px;
  line-height: 24px; }

@media (max-width: 1499px) {
  #wdservicecmsblock .service_container .service-area-carousel {
    padding: 30px 22px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
    height: 60px;
    width: 60px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
    margin: 4px 0 0;
    float: left;
    width: 74%; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth {
    padding: 0px 0px; } }

@media (max-width: 1259px) and (min-width: 1200px) {
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 {
    padding-right: 0px; } }

@media (max-width: 1199px) {
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth:after {
    top: 25px;
    right: 5px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon:after {
    display: none; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 {
    padding-left: 0px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 {
    padding-right: 0px; }
  #wdservicecmsblock .service_container .service-area-carousel {
    padding: 40px 30px; } }

@media (max-width: 991px) {
  #wdservicecmsblock {
    margin: 0px 0px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth {
    padding-left: 10px;
    padding-right: 10px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth:after {
    border: 0; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1,
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 {
    margin-bottom: 0px;
    padding: 0; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3,
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 {
    padding: 0px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
    background-size: 180px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 .icon1 {
    background-position: -24px -19px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1:hover .icon1 {
    background-position: -112px -19px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 .icon2 {
    background-repeat: no-repeat;
    background-position: -14px -109px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2:hover .icon2 {
    background-position: -106px -109px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 .icon3 {
    background-size: 180px;
    background-repeat: no-repeat;
    background-position: -19px -197px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3:hover .icon3 {
    background-position: -106px -197px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 .icon4 {
    background-position: -19px -290px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4:hover .icon4 {
    background-size: 180px;
    background-repeat: no-repeat;
    background-position: -106px -297px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
    height: 50px;
    width: 55px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
    width: 72%;
    margin: 7px 0 0; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-description,
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-heading {
    font-size: 12px;
    line-height: 19px; }
  #wdservicecmsblock .service_container .service-area-carousel {
    padding: 10px 10px; } }

@media (max-width: 767px) {
  #wdservicecmsblock {
    padding: 20px 10px 20px; }
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
    width: 72%; }
  .service-area-carousel .service-fourth .service-icon {
    margin-right: 5px !important; }
  #wdservicecmsblock .service_container .service-area-carousel {
    padding: 4px 0px; } }

@media (max-width: 600px) {
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
    width: 74%; } }

@media (max-width: 450px) {
  #wdservicecmsblock .service_container .service-area-carousel .service-fourth {
    width: 100%; } }

@media (max-width: 399px) {
  #wdservicecmsblock {
    margin: 0px 0px; } }

/**************************End Home page Service CMS Block -------------------------------*/
/* CMS block */
.cmsbanner-block {
  margin: 30px 0 70px;
  float: left;
  position: relative;
  width: 100%; }

.cms-inner-block1, .cms-inner-block2 {
  position: relative;
  float: left;
  overflow: hidden;
  border-radius: 4px; }

.cms-inner-block1 {
  margin-right: 30px; }

.cms-inner-block1 .cms1_img .cms_image1, .cms-inner-block2 .cms2_img .cms2_image2 {
  max-width: 100%;
  width: 100%;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease; }

.cms-inner-block1:hover .cms1_img .cms_image1, .cms-inner-block2:hover .cms2_img .cms2_image2 {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease; }

.cmsbanner-block .cms-inner-block1 .description, .cmsbanner-block .cms-inner-block2 .description {
  left: 50px;
  position: absolute;
  top: 22%;
  bottom: auto;
  right: auto; }

.cmsbanner-block .description .title {
  font-size: 24px;
  color: #000;
  margin: 0 0 15px 0; }

.cmsbanner-block .description .toptitle {
  font-size: 24px;
  letter-spacing: 0.4px;
  color: #000;
  text-transform: inherit;
  font-weight: 600; }

.cmsbanner-block .description .banner_text {
  color: #ff3300;
  margin: 50px 0 0 0;
  text-transform: inherit; }

/* end of cms block */
/* homepage sub banner2*/
.webdigify-tab-text {
  background: #2196f3 none repeat scroll 0 0;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 20px 0;
  text-align: center; }

.webdigify-tab-text > span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

/*end homepage sub banner2*/
/* cart */
#cart .item-count {
  background: #fbb50d;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 18px;
  font-size: 12px;
  color: #ffffff;
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: middle;
  font-weight: 400; }

#cart .mycart {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.6px; }

#cart .price {
  font-size: 14px;
  font-weight: 600;
  /* display: block; */
  line-height: 22px;
  text-align: left;
  letter-spacing: 0.8px; }

.col-sm-3.header_cart {
  width: auto;
  float: right;
  padding: 0 0 0 15px; }

.fa.fa-heart-o {
  font-size: 25px;
  padding: 7px 25px 0 10px; }

.header-cart .cart-menu li > a > img {
  width: 100%; }

.col-sm-3.header-cart {
  float: left;
  width: auto;
  padding: 0;
  margin-left: 0; }

.cart-menu .img-thumbnail {
  max-width: none;
  width: auto;
  border: 1px solid #e8e9eb; }

#cart .dropdown-menu .table {
  border: medium none;
  margin-bottom: 10px; }

#cart button > h4 {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  text-transform: inherit;
  line-height: 16px;
  padding: 0 0 0 21px;
  margin: -20px 0 0 10px;
  color: #fff; }

.header-container .dropdown.myaccount .dropdown-toggle .fa.fa-user {
  color: #ef9d2a;
  font-size: 17px; }

.header-container .dropdown.myaccount {
  background: #394d66 none repeat scroll 0 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  height: 40px;
  padding: 12px 17px; }

.header_wishlist {
  float: right;
  margin: 5px 10px;
  position: relative;
  height: 40px;
  width: 40px; }

.header_wishlist > a {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -153px;
  display: block;
  transition: all 600ms ease-in-out 0s;
  -webkit-transition: all 600ms ease-in-out 0s;
  -moz-transition: all 600ms ease-in-out 0s;
  -o-transition: all 600ms ease-in-out 0s;
  -ms-transition: all 600ms ease-in-out 0s; }

.header_wishlist > a:hover {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg); }

.header_wishlist span {
  background: #fbb50d;
  height: 18px;
  position: absolute;
  width: 18px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  right: -6px;
  top: -5px;
  color: #ffffff;
  font-weight: 400;
  line-height: 18px; }

#cart-total {
  float: left;
  color: #111;
  margin-top: 9px; }

.cart_image {
  background: rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -75px;
  height: 40px;
  width: 40px;
  float: left;
  margin: 0 0px 0 0;
  transition: all 600ms ease-in-out 0s;
  -webkit-transition: all 600ms ease-in-out 0s;
  -moz-transition: all 600ms ease-in-out 0s;
  -o-transition: all 600ms ease-in-out 0s;
  -ms-transition: all 600ms ease-in-out 0s; }

#cart:hover .cart_image {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  -ms-transform: rotateY(360deg); }

#cart-text {
  color: #fff;
  font-size: 13px;
  font-weight: 300; }

.header_cart .dropdown-toggle {
  border: 1px solid #111111; }

#cart > .btn {
  background: none;
  width: 100%;
  padding: 0 !important;
  border: none;
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s; }

#cart {
  text-transform: inherit;
  text-align: right;
  margin: 0;
  padding: 5px 0px; }

.shopping-cart .btn.btn-danger {
  border-radius: 0; }

#cart h4 {
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: -2px;
  z-index: 999; }

.pull-left #form-language .btn-group span, .pull-left #form-currency .btn-group span {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  color: #111; }

#nav-top {
  border-bottom: 1px solid #eaeaea; }

.table.table-bordered.shopping-cart.responsive {
  display: none; }

.pull-left #form-language .fa.fa-caret-down {
  padding: 0 10px; }

.pull-left #form-currency .fa.fa-caret-down {
  padding: 0 12px; }

#top-links .fa {
  display: inline-block; }

#top-links .fa.fa-angle-down {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 -10px 0 10px; }

#top-links .list-inline a {
  color: #fff;
  border-right: 1px solid #55ABA4;
  padding: 0 10px 0 10px; }

#checkout-cart .text-right.total {
  font-size: 15px;
  font-weight: 700; }

#cart .dropdown-menu {
  width: 330px;
  padding: 10px;
  border: none;
  overflow: hidden;
  display: none;
  margin: 0;
  height: auto;
  box-shadow: 1px 1px 2px 2px black;
  top: 130px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  position: absolute; }

#cart .dropdown-menu li {
  float: none; }

#cart .dropdown-menu li:first-child {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden; }

#cart .dropdown-menu li + li {
  border-top: 1px solid #e5e5e5;
  padding: 5px 0 0; }

#cart .dropdown-menu li::-webkit-scrollbar {
  width: 4px;
  height: 4px; }

#cart .dropdown-menu li::-webkit-scrollbar-thumb {
  border: 4px solid #888; }

#cart .dropdown-menu li::-webkit-scrollbar-track {
  background: #cccccc; }

.header_cart .text-right.button-container > a {
  padding: 9px 25px;
  background: #fbb50d;
  display: inline-block;
  border-radius: 25px;
  color: #fff; }

.header_cart .text-right.button-container > a.addtocart {
  background: #111111;
  color: #fff; }

.header_cart .text-right.button-container > a.addtocart:hover {
  background: #fbb50d;
  color: #fff; }

.header_cart .text-right.button-container > a.checkout:hover {
  background: #111111;
  color: #ffffff; }

#cart .dropdown-menu table {
  margin-bottom: 10px;
  border: none;
  font-size: 13px; }

#cart .dropdown-menu table td {
  border: none;
  background: none; }

#cart .dropdown-menu table.total-table td {
  border: medium none; }

.productpage .image a.elevatezoom-gallery {
  border: 1px solid #e0e0e0; }

.cart-menu .table td {
  padding: 10px 0 5px 5px; }

.table-responsive .table.table-bordered.shopping-cart .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 15px;
  vertical-align: top;
  width: 25%; }

#account-wishlist #content tr td {
  padding: 9px; }

#checkout-cart span.customSelect {
  border-radius: 5px;
  height: 40px;
  padding: 9px 15px 10px 15px; }

.dropdown-menu .cart-menu .table.table-striped > tr > td .text-center > img {
  width: 100%; }

.text-right > strong {
  font-weight: 600;
  color: #111; }

.content-bottom > .container {
  width: 100%;
  background-color: #fff; }

.serach-inner {
  float: left;
  margin-top: 25px;
  width: 100%; }

#content .panel {
  background-color: #fff; }

.panel-default > .panel-heading:hover {
  border-color: #111111; }

/* menu */
.nav-responsive {
  display: none;
  background: none !important; }

.nav-inner.container {
  background: #ffffff none repeat scroll 0 0;
  padding: 35px 45px 30px;
  top: auto;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
  display: none; }

#res-menu {
  display: none; }

.responsive-menu, .main-menu {
  float: left;
  width: auto; }

.lang-curr .fa.fa-angle-down {
  vertical-align: middle; }

.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0; }

#menu ul li .megamenu ul.list-unstyled {
  padding: 0px;
  border: none; }

.main-menu > ul > li {
  position: relative;
  float: left;
  z-index: 20;
  background-color: transparent; }

.main-menu ul > li {
  background-color: rgba(0, 0, 0, 0);
  display: inline-block;
  float: none;
  position: inherit; }

.main-menu ul > li.dropdown:hover .dropdown-menu {
  display: block; }

.main-menu a {
  font-size: 14px;
  font-weight: 400;
  padding: 21px 20px;
  display: block;
  color: #fff;
  line-height: 18px; }

.more-brand {
  float: right; }

.more-brand > a {
  background: #fbb50d;
  color: #fff;
  padding: 5px 12px 5px 15px;
  margin: 0 -15px -15px 0;
  border-radius: 5px 0px 5px 0;
  font-size: 12px;
  font-weight: 400; }

.more-brand > a > i {
  font-size: 14px;
  margin-left: 5px; }

.static-menu {
  margin-left: 15px; }

.main-menu a:hover {
  text-decoration: none;
  color: #ffffff; }

.main-menu > ul > li:hover > a {
  background: transparent;
  color: #111111; }

.main-menu ul > li.top_level.dropdown:hover > a, .main-menu ul > li.top_level:hover > a {
  color: #111111; }

#menu ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
  display: block;
  padding: 0 0 0 10px;
  position: relative;
  top: 0;
  display: block;
  width: 100%;
  margin: 0 !important; }

.main-menu > ul > li ul > li > a {
  padding: 8px;
  color: #1f2022;
  display: block;
  white-space: nowrap; }

.responsive-menu .main-navigation li a {
  padding: 5px 10px;
  color: #333333;
  display: block;
  white-space: normal; }

.main-menu ul > li.dropdown:hover > a, .main-menu ul > li.dropdown > a:focus {
  color: #000000; }

.main-menu > ul > li ul > li > .dropdown-inner ul li a {
  color: #adadad; }

.main-menu ul li ul .list-unstyled a {
  color: #808080;
  font-weight: normal;
  padding: 8px 0;
  text-transform: inherit;
  line-height: 22px; }

.main-menu > ul > li ul > li > a:hover, .responsive-menu .main-navigation li a:hover {
  color: #111111;
  background-color: #fff; }

#menu ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown:hover > .dropdown-menu {
  display: block; }

#menu .nav li .list-unstyled .dropdown {
  float: left; }

#menu .dropdown .dropdown-menu {
  padding: 15px;
  background: #fff none repeat scroll 0 0;
  border: none;
  width: 100%;
  top: 60px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

#menu ul li.hiden_menu .dropdown-menu {
  visibility: visible !important;
  display: block; }

#menu ul.nav li .megamenu ul.list-unstyled li .dropdown-menu {
  border: medium none;
  margin: 0;
  padding: 5px 0;
  position: relative; }

#menu ul li .megamenu.column1, #menu ul li.hiden_menu .dropdown-menu {
  border: medium none;
  margin: 0 !important;
  padding: 0;
  position: relative; }

#menu li.hiden_menu .dropdown-menu.megamenu {
  display: none; }

#menu ul li .megamenu.column1 ul.list-unstyled {
  display: block;
  padding: 0;
  width: 100%;
  border: none; }

#menu ul li .megamenu.column1 ul.list-unstyled li, .hiden_menu li.top_level {
  float: left;
  padding: 0;
  width: 100%; }

#menu li.hiden_menu:hover .dropdown-menu.megamenu {
  display: none; }

/* Webdigify Category CSS */
.catgory-left {
  width: 24%;
  float: left;
  padding-right: 30px; }

#nav-one .dropdown:hover .dropdown-menu, #nav-one .hiden_menu li.dropdown:hover .dropdown-menu {
  display: block; }

#nav-one .hiden_menu .dropdown-menu li.dropdown .dropdown-menu {
  display: none; }

#nav-one > li > .dropdown-menu {
  background: #fff none repeat scroll 0 0;
  margin-top: 0;
  padding: 20px;
  left: 270px;
  top: 0;
  border-radius: 0;
  box-shadow: 0 0 10px black;
  border: none; }

.box-content-category ul .childs_1 li {
  padding: 0 0;
  border: none; }

.box-content-category ul li {
  line-height: 21px;
  position: relative; }

.dropdown {
  position: relative; }

#nav-one > li.hiden_menu > .dropdown-menu {
  padding: 22px; }

.top_level.hiden_menu {
  position: relative; }

#nav-one li .megamenu ul.childs_1 {
  padding: 0 10px; }

#nav-one li .megamenu.column1 ul.childs_1 {
  padding: 0;
  display: inline-block;
  width: 100%;
  border: medium none; }

#nav-one > li > .dropdown-menu.megamenu.column1 a, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown > a, #nav-one .hiden_menu .dropdown-menu a {
  border: none;
  padding: 0px 0 0px 0px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: inherit;
  color: #666;
  text-transform: inherit; }

#nav-one > li > .dropdown-menu.megamenu.column1 a:hover,
#nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown > a:hover,
#nav-one .hiden_menu .dropdown-menu a:hover {
  color: #111; }

#nav-one > li > .dropdown-menu.megamenu.column1 li a:after {
  display: none; }

#nav-one li ul {
  display: table-cell;
  float: none; }

#nav-one ul {
  display: inline-block;
  float: none;
  margin: 0 auto;
  text-align: left; }

#nav-one li .megamenu ul.list-unstyled > li.dropdown > a {
  font-weight: 500;
  margin: 0 0 0 0;
  padding: 0px;
  border: none;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #111111; }

.quickview-model .product-right .desc {
  font-weight: 500;
  padding: 0 10px 0 0;
  color: #414141; }

.quickview-model h4.special-price {
  color: #111;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px; }

.productpage-quickview .rating-wrapper {
  margin-bottom: 10px; }

.quickview-model .page-title {
  display: none; }

.box-content-category ul .childs_1 li img {
  margin: 10px 0 10px 0; }

#product2 hr {
  margin: 10px; }

.form-group.cart-block {
  margin-top: 20px; }

/* Megamenu*/
#nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown:hover > .dropdown-menu {
  display: block; }

/* more menu */
.dropmenu li:hover ul, .dropmenu li div li:hover div {
  display: block; }

/* end of more menu */
#nav-one li .megamenu ul.list-unstyled li .dropdown-menu {
  background: #fff none repeat scroll 0 0;
  margin: 5px 0 0 0;
  padding: 0;
  position: relative;
  border: none;
  box-shadow: none; }

#nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a {
  padding: 0px 0 0px 0px;
  border: none;
  color: #666;
  font-weight: 400;
  font-size: 13px;
  text-transform: inherit; }

#nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a:hover {
  color: #111111; }

.box-content-category .list-unstyled.childs_2 {
  padding: 0; }

.main-menu > ul > .dropdown .megamenu.column1 ul > li.dropdown:last-child > a {
  border-bottom: 1px dashed #d9d9d9; }

#nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
  left: 100%;
  top: 0;
  position: absolute;
  border-radius: 0;
  padding: 15px 0 15px 15px;
  display: none;
  margin: 0;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  min-width: 200px; }

.breadcrumb li:last-child a {
  color: #666; }

.column_left_cms .box.last .list-group {
  border-bottom: medium none;
  margin-bottom: 0;
  padding: 4px 0 0; }

.column_left_cms .box.last {
  margin-bottom: 0; }

/* Webdigify Category CSS */
/* 1-col layout */
.layout-2.left-col #column-left .swiper-slide a img.img-responsive {
  margin: 0 auto; }

.layout-1 #content {
  padding: 15px; }

.layout-2.left-col #column-left {
  padding: 0 15px 0 0px;
  width: 22%; }

.layout-2.right-col #column-right {
  width: 26%;
  padding: 0; }

/* product info page */
#tabs_info.product-tab {
  float: left;
  margin: 50px 0 0px;
  padding: 0 0;
  width: 100%; }

#tabs_info.product-tab ul.nav.nav-tabs {
  padding: 10px 0 0px;
  margin: 0px;
  border-bottom: 3px solid #fbb50d; }

.nav-tabs > li > a {
  color: #111111;
  font-weight: 400; }

/* End of product info page */
#account-return #content {
  margin: 0 0 20px 15px !important; }

#information-information #content,
#account-return #content,
#information-sitemap #content,
#account-account #content,
#account-voucher #content,
#account-wishlist #content,
#account-order #content,
#account-edit #content,
#account-newsletter #content,
#account-password #content, #account-address #content,
#account-recurring #content, #account-reward #content,
#account-transaction #content, #checkout-checkout #content,
#checkout-cart #content, #account-register #content {
  padding: 20px 20px !important;
  border: 1px solid #eaeaea;
  margin: 0 0 0 15px;
  width: 73%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px; }

#information-information #content h3 {
  font-size: 14px;
  font-weight: 600; }

#information-information .image1,
#information-information .image2,
#information-information .image3,
#information-information .image4 {
  border-right: 1px solid #ededed;
  margin: 0 18px 0 0; }

#information-information h1, #information-information h2,
#account-return h1, #account-return h2,
#account-wishlist h1, #account-wishlist h2,
#account-order h1, #account-order h2,
#account-edit h1, #account-edit h2,
#account-password h1, #account-password h2,
#account-address h1, #account-address h2,
#account-recurring h1, #account-recurring h2,
#account-reward h1, #account-reward h2,
#account-transaction h1, #account-transaction h2,
#checkout-checkout h1, #checkout-checkout h2,
#checkout-cart h1, #checkout-cart h2,
#account-register h1, #account-register h2 {
  margin: 0 0 20px 0; }

#account-account h1, #account-account h2 {
  font-size: 18px;
  line-height: 24px; }

.layout-2.left-col #content {
  padding: 0 0 0 15px;
  width: 78%; }

.layout-2.right-col #content {
  padding: 0 30px 0 0; }

.layout-2 .content-bottom #content {
  width: 100%;
  padding: 0;
  margin-left: auto;
  margin-right: auto; }

#account-login .well p strong {
  font-weight: 400; }

td.text-right a.btn.btn-info {
  background: #020202;
  border-color: #020202; }

td.text-right a.btn.btn-info:hover,
td.text-right a.btn.btn-info:focus,
td.text-right a.btn.btn-info:active {
  background: #fbb50d;
  border-color: #fbb50d;
  color: #fff; }

#account-register fieldset {
  padding: 20px;
  margin: 0 0 20px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px; }

/* 3-col layout */
.layout-3 #column-left {
  width: 24%; }

.layout-3 #column-right {
  width: 24%; }

.layout-3 #content {
  width: 52%; }

/* content */
.layout-2.left-col #content.productpage-quickview {
  float: right;
  padding: 20px; }

.common-home #content {
  padding: 0;
  float: left;
  width: 100%; }

.checkout-cart #accordion .panel-title > a, .checkout-checkout #accordion .panel-title {
  font-weight: 500;
  padding: 0px 0px; }

/* footer */
.social-block ul {
  padding: 0px; }

footer {
  position: relative;
  margin-top: 50px;
  float: left;
  width: 100%;
  overflow: hidden; }

.common-home footer {
  margin-top: 30px; }

footer h5 {
  font-size: 18px;
  margin: 0px 0 20px;
  position: relative;
  text-transform: inherit;
  color: #111;
  font-weight: 500;
  line-height: 24px; }

.footer-blocks .footerleft .contact-block ul {
  padding: 0;
  margin: 20px 0 10px; }

.footer-blocks .footerleft .social-block ul {
  margin: 10px 0 0; }

.footer-blocks .footerleft .social-block ul li {
  margin: 0px;
  line-height: 20px; }

.footer-blocks .footerleft li.block-call span {
  color: #fbb50d;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px; }

.footer-blocks .footerleft .social-block ul li a .fa:hover {
  color: #fbb50d; }

i.fa.fa-phone {
  display: none; }

.footer-blocks .footerleft li.block-call {
  margin: 0 0 20px 0; }

.footer-blocks .footerleft .contact-block span {
  color: black;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 5px; }

.footer-blocks .footerleft .contact-block li {
  margin: 0px 0px 10px;
  line-height: 24px; }

.footer-blocks .footerleft .contact-block .fa {
  margin-right: 15px;
  color: #777;
  font-size: 16px; }

#footer .column li a {
  line-height: 30px;
  color: #777;
  font-size: 13px;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

#footer .column li a:hover {
  color: #111111; }

#footer .footer-area li a::before {
  display: none; }

#footer .footer-area li a {
  padding: 0; }

.footer-blocks {
  margin: 0 -15px; }

#footer {
  padding: 60px 0px;
  float: left;
  position: relative;
  width: 100%; }

.footer-top {
  background: #fbb50d;
  padding: 0px;
  width: 100%;
  float: left; }

.input-group.date span.input-group-btn button.btn.btn-default {
  border-radius: 5px; }

/*** Responsive part ***/
#footer .social-footer .block-social #social-follow {
  display: block; }

#footer .social-footer {
  margin: 0;
  text-align: center; }

#newslatter {
  float: left;
  padding: 38px 90px;
  width: 100%; }

#newslatter::after {
  content: '';
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  position: absolute;
  right: 0;
  height: 160%;
  top: 0;
  bottom: 0;
  margin: auto; }

.block_newsletter h3 span.desc {
  font: 400 13px/24px 'Rubik',Helvetica,sans-serif;
  margin: 0 0 20px;
  float: left;
  width: 100%;
  line-height: 1.1;
  letter-spacing: 0.6px;
  text-transform: inherit; }

.block_newsletter {
  padding: 0;
  float: left;
  width: 100%;
  display: flex;
  align-items: center; }

.block_newsletter h3 .subtitle {
  font: 400 18px/20px 'Rubik',Helvetica,sans-serif;
  float: left;
  width: 100%;
  margin: 0 0 10px; }

.block_newsletter h3 .title {
  float: left;
  width: 100%;
  margin: 0 0 0px;
  color: #ffffff;
  text-transform: inherit;
  font: 500 24px/42px 'Rubik',Helvetica,sans-serif; }

.block_newsletter h3 .title .material-icons {
  font-size: 0;
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
  background-image: url(../image/webdigify/news_icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top; }

.newsletter.col-sm-6 {
  float: right; }

.footer-appinner ul.list-unstyled {
  padding-top: 5px; }

.block_newsletter form {
  position: relative;
  width: 100%;
  float: none;
  display: inline-block;
  vertical-align: top; }

.block_newsletter h3 {
  font: 500 24px/42px 'Rubik',Helvetica,sans-serif;
  color: #111;
  margin: 0;
  display: block; }

.block_newsletter form input[type="email"] {
  background: #ffffff none repeat scroll 0 0;
  border: none;
  float: left;
  padding: 0 125px 0 15px;
  width: 100%;
  font-size: 13px;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -khtml-border-radius: 25px;
  font-weight: 400;
  height: 44px; }

.block_newsletter form input[type=email]:focus + button .search {
  color: #111; }

.block_newsletter form input[type=email]::-webkit-input-placeholder {
  color: #111;
  text-indent: 0.625rem; }

.block_newsletter form input[type=email]::-moz-placeholder {
  color: #111;
  text-indent: 0.625rem; }

.block_newsletter form input[type=email]:-moz-placeholder {
  color: #111;
  text-indent: 0.625rem; }

.block_newsletter form input[type=email]:-ms-input-placeholder {
  color: #111;
  text-indent: 0.625rem; }

.block_newsletter form button[type=submit] {
  position: absolute;
  background: none;
  border: none;
  bottom: 0.3125rem;
  right: 0.125rem;
  color: #666; }

.block_newsletter form button[type=submit] .search:hover {
  color: #111111; }

.block_newsletter p {
  padding: 10px;
  font-size: 13px;
  margin: 5px 0 0;
  float: left;
  width: 100%;
  font-style: italic;
  line-height: 18px; }

.block_newsletter form .input-wrapper {
  overflow: hidden; }

.block_newsletter form input {
  height: 42px; }

.block-contact {
  color: #666666; }

.block-contact .block-contact-title {
  color: #414141; }

/* New CSS */
.product-block.product-thumb.transition {
  margin-bottom: 0px;
  float: left;
  width: 100%;
  padding: 10px 15px;
  height: auto !important; }

/*cartbit*/
.special .product-block.product-thumb.transition {
  height: 100% !important; }

/*cartbit*/
.hometab .row, .ProductbyCategory .row, .featured .row {
  background: #fff; }

.manufacture_block {
  float: left;
  padding: 50px 0 50px;
  width: 100%;
  text-align: left;
  border-top: 1px solid #e8e9eb; }

.manufacturer_title {
  float: left;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  text-transform: inherit; }

.manufacture_block ul {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding-left: 15px; }

.manufacture_block ul li {
  background: url("../image/webdigify/pipe.png") no-repeat scroll right 6px transparent;
  display: inline-block;
  padding: 0 15px 2px 10px; }

.manufacturer_item a {
  color: #7a7a7a; }

.manufacture_block ul li:last-child {
  background: none repeat scroll 0 0 transparent; }

.newsletter label {
  display: none; }

.newsletter .input-news {
  width: 100%;
  position: relative; }

.input-news .text-danger {
  color: #ff0000;
  position: absolute;
  left: 0px; }

.subscribe-btn {
  width: auto;
  float: right;
  position: relative; }

.newsletter .form-group {
  margin: 0;
  float: left;
  width: 100%; }

.newsletter #txtemail {
  background-color: #ffffff;
  height: 44px;
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 10px 120px 10px 20px; }

.special-vrstk {
  max-width: 276px; }

.newsletter .form-control::placeholder {
  color: #111111; }

.newsletter .btn.btn-lg {
  background: #020202;
  border-radius: 0px 5px 5px 0px;
  border: none;
  position: absolute;
  right: 0;
  color: #ffffff;
  padding: 0px 20px;
  top: 0px;
  font-size: 14px;
  height: 44px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.6px; }

.newsletter .btn.btn-lg:hover {
  background-color: #333;
  color: #fff; }

#account-order .col-sm-6.text-right, #account-transaction .col-sm-6.text-right {
  clear: both;
  margin-bottom: 10px;
  width: auto; }

#contact ul li, .newsletter ul li {
  line-height: 22px; }

#contact li.call-num {
  margin-top: 15px; }

#product .form-group #input-quantity, .quickview-model #input-quantity {
  width: 60px;
  height: 38px;
  padding: 8px;
  text-align: center;
  margin-right: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  -moz-border-radius: 20px;
  display: inline-block; }

.form-group.qty #button-cart:hover, #button-cart2:hover {
  color: #ffffff; }

.form-group.qty #button-cart, #button-cart2 {
  padding: 11px 15px 11px 37px;
  border-radius: 5px;
  color: #ffffff;
  background: #fbb50d;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  background-image: url(../image/webdigify/cart-hover.svg);
  background-repeat: no-repeat;
  background-position: 9px 8px;
  background-size: 22px;
  position: relative;
  z-index: 1; }

.form-group.qty #button-cart:hover:after, #button-cart2:hover:after {
  content: "";
  background-image: url(../image/webdigify/cart-hover.svg);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  position: absolute;
  background-size: 22px;
  background-position: 9px -3px;
  background-repeat: no-repeat;
  z-index: 9;
  left: 0; }

label.control-label.qty {
  margin: 0px 10px; }

.form-group.qty #button-cart:hover:before,
#button-cart2:hover:before {
  height: 100%;
  background: #111111;
  color: #fff;
  border-radius: 5px; }

.form-group.qty #button-cart:before,
#button-cart2:before {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0; }

.box-category-top {
  float: left;
  width: auto;
  position: relative;
  padding: 13px 54px 13px 40px;
  cursor: pointer;
  background: #fbb50d;
  color: #fff;
  margin: 10px 0 0 0;
  border-radius: 5px 5px 0 0; }

.box-category-top::before {
  position: absolute;
  content: "";
  background-image: url(../image/webdigify/menu.svg);
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: 20px;
  background-color: transparent;
  left: 18px;
  width: 20px;
  height: 20px;
  top: 14px; }

.quickview-container .wish-comp {
  margin-top: 20px;
  float: left;
  width: 100%; }

.common-home .box-content-category {
  display: block; }

.common-home .header_bottom.fixed .box-content-category {
  display: none; }

.box-content-category {
  background-color: #fff;
  display: none;
  position: absolute;
  top: 60px;
  width: 270px;
  z-index: 99999;
  box-shadow: 0 0 8px black; }

#nav-one li.hiden_menu .dropdown-inner {
  display: none;
  margin: 0 !important;
  padding-left: 0;
  padding-right: 0; }

.box-content-category .hiden_menu span.main {
  padding: 15px 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 100%;
  color: #111;
  font-weight: 400;
  text-transform: uppercase; }

.box-content-category .hiden_menu span.main::after {
  content: " ";
  position: absolute;
  right: 25px;
  top: 20px;
  left: auto;
  background: rgba(0, 0, 0, 0) url("../image/webdigify/more.png") no-repeat scroll center 2px;
  width: 10px;
  height: 10px; }

.box-content-category .hiden_menu span.main.active::after {
  background-position: right -28px; }

.box-content-category .hiden_menu span.main:hover::after {
  background-position: center 2px; }

.box-content-category .hiden_menu span.main.active:hover::after {
  background-position: right -28px; }

.box-content-category .hiden_menu span.main:hover::before {
  background: rgba(0, 0, 0, 0) url("../image/webdigify/myaccountArrow.png") no-repeat scroll center -54px; }

#block_1 {
  width: 20%;
  float: left;
  position: relative;
  text-align: left; }

.bottomfooter {
  float: left;
  padding: 23px 0px;
  width: 100%;
  background: #f5f5f5; }

p.powered {
  float: left;
  margin: 0;
  color: #777;
  padding-top: 5px; }

p.powered a {
  color: #777;
  text-decoration: underline; }

.payment-block li img {
  margin: 0px 5px 0px 0px;
  cursor: pointer; }

.payment-block ul {
  margin: 0px; }

.footer-logo {
  float: left;
  margin-top: 10px; }

.content-top-breadcum {
  float: left;
  width: 100%;
  background: #f5f5f5;
  margin: 0 0 20px; }

.footer-appinner .title {
  font: 600 24px/42px 'Rubik',Helvetica,sans-serif;
  margin: -10px 0 10px;
  color: #fbb50d; }

.footer-appinner .subtitle {
  font: 400 13px/24px 'Rubik',Helvetica,sans-serif;
  margin: 0 0 15px;
  color: #ffffff; }

.common-home .content-top-breadcum {
  display: none; }

/* alert */
.alert {
  padding: 8px 14px 8px 14px; }

.productpage-quickview .btn-group.prd_page {
  padding: 10px 0 0 0; }

/* breadcrumb */
.breadcrumb {
  margin: 0 0 20px 0;
  padding: 8px 0; }

.breadcrumb i {
  font-size: 15px;
  color: #111; }

.breadcrumb > li {
  position: relative;
  white-space: nowrap;
  line-height: 14px; }

.breadcrumb > li + li:before {
  content: '';
  padding: 0;
  color: #000 !important; }

.col-xs-11.col-sm-3.alert span a:hover {
  color: #111111; }

.dropdown.myaccount ul.dropdown-menu.dropdown-menu-right.myaccount-menu {
  top: 46px;
  margin: 0;
  border-radius: 5px !important; }

.login_acc li a {
  color: #666; }

.login_acc li a:hover {
  color: #111; }

#cart .dropdown-menu li p.text-center {
  color: #666;
  margin: 20px auto; }

.box .filterbox {
  border: medium none; }

.panel-footer {
  background-color: transparent !important;
  border-top: medium none !important;
  padding: 0 !important; }

.product-layout.product-grid .action button.wishlist,
.product-layout.product-grid .action .quickview-button,
.product-layout.product-grid .action button.compare_button {
  display: none; }

.manufacturer-list .manufacturer-content .col-sm-12 a {
  color: #666; }

.panel-footer.text-right {
  text-align: right; }

a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
  background: transparent !important;
  color: #111111; }

.list-group-item {
  border: none;
  padding: 0 !important; }

#column-left .list-group-item.heading {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px; }

.list-group a {
  line-height: 30px;
  text-transform: inherit; }

.fa.fa-pencil {
  margin-right: 5px; }

#column-left .checkbox > label {
  color: #666;
  text-transform: inherit;
  font-size: 13px;
  line-height: 20px; }

#column-left .checkbox > label:hover {
  color: #111111; }

.alert-success {
  width: 100%;
  float: right; }

.list-group-item.group-name {
  color: #1f2022;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 18px; }

.filterbox .panel-footer {
  padding: 0 0 15px;
  text-align: left;
  border: medium none;
  background: #fff; }

/* carousel */
.carousel-caption {
  color: #FFFFFF; }

.carousel-control .icon-prev:before {
  content: '\f053';
  font-family: FontAwesome; }

.carousel-control .icon-next:before {
  content: '\f054';
  font-family: FontAwesome; }

/* product list */
.product-layout.product-grid {
  float: none;
  min-height: 415px;
  padding: 0px 8px;
  width: 24.5%;
  display: inline-block;
  vertical-align: top; }

.product-layout.product-list {
  padding: 10px 0 0;
  margin: 0 0 0px; }

.product-layout.product-grid .product-block .product-details {
  padding: 10px 0px; }

.product-list .product-block .product-block-inner {
  border-bottom: 1px solid #eaeaea; }

#content .product-grid .product-block {
  padding: 0; }

.row.cat_prod {
  margin: 0 -15px; }

.alert-success .fa.fa-check-circle {
  margin-right: 5px; }

.product-thumb h4 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  line-height: 22px;
  /* overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap; */
  color: #0a7ef0; }

.product-thumb h4:hover {
  color: #111; }

.product-thumb h4 a {
  color: #111111;
  word-wrap: break-word;
  font: 500 13px/24px 'Rubik',Helvetica,sans-serif; }

.product-thumb h4 a:hover {
  color: #111; }

.product-list .product-thumb h4 {
  margin-bottom: 2px;
  text-transform: inherit;
  float: left;
  width: 100%; }

.product-list .description {
  color: #7a7a7a;
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 3px; }

.product-list .product-block:hover .product-block-inner {
  box-shadow: none;
  padding: 0;
  margin: 0; }

.product-brand {
  color: #9f9f9f;
  margin-bottom: 3px;
  float: left;
  width: 100%; }

.product-details .rating {
  margin-top: 8px;
  float: left;
  width: 100%; }

#column-left .product-thumb .price {
  width: 100%; }

#column-left .product-thumb .price-new {
  float: left; }

#column-left .product-thumb .price-old {
  float: left;
  margin-left: 8px; }

#column-left .product-block:hover .price {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  -moz-transform: none; }

#column-left .product-thumb .caption {
  min-height: 0px; }

#special-carousel .product-carousel .product-thumb .caption, #special-carousel .product-carousel-custom .product-thumb .caption {
  padding: 10px; }

.account-wishlist table .text-center img {
  border: 1px solid #e5e5e5; }

.pagination-wrapper .active > span {
  background: #020202 none repeat scroll 0 0 !important;
  border: 1px solid #020202 !important;
  border-radius: 5px;
  color: #fff !important;
  padding: 6px 12px; }

.product-layout.product-list .image button.wishlist {
  display: none; }

.product-list .product-details .product_hover_block .action {
  float: none; }

.product-layout.product-list .quickview-button a.quickbox {
  border-radius: 25px; }

.product-list .product-details .product_hover_block button.wishlist,
.product-layout.product-list .product-details .quickview-button {
  float: left;
  margin: 0 5px 0 0; }

.product-layout.product-list .quickview-button {
  margin: 0 5px 0 0; }

.product-layout.product-grid .product-details .product_hover_block {
  display: none; }

.product-layout.product-list .product_hover_block .action button.cart_button {
  margin: 0 5px 0 0; }

.col-sm-4.product-left {
  padding-left: 0px;
  width: 35%; }

.col-sm-5.product-right {
  width: 40%; }

.col-sm-3.product {
  width: 22%;
  padding: 0; }

#column-left .box .caption p.price span.price-new, #column-right .box .caption p.price span.price-new,
#column-left .product-thumb .price, #column-right .product-thumb .price {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px; }

#column-left .box .caption p.price .product-thumb .price-old, #column-right .box .caption p.price .product-thumb .price-old {
  font-size: 13px; }

#footer .row .product-block {
  border: none; }

#footer .row .product-block:hover {
  border: none; }

#carousel-0 .product-block:hover, #carousel-0 .product-block {
  border: medium none; }

.rating .fa-stack, #review .fa-stack {
  font-size: 14px;
  width: 10px;
  color: #d9d9d9;
  height: 13px; }

.rating .fa-star, #review .fa-star, .rating-wrapper .fa-star {
  color: #ffab00;
  font-size: 13px;
  height: 13px; }

.rating .fa-star + .fa-star-o, #review .fa-star + .fa-star-o, .rating-wrapper .fa-star + .fa-star-o {
  color: #ffab00; }

.rating .fa-star.off, .rating-wrapper .fa-star.off, #review table .fa-star.off {
  color: #d9d9d9; }

h2.price {
  margin: 0; }

.productpage .nav-tabs > li.active > a {
  color: #000 !important;
  font-size: 20px;
  font-weight: 500; }

#tabs_info.product-tab li.active a {
  background: #fbb50d;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -khtml-border-radius: 5px 5px 0 0;
  font-weight: 500;
  color: #fff; }

.nav-tabs > li.active::after {
  border-bottom: 3px solid #f6d000;
  bottom: 0; }

.nav > li > a:focus, .nav > li > a:hover {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #000; }

#content #tabs_info .nav.nav-tabs {
  padding: 0;
  border-bottom: 2px solid #f0f0f0; }

.product-thumb .price {
  color: #111;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 15px;
  float: left;
  width: 100%;
  line-height: 19px; }

.product-thumb .price .price-new {
  color: #e02b40 !important; }

.product-thumb .list .price {
  font-size: 18px;
  margin: 0 0 20px 0; }

.product-thumb .list .price-new {
  font-size: 18px; }

.product-thumb .price-old {
  color: #666666;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0 5px;
  line-height: 16px; }

.product-thumb .price-tax {
  color: #808080;
  display: none;
  font-size: 12px;
  width: 100%; }

.product-list .product-thumb .price-tax {
  display: block;
  clear: both;
  line-height: 18px;
  margin: 5px; }

.myaccount-menu.dropdown-menu > li > a {
  padding: 6px 10px !important;
  margin-right: 0;
  font-size: 12px; }

.product-list .product-block .product-details.list .compare {
  display: block; }

#product span.customSelect, #product .form-group select {
  border-radius: 5px;
  height: 40px;
  color: #7a7a7a;
  padding: 9px 15px 10px 15px;
  border: 1px solid #e8e9eb; }

.product-list .product_hover_block {
  position: relative;
  right: 0px; }

.product-list .product-details .action {
  float: left;
  margin: 0 5px 0 0; }

.product-list .product-details .action button.cart_button {
  padding: 10px 15px 10px 35px; }

.header-cart .cart-menu li a {
  margin: 0;
  padding: 0 !important;
  height: auto;
  width: 100%;
  float: right;
  text-align: center; }

.cart-menu .total-table .text-right {
  border: none; }

.cart-menu .total-table {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #dddddd currentcolor currentcolor;
  border-image: none;
  border-style: solid none none;
  border-width: 1px medium medium; }

#column-left .product_hover_block, #column-right .product_hover_block {
  display: none; }

.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0; }

.thumbnails > img {
  width: 100%; }

.thumbnails .image-additional {
  float: left; }

.product-info .image {
  margin-bottom: 25px; }

.product-info .image .thumbnail {
  margin: 0; }

.product-info .zoomContainer {
  z-index: 9; }

/* It need for ie7 */
.product-info .additional-carousel {
  position: relative;
  padding: 0 30px;
  overflow: hidden; }

.product-info .image-additional {
  clear: both;
  overflow: hidden;
  width: 100%;
  float: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.product-info .image-additional img {
  height: auto;
  max-width: 100%; }

.product-right .btn-group .wishlist .fa, .product-right .btn-group .compare .fa {
  padding: 0;
  text-align: center;
  margin: 0;
  line-height: 25px; }

#input-captcha, #input-payment-captcha {
  margin-bottom: 10px; }

/* .productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare, .quickview .form-group.qty .wishlist, .quickview .form-group.qty .compare {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding:7px 5px;
    text-transform: inherit;
} */
.productpage .form-group.qty .wishlist:hover, .productpage .form-group.qty .compare:hover, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:hover, .product-rightinfo .btn-group.prd_page .btn-default.compare:hover,
.productpage .form-group.qty .wishlist:hover:before, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:hover:before,
.productpage .form-group.qty .compare:hover:before, .product-rightinfo .btn-group.prd_page .btn-default.compare:hover:before {
  color: #111; }

.productpage #input-quantity, .quickview #input-quantity {
  float: left;
  margin-right: 15px;
  width: auto;
  height: 38px;
  text-align: center; }

.form-group.qty .control-label {
  float: left;
  margin: 10px 10px 0 0; }

/* box */
.box {
  margin-bottom: 0; }

.box.webdigifyblog .box-heading, .banners-slider-carousel .box-heading {
  float: none;
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: 24px;
  border-radius: 0;
  margin: 0;
  padding: 0 0 15px 0; }

#column-left .box .box-heading, #column-right .box .box-heading {
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 600;
  background: #f7f7f7;
  position: relative;
  line-height: 20px;
  color: #111;
  border-radius: 5px;
  text-transform: inherit; }

.service-banner .box-heading {
  display: none; }

.box-heading .fa.fa-angle-down {
  margin: 0 0 0 2px;
  font-weight: 600;
  display: none; }

.box-heading {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
  padding: 0 0 0 15px; }

.hometab.box .customNavigation a:hover::before {
  color: #fff; }

.box.ProductbyCategory .box-content .customNavigation a.prev:hover,
.box.ProductbyCategory .box-content .customNavigation a.next:hover {
  background: #fbb50d; }

.box.special {
  position: relative;
  float: left;
  width: 100%;
  margin: 30px 0 35px; }

#content .box .box-heading, .box.related_prd .box-heading, .banners-slider-carousel .box-heading {
  font-size: 24px;
  font-weight: 500;
  position: relative;
  text-transform: inherit;
  line-height: 34px;
  float: left;
  text-align: left;
  color: #111;
  letter-spacing: 0.6px;
  padding: 8px 15px 8px;
  font-family: 'Poppins',Helvetica,sans-serif;
  background: #f5f5f5; }

#product-product .box-head .box-heading {
  width: 100%;
  margin: 0 0 20px 0; }

.box.special .box-heading {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 0px; }

.product-layout.product-grid .product_hover_block a.quickbox {
  border-radius: 5px; }

.hometab-heading.box-heading {
  padding: 50px 32px 0 30px;
  border-bottom: none;
  line-height: 30px; }

.header_top .account {
  float: right;
  width: auto;
  border: none;
  padding: 0;
  margin: 0; }

#content .box .box-content, .related_prd .box-content {
  border: none;
  padding: 0;
  clear: both;
  margin: 0 -15px;
  position: relative; }

.box.special .customNavigation {
  top: -49px; }

.row .additional-carousel .product-block:hover {
  border: none; }

#products-related {
  position: relative; }

.box .box-content ul, #content .content ul {
  padding: 0px;
  margin: 0px;
  list-style: none; }

.all-blog .panel-default .panel .panel-body h5 {
  text-align: left;
  float: left;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 15px 0; }

.box .box-content ul li, #content .content ul li {
  line-height: 22px;
  padding: 4px 0;
  border-bottom: 1px dashed #e4e4e4; }

.box .box-content ul li:last-child, #content .content ul li:last-child {
  border: medium none; }

.box .box-content ul li a + a, .box .box-content ul li a + a:hover {
  background: none;
  padding-left: 0; }

.box .box-content ul ul {
  margin-left: 0px; }

.product-grid li, #content .box-product .product-items {
  float: left;
  position: relative; }

#content .image-additional .slider-item .product-block {
  margin: 0 6px;
  display: inline-block; }

.slider-item {
  width: 130px;
  float: none;
  display: inline-block;
  vertical-align: top; }

.product-block:hover .product-block-inner {
  z-index: 9; }

.banners-slider-carousel .product-block:hover .product-block-inner {
  box-shadow: none; }

.product-block-inner {
  position: relative;
  background: #fff;
  float: left;
  width: 100%; }

.product-layout .product-block-inner {
  margin: 0; }

.grid_default_width {
  width: 225px; }

.module_default_width {
  width: 220px; }

.latest_default_width {
  width: 225px; }

.special_default_width {
  width: 235px; }

.wdproductcategory {
  width: 240px; }

.related_default_width {
  width: 240px; }

.bestseller_default_width {
  width: 240px; }

.tabspecial_default_width {
  width: 240px; }

.tabbestseller_default_width {
  width: 240px; }

.additional_default_width {
  width: 100px; }

#content .banners-slider-carousel .product-carousel .product-block-inner, #content .banners-slider-carousel .product-carousel-custom .product-block-inner, #content .banners-slider-carousel .product-grid .product-block-inner {
  text-align: center; }

.product_hover_block {
  position: absolute;
  right: -10px;
  bottom: 0;
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  z-index: 1;
  height: 130px;
  top: 0;
  margin: auto; }

.product-block:hover .product_hover_block {
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  right: 10px; }

.product_hover_block .action {
  float: left;
  width: 100%; }

.product_hover_block button, .quickbox {
  background-position: center center;
  background-size: 16px;
  background-color: #111111;
  float: left;
  margin: 0;
  color: #333;
  border: none;
  position: relative;
  border-radius: 5px;
  text-align: center;
  height: 40px;
  width: 40px;
  z-index: 1;
  overflow: hidden; }

.product_hover_block .action button.cart_button {
  background-position: 0px;
  font-size: 12px;
  font-weight: 600;
  display: block;
  padding: 8px 12px;
  margin: 0 3px 0 0;
  width: auto;
  background: #fbb50d;
  border-radius: 50px;
  letter-spacing: 0.6px;
  z-index: 9;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

.product_hover_block button.wishlist {
  display: block;
  float: none;
  margin: 0 0 5px;
  background-image: url(../image/webdigify/wishlist.svg);
  background-repeat: no-repeat; }

.product_hover_block button.compare_button {
  background-image: url(../image/webdigify/compare.svg);
  background-repeat: no-repeat;
  float: none;
  display: block; }

.img-wrap {
  overflow: hidden;
  position: relative;
  float: left;
  width: 100%; }

.product-block:hover .product_hover_block button.compare_button {
  opacity: 1; }

.quickview-model .alert.alert-info {
  float: left;
  width: 100%;
  margin: 10px 0 0 0; }

.quickview-button {
  display: block;
  float: none; }

.quickbox {
  display: inline-block;
  margin: 0 0 5px;
  background-image: url(../image/webdigify/quick-view.svg);
  background-repeat: no-repeat; }

#productcategory-carousel .product_hover_block {
  left: 15px; }

.quickbox .fa.fa-eye {
  font-size: 15px; }

.product_hover_block button .fa {
  font-size: 14px; }

.product-block .product-details {
  padding: 10px 0px;
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  height: 100%; }

.product-grid .description {
  display: none; }

.sale {
  color: #111111;
  display: block;
  font-size: 12px;
  left: 10px;
  line-height: 18px;
  position: absolute;
  right: auto;
  top: 15px !important;
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out; }

.product-block .product-block-inner .image span.sale:hover {
  opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  transition: 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  left: -90px; }

.product-block .product-details:hover .action button.cart_button:after {
  content: "";
  background-image: url(../image/webdigify/cart-hover.svg);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  position: absolute;
  background-size: 20px;
  background-position: 10px 8px;
  background-repeat: no-repeat;
  z-index: 9;
  left: 0;
  top: 0; }

.action button.cart_button {
  font-size: 14px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  width: auto;
  padding: 9px 17px 9px 35px;
  text-transform: inherit;
  line-height: 20px;
  height: auto;
  border: none;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -khtml-border-radius: 25px;
  -moz-border-radius: 25px;
  color: #ffffff;
  background-color: #fbb50d;
  background-image: url(../image/webdigify/cart-hover.svg);
  background-size: 20px;
  background-position: 10px 8px;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
  transition: all 400ms ease-in-out 0s;
  -webkit-transition: all 400ms ease-in-out 0s;
  -moz-transition: all 400ms ease-in-out 0s;
  -o-transition: all 400ms ease-in-out 0s;
  -ms-transition: all 400ms ease-in-out 0s; }

.product-details:hover .action button.cart_button {
  color: #fff; }

.product_hover_block button.wishlist:hover:after {
  background-image: url(../image/webdigify/wishlist.svg);
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  border: red;
  background-position: center;
  top: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  z-index: 9;
  left: 0; }

.product_hover_block .quickbox:hover:after {
  background-image: url(../image/webdigify/quick-view.svg);
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  border: red;
  background-position: center;
  top: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  z-index: 9;
  left: 0; }

.product_hover_block button.compare_button:hover:after {
  background-image: url(../image/webdigify/compare.svg);
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  background-position: center;
  top: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  z-index: 9;
  left: 0; }

.product_hover_block button.wishlist:hover:before,
.product_hover_block .quickbox:hover:before,
.product_hover_block button.compare_button:hover:before {
  height: 100%; }

.product_hover_block button.wishlist:before,
.product_hover_block .quickbox:before,
.product_hover_block button.compare_button:before {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0;
  border-radius: 5px; }

.product-details .action button.cart_button:before {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0;
  border-radius: 5px; }

.product-details:hover .action button.cart_button:before {
  height: 100%;
  background: #020202;
  color: #fff; }

.product-layout.product-grid .product-block-inner {
  margin: 0 0 20px 0; }

#content .banners-slider-carousel .product-block {
  cursor: pointer; }

/* #content .banners-slider-carousel .product-block .product-block-inner { padding: 15px 0 !important; } */
.product-layout.product-list .img-wrap {
  float: left;
  width: 25%; }

.product-layout.product-list .img-wrap .product_hover_block {
  display: none; }

.product-list .product-details {
  width: 75%;
  padding: 20px 0 15px 15px; }

#content .banners-slider-carousel .product-block .product-block-inner a .img-responsive {
  display: inline-block; }

.banners-slider-carousel .product-block .product-block-inner img {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.banners-slider-carousel .product-block:hover .product-block-inner img {
  opacity: 1;
  filter: alpha(opacity=100);
  animation: 3s ease-in 1s 2 reverse both paused bounceIn;
  animation: 3s linear 1s bounceIn;
  animation: bounceIn 1s; }

@-webkit-keyframes slideDashXopp {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); } }

@-moz-keyframes slideDashXopp {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); } }

@keyframes slideDashXopp {
  from {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); }
  to {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); } }

@-webkit-keyframes slideDash {
  from {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); } }

@-moz-keyframes slideDash {
  from {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); } }

@keyframes slideDash {
  from {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%); }
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%); } }

@-webkit-keyframes slideDashY {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); } }

@-moz-keyframes slideDashY {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); } }

@keyframes slideDashY {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); } }

@-webkit-keyframes slideDashYopp {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); } }

@-moz-keyframes slideDashYopp {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); } }

@keyframes slideDashYopp {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%); }
  to {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); } }

.image_content {
  position: absolute;
  height: 100%;
  width: 100%; }

.product-info .product-image .customNavigation span.prev:hover::before, .product-info .product-image .customNavigation span.next:hover::before {
  background: #0a7ef0  none repeat scroll 0 0;
  border: 1px solid #0a7ef0;
  color: #ffffff; }

#column-left .swiper-pagination.swiper-pagination-bullets {
  display: none; }

#content .product-list .product-block .image {
  float: left;
  width: auto; }

.banners-slider-carousel {
  margin: 35px 0 50px;
  width: 100%;
  float: left; }

.banners-slider-carousel .product-carousel .slider-wrapper-outer, .banners-slider-carousel .product-carousel-custom .slider-wrapper-outer {
  padding: 0; }

#column-left .box-product,
#column-right .box-product {
  width: 100%;
  overflow: hidden;
  padding: 0px 5px; }

#column-left .box-product .product-block-inner, #column-right .box-product .product-block-inner {
  margin: 0;
  padding: 0;
  box-shadow: none;
  width: auto;
  border: none; }

.product-layout.product-list .product_hover_block {
  right: 0px;
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1; }

.product-layout.product-list .product_hover_block button {
  border-radius: 25px; }

#column-left .box-product > div,
#column-right .box-product > div {
  display: block;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0;
  width: 100%; }

#column-left .box .box-content div.product-items:last-child > div, #column-right .box .box-content div.product-items:last-child > div,
#column-left .box .box-content div.slider-item:last-child > div, #column-right .box .box-content div.slider-item:last-child > div {
  border: 0 none; }

#column-left .box-product .image, #column-right .box-product .image {
  display: block;
  margin-bottom: 10px;
  float: left;
  margin-right: 0px;
  margin-top: 0px;
  border: none;
  width: 25%; }

#column-left .product-block:hover, #column-right .product-block:hover {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

#column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
  float: right;
  width: 70%;
  overflow: hidden;
  padding: 0; }

#column-left .product-thumb .caption,
#column-right .product-thumb .caption {
  padding: 0;
  border: none;
  float: left;
  width: 100%;
  margin-top: 0px; }

#column-left .special .product-thumb .caption,
#column-right .special .product-thumb .caption {
  width: 100%; }

.content_headercms_bottom .tags > a:last-child::after {
  border-right: medium none; }

#column-left .product-thumb .price, #column-right .product-thumb .price {
  text-align: left; }

#column-left .box-product .name,
#column-right .box-product .name {
  display: block; }

#column-left .product-thumb, #column-right .product-thumb {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 0px;
  padding: 20px 0 5px;
  float: left;
  width: 100%; }

#column-left .price-tax, #column-left .wishlist, #column-left .compare,
#column-right .price-tax, #column-right .wishlist, #column-right .compare {
  display: none !important; }

#column-left .container, #column-right .container {
  width: auto; }

#column-left .box, #column-right .box {
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #eaeaea;
  padding: 5px 5px;
  width: 100%;
  border-radius: 5px; }

.top_button:hover {
  background-color: #020202; }

.top_button {
  bottom: 10%;
  cursor: pointer;
  height: 45px;
  padding: 0px 12px;
  position: fixed;
  right: 8.5%;
  text-align: center;
  width: 45px;
  z-index: 99;
  font-size: 0px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background: #fbb50d;
  -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
  animation-name: hvr-icon-bob-float, hvr-icon-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out; }

.top_button:hover:before {
  color: #fff; }

.top_button::before {
  color: #ffffff;
  content: "\f106";
  font-family: "FontAwesome";
  font-size: 22px;
  line-height: 37px;
  font-weight: 300;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out; }

.top_button:after {
  border-radius: 50%;
  bottom: -5px;
  height: 50px;
  opacity: 1;
  right: -5px;
  content: "";
  position: absolute;
  width: 50px;
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s; }

#content ul.list-unstyled {
  margin-bottom: 10px;
  overflow: auto;
  padding: 0; }

#content ul.list-unstyled li {
  line-height: 20px;
  padding: 4px 0;
  font-size: 14px; }

#content ul.list-unstyled li a:hover {
  color: #111; }

.account-address .btn-info:hover {
  background: #fbb50d;
  border-color: #fbb50d;
  color: #111; }

#content ul.list-unstyled li a {
  color: #666; }

.manufacturer-list {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px; }

.manufacturer-heading {
  background: none repeat scroll 0 0 #f8f8f8;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 6px;
  padding: 5px 8px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px; }

.brand-name {
  float: left;
  border: 1px solid #e8e9eb;
  margin: 10px 18px; }

.brand-name:hover {
  border-color: #111; }

.brand-name a .img-responsive {
  width: 123px;
  height: 50px;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.brand-name a .img-responsive:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.main-menu .brand-name a {
  padding: 0; }

.main-menu .brand-name a span {
  width: 100%;
  color: #111;
  float: left;
  background: #fafafa;
  text-align: center;
  padding: 5px 14px 6px;
  border-top: 1px solid #eaeaea;
  font-weight: 500; }

.manufacturer-content-home {
  float: left;
  width: 100%; }

.manufacturer-content {
  padding: 8px; }

.manufacturer-list ul {
  float: left;
  list-style: outside none none;
  margin: 0 0 10px;
  padding: 0;
  width: 25%; }

.productpage .box {
  margin-top: 20px; }

.col-sm-4.total_amount {
  margin-top: 70px; }

.product-tag {
  margin: 5px 0; }

.aboutus {
  clear: both;
  margin-bottom: 60px; }

#content h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #111; }

.image1 {
  background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -165px;
  float: left;
  height: 50px;
  width: 60px; }

.image2 {
  background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -116px;
  float: left;
  height: 50px;
  width: 60px; }

.image3 {
  background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -52px;
  float: left;
  height: 50px;
  width: 60px; }

.image4 {
  background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px 6px;
  float: left;
  height: 50px;
  width: 60px; }

.aboutus h2 {
  clear: both;
  font-size: 20px; }

.about-content {
  overflow: hidden; }

.information-information .right {
  float: right; }

.category_filter #grid-view, .category_filter #list-view {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;
  border: none;
  padding: 6px 12px;
  box-shadow: none; }

#column-left .swiper-viewport {
  border: medium none;
  box-shadow: none;
  margin: 0 0 30px;
  border-radius: 5px; }

.category_filter #grid-view {
  background: url("../image/webdigify/sprite.png") no-repeat scroll -4px -165px !important;
  width: 25px;
  height: 25px; }

.category_filter #list-view {
  background: url("../image/webdigify/sprite.png") no-repeat scroll -37px -165px !important;
  width: 25px;
  height: 25px; }

.category_filter #list-view:hover, .category_filter #list-view.active {
  background-position: -38px -112px !important; }

.category_filter #grid-view:hover, .category_filter #grid-view.active {
  background-position: -7px -113px !important; }

.category_filter #grid-view .fa, .category_filter #list-view .fa {
  display: none; }

#list-view {
  margin-left: 2px; }

.category_filter #grid-view:hover .fa, .category_filter #list-view:hover .fa {
  color: #fff; }

.category_filter .btn-list-grid {
  float: left;
  width: auto;
  padding: 0px; }

.compare-total {
  float: left;
  margin: 7px 20px 0; }

.pagination-right {
  float: right;
  margin: 0;
  width: auto; }

.category_filter .sort-by {
  float: left;
  margin: 7px 10px 0 0;
  width: auto;
  padding: 0; }

.category_filter .show {
  float: left;
  margin: 7px 10px 0;
  padding: 0;
  width: auto; }

.category_filter .sort {
  float: left;
  width: 150px;
  padding: 0; }

.category_filter .limit {
  float: right;
  width: 100px;
  padding: 0; }

.sort-by-wrapper, .show-wrapper {
  float: left;
  margin-right: 15px; }

.show-wrapper {
  margin: 0; }

.category_thumb .category_description {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  color: #666; }

.row.category_thumb {
  margin: 0 -15px;
  padding: 0; }

.category_thumb .category_img, .category_thumb .category_description {
  float: left;
  width: 100%; }

.category_img img {
  margin-bottom: 10px;
  border: none;
  max-width: 100%;
  border-radius: 5px; }

.category_list ul {
  padding: 0;
  display: inline-block;
  margin: 7px 0 0; }

.category_above {
  overflow: hidden;
  max-height: 147px; }

.category_above:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 110px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
  pointer-events: none; }

.showallbtn {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  border: none;
  background-color: inherit; }

.showallbtnarrow {
  background-image: url(/catalog/view/theme/default/stylesheet/ocfilter/arrow-down-svgrepo-com.svg);
  background-size: 20px;
  padding: 0px 10px 0px 10px;
  border: none;
  background-color: inherit;
  margin-top: 1px;
  height: 15px; }

.showallbuttons {
  display: flex;
  justify-content: center; }

/* .cat_and_btns{
	
} */
#content .refine-search {
  margin-bottom: 5px;
  margin-top: 5px;
  text-transform: inherit; }

.refine-search ul {
  padding: 0; }

.refine-search ul li {
  list-style: none; }

.category_list li a {
  display: block;
  padding: 8px 20px;
  border: 1px solid #eaeaea;
  color: #666;
  border-radius: 5px; }

.compare-total #compare-total {
  color: #666; }

.category_list li a:hover {
  color: #111111;
  border-color: #111111; }

.category_list li {
  float: left;
  list-style: outside none none;
  margin: 5px 10px 0px 0; }

.pagination-wrapper {
  border: 1px solid #eaeaea;
  display: inline-block;
  margin: 20px 0 0;
  padding: 10px;
  width: 100%;
  background: none;
  border-radius: 5px; }

.pagination > li:last-child {
  margin: 0; }

.category_filter {
  display: inline-block;
  padding: 10px 10px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 13px;
  border: 1px solid #eaeaea;
  border-radius: 5px; }

.pagination-wrapper .page-link {
  float: right;
  padding: 0;
  width: auto; }

.pagination-wrapper .page-result {
  float: left;
  padding: 6px 0;
  width: auto; }

.information-contact #content {
  width: 100%;
  padding: 0;
  background: #fff;
  margin-bottom: -20px; }

.left .address-detail {
  border-bottom: 1px solid #e8e9eb;
  padding-bottom: 20px;
  margin-bottom: 15px; }

.address-detail1 a.btn.btn-info:hover {
  background: #020202;
  border: 1px solid #020202;
  color: #ffffff; }

.left strong {
  font-size: 13px;
  text-transform: inherit;
  padding-bottom: 5px;
  display: block;
  color: #111111; }

.left .btn.btn-info {
  margin-bottom: 20px;
  border-radius: 30px;
  color: #fff;
  background: #fbb50d;
  border-color: #fbb50d;
  font-weight: 500; }

.row.contact-info {
  margin: 0; }

.information-contact .panel-body {
  padding: 0; }

.information-contact .panel {
  border: medium none;
  box-shadow: none;
  margin: 0 0 40px; }

.map_button {
  clear: both;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #f6d000;
  padding: 12px 15px;
  cursor: pointer; }

.map_button > i {
  font-size: 18px; }

.row.site-map ul a {
  color: #666; }

.row.site-map ul a:hover {
  color: #111; }

.contact-form-design .left {
  float: left;
  width: 28%;
  margin-right: 30px;
  padding: 20px 20px;
  color: #666666;
  position: relative;
  z-index: 9;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

.contact-form fieldset {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

.contact-form .pull-right {
  padding: 30px 0px 0 0; }

.contact-form-design.container {
  padding: 30px 0 70px 0;
  background: #fff;
  margin: 30px auto;
  position: relative; }

.checkout-cart .alert.alert-danger {
  float: left;
  width: 100%;
  margin-top: 20px; }

.address-detail .btn {
  clear: both;
  float: left;
  margin: 15px 0 0 37px;
  color: #000; }

#spinner {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: url("../image/webdigify/ajax-loader.gif") 50% 50% no-repeat #fff; }

.main-slider {
  position: relative;
  float: left;
  width: 100%; }

.productpage .write-review:hover, .productpage .review-count:hover {
  color: #111; }

.main-slider img {
  border: none;
  width: 100%;
  height: auto; }

.information-sitemap .sitge-map ul {
  padding: 0 0 0 20px; }

.forget-password {
  margin: 5px 0 0; }

.product-compare .btn-primary {
  margin: 5px 0 5px; }

.productpage .write-review, .productpage .review-count {
  margin: 0 10px;
  text-transform: inherit;
  color: #666; }

.productpage .rating-wrapper, .quickview .rating-wrapper {
  margin: 15px 0 15px; }

.productpage .productpage_details {
  float: left;
  margin-top: 0px;
  width: 100%; }

.productpage #tabs_info {
  background: #ffffff none repeat scroll 0 0;
  margin: 30px 0;
  padding: 30px; }

.productpage .productpage_details .product-left {
  padding: 0;
  width: 445px; }

.productpage .productpage_details .product-right {
  margin-left: 30px;
  width: 45%; }

#content .productpage_details #column-right {
  float: right;
  margin: 0;
  padding: 0;
  width: 238px; }

.productpage #banner0.owl-carousel {
  overflow: visible; }

.productpage #banner0 .owl-pagination {
  bottom: -30px; }

.productpage #banner0 .owl-page {
  border: 2px solid #000000; }

.productpage #banner0 .owl-page > span {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #ffffff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 10px;
  position: relative;
  text-indent: -9999px;
  width: 10px; }

/* Product tab*/
.content_product_block {
  margin: 20px 0;
  float: right;
  width: 100%; }

.product-detail-left hr {
  margin: 0px; }

.addthis_toolbox.addthis_default_style {
  float: left; }

#custom_tab.product-tabs {
  overflow: hidden; }

#custom_tab > ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

#custom_tab li {
  float: left;
  margin: 0; }

#custom_tab.product-tabs a.selected {
  color: #111;
  font-weight: 500;
  background: #e8e9eb; }

#custom_tab.product-tabs a {
  background: none repeat scroll 0 0;
  color: #7a7a7a;
  float: left;
  padding: 10px 15px;
  text-transform: inherit;
  margin-right: -1px; }

#custom_tab.product-tabs a:first-child {
  padding: 10px 15px 10px 15px; }

#custom_tab ul li a {
  color: #aaaaaa;
  float: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 16px;
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none; }

.content_product_block .tab_product {
  border: 3px solid #e8e9eb;
  padding: 20px;
  font-size: 13px;
  position: relative; }

.productpage .tab_product b, .productpage .tab_product strong {
  font-weight: 500;
  color: #000;
  font-size: 13px; }

.size_guide_table {
  border: 1px solid #f0f0f0;
  width: 100%; }

.size_guide_table tbody th {
  background: #f0f0f0 none repeat scroll 0 0;
  border-bottom: 1px solid #f0f0f0; }

.size_guide_table tbody th {
  color: #262626;
  font-weight: bold;
  padding: 7px 8px;
  vertical-align: middle;
  white-space: nowrap; }

.size_guide_table tbody td {
  padding: 5px 8px; }

/* end product tab*/
.col-sm-8.product-left {
  padding: 0;
  margin: 0;
  width: 40%;
  position: sticky;
  top: 80px; }

.col-sm-4.product-right {
  padding: 0 0 0 40px;
  width: 60%; }

.quickview .review-count, .quickview .write-review {
  display: none; }

#content.productpage ul.list-unstyled {
  border-bottom: medium none; }

#content ul.list-unstyled.attr span {
  font-weight: 600;
  color: #333;
  font-size: 14px; }

.common-home .content-top-breadcum .container {
  display: none; }

#content ul.list-unstyled.attr {
  padding-bottom: 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 15px;
  margin-bottom: 20px; }

#content ul.list-unstyled.attr li {
  clear: both; }

.productpage .product-right ul.price, .productpage .product-right ul.price {
  padding-bottom: 10px; }

#content ul.list-unstyled.price li {
  float: left;
  padding: 2px 0;
  font-size: 13px;
  color: #000; }

.productpage .product-right ul li span.old-price, .productpage .product-right ul li span.special-price {
  margin-left: 10px;
  color: #666666; }

.productpage .product-right ul li.discount, .productpage .product-right ul li.rewardpoint, .productpage .product-right ul li.price-tax {
  clear: both; }

.quickview .page-title {
  display: none; }

.refine-search, .product-title, .product-option {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #333333; }

.copy-right {
  border-top: 1px solid #e5e5e5;
  padding: 10px 0;
  text-align: center; }

.category_description > p {
  margin: 0; }

#bottom-footer li {
  display: inline;
  padding: 1px 7px 1px 2px;
  background: url("../image/webdigify/pipe.gif") no-repeat scroll right center transparent; }

#bottom-footer li.contact {
  background: none; }

.account-address .table > tbody > tr > td {
  vertical-align: middle;
  padding: 15px; }

.account-address .btn-info {
  margin: 2px 0; }

.affiliate-account .btn-primary .list-group-item, .affiliate-account .btn-primary .list-group-item:hover {
  padding: 0;
  background: none;
  color: #fff; }

.shopping-cart .img-thumbnail {
  width: auto; }

.shopping-cart .input-group .form-control {
  width: auto;
  height: 40px;
  text-align: center;
  border-radius: 5px !important; }

#accordion label.col-sm-2.control-label {
  margin: 7px 0 0;
  padding: 0 13px 0 0; }

#accordion #collapse-shipping label.col-sm-2.control-label {
  padding: 0 15px; }

.checkout-checkout #accordion label.col-sm-2.control-label {
  padding-left: 15px; }

.checkout-cart .input-group-btn:last-child > .btn-group {
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0; }

.col-sm-3.search_subcategory {
  width: 100%;
  margin-top: 7px; }

#button-search {
  float: right; }

.box .box-content .megamenu ul li ul li {
  padding: 0; }

#column-left .treeview-list .expandable .collapsable-hitarea::before, #column-left .treeview-list .expandable-hitarea::before,
#column-left .treeview-list .collapsable .expandable-hitarea::before, #column-left .treeview-list .collapsable-hitarea::before {
  position: relative;
  top: 6px; }

.box .box-content ul, #content .content ul {
  padding: 0;
  margin: 0px;
  list-style: none; }

.box .box-content ul li {
  line-height: 23px;
  padding: 0px 25px;
  border-bottom: none; }

.box .box-content ul ul {
  margin-left: 10px; }

#column-left .box .box-content ul li, #column-right .box .box-content ul li {
  padding: 0; }

#column-left .box .box-content ul li a, #column-right .box .box-content ul li a {
  padding: 6px 0;
  color: #7a7a7a; }

.box .box-content ul li:last-child a {
  border: medium none; }

.box .box-content ul li ul li {
  padding: 0 25px;
  border-bottom: none; }

.panel-footer.text-right button#button-filter {
  color: #111;
  background: none;
  padding: 8px 25px 0; }

.panel-footer.text-right button#button-filter:hover {
  text-decoration: underline; }

#column-left .list-group, #column-right .list-group {
  padding: 10px 15px;
  margin: 0px; }

.box .box-content .box-category {
  padding: 15px 0; }

#content .content ul li {
  line-height: 22px;
  padding: 5px; }

.box .box-content ul li ul li + li {
  border-top: medium none; }

.box .box-content ul li a, #content .content ul li a {
  display: inline-block;
  text-transform: inherit; }

.box .box-content ul li a + a, .box .box-content ul li a + a:hover {
  background: none;
  padding-left: 0; }

.box .box-content ul li a:hover, #content .content ul li a:hover {
  color: #111111 !important; }

.box .box-content ul ul {
  z-index: 99;
  width: auto; }

#column-left .treeview .hitarea.expandable-hitarea {
  color: #808080 !important; }

#column-left .treeview .hitarea.expandable-hitarea {
  color: #808080 !important; }

#column-right .single-banner {
  margin: 0; }

.box .box-content ul ul li ul {
  padding: 0; }

.box .box-content ul ul li:hover ul {
  display: block; }

.navbar-nav > ul > li > a:hover .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0; }

.box-category-heading {
  color: #fff;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  overflow: hidden;
  padding: 12px 0px;
  position: relative;
  text-transform: uppercase;
  width: 200px;
  line-height: 16px;
  display: none; }

.box-category-heading .fa {
  float: left;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px; }

/* Tab cms */
.hometab, .ProductbyCategory, .featured {
  width: 100%;
  padding: 35px 0 20px;
  float: left; }

.box.webdigifyblog {
  margin-bottom: 40px; }

.etabs {
  margin: 0;
  padding: 0 60px 0 0px;
  float: right; }

.htabs .etabs li:last-child {
  background: none;
  display: none; }

.htabs a {
  display: inline-block;
  float: none;
  cursor: pointer;
  text-transform: inherit;
  width: 100%;
  border: 1px solid transparent;
  padding: 11px 20px;
  color: #111;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 8px 0 0;
  font-family: 'Poppins',Helvetica,sans-serif; }

.etabs li.tab {
  display: inline-block; }

.htabs a.selected, .htabs li.active a {
  position: relative;
  color: #fff;
  background: #fbb50d;
  border-radius: 5px 5px 0 0; }

#tabs_info.product-tab .tab-content {
  margin-bottom: 15px;
  padding: 20px;
  float: left;
  border: 1px solid #eaeaea;
  border-top: 0px;
  width: 100%; }

.nav-tabs > li {
  border: none !important; }

.tab-content .tab {
  display: none; }

.box .box-content ul li:last-child ul li a {
  border-bottom: 1px dashed #e5e5e5; }

.box .box-content ul li:last-child ul li:last-child a {
  border: medium none; }

.product-column {
  float: left;
  width: 100%; }

.tab-head {
  width: 100%;
  float: left;
  background: #f5f5f5;
  padding: 0 15px;
  margin-bottom: 20px; }

/*Right banner*/
.collection-right-banner {
  float: right;
  width: 22%;
  position: relative;
  text-align: center; }

.collection-right-banner .image-title {
  position: absolute;
  top: 54px;
  width: 100%; }

.collection-right-banner .image-button {
  position: absolute;
  bottom: 60px;
  width: 100%; }

#content .collection-right-banner .image-title > h3 {
  font-size: 34px;
  text-transform: inherit;
  font-weight: 700;
  margin: 0;
  line-height: 36px;
  padding: 0 10px; }

.banner-btn {
  padding: 14px 45px;
  background: #f6d001;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500; }

.banner-btn:hover {
  background: #333;
  color: #fff; }

/*Right banner*/
.tab-head .sub-heading {
  float: left;
  margin: 10px 10px 0 30px;
  color: #7a7a7a;
  line-height: 24px; }

.tab-head .coupon {
  float: left;
  margin-top: 40px;
  color: #0a7ef0;
  border: 2px dashed #f6d000;
  padding: 6px 20px;
  font-weight: 500; }

.btn-danger:hover {
  background-color: #c9302c;
  border-color: #ac2925;
  color: #fff !important; }

.btn-block + .btn-block {
  margin-top: 0; }

.table-responsive {
  width: 100%; }

.table-responsive .shopping-cart .btn.btn-primary {
  margin: 0 10px; }

.hometab .customNavigation a.prev:before:hover, .hometab .customNavigation a.next:before:hover {
  color: #fff; }

/* End Tab cms */
/*Webdigify Blog CSS */
.webdigifyblog .bx-controls-direction .bx-prev::after {
  content: "\f107 ";
  cursor: pointer;
  font-family: "FontAwesome";
  font-size: 24px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 25px;
  text-align: right;
  top: 0;
  vertical-align: middle; }

.bx-controls-direction .bx-prev::after {
  font-size: 18px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: -1px;
  vertical-align: middle; }

.webdigifyblog .bx-controls-direction .bx-next::after {
  content: "\f106";
  cursor: pointer;
  font-family: "FontAwesome";
  font-size: 24px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 45px;
  text-align: right;
  top: 0;
  vertical-align: middle; }

.bx-pager-item {
  display: none; }

.content_footer_right .blog_stats {
  display: none; }

#footer .blog_item .summary p {
  display: none; }

#information-information .webdigifyblog {
  float: right;
  margin-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  display: none; }

#content .webdigifyblog .box-heading, .banners-slider-carousel .box-heading {
  margin: 0 0 15px 0; }

.content_footer_right .post_hover {
  display: none; }

.box.webdigifyblog {
  float: right;
  position: relative;
  width: 100%;
  margin: 35px 0; }

.social-block ul {
  margin: 10px 0; }

.webdigifyblog .box-head {
  float: left;
  padding-bottom: 10px;
  width: 100%; }

.box-head .blog_tagline {
  color: #808080;
  float: left;
  font-size: 14px;
  margin-bottom: 12px;
  display: none; }

#content .box-product .blog-items {
  float: left; }

.blog-left {
  margin: 0;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: auto;
  float: left; }

.blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

.blog-left .blog-image img {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none; }

.blog-right {
  float: left;
  text-align: center;
  width: auto;
  margin: 15px 0 0; }

.blog-right h4 a {
  float: left;
  font-size: 14px;
  line-height: 24px;
  text-transform: inherit;
  width: 100%;
  text-align: left;
  overflow: hidden;
  color: #333;
  font-weight: 500;
  margin-bottom: 12px; }

.footer-blocks .blog-item .blog-desc {
  display: none; }

.content_footer_top .news-title3 {
  display: none; }

.view-blog {
  width: 100%;
  margin: 0; }

.webdigifyblog .view-blog {
  width: auto;
  margin: 0; }

.read-more {
  text-align: end; }

.read-more a {
  text-transform: inherit;
  display: inline-block;
  vertical-align: top;
  position: relative;
  font: 500 14px/24px 'Rubik',Helvetica,sans-serif;
  overflow: hidden;
  z-index: 1;
  color: #fbb50d;
  text-decoration: underline;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out; }

.read-more .fa {
  display: none; }

.write-comment {
  display: inline-block;
  float: none;
  padding-left: 18px;
  position: relative;
  text-align: left; }

.common-home .write-comment::before {
  background: #a8a8a8 none repeat scroll 0 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  /* content: ""; */
  display: block;
  font-family: fontawesome;
  height: 4px;
  left: 9px;
  position: absolute;
  top: 11px;
  width: 4px; }

.date-time {
  text-transform: inherit; }

.blog_default_width {
  width: 240px; }

.blog-desc {
  font-family: Rubik;
  text-align: left;
  line-height: 22px;
  color: #7A7A7A;
  margin: 0px 0 15px;
  width: 100%;
  float: left;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.6px; }

.information-blogger-blogs .blog-desc {
  width: 100%;
  margin: 3px 0 20px 0; }

.blog-right h4 {
  margin: 0; }

.blog-img {
  padding: 0px; }

.comment-wrapper {
  position: relative;
  float: right;
  margin: 5px 0 0; }

.comment-wrapper .write-comment a::before {
  color: #000;
  content: "\f0e5";
  float: left;
  font-family: "FontAwesome";
  font-size: 13px;
  margin-right: 5px; }

.blog-img .img-thumbnail {
  border: none; }

.blog-date {
  float: left; }

#content #blog-carousel, #content #blog-grid {
  padding: 0px;
  border: none;
  overflow: visible;
  float: left;
  width: 100%; }

#content .box.webdigifyblog .box-content {
  padding: 0;
  margin: 0 -15px 0; }

#content #blog-carousel .blog-item {
  overflow: hidden;
  position: relative;
  float: left; }

#content #blog-carousel .blog-item {
  overflow: hidden;
  position: relative;
  float: left; }

.owl-carousel .owl-buttons div i {
  display: none; }

.owl-carousel .owl-buttons .owl-prev {
  left: 0;
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1; }

#blog-carousel .owl-controls .owl-buttons .owl-prev {
  right: auto;
  left: -60px;
  text-align: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 37px; }

#blog-carousel .owl-controls .owl-buttons .owl-prev {
  right: 42px;
  left: auto;
  text-align: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  height: 30px;
  width: 30px; }

.owl-buttons {
  position: absolute;
  right: 0px;
  top: -56px;
  z-index: 1;
  bottom: auto; }

#blog-carousel .owl-controls .owl-buttons .owl-prev::before {
  content: "\f104";
  color: #363636;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  margin: auto;
  font-size: 25px;
  font-family: FontAwesome;
  line-height: 30px;
  font-weight: 400; }

#blog-carousel .owl-controls .owl-buttons .owl-next {
  right: 15px;
  left: auto;
  text-align: center;
  padding: 0;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  height: 30px;
  width: 30px; }

#blog-carousel .owl-controls .owl-buttons .owl-next::before {
  content: "\f105";
  color: #666666;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  margin: auto;
  font-size: 25px;
  font-family: FontAwesome;
  line-height: 30px;
  color: #363636;
  font-weight: 400; }

#blog-carousel .product-block, #blog-grid .product-block {
  padding: 10px 15px 15px;
  background: #FFFFFF; }

.single-blog .blog-img {
  position: relative;
  margin-bottom: 15px; }

.single-blog .panel-default {
  border: medium none;
  float: left;
  width: 100%;
  margin: 0; }

#add-comment .col-sm-10.buttons {
  padding: 0 8px; }

.single-blog .block-title {
  float: left;
  margin: 10px  0;
  width: 99%; }

.single-blog .block-title > legend {
  margin: 10px 0 0; }

.single-blog .blog-desc {
  padding: 0;
  text-align: justify;
  display: block; }

.single-blog .block-title h3 {
  padding-top: 10px; }

.single-blog .view-comment .panel-default {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  padding: 15px !important;
  border: 2px solid #f0f0f0; }

.single-blog h4 {
  font-size: 16px;
  display: inline-block;
  width: 100%; }

.view-comment .form-group b {
  font-weight: 600; }

.view-comment .col-sm-3 .name {
  font-weight: 500;
  color: #333333; }

.view-comment .col-sm-3 .date {
  color: #b5b5b5; }

.user_icon {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  float: left;
  margin: 0px 15px 0 0;
  padding: 10px 15px;
  text-align: center; }

.comment_info {
  float: left;
  width: 85%; }

.comment_info .comment-text {
  margin-top: 12px; }

.view-comment {
  clear: both; }

.view-comment .col-sm-8 {
  width: 100%;
  margin-top: 10px; }

#add-comment .col-sm-8 {
  width: 100%; }

/*Webdigify Blog light-box CSS */
.blog-left:hover .post-image-hover::before {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px); }

.post-image-hover::before {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
  -webkit-transition: opacity 0.35s ease 0s, -webkit-transform 0.35s ease 0s;
  -moz-transition: opacity 0.35s ease 0s, -moz-transform 0.35s ease 0s;
  -ms-transition: opacity 0.35s ease 0s, -ms-transform 0.35s ease 0s;
  -o-transition: opacity 0.35s ease 0s, -o-transform 0.35s ease 0s;
  width: 100%; }

.post-image-hover {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  width: 100%; }

.blog-image .post_hover {
  bottom: 0;
  height: 51px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  top: 0;
  width: 110px;
  opacity: 0;
  filter: alpha(opacity=0); }

.product-block .blog-left:hover .post_hover {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -kkhtml-opacity: 1;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s; }

.product-block .blog-left:hover .zoom, .product-block .blog-left:hover .readmore_link,
.all-blog .blog-left-content:hover .zoom, .all-blog .blog-left-content:hover .readmore_link {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -kkhtml-opacity: 1;
  margin: 0 5px;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s; }

.blog-left .icon.zoom .fa, .blog-left .icon.readmore_link .fa {
  font-size: 13px;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s; }

.blog-left .blog-image .zoom:hover, .blog-left .blog-image .readmore_link:hover {
  color: #ffffff;
  border-color: #fbb50d;
  background: #fbb50d; }

.all-blog .blog-left-content {
  float: left;
  margin-bottom: 20px; }

.all-blog .blog-left-content .blog-image {
  float: left;
  position: relative;
  width: 100%;
  border-radius: 0px; }

.blog-right-content {
  float: left;
  padding-bottom: 30px; }

.blog-right-content .blog-title {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
  padding: 0;
  position: relative;
  text-transform: inherit; }

.blog-right-content .read-more a {
  padding: 8px 20px;
  background: #020202;
  border-radius: 25px;
  text-transform: inherit;
  position: relative;
  text-decoration: none;
  color: #fff; }

.blog-right-content .read-more a:hover {
  color: #fff;
  background: #fbb50d; }

.blog-right-content .write-comment a {
  color: #0a7ef0;
  font-size: 13px; }

.write-comment-count {
  float: left;
  margin-right: 10px;
  color: #000; }

.information-blogger-blogs .write-comment {
  float: left; }

.information-blogger-blogs .blog-image {
  margin-bottom: 0px;
  background-position: center center;
  height: auto;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat; }

.information-blogger-blogs .panel-body {
  padding: 0px; }

body.information-blogger-blogs.layout-2.left-col .panel-inner {
  margin: 0px -15px; }

.information-blogger-blogs .panel-default > .panel {
  margin-bottom: 30px;
  border: none; }

.information-blogger-blogs .panel-default .panel-inner .panel-default {
  float: none;
  display: inline-block;
  vertical-align: top;
  width: 49.7%;
  margin-bottom: 5px;
  margin-top: 0px;
  border: 0px; }

.blog-image img {
  border-radius: 15px; }

.panel-default {
  margin-top: 20px; }

.write-comment a, .date-time i.fa.fa-calendar {
  float: left;
  padding: 3px 8px 4px 6px; }

.blog-date {
  float: left;
  padding-top: 2px; }

.blog-left-content:hover .post-image-hover::before {
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
  -ms-opacity: 1; }

.blog-date .fa.fa-calendar {
  display: none; }

#content.single-blog > legend {
  margin: 15px 0 20px; }

#content #blog-carousel .product-block:hover .date-comment, #content #blog-grid .product-block:hover .date-comment {
  bottom: 0px; }

.write-comment a, .date-time {
  color: #111;
  font-weight: 400;
  font-size: 13px;
  margin: 0 0 15px 0;
  float: left;
  background: #e0f1fb;
  padding: 4px 7px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

.write-comment > a {
  color: #2b85f4;
  padding: 0 0 0 15px; }

.write-comment a:hover, .write-comment a:focus {
  color: #000; }

.comment-wrapper .write-comment a:hover, .comment-wrapper .write-comment a:focus, .comment-wrapper .write-comment a:hover::before {
  color: #111111; }

.information-blogger-blogs .read-more {
  float: left; }

.static_links .cart-menu > li:first-child {
  max-height: 185px;
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 20px; }

.blog_top_content:hover .post-image-hover {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1; }

.all-blog .panel-default .main_description {
  display: none; }

.blog_top_content .post-image-hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  width: 100%; }

.product-block .blog-left:hover .zoom, .product-block .blog-left:hover .readmore_link, .blog_top_content:hover .blog-image .zoom, .blog_top_content:hover .blog-image .readmore_link {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1; }

.blog-left .blog-image .icon, .blog_top_content .blog-image .icon {
  display: inline-block;
  float: none;
  height: 45px;
  width: 45px;
  line-height: 14px;
  border: 2px solid white;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  padding: 14px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
  margin: 0 5px; }

.post-image-hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  width: 100%; }

.blog_post .text-right .btn-primary {
  color: #F9BF39;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 2px solid #F9BF39;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  -moz-border-radius: 5px;
  background: #fff;
  line-height: 20px; }

.blog-left-content .post_hover {
  left: 0;
  margin: 0 auto;
  opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 44%; }

.all-blog .blog-left-content:hover .post_hover {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -kkhtml-opacity: 1;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s; }

.blog-left-content .icon.zoom .fa, .blog-left-content .icon.readmore_link .fa {
  font-size: 14px;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  color: #fff; }

.blog-left-content .blog-image .zoom {
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  opacity: 0;
  -khtml-opacity: 0;
  height: 45px;
  width: 45px;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  padding: 11px 12px 9px 12px;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  z-index: 10;
  background: none; }

.blog-left-content .blog-image .zoom:hover, .blog-left-content .blog-image .readmore_link:hover {
  background: #fbb50d  none repeat scroll 0 0;
  color: #111 !important;
  border-color: #fbb50d; }

.webdigifyblog .product-block:hover .post_hover {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -kkhtml-opacity: 1; }

.blog-item:hover .blog-left .blog-image img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.blog-left-content .blog-image .readmore_link {
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -khtml-border-radius: 50%;
  color: #ffffff;
  background: none;
  display: inline-block;
  height: 45px;
  width: 45px;
  opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  padding: 11px 12px 9px 12px;
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  z-index: 10; }

#add-comment .col-sm-10 {
  width: 66.6667%; }

#add-comment .col-sm-2 {
  width: 25%; }

#content .webdigifyblog .buttons, #blog-carousel .owl-pagination {
  display: none; }

/*Webdigify Blog light-box CSS */
/*Webdigify Blog CSS */
/* Datetimepicker Css */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right {
  position: relative; }

.bootstrap-datetimepicker-widget {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -khtml-border-radius: 0 !important; }

.bootstrap-datetimepicker-widget td,
.bootstrap-datetimepicker-widget th {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -khtml-border-radius: 0 !important; }

.bootstrap-datetimepicker-widget td.day:hover,
.bootstrap-datetimepicker-widget td.hour:hover,
.bootstrap-datetimepicker-widget td.minute:hover,
.bootstrap-datetimepicker-widget td.second:hover {
  background: #f3f3f3 !important; }

.bootstrap-datetimepicker-widget td.today:before {
  border-bottom: 7px solid #2196f3 !important; }

.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover {
  background-color: #2196f3 !important;
  color: #161b1e !important; }

.bootstrap-datetimepicker-widget td.active.today:before {
  border-bottom-color: #161b1e !important; }

.bootstrap-datetimepicker-widget td span {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -khtml-border-radius: 0 !important;
  background-color: #f3f3f3 !important; }

.bootstrap-datetimepicker-widget td span:hover {
  color: #111111; }

.bootstrap-datetimepicker-widget td span.active {
  background-color: #f6d000 !important;
  color: #0a7ef0 !important;
  text-shadow: none !important; }

.bootstrap-datetimepicker-widget thead tr:first-child th:hover {
  background: #f3f3f3 !important; }

.bootstrap-datetimepicker-widget td span.old:hover {
  color: #fff !important; }

.picker-switch.accordion-toggle .btn {
  border: none;
  background-color: #111111;
  color: #fff !important; }

.bootstrap-datetimepicker-widget td.day {
  position: relative; }

.bootstrap-datetimepicker-widget td.day.active:before {
  border-bottom: 7px solid #0a7ef0 !important;
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover {
  background-color: #f6d000 !important;
  color: #0a7ef0 !important;
  text-shadow: none !important; }

.bootstrap-datetimepicker-widget a[data-action] {
  border: none; }

/* End Datetimepicker Css */
#blog-carousel .owl-controls .owl-buttons .owl-prev:hover::before, #blog-carousel .owl-controls .owl-buttons .owl-next:hover::before {
  color: #111111; }

#blog-carousel .owl-controls .owl-buttons .owl-prev, #blog-carousel .owl-controls .owl-buttons .owl-next {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none; }

.product-block .image .reg-image, #column-right .image .reg-image, #column-left .image .reg-image {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1; }

.product-thumb .image img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  vertical-align: middle; }

.product-thumb .image a {
  display: block;
  text-align: center;
  position: relative; }

#form-review .col-sm-2, #form-review .col-sm-10 {
  width: 100%; }

.container h1, .container h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #111; }

.category_thumb .category_description {
  padding: 0px; }

#form-review > h3 {
  font-size: 16px;
  font-weight: 500; }

#content.productpage .product-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  margin: 0px 0 10px 0px;
  padding: 0px 0px 10px;
  text-transform: inherit;
  color: #222;
  border-bottom: 1px solid #eaeaea; }

#content h3.product-option {
  text-transform: inherit;
  font-size: 16px;
  font-weight: 500; }

.breadcrumb + .row {
  clear: both; }

#content table thead tr td {
  background: #f5f5f5 none repeat scroll 0 0;
  color: #303030;
  font-weight: 500;
  font-size: 14px; }

.account-account #content ul.list-unstyled {
  margin-bottom: 25px; }

.breadcrumb {
  padding: 23px 0;
  margin: 0;
  list-style: none;
  float: left;
  width: 100%;
  text-align: center; }

#content #carousel-0 .box-content {
  margin: 0;
  padding: 0;
  position: relative; }

.pull-left.footer-block {
  width: 19%;
  text-align: right !important; }

#social_block .title_block {
  display: none; }

#social_block .toggle-footer {
  padding: 55px 0 0; }

/* QUCIK-VIEW */
.quickview .wish-comp .btn.btn-default.wishlist, .quickview .wish-comp .btn.btn-default.compare {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
  border: medium none;
  color: #808080 !important;
  font-weight: normal; }

.productpage-quickview .product-left {
  width: 50%;
  float: left; }

.productpage-quickview .product-right {
  width: 50%;
  float: left;
  clear: none !important; }

.productpage-quickview h3.product-title {
  font-weight: 500;
  text-transform: inherit;
  font-size: 18px;
  border-bottom: 1px solid #eaeaea;
  margin: 0 auto 10px;
  padding: 0 0 10px; }

.quickview .product-right ul li.discount, .quickview .product-right ul li.rewardpoint, .quickview .product-right ul li.price-tax {
  clear: both; }

.header-quickview .background_wrapper {
  display: none; }

.header-quickview .responsive-menu {
  display: none; }

.header-quickview .quickview {
  top: 0;
  padding: 20px; }

.image .quickview {
  opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0; }

#column-left .quickview {
  display: none; }

.quickview .fa.fa-heart {
  color: #808080; }

.quickview .btn.btn-primary.btn-lg.btn-block {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #7e5cdd;
  color: #7e5cdd;
  font-weight: normal;
  padding: 7px 20px 6px !important;
  text-transform: inherit; }

.quickview {
  display: inline-block;
  left: 0%;
  right: auto;
  padding: 15px 0;
  position: absolute;
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  z-index: 99;
  right: 0;
  top: 50%;
  cursor: pointer; }

.quickview .fa.fa-eye:hover {
  background: #111111 none repeat scroll 0 0;
  border-color: #111111; }

.quickview .fa.fa-eye {
  background: #111111 none repeat scroll 0 0;
  border: 1px solid #111111;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  color: #ffffff;
  padding: 15px; }

.product-quickview .quickview {
  bottom: 0;
  left: 10px;
  top: 10px;
  opacity: 1 !important;
  -khtml-opacity: 1 !important;
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important; }

.product-thumb:hover .quickview {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  color: #000000 !important; }

h4:hover a, h4:focus a {
  color: #111111;
  text-decoration: none; }

.quickview > a {
  color: #000; }

#column-left .quickview-button {
  display: none; }

.row .cat_prod .button-gr.lists {
  bottom: 50%;
  position: absolute;
  margin: 0 auto;
  width: 100%; }

.quickview .container {
  width: 100%;
  background: #FFFFFF;
  padding: 0 10px; }

.quickview .container .row {
  margin: 0; }

.quickview .top_button {
  display: none; }

.quickview #product2 {
  float: left;
  width: 100%; }

.quickview #product2 h3 {
  width: 100%; }

.productpage-quickview #product2 h3 {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  text-transform: none;
  color: #333333; }

.box.related_prd {
  float: left;
  width: 100%;
  margin-top: 70px; }

.alert.alert-success.animated {
  width: 500px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  padding: 20px 20px;
  opacity: 0.95 !important;
  -webkit-opacity: 0.95 !important;
  -moz-opacity: 0.95 !important;
  -khtml-opacity: 0.95 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #111111 !important;
  right: 0 !important;
  left: auto !important; }

#banner1 .swiper-pagination {
  display: none;
  cursor: pointer; }

.alert.alert-success.animated span {
  font-size: 15px !important; }

.alert.alert-success.animated span a {
  color: #111111;
  text-decoration: underline; }

.alert.alert-success.animated span .fa.fa-check-circle {
  color: #9bcf85; }

.alert.alert-success.animated button.close {
  opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  color: #111111; }

.account-login .well .form-group a, .affiliate-login .well .form-group a {
  line-height: 34px;
  float: right;
  text-decoration: underline; }

.form-group.qty {
  clear: both;
  overflow: hidden;
  position: relative;
  padding: 15px 0px 20px;
  margin: 0; }

.discount-per {
  color: #228B22; }

.stock_msg {
  display: block;
  padding: 10px 0 0 5px;
  text-transform: inherit;
  font-weight: 500; }

#related-carousel {
  padding-bottom: 20px; }

.productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist, .product-rightinfo .btn-group.prd_page .btn-default.compare {
  color: #666666;
  font-size: 13px;
  display: inline-block;
  padding: 5px 15px 5px 0;
  text-align: left;
  width: auto;
  transition: none;
  height: auto;
  background: none;
  border: 0;
  top: 0px;
  font-weight: 400; }

.productpage .form-group.qty .compare:before, .product-rightinfo .btn-group.prd_page button.btn.btn-default.compare:before {
  content: "";
  float: left;
  background-image: url(../image/webdigify/sprite3.png);
  background-repeat: no-repeat;
  background-position: -36px -75px;
  background-size: 58px;
  margin: 0 0px;
  color: #111;
  border: none;
  position: relative;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  padding: 10px 12px; }

.productpage .form-group.qty .wishlist, .quickview-model .form-group.qty .wishlist {
  margin: 0px; }

#button-cart, #button-cart2, .productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist, .product-rightinfo .btn-group.prd_page .btn-default.compare {
  float: left; }

#form-currency .btn-link:hover, #form-language .btn-link:hover,
#form-currency .btn-link:focus, #form-language .btn-link:focus {
  text-decoration: none; }

.btn-group, .btn-group-vertical .btn-group.prd_page {
  width: 100%;
  float: left;
  padding: 9px 0px; }

#product .btn-group.prd_page {
  padding-top: 20px; }

.lang-curr-wrapper .wd-language {
  padding: 0 20px 0 0; }

.productpage .form-group.qty .wishlist:before, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:before {
  content: "";
  float: left;
  background-image: url(../image/webdigify/sprite3.png);
  background-repeat: no-repeat;
  background-position: -35px -38px;
  background-size: 57px;
  margin: 0 0px;
  color: #111;
  border: none;
  position: relative;
  border-radius: 50%;
  padding: 11px 12px; }

.headertop {
  position: relative; }

#search .fa.fa-search {
  display: block; }

.input-group-btn .search {
  display: block; }

.input-group-btn button.btn.btn-default {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px; }

/********************* Responsive start ******************************/
@media (max-width: 1499px) {
  #content .banners-slider-carousel .product-block {
    margin: 0px 15px; }
  .box.webdigifyblog {
    margin: 15px 0 40px; }
  .ProductbyCategory {
    padding: 30px 0 15px; }
  .product-layout.product-grid {
    width: 24.5%; } }

@media (max-width: 1459px) {
  .product-layout.product-grid {
    width: 24.5%; } }

@media (max-width: 1249px) {
  .cms-inner-block1 {
    margin-right: 23px; }
  .cms-inner-block1, .cms-inner-block2 {
    width: 49%; }
  .countbox .timebox {
    margin: 0 3px 0 0; }
  .quickview-button {
    margin: 0 3px 0 0; }
  .product_hover_block {
    right: -50px; } }

@media (max-width: 1199px) and (min-width: 768px) {
  #nav-one > li > .dropdown-menu.megamenu.column1, #nav-one .hiden_menu .dropdown-menu {
    overflow: unset; } }

@media (max-width: 1199px) {
  .footer-blocks {
    padding: 0 15px; }
  .main-menu a {
    padding: 21px 16px; }
  .col-sm-4.header-logo {
    margin: 0 15px 0 0; }
  .cms-inner-block1, .cms-inner-block2 {
    width: 48%; }
  .collection-right-banner {
    display: none; }
  .tab-head span {
    float: left; }
  .swiper-viewport .swiper-button-prev::before, .swiper-viewport .swiper-button-next::before {
    height: 40px;
    width: 40px;
    padding: 10px 0; }
  .main-slider .swiper-button-next, .main-slider .swiper-button-prev {
    margin: 0; }
  .cmsbanner-block .description .banner_text {
    margin: 30px 0 0 0; }
  .product-list .product-details {
    width: 63%; }
  .hometab, .ProductbyCategory, .featured {
    padding: 30px 0; }
  .header_search .form-control.input-lg {
    width: 170px; }
  .header_search .btn.btn-default.btn-lg {
    padding: 11px 16px 11px 13px; }
  .header_right {
    width: 20%;
    margin: 0 0 0 10px; }
  #column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
    width: 100%; }
  #column-left .product-items .product-details, #column-left .product-items .product-details h4, #column-right .product-items .product-details h4 {
    padding-top: 7px; }
  .product-layout.product-grid {
    width: 32.8%; }
  .search_box .form-control-select {
    width: 130px; }
  .dropdown.myaccount {
    margin: 5px 0 5px 0px; }
  #newslatter {
    padding: 38px 40px; } }

@media (min-width: 992px) {
  .col-sm-3.column.footerleft .contact-block h5 {
    display: none; } }

@media (max-width: 991px) {
  #form-currency .btn-link strong {
    padding: 0 11px 0 6px; }
  .col-sm-5.product-right {
    width: 50%; }
  .col-sm-4.product-left {
    width: 50%; }
  .newsletter #txtemail {
    padding: 10px 120px 10px 20px; }
  .header_wishlist span {
    top: -7px;
    background: #fff;
    color: #111; }
  .header_search .btn.btn-default.btn-lg {
    font-size: 0px;
    padding: 10px 13px 3px; }
  .header_search .form-control.input-lg {
    width: 190px;
    padding: 8px 15px 8px 15px; }
  .search_box {
    width: 120px; }
  .header_search .btn.btn-default.btn-lg .fa.fa-search:before {
    padding-right: 0px; }
  .search_box .form-control-select, .search_box .form-control-select.active {
    width: 120px; }
  span.res-menu-close {
    display: none; }
  #searchbox {
    width: auto; }
  .box-category-top {
    padding: 7px 25px 7px 40px;
    margin-top: 2px;
    display: none; }
  .box-category-top::before {
    background-size: 19px;
    left: 12px;
    width: 16px;
    height: 17px;
    top: 9px; }
  .social-block ul li {
    padding: 0 !important; }
  .header_top .lang-curr-wrapper {
    display: none; }
  h5.toggle:hover {
    background: rgba(255, 255, 255, 0.05); }
  .dropdown.myaccount ul.dropdown-menu.dropdown-menu-right.myaccount-menu {
    top: 35px; }
  #cart .dropdown-menu {
    top: 68px; }
  .header_bottom_cover {
    height: 58px; }
  .login_acc {
    border-bottom: 1px solid #eaeaea; }
  .login_acc li {
    padding: 3px 40px 7px; }
  .pull-left #form-language .btn-group span img {
    margin-bottom: 2px;
    padding: 0 11px 0 3px; }
  #footer {
    padding: 40px 0 30px; }
  #cart-total .mycart, #cart-total {
    display: none; }
  .nav-inner.container {
    margin: 0 auto; }
  .layout-2.left-col #content, .layout-2.right-col #content {
    width: 100%; }
  .layout-2.left-col #column-left, .layout-2.right-col #column-right {
    width: 100%;
    float: left;
    padding: 10px 0 10px;
    display: block; }
  #column-left .box, #column-right .box {
    margin: 0px 0 15px;
    /* border: none; */
    float: left;
    width: 100%;
    padding: 0px; }
  #column-left .swiper-viewport {
    display: none; }
  .layout-2.left-col #content {
    padding: 0;
    margin-top: 0;
    clear: both; }
  #column-left .box .box-content, #column-right .box .box-content, #column-left .box .filterbox, #column-right .box .filterbox {
    display: none; }
  #column-left .list-group, #column-right .list-group {
    display: none; }
  .menu_toggle {
    display: none !important; }
  /*cartbit*/
  .static-menu {
    display: none; }
  .col-sm-5.header_search {
    float: right;
    padding: 9px 0; }
  .cmsbanner-block .description .title {
    font-size: 20px; }
  .cmsbanner-block .description .banner_text {
    margin: 15px 0 0 0; }
  .footer-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 15px; }
  .footer-logo img {
    display: inline-block; }
  .newsletter {
    width: 50%; }
  .footer-blocks .col-sm-3 {
    width: 100%;
    padding: 0; }
  #content ul.list-unstyled {
    display: block; }
  .btn-group.prd_page {
    width: 100%; }
  .contact-form-design .left {
    width: 100%;
    float: left; }
  .contact-form-design .contact-form {
    float: left;
    width: 100%;
    margin-top: 20px; }
  /*cartbit*/
  .block_newsletter h3 .subtitle {
    font-size: 14px;
    line-height: 18px; }
  .block_newsletter h3 {
    margin: 0 0 10px;
    float: left;
    width: 100%; }
  .footer-appinner .title {
    font-size: 18px;
    line-height: 28px; }
  .footer-appinner .subtitle {
    font-size: 12px;
    line-height: 20px; }
  .social-block ul li a {
    margin-right: 0px; }
  .dropdown.myaccount .lang-curr-wrapper {
    display: block; }
  #cart {
    padding: 9px 0px; }
  .dropdown.myaccount .lang-curr-wrapper.active .pull-left #form-language .btn-group span,
  .dropdown.myaccount .lang-curr-wrapper.active .pull-left #form-currency .btn-group span {
    font-size: 13px;
    color: #111;
    font-weight: 400; }
  .dropdown.myaccount .lang-curr-wrapper.active .language-menu, .dropdown.myaccount .lang-curr-wrapper.active .currency-menu {
    position: initial;
    border: 0px;
    padding: 0px 10px;
    width: 100%; }
  .search_box .form-control-select {
    padding: 5px 10px 5px 10px; }
  #form-currency .btn-link, #form-language .btn-link {
    padding: 3px 10px; }
  .box-content-category {
    top: 50px; }
  .common-home .box-content-category {
    display: none; }
  #nav-one li .megamenu .dropdown-inner ul li.dropdown:after {
    right: 0px; }
  #nav-one .dropdown a::after, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown a::after,
  #nav-one li .megamenu .dropdown-inner ul li.dropdown:after {
    content: "\f107";
    right: 20px;
    position: absolute;
    font-family: 'fontawesome';
    font-size: 16px;
    display: inline-block; }
  #nav-one li .megamenu ul.childs_1 {
    display: block;
    padding-top: 0px; }
  .responsive-menu ul > li {
    display: block;
    position: static; }
  #res-menu.responsive-menu .main-navigation.active {
    left: 0px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  header .responsive-menu .main-navigation {
    position: fixed;
    z-index: 9999;
    display: block;
    margin-top: 0px;
    padding: 0px 10px 10px;
    width: 300px;
    height: 100%;
    max-height: initial;
    background-color: #ffffff;
    list-style: none;
    overflow: auto;
    left: -100%;
    top: 0;
    border: 1px solid #ededed;
    -webkit-box-shadow: 0 8px 15px 0 black;
    box-shadow: 0 8px 15px 0 black;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s; }
  .menu-active {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  .menu-active::before {
    content: "";
    opacity: 0.7;
    filter: alpha(opacity=70);
    position: absolute;
    left: 300px;
    height: 100%;
    width: calc(100% - 300px);
    top: 0;
    z-index: 99999;
    overflow: hidden; }
  .nav-responsive span:before {
    content: "";
    background-image: url(../image/webdigify/menu.svg);
    background-repeat: no-repeat;
    background-position: -1px 0px;
    background-color: transparent;
    background-size: 20px;
    width: 18px;
    height: 22px;
    position: absolute;
    left: 0px;
    top: 17px 0 18px 10px;
    display: inline-block;
    vertical-align: top; }
  #res-menu {
    display: block;
    padding: 6px 11px; }
  ul.main-navigation.treeview.active .menu.expandable span {
    display: inline-block;
    vertical-align: top; }
  .header_center #res-menu.responsive-menu .main-navigation.active .menu.expandable {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
    padding: 12px 0px 12px 10px;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 99;
    cursor: pointer; }
  .header_center #res-menu.responsive-menu .main-navigation.active .menu.expandable span {
    font-size: 15px;
    text-align: left;
    line-height: 26px;
    font-weight: 600;
    color: #000000;
    display: block; }
  .responsive-menu .collapsable-hitarea {
    height: auto;
    width: auto;
    padding: 5px; }
  .responsive-menu .main-navigation li a {
    padding: 10px 10px;
    font-weight: 400;
    font-size: 13px;
    text-align: left; }
  .count-down .countbox strong {
    font-size: 11px;
    line-height: 22px; }
  .countbox .timebox .time {
    font-size: 9px; }
  .box.special .product-block .product-details {
    padding: 15px 10px; }
  .blog_default_width {
    width: 50%; }
  .footer-blocks .footerleft .contact-block ul {
    display: none;
    clear: both; }
  .footer-blocks ul.list-unstyled {
    display: none;
    clear: both; }
  .product-list .product-details {
    width: 70%; }
  #content.productpage .product-title {
    padding: 20px 0px 10px; }
  #column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
    width: 60%;
    padding: 0px 10px;
    float: left; }
  .product_hover_block .action button.cart_button {
    font-size: 0px;
    background-image: url(../image/webdigify/sprite3.png);
    background-repeat: no-repeat;
    background-position: -34px 8px;
    background-size: 65px;
    background-color: #f5f5f5;
    height: 40px;
    width: 40px; }
  .product-block .product-details:hover .product_hover_block .action button.cart_button {
    background-color: #f5f5f5; }
  .product-block .product-details .product_hover_block .action button.cart_button:hover {
    background-color: #fbb50d; }
  footer h5 {
    font-size: 16px;
    line-height: 22px;
    float: left;
    width: 100%;
    color: #111111; }
  .block_newsletter h3 .title .material-icons {
    height: 25px;
    width: 25px;
    background-size: 25px;
    background-repeat: no-repeat; }
  .footer-appinner ul.list-unstyled {
    display: flex; }
  #column-left .product-thumb, #column-right .product-thumb {
    padding: 15px 10px 5px; }
  #column-left .box .box-heading, #column-right .box .box-heading {
    background: none;
    padding: 15px 15px;
    font-size: 14px;
    line-height: 16px; }
  .newsletter.col-sm-6 {
    display: inline-block;
    float: none;
    vertical-align: top;
    width: 80%;
    text-align: center; }
  .footer-blocks .footerleft .contact-block li,
  #footer .column li {
    padding: 0px 15px; }
  .product-layout.product-grid {
    width: 24%; }
  #content .box .box-heading, .box.related_prd .box-heading {
    font-size: 20px;
    line-height: 24px; }
  .box.special .box-heading {
    margin-bottom: 8px; }
  .tab-head {
    margin-bottom: 8px; }
  #content .webdigifyblog .box-heading {
    margin: 0 0 10px 0; }
  .countbox.hastime {
    padding: 2px 2px; }
  .hometab, .ProductbyCategory, .featured {
    padding: 25px 0 25px; }
  .htabs a {
    padding: 10px 20px; }
  .box.webdigifyblog {
    margin: 15px 0 25px; }
  .pull-left.wd-language, .pull-left.wd-currency {
    top: 0px;
    width: 100%;
    padding: 0; }
  .btn-group, .btn-group-vertical .btn-group.prd_page {
    padding: 9px 0px 0px; }
  #form-currency .btn-link, #form-language .btn-link {
    border: medium none;
    color: #666;
    font-size: 13px;
    line-height: 22px;
    text-decoration: none;
    padding: 0px 20px;
    float: left;
    letter-spacing: 0.3px; }
  .wd-currency, .wd-language {
    position: relative;
    right: auto; }
  #form-currency .btn-link.dropdown-toggle, #form-language .btn-link.dropdown-toggle {
    background: no-repeat;
    padding: 0px 10px; }
  .dropdown.myaccount {
    margin: 9px 0 9px 0px;
    float: right; }
  .header_wishlist {
    display: none; }
  .action button.cart_button {
    padding: 7px 10px 7px 32px;
    background-position: 10px 8px; }
  #newslatter {
    padding: 18px 20px 30px;
    text-align: center; }
  .block_newsletter {
    display: block; }
  #block_1 {
    width: 100%;
    padding: 0; }
  .col-sm-3.column.footerleft {
    margin: 0px; }
  #wdfooter {
    text-align: center;
    padding: 0 0 10px 0; }
  .product-list .product-details .product_hover_block button.wishlist,
  .product-layout.product-list .product-details .product_hover_block button,
  .product-layout.product-list .product-details.product_hover_block button.compare_button,
  .product-layout.product-list .product_hover_block .quickview-button a.quickbox {
    height: 35px;
    width: 35px; }
  #information-information #content,
  #account-return #content,
  #information-sitemap #content,
  #account-account #content,
  #account-voucher #content,
  #account-wishlist #content,
  #account-order #content,
  #account-edit #content,
  #account-newsletter #content,
  #account-password #content, #account-address #content,
  #account-recurring #content, #account-reward #content,
  #account-transaction #content, #checkout-checkout #content,
  #checkout-cart #content, #account-register #content {
    width: 100%;
    margin: 0; }
  .offer_title {
    color: #111111; }
  .offer-content {
    text-align: left; }
  .header_right {
    float: left;
    width: 100%;
    margin: 0;
    display: none; }
  .header_center {
    padding: 0;
    position: static;
    width: 40px;
    margin: 9px 0;
    height: 40px;
    background: #020202;
    cursor: pointer;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    margin-right: 20px; }
  nav.nav-container {
    position: static;
    background: none;
    padding: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none; }
  .nav-responsive span {
    display: block;
    text-align: center;
    position: relative; }
  .header_top {
    padding: 10px 0; }
  .col-sm-4.header-logo {
    width: 160px;
    padding: 7px 0; }
  .header_top .account {
    padding: 0 0 0 15px; } }

@media (max-width: 767px) {
  .offer-title {
    display: none; }
  #top {
    background: #f5f5f5;
    padding: 10px 0;
    border-top: none;
    position: relative;
    height: 60px; }
  .top-links-toggle {
    display: block;
    cursor: pointer; }
  .top-links {
    display: none;
    position: absolute;
    background: #fff;
    padding: 10px;
    width: 200px;
    z-index: 999;
    border: 1px solid #e8e9eb;
    top: 100%;
    right: 0; }
  .top-links-toggle > i, #res-menu i {
    font-size: 21px; }
  .top-links .links-item {
    padding: 5px;
    width: 100%;
    display: block; }
  .top-links .links-item::before {
    display: none; }
  .box-category-top {
    display: none; }
  #newslatter::after {
    border-right: 0px; }
  .col-sm-5.product-right {
    width: 100%; }
  .col-sm-4.product-left {
    width: 100%; }
  .quickview-button {
    display: none; }
  #column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
    float: left; }
  .newsletter .btn.btn-lg {
    padding: 0px 20px; }
  .responsive-menu .nav-responsive .expandable {
    margin: 0;
    height: 20px; }
  .main-navigation img {
    display: none; }
  .res-menu-close {
    visibility: hidden;
    position: absolute;
    cursor: pointer;
    opacity: 0; }
  .menu-active .res-menu-close {
    visibility: visible;
    left: 240px;
    z-index: 9999;
    transition: all 600ms ease 0s;
    top: 10px;
    opacity: 1;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px; }
  .res-menu-close > svg {
    width: 12px;
    height: 12px;
    fill: #0a7ef0;
    stroke: #0a7ef0;
    stroke-width: 5px; }
  .cmsbanner-block {
    margin: 20px 0; }
  .cms-inner-block1, .cms-inner-block2 {
    width: 100%;
    margin: 0 0 20px 0; }
  .top-links-toggle > svg {
    width: 20px;
    height: 20px;
    fill: #0a7ef0; }
  .special .product-block:hover .product-details {
    margin-top: 0; }
  .product-block:hover .product-block-inner {
    box-shadow: none;
    padding-bottom: 0;
    margin-bottom: 0;
    z-index: 9; }
  .product-block .product-block-inner {
    z-index: 9; }
  .product-block:hover .countdown {
    opacity: 1; }
  .product-thumb .price {
    font-size: 14px; }
  .product-thumb .price-old {
    font-size: 12px; }
  .manufacturer_title {
    display: none; }
  .manufacture_block ul {
    padding: 0;
    float: none; }
  .manufacture_block {
    padding: 25px 0;
    text-align: center; }
  .payment-block ul {
    padding: 0; }
  .payment-block {
    width: 100%;
    text-align: center; }
  p.powered {
    margin: 10px 0;
    width: 100%;
    text-align: center; }
  .newsletter {
    width: 100%;
    margin: 0; }
  .social-block ul li {
    float: none; }
  .tab-head {
    padding: 0;
    background: none;
    border-bottom: 1px solid #eaeaea; }
  .hometab-heading.box-heading {
    padding: 20px 0 0 20px; }
  .htabs {
    margin: 0;
    width: 100%; }
  .tab-head .sub-heading {
    margin: 0px 0px 0 20px; }
  .tab-head .coupon {
    margin-top: 15px; }
  .featured {
    padding: 40px 0; }
  .tab-head span {
    width: 100%;
    font-size: 12px; }
  footer {
    margin-top: 25px; }
  .product-layout.product-grid {
    width: 32.5%;
    min-height: 360px; }
  .pagination-right {
    width: 100%;
    margin: 20px 0 0; }
  .col-sm-12.category_list {
    padding: 0; }
  .category_list li {
    margin: 0 10px 10px 0; }
  .breadcrumb {
    width: 100%; }
  .breadcrumb > li {
    white-space: normal; }
  .col-sm-8.product-left {
    position: relative;
    top: auto;
    width: 410px;
    margin: 0 auto; }
  .layout-1 #content {
    padding: 0; }
  .col-sm-4.product-right {
    padding: 20px 0 0;
    width: 100%; }
  #tabs_info.product-tab, .box.related_prd {
    margin: 30px 0 0; }
  .col-sm-4.search_term {
    padding: 0 0 10px; }
  .col-sm-3.search_subcategory {
    padding: 0; }
  .information-contact #content {
    padding: 30px; }
  .contact-form-design.container {
    margin: 0px auto 70px;
    padding: 15px; }
  .table-responsive.full-view {
    display: none; }
  .table.table-bordered.shopping-cart.responsive {
    display: block; }
  .shopping-cart .btn-primary {
    margin: 0; }
  .listing {
    min-height: unset;
    padding: 80px 15px;
    line-height: 30px; }
  .alert.alert-success.animated {
    width: 100%; }
  .box.special .product-block .product-details {
    padding: 10px 5px; }
  .countbox.hastime {
    padding: 5px 5px; }
  .col-sm-5.product-right {
    width: 100%; }
  .banners-slider-carousel {
    margin: 0px 0 20px; }
  .tablatest_default_width, .tabbestseller_default_width, .tabspecial_default_width, .featured_default_width {
    width: 26%; }
  .productcategory0_default_width.wdproductcategory, .productcategory1_default_width.wdproductcategory,
  .productcategory2_default_width.wdproductcategory, .productcategory3_default_width.wdproductcategory,
  .productcategory4_default_width.wdproductcategory, .productcategory5_default_width.wdproductcategory {
    width: 26%; }
  .special_default_width {
    width: 26%; }
  #content .banners-slider-carousel .product-block .product-block-inner {
    border: 0px; }
  .footer-appinner ul.list-unstyled {
    display: block; }
  .box.special .box-heading {
    margin-bottom: 8px; }
  .owl-buttons {
    top: -50px; }
  .box.webdigifyblog {
    margin: 15px 0 25px; }
  .footer-top {
    padding: 28px 0 28px; }
  .product-list .product_hover_block {
    bottom: 0px; }
  .product-block .product_hover_block {
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: static;
    text-align: left;
    height: auto; }
  .product_hover_block button.wishlist, .product_hover_block button.compare_button {
    display: inline-block;
    vertical-align: top; }
  .product_hover_block button, .quickbox {
    background-color: #f5f5f5; }
  .product_hover_block button.wishlist {
    background-image: url(../image/webdigify/wishlist-hover.svg);
    background-repeat: no-repeat; }
  .product_hover_block button.compare_button {
    background-image: url(../image/webdigify/compare-hover.svg);
    background-repeat: no-repeat; }
  .product_hover_block button, .quickbox {
    height: 35px;
    width: 35px;
    background-size: 15px; }
  .countdown {
    float: right;
    right: 5px;
    left: auto;
    top: 5px;
    bottom: auto;
    position: absolute; }
  .countbox .timebox {
    margin: 0 0px 5px 0;
    display: block;
    height: 32px;
    min-width: 32px; }
  .count-down .countbox strong {
    height: 32px; }
  .countbox .timebox .time {
    margin: -20px 0 0 0; }
  .product_hover_block button.wishlist:hover:after,
  .product_hover_block button.compare_button:hover:after {
    height: 36px;
    width: 36px; }
  .col-sm-4.header-logo {
    width: 130px;
    padding: 11px 0;
    margin: 0; }
  .header_center {
    margin-right: 10px; }
  .header_top .account {
    padding: 0 0 0 8px; }
  .col-sm-3.header_cart {
    padding: 0 0 0 8px; }
  #content .box .box-heading, .box.related_prd .box-heading, #carousel-0 .box-heading {
    background: none;
    padding: 0 0 10px;
    border-bottom: 1px solid #eaeaea; }
  .tab-head .hometab-heading.box-heading {
    border: none !important; }
  .htabs a {
    margin: 0px 0 0; }
  .product_hover_block button.wishlist, .product_hover_block button.compare_button {
    margin: 15px 0 5px; }
  .product-layout.product-list .product_hover_block button.compare_button {
    margin: 0px; } }

@media only screen and (max-width: 660px) {
  .productpage .description {
    font-size: 10px;
    line-height: 20px; }
  .productpage .special-price {
    font-size: 30px; }
  .product-layout.product-list {
    padding: 0 0 20px; }
  .module_default_width {
    width: 33%; }
  .banners-slider-carousel .container {
    padding: 0 10px; }
  .layout-2.left-col #content .col-sm-12 {
    padding: 0 10px; }
  .product-layout.product-list .img-wrap {
    width: 30%; }
  .dropdown.myaccount {
    margin: 3px 0; }
  #cart {
    padding: 2px 0px; }
  .col-sm-5.header_search {
    float: left;
    width: 100%;
    text-align: center;
    padding: 15px 0px 5px; }
  #searchbox {
    width: auto;
    float: none;
    margin: 0 auto; }
  .header_center {
    height: 40px;
    margin: 4px 10px 4px 0; }
  .col-sm-4.header-logo {
    padding: 6px 0; }
  #cart .dropdown-menu {
    top: 62px; }
  .search_box .form-control-select {
    font-size: 11px; }
  .header_search .form-control.input-lg {
    padding: 8px 8px 8px 8px;
    font-size: 11px; } }

@media only screen and (max-width: 650px) {
  .col-sm-4.header-logo {
    display: block;
    text-align: center; }
  .htabs a.selected, .htabs li a {
    padding: 10px 10px 10px 0px; }
  .etabs {
    float: none;
    text-align: center;
    width: 100%;
    padding: 0px 0px 50px 0px; }
  .htabs a.selected, .htabs li.active a {
    background: transparent;
    color: #fbb50d; }
  #searchbox {
    float: none;
    display: inline-block;
    vertical-align: top; }
  .etabs li.tab {
    width: 86%;
    text-align: center; }
  .htabs a.selected, .htabs li.active a {
    background: #fbb50d;
    color: #fff; }
  .hometab-heading.box-heading {
    text-align: center !important;
    float: none !important; }
  #blog-carousel .product-block, #blog-grid .product-block {
    padding: 0px 10px 0px; }
  .countdown {
    margin: 0 0 8px 0px; }
  .box.special {
    margin: 40px 0 0; }
  .product-block.product-thumb.transition {
    padding: 10px 10px; }
  .special-vrstk {
    max-width: 188px; }
  #footer {
    padding: 20px 0px; } }

@media only screen and (max-width: 543px) {
  .tablatest_default_width, .tabbestseller_default_width, .tabspecial_default_width, .productcategory0_default_width,
  .productcategory1_default_width, .productcategory2_default_width, .productcategory3_default_width, .productcategory4_default_width, .featured_default_width {
    width: 50%; }
  .productcategory0_default_width.wdproductcategory, .productcategory1_default_width.wdproductcategory,
  .productcategory2_default_width.wdproductcategory, .productcategory3_default_width.wdproductcategory,
  .productcategory4_default_width.wdproductcategory, .productcategory5_default_width.wdproductcategory, .special_default_width {
    width: 49%; }
  .banners-slider-carousel {
    margin: 10px 0 10px; }
  .product-layout.product-grid {
    width: 49%; }
  .module_default_width {
    width: 33%; }
  .box.webdigifyblog {
    margin: 0px 0 10px; }
  #block_1 {
    padding: 15px 0px 0px; }
  .hometab, .ProductbyCategory, .featured {
    padding: 15px 0 10px; }
  .box.special {
    margin: 8px 0 10px; }
  .social-block ul {
    margin: 0px 0; }
  .footer-appinner .subtitle {
    margin: 0 0 8px; }
  .block_newsletter h3 .title {
    font-size: 18px;
    line-height: 20px; }
  .footer-top {
    padding: 18px 20px; }
  .footer-appinner .title {
    font-size: 16px;
    line-height: 26px;
    margin: 0px 0 4px; }
  .block_newsletter h3 {
    font-size: 16px;
    line-height: 26px; }
  #newslatter {
    padding: 0px; }
  .newsletter.col-sm-6 {
    width: 100%;
    padding: 0; }
  .product-list .product-block .product-details {
    width: 100%;
    text-align: center; }
  .product-layout.product-list .img-wrap {
    float: none;
    text-align: center;
    width: 100%;
    height: auto; }
  .product-list .product-details .action {
    margin: 0 2px 0 0;
    display: inline-block;
    vertical-align: top;
    width: auto;
    text-align: center;
    float: none; }
  .product-block .product_hover_block {
    width: auto;
    float: left;
    text-align: left; }
  .product-layout.product-list .product-block .product_hover_block {
    display: inline-block;
    vertical-align: top;
    float: none; }
  .stock_status {
    display: none; }
  #content .product-list .product-block .image {
    float: none;
    width: auto; }
  .product-layout.product-list .img-wrap .product_hover_block {
    display: none; } }

@media only screen and (max-width: 479px) {
  .cmsbanner-block .cms-inner-block1 .description, .cmsbanner-block .cms-inner-block2 .description {
    left: 20px;
    top: 10%; }
  .cmsbanner-block .description .title {
    font-size: 14px;
    margin: 0 0 10px 0; }
  .cmsbanner-block .description .toptitle {
    font-size: 16px; }
  .cmsbanner-block .description .banner_text {
    margin: 10px 0 0 0; }
  #cart .dropdown-menu {
    width: 280px; }
  .stock_status {
    font-size: 12px;
    padding: 7px 10px; }
  .social-block > ul {
    padding: 0;
    margin: 5px 0; }
  .social-block ul li a {
    margin-right: 0; }
  .module_default_width {
    width: 50%; }
  #content .banners-slider-carousel .product-block {
    margin: 0; }
  #content .banners-slider-carousel .product-block .product-block-inner {
    padding: 0 !important; }
  .sort-by-wrapper {
    width: 100%; }
  .show-wrapper {
    margin: 15px 0 0; }
  .category_filter .btn-list-grid {
    padding: 0; }
  .category_filter .show {
    margin: 7px 10px 0 0; }
  .pagination-wrapper .page-link, .pagination-wrapper .page-result {
    width: 100%;
    text-align: center; }
  .payment-block ul img {
    margin-bottom: 5px; }
  .col-sm-8.product-left {
    width: 290px; }
  #content.productpage .product-title {
    font-size: 16px; }
  .productpage .write-review, .productpage .review-count {
    margin: 0 5px; }
  #button-search {
    width: 100%;
    margin: 10px 0 20px; }
  .information-contact #content {
    padding: 15px;
    background-repeat: repeat; }
  .header_search .form-control.input-lg {
    padding: 8px 10px 8px 15px;
    width: 180px; }
  #accordion h4.panel-title a.accordion-toggle {
    display: block; }
  .checkout-cart #accordion .panel-title > a, .checkout-checkout #accordion .panel-title {
    background: #f5f5f5 none repeat scroll 0 0;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    margin: 0 0 10px 0; }
  #accordion .panel-title > a {
    color: inherit; }
  #accordion .panel-heading .fa.fa-caret-down {
    float: right;
    margin-top: 4px; }
  #accordion .col-sm-10 {
    width: 75%; }
  #accordion .form-horizontal .control-label {
    text-align: left; }
  .alert-dismissable .close, .alert-dismissible .close {
    right: 0 !important; }
  #accordion.panel-group .panel {
    width: 100%;
    background-color: #fff;
    border: none; }
  .checkout-success p a {
    color: #111111; }
  .container {
    padding: 0; }
  .product-compare .table-bordered {
    border: 1px solid #e5e5e5;
    float: left;
    overflow: auto; }
  .swiper-viewport .swiper-button-prev::before, .swiper-viewport .swiper-button-next::before {
    background: none;
    border: 0px;
    color: #111; }
  .swiper-viewport .swiper-button-next {
    right: 0px;
    width: 40px;
    height: 40px;
    transition: none; }
  .swiper-viewport .swiper-button-prev {
    left: 0px;
    transition: none;
    height: 40px;
    width: 40px; }
  .main-slider .swiper-viewport:hover .swiper-button-prev {
    left: 0px; }
  .main-slider .swiper-viewport:hover .swiper-button-next {
    right: 0px; }
  .swiper-viewport .swiper-button-next:hover::before, .swiper-viewport .swiper-button-prev:hover::before {
    background: none;
    color: #fff; }
  .newsletter #txtemail {
    padding: 10px 90px 10px 20px; }
  .product-block.product-thumb.transition {
    padding: 10px 7px; }
  .product-thumb h4 {
    font-size: 12px;
    line-height: 16px; }
  #tabs_info.product-tab ul.nav.nav-tabs {
    width: 100%;
    margin: 0 0 10px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    float: none;
    text-align: center;
    display: block; }
  .nav-tabs > li {
    margin: 0 0 10px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    float: none !important; }
  .footer-top {
    padding: 20px 0 15px; }
  .hometab, .ProductbyCategory, .featured {
    padding: 10px 0 10px; }
  #content .box .box-heading, .box.related_prd .box-heading {
    padding: 15px 0px 10px; }
  #block_1 .list-unstyled li:first-child {
    margin: 0 0px 0 0; }
  #footer .container {
    padding: 0px; }
  .pagination {
    float: none !important; } }

@media only screen and (max-width: 380px) {
  .search_box {
    width: 100px; }
  .search_box .form-control-select, .search_box .form-control-select.active {
    width: 100px;
    height: 40px; }
  .header_search .form-control.input-lg {
    width: 150px;
    padding: 8px 10px 8px 10px;
    height: 40px; }
  .box.special .product-block-inner .image,
  .box.special .product-block .product-details {
    width: 100%; }
  .ProductbyCategory {
    padding: 15px 0 5px; }
  .box.webdigifyblog {
    margin: 10px 0 20px; }
  #column-left .product-items .product-details, #column-left .product-items .product-details h4, #column-right .product-items .product-details h4 {
    padding-top: 0px; }
  .product-info .additional-carousel {
    position: relative;
    padding: 0 20px;
    overflow: hidden; }
  .pagination {
    float: none !important; }
  .header_search .btn.btn-default.btn-lg {
    padding: 10px 10px 3px;
    height: 40px; }
  .product-details .rating span {
    font-size: 0; } }

/* ---------------------- Start wd - Product-Extended block  --------------------------*/
#tab-description {
  font-size: 18px !important;
  font-weight: 350 !important;
  color: #000 !important; }

#tab-description p {
  color: #000 !important; }

#tab-description {
  float: left;
  width: 100%; }

#tab-description .product-description,
#tab-description .producttabdescription {
  float: left;
  width: 100%; }

#tab-description .producttabdescription .tabdescleft {
  padding-left: 0;
  float: left; }

#tab-description .producttabdescription .tabdescleft .tabdescleft-img {
  float: left; }

#tab-description .producttabdescription .tabdescleft .tabdescleft-img img {
  transition: all 1.2s ease-out 0s;
  -webkit-transition: all 1.2s ease-out 0s;
  display: block;
  max-width: 100%; }

#tab-description .producttabdescription .tabdescright {
  float: left;
  padding-right: 0; }

#tab-description .producttabdescription .tabdescright .tabdescright-details,
#tab-description .producttabdescription .tabdescright .table-info-product {
  float: left;
  width: 100%; }

#tab-description .producttabdescription .tabdescright .table-info-product .maintitle {
  font: 700 18px/24px 'Rubik',Helvetica,sans-serif;
  color: #111;
  float: left;
  width: 100%; }

#tab-description .producttabdescription .tabdescright .table-info-product .title {
  font: 600 14px/21px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  color: #111111;
  width: 13%; }

#tab-description .producttabdescription .tabdescright .table-info-product .table-detail {
  float: left;
  width: 100%; }

#tab-description .producttabdescription .tabdescright .table-info-product .table-detail .table-feature {
  display: flex;
  align-items: center;
  padding: 10px 0; }

@media (max-width: 1200px) {
  #tab-description .producttabdescription .tabdescright .table-info-product .title {
    width: 20%; } }

@media (max-width: 991px) {
  #tab-description .producttabdescription .tabdescright .table-info-product .title {
    width: 40%; }
  #tab-description .producttabdescription .tabdescright .table-info-product .detail {
    width: 60%; }
  #tab-description .producttabdescription .tabdescright {
    width: 51%; }
  #tab-description .producttabdescription .tabdescleft {
    width: 49%; } }

@media (max-width: 767px) {
  #tab-description .producttabdescription .tabdescleft .tabdescleft-img {
    float: none;
    display: inline-block;
    vertical-align: top; }
  #tab-description .producttabdescription .tabdescleft {
    text-align: center;
    margin: 0 0 15px;
    float: left;
    width: 100%;
    padding: 0; }
  #tab-description .producttabdescription .tabdescright {
    float: left;
    padding: 0;
    width: 100%; }
  #tab-description .producttabdescription .tabdescright .table-info-product .title {
    width: 20%; }
  #tab-description .producttabdescription .tabdescright .table-info-product .detail {
    width: 80%; } }

@media (max-width: 543px) {
  #tab-description .producttabdescription .tabdescright .table-info-product .title {
    width: 50%; }
  #tab-description .producttabdescription .tabdescright .table-info-product .detail {
    width: 50%; }
  #tab-description .producttabdescription .tabdescright .table-info-product .table-detail .table-feature {
    align-items: baseline; } }

/* ---------------------- End wd - Product-Extended block --------------------------*/
/***********************Product page Banner*********************/
.product-detail-right {
  float: left;
  width: 100%;
  padding-left: 15px;
  overflow: hidden; }

#wdcmsrightservices {
  float: left;
  width: 100%;
  padding: 0 0 20px; }

#wdcmsrightservices .product-services {
  background: #f5f5f5;
  padding: 20px 15px;
  float: left;
  width: 100%; }

#wdcmsrightservices .product-services .wdproduct-services {
  margin: 0;
  padding: 0; }

#wdcmsrightservices .product-services .wdservice .service-title {
  font: 400 13px/24px 'Rubik',Helvetica,sans-serif;
  color: #111111; }

#wdcmsrightservices .wdservice .service-icon {
  height: 42px;
  width: 42px;
  transition: all 600ms ease-in-out 0s;
  -webkit-transition: all 600ms ease-in-out 0s;
  -moz-transition: all 600ms ease-in-out 0s;
  -o-transition: all 600ms ease-in-out 0s;
  -ms-transition: all 600ms ease-in-out 0s;
  float: left;
  flex-shrink: 0; }

#wdcmsrightservices .product-services .wdservice {
  display: flex;
  align-items: center; }

#wdcmsrightservices .wdservice .wdservice-desc {
  padding: 0 0 0 10px; }

#wdcmsrightservices .product-services .wdproduct-services > li {
  float: left;
  width: 100%;
  margin: 10px 0; }

#wdcmsrightservices .wd-return.wdservice .service-icon {
  background: url("../image/webdigify/product-services.png") repeat scroll -25px -18px; }

#wdcmsrightservices .wd-secure.wdservice .service-icon {
  background: url("../image/webdigify/product-services.png") repeat scroll -25px -107px; }

#wdcmsrightservices .wd-support.wdservice .service-icon {
  background: url("../image/webdigify/product-services.png") repeat scroll -25px -188px; }

#wdcmsrightservices .wd-shop.wdservice .service-icon {
  background: url("../image/webdigify/product-services.png") repeat scroll -25px -277px; }

#wdcmsrightservices .wd-return.wdservice:hover .service-icon {
  background-position: -109px -18px; }

#wdcmsrightservices .wd-secure.wdservice:hover .service-icon {
  background-position: -109px -107px; }

#wdcmsrightservices .wd-support.wdservice:hover .service-icon {
  background-position: -109px -188px; }

#wdcmsrightservices .wd-shop.wdservice:hover .service-icon {
  background-position: -109px -277px; }

#wdcmsrightbanner:hover .wdcmrightbanner a img {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04); }

#wdcmsrightbanner .wdcmrightbanner a img {
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out 0s;
  -moz-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  -ms-transition: all 300ms ease-in-out 0s;
  max-width: 100%; }

#wdcmsrightbanner .wdcmrightbanner a {
  overflow: hidden;
  display: block; }

@media (max-width: 1499px) {
  #wdcmsrightservices .wdservice .wdservice-desc {
    padding: 0 0 0 10px; }
  #wdcmsrightservices .product-sevices .wdservice .service-title {
    font-size: 13px;
    line-height: 22px; }
  #wdcmsrightservices .product-services {
    padding: 10px 10px; }
  .product-detail-right {
    padding-left: 0px; } }

@media (max-width: 1199px) {
  #wdcmsrightservices .product-sevices .wdservice .service-title {
    font-size: 12px;
    line-height: 20px; } }

@media (max-width: 991px) {
  #wdcmsrightservices {
    max-width: 280px;
    padding: 0 15px 20px 0; }
  .col-sm-3.product {
    width: 100%; }
  .product-detail-left {
    width: 100%; } }

@media (max-width: 543) {
  #wdcmsrightbanner {
    margin: 0px; } }

#wdcmsrightbanner {
  float: left; }

@media (max-width: 991px) and (min-width: 544) {
  #wdcmsrightbanner {
    float: left;
    width: 190px;
    margin-left: 15px; } }

/***********************End Product Page Banner**************/
.product-detail-left {
  width: 100%;
  float: left;
  padding-right: 0px; }

/* Webdigify www.templatemela.com End */
#product .form-group.qty button#button {
  color: #fff;
  background: #293544; }

#product .form-group.qty button#button:hover {
  color: #111;
  background: #fbb50d; }

@media (max-width: 991px) {
  .product-detail-left {
    width: 100%;
    padding-top: 20px; } }

/***************Start Testimonial Cms Block ***************/
#wdcmstestimonial {
  width: 100%;
  float: left;
  padding: 50px 0; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title h4 {
  color: #111 !important;
  font: 700 14px/28px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  margin: 0; }

#wdcmstestimonial .testimonial-content .testimonial-desc {
  float: left;
  width: 100%;
  margin: 0 0 10px; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title .wdtestimonial-subtitle {
  font: 400 14px/28px 'Rubik',Helvetica,sans-serif;
  text-transform: inherit;
  color: #111; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
  font: 400 14px/24px 'Rubik',Helvetica,sans-serif;
  color: #111;
  text-transform: inherit;
  margin: 0 0 10px;
  text-align: left; }

#wdcmstestimonial .wdtestimonial-content .tttestimonial-title {
  font: 700 30px/44px 'Rubik',Helvetica,sans-serif;
  color: #111; }

#wdcmstestimonial .testimonial-content {
  width: 64%;
  padding: 0 0 0 60px;
  position: relative; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title h4::after {
  content: '-';
  font-weight: 400;
  margin: 0 8px; }

#wdcmstestimonial .testimonial-user-title {
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  float: left;
  width: 100%; }

#wdcmstestimonial #wdtestimonial-carousel {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
  float: left;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 60px 0 0; }

#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  float: left;
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05); }

#wdtestimonial-carousel li {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

#wdcmstestimonial .owl-carousel .owl-pagination .owl-page.active {
  background: #fbb50d;
  height: 12px;
  width: 12px; }

#wdcmstestimonial .owl-carousel .owl-pagination .owl-page {
  height: 8px;
  width: 8px;
  background: #293544;
  border-radius: 8px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px 0 0;
  transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out; }

#wdcmstestimonial .owl-carousel .owl-pagination {
  position: static;
  float: left;
  width: 100%;
  text-align: center;
  margin: 15px 0 0; }

@media (max-width: 1199px) {
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
    font-size: 14px;
    line-height: 26px; }
  #wdcmstestimonial .testimonial-content {
    padding: 0 0 0 40px; }
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
    padding: 0 40px 0 0; } }

@media (max-width: 991px) {
  #wdcmstestimonial {
    padding: 30px 0; }
  #wdcmstestimonial .testimonial-content {
    width: 72%; } }

@media (max-width: 767px) {
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
    font-size: 13px;
    line-height: 24px; }
  #wdcmstestimonial .testimonial-content {
    padding: 0 0 0 20px; }
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
    padding: 0 20px 0 0; } }

@media (max-width: 543px) {
  #wdcmstestimonial {
    padding: 15px 0; }
  #wdtestimonial-carousel li {
    display: block; }
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
    padding: 0 0 15px;
    float: none;
    width: 100%;
    border: none;
    text-align: center; }
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image img {
    float: none;
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top; }
  #wdcmstestimonial .testimonial-content {
    width: 100%;
    float: left;
    padding: 0; }
  #wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
    text-align: center; }
  #wdcmstestimonial .testimonial-user-title {
    justify-content: center; } }

.header-logo {
  width: 20% !important; }

.header-cms-block span.content {
  margin-right: 10px; }

#nav-one {
  list-style-type: none; }

#nav-one li {
  display: block;
  float: left;
  width: 100%; }

.box-content-category {
  position: fixed;
  left: 0;
  top: 0;
  width: 33vw;
  height: 100vh;
  overflow-y: scroll;
  /* width:auto!important; */ }

.box-content-category ul li a:before {
  display: none !important; }

@media (max-width: 767px) {
  .col-sm-4.header-logo {
    width: 200px !important; } }

@media only screen and (max-width: 660px) {
  .col-sm-4.header-logo {
    padding: 0px 0 !important; } }

.fa-tags:before {
  content: none !important; }

.header_top.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin-top: -1px;
  animation: 1s ease 0s normal both 1 running fixedmenu;
  z-index: 100;
  background: white; }

.header_bottom.fixed {
  margin-top: 75px !important; }

.header_top {
  padding: 10px 0px 0px 0px !important; }

#content.productpage .product-title {
  text-transform: inherit !important; }

.description-right a {
  color: #fbb50d; }

/********** End Testimonial Cms BLock  **********/
.title-module span {
  margin-top: 15px;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  color: black; }

.oneclick > .help-block {
  font-size: 0.65em; }

#button-oneclick {
  padding: 11px 15px 11px 37px;
  border-radius: 5px;
  color: #ffffff;
  background: #fbb50d;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  background-image: url(../image/webdigify/cart-hover.svg);
  background-repeat: no-repeat;
  background-position: 9px 8px;
  background-size: 22px;
  position: relative;
  z-index: 1; }

#button-oneclick:before {
  content: "";
  position: absolute;
  background: #fbb50d;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 400ms ease-in-out;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  height: 0; }

#button-oneclick:hover:before {
  height: 100%;
  background: #111111;
  color: #fff;
  border-radius: 5px; }

#button-oneclick:hover:after {
  content: "";
  background-image: url(../image/webdigify/cart-hover.svg);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  position: absolute;
  background-size: 22px;
  background-position: 9px -3px;
  background-repeat: no-repeat;
  z-index: 9;
  left: 0; }

.oleclick {
  border-top: 1px solid #eaeaea;
  padding: 15px 5px 0px 0; }

.product-description {
  margin-bottom: 20px; }

.delivery-icon {
  margin-right: 10px;
  padding-bottom: 2px; }

.glyphicon {
  margin-right: 10px; }

.oleclick h3 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.6px;
  color: #111111;
  margin-bottom: 0px !important; }

table.delivery {
  margin-left: 30px; }

.colcat {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #838383; }

.colval {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87); }

td.colright {
  padding-left: 110px; }

@media (max-width: 767px) {
  .colcat {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #838383; }
  .colval {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87); }
  td.colright {
    padding-left: 9px; }
  table.delivery td {
    padding-bottom: 10px;
    line-height: 10px !important; }
  .oleclick h3 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: #111111; }
  table.delivery {
    margin-top: 15px;
    margin-left: 0px !important; }
  .advant {
    margin-left: 0px !important;
    max-width: 100% !important;
    margin-top: 15px !important; } }

table.delivery td {
  line-height: 30px; }

.advant {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  max-width: 72%; }

.advant p {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000; }

.delivery-icon {
  width: 20px !important; }

.colleft {
  min-width: 170px; }

.ass h1 {
  color: #111 !important;
  font-size: 30px !important;
  font-family: Rubik !important;
  font-weight: 700 !important;
  line-height: 40px !important;
  letter-spacing: 0.6px !important; }

.ass {
  margin-bottom: 40px; }

.mg-top {
  border-radius: 10px;
  height: 324px !important;
  background-position: center !important; }

.blog-desc1 {
  margin-bottom: 40px; }

.blog-manu {
  margin-top: 40px; }

.manu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background: #F7F7F5;
  width: 920px;
  height: 75px;
  flex-shrink: 0;
  margin-bottom: 20px; }

.manu img {
  max-height: 55px;
  max-width: 100px;
  margin-left: 20px; }

.manu span {
  margin-left: 25px;
  color: #000;
  font-size: 13px;
  font-family: Rubik;
  line-height: 22px;
  letter-spacing: 0.6px;
  width: 68%; }

.manu a {
  color: #FBB50D !important;
  font-size: 15px !important;
  line-height: 24px !important; }

.navigation span {
  color: #000;
  font-size: 18px;
  line-height: 26px; }

.navigation {
  margin-top: 40px !important;
  border-bottom: 2px solid #F7F7F5;
  margin-bottom: 40px !important; }

.ul-hd {
  margin-top: 13px !important;
  margin-bottom: 13px !important; }

.h-nav {
  color: #3D3BFF;
  font-size: 18px;
  line-height: 26px;
  margin-top: 13px; }

.blog-desc h2 {
  color: #111;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.6px;
  margin-bottom: 15px; }

.blog-desc1 ul li {
  color: #666;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.6px;
  list-style-type: square; }

.blog-desc1 ul li::marker {
  color: #ffab00;
  font-size: 25px; }

.blog-desc ul li {
  color: #666;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.6px;
  list-style-type: square; }

.blog-desc ul li::marker {
  color: #ffab00;
  font-size: 25px; }

.perelink-block {
  cursor: pointer;
  margin: 40px 0px 40px 0px;
  border-radius: 16px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row; }

.perelink-block img {
  width: 209px;
  flex-shrink: 0;
  margin-left: 41px;
  border-radius: 5px;
  margin-bottom: 41px;
  margin-top: 41px; }

.perelink-flex {
  display: flex;
  flex-direction: column;
  margin-left: 30px; }

.perelink-flex span {
  color: #000;
  font-size: 16px;
  line-height: 23px;
  margin-top: 41px;
  margin-bottom: 18px; }

.perelink-flex a {
  color: #FBB50D;
  font-size: 20px;
  line-height: 29px; }

.read-more-blog {
  padding: 20px;
  background: #FEF5E0;
  text-align: left;
  margin-top: 40px; }

.read-more-blog span {
  color: #000;
  font-size: 13px;
  font-family: Rubik;
  line-height: 21px;
  letter-spacing: 1px;
  text-transform: uppercase; }

.read-more-blog ul li {
  list-style: none !important;
  margin-top: 13px !important;
  color: #3D3BFF !important;
  font-size: 14px !important;
  font-family: Rubik !important;
  line-height: 21px !important; }

input.form-control.input-lg {
  border-radius: 5px 0px 0px 5px; }

.blog-desc table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  border-collapse: collapse;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px; }

.blog-desc th, .blog-desc td:first-child {
  background: #AFCDE7;
  color: black;
  padding: 10px 20px; }

.blog-desc th, td {
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: white; }

.blog-desc td {
  background: #D8E6F3; }

.blog-desc th:first-child, td:first-child {
  text-align: left; }

@media (max-width: 767px) {
  .manu a {
    margin-left: 0; }
  .manu {
    width: auto; }
  .manu img {
    margin-left: 0px; }
  .manu span {
    margin-left: 0px; }
  .perelink-block img {
    width: 300px;
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 5px; }
  .perelink-block {
    justify-content: center;
    flex-direction: column;
    align-items: center; }
  .perelink-flex {
    margin-left: 0px;
    padding: 0px 25px 0px 25px; }
  .perelink-flex span {
    margin-top: 16px;
    margin-bottom: 18px; }
  .perelink-flex a {
    margin-bottom: 12px;
    word-break: break-all; }
  .blog-desc ul {
    column-count: 1 !important; }
  .blog-desc h2 {
    font-size: 18px; }
  .blog-desc1 ul {
    column-count: 1 !important; }
  .blog-desc1 h2 {
    font-size: 18px; } }

/* Color */
/* Color */
/* Border radius */
/* Border radius */
/* Font family */
/* Font family */
@font-face {
  font-family: "remixicon";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/remixicon.woff2") format("woff2"); }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Roboto-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Roboto-Bold.ttf") format("truetype"); }

.dl_theme * {
  box-sizing: border-box; }
  .dl_theme *:before, .dl_theme *:after {
    box-sizing: border-box; }

.dl_theme html,
.dl_theme body,
.dl_theme div,
.dl_theme span,
.dl_theme applet,
.dl_theme object,
.dl_theme iframe,
.dl_theme h1,
.dl_theme h2,
.dl_theme h3,
.dl_theme h4,
.dl_theme h5,
.dl_theme h6,
.dl_theme p,
.dl_theme blockquote,
.dl_theme pre,
.dl_theme a,
.dl_theme abbr,
.dl_theme acronym,
.dl_theme address,
.dl_theme big,
.dl_theme cite,
.dl_theme code,
.dl_theme del,
.dl_theme dfn,
.dl_theme em,
.dl_theme img,
.dl_theme ins,
.dl_theme kbd,
.dl_theme q,
.dl_theme s,
.dl_theme samp,
.dl_theme small,
.dl_theme strike,
.dl_theme strong,
.dl_theme sub,
.dl_theme sup,
.dl_theme tt,
.dl_theme var,
.dl_theme b,
.dl_theme u,
.dl_theme i,
.dl_theme center,
.dl_theme dl,
.dl_theme dt,
.dl_theme dd,
.dl_theme ol,
.dl_theme ul,
.dl_theme li,
.dl_theme fieldset,
.dl_theme form,
.dl_theme label,
.dl_theme legend,
.dl_theme table,
.dl_theme caption,
.dl_theme tbody,
.dl_theme tfoot,
.dl_theme thead,
.dl_theme tr,
.dl_theme th,
.dl_theme td,
.dl_theme article,
.dl_theme aside,
.dl_theme canvas,
.dl_theme details,
.dl_theme embed,
.dl_theme figure,
.dl_theme figcaption,
.dl_theme footer,
.dl_theme header,
.dl_theme hgroup,
.dl_theme menu,
.dl_theme nav,
.dl_theme output,
.dl_theme ruby,
.dl_theme section,
.dl_theme summary,
.dl_theme time,
.dl_theme mark,
.dl_theme audio,
.dl_theme video {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  outline: none; }

@-ms-viewport {
  .dl_theme {
    width: device-width; } }

.dl_theme article,
.dl_theme aside,
.dl_theme dialog,
.dl_theme figcaption,
.dl_theme figure,
.dl_theme footer,
.dl_theme header,
.dl_theme hgroup,
.dl_theme main,
.dl_theme nav,
.dl_theme section {
  display: block;
  position: relative; }

.dl_theme html {
  font-family: "Rubik", Helvetica, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.dl_theme body {
  margin: 0;
  color: #111111;
  font-size: 14px;
  font-family: "Rubik", Helvetica, sans-serif;
  font-weight: 400;
  text-align: left;
  line-height: 1.5; }

.dl_theme [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

.dl_theme hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-color: #eee; }

.dl_theme h1,
.dl_theme h2,
.dl_theme h3,
.dl_theme h4,
.dl_theme h5,
.dl_theme h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.3; }

.dl_theme p {
  margin-top: 0;
  margin-bottom: 10px; }

.dl_theme time {
  display: block; }

.dl_theme ul {
  margin: 10px 10px 10px 25px;
  padding: 0;
  list-style: disc; }
  .dl_theme ul ul {
    margin: 10px 10px 10px 25px;
    padding: 0;
    list-style: disc; }

.dl_theme ol {
  margin: 10px 10px 10px 25px;
  padding: 0;
  list-style-type: decimal; }
  .dl_theme ol ol {
    margin: 10px 10px 10px 25px;
    padding: 0;
    list-style-type: decimal; }

.dl_theme abbr[title], .dl_theme abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  cursor: help; }

.dl_theme address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

.dl_theme dl {
  margin-top: 0;
  margin-bottom: 0; }

.dl_theme dt {
  font-weight: 400; }

.dl_theme dd {
  margin-bottom: 0;
  margin-left: 0; }

.dl_theme blockquote {
  margin: 0; }

.dl_theme b,
.dl_theme strong {
  font-weight: 700; }

.dl_theme small {
  font-size: 80%; }

.dl_theme sub,
.dl_theme sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0; }

.dl_theme sub {
  bottom: -.25em; }

.dl_theme sup {
  top: -.5em; }

.dl_theme a {
  color: #FBB50D;
  text-decoration: none;
  background-color: transparent; }
  .dl_theme a:hover {
    color: #111111; }
  .dl_theme a:not([href]) {
    color: inherit;
    text-decoration: none; }
    .dl_theme a:not([href]):hover {
      color: inherit;
      text-decoration: none; }

.dl_theme pre,
.dl_theme code,
.dl_theme kbd,
.dl_theme samp {
  font-size: 14px;
  font-family: "Rubik", Helvetica, sans-serif; }

.dl_theme pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

.dl_theme figure {
  margin: 0; }

.dl_theme img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
  border-style: none; }

.dl_theme svg {
  overflow: hidden;
  vertical-align: middle; }

.dl_theme table {
  border-collapse: collapse;
  border-spacing: 0; }

.dl_theme caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #111111;
  text-align: left;
  caption-side: bottom; }

.dl_theme th {
  text-align: inherit; }

.dl_theme label {
  display: inline-block;
  margin-bottom: 5px; }

.dl_theme input,
.dl_theme label,
.dl_theme select,
.dl_theme button,
.dl_theme textarea {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: middle;
  font-size: 14px;
  font-family: "Rubik", Helvetica, sans-serif;
  line-height: 1;
  white-space: normal;
  background: none;
  box-shadow: none;
  outline: none; }

.dl_theme input[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }
  .dl_theme input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

.dl_theme input[type="radio"], .dl_theme input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

.dl_theme input[type="date"], .dl_theme input[type="time"], .dl_theme input[type="datetime-local"], .dl_theme input[type="month"] {
  -webkit-appearance: listbox; }

.dl_theme input[type="number"]::-webkit-inner-spin-button, .dl_theme input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

.dl_theme input[type="reset"], .dl_theme input[type="button"], .dl_theme input[type="submit"], .dl_theme input[type="checkbox"], .dl_theme input[type="radio"] {
  width: auto;
  box-sizing: border-box;
  overflow: visible; }

.dl_theme input:focus {
  outline: 0; }

.dl_theme input,
.dl_theme button,
.dl_theme select,
.dl_theme optgroup,
.dl_theme textarea {
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: inherit; }

.dl_theme textarea {
  resize: vertical;
  overflow: auto; }

.dl_theme input,
.dl_theme button {
  overflow: visible; }

.dl_theme select,
.dl_theme button {
  text-transform: none; }

.dl_theme select {
  word-wrap: normal; }

.dl_theme button {
  border-radius: 0px;
  -webkit-appearance: button; }
  .dl_theme button[type="button"], .dl_theme button[type="reset"], .dl_theme button[type="submit"] {
    -webkit-appearance: button; }
    .dl_theme button[type="button"]::-moz-focus-inner, .dl_theme button[type="reset"]::-moz-focus-inner, .dl_theme button[type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none; }
  .dl_theme button:focus {
    outline: none; }

.dl_theme button,
.dl_theme select {
  width: auto;
  box-sizing: border-box;
  overflow: visible; }

.dl_theme fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.dl_theme legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  padding: 0;
  color: inherit;
  font-size: 14px;
  line-height: inherit;
  white-space: normal; }

.dl_theme progress {
  vertical-align: baseline; }

.dl_theme ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

.dl_theme output {
  display: inline-block; }

.dl_theme summary {
  display: list-item;
  cursor: pointer; }

.dl_theme template {
  display: none; }

.dl_theme [hidden] {
  display: none !important; }

.dl_theme .trans-1 {
  transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-all-1 * {
  transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1); }
  .dl_theme .trans-all-1 *:before, .dl_theme .trans-all-1 *:after {
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-2 {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-all-2 * {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1); }
  .dl_theme .trans-all-2 *:before, .dl_theme .trans-all-2 *:after {
    transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-3 {
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-all-3 * {
  transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1); }
  .dl_theme .trans-all-3 *:before, .dl_theme .trans-all-3 *:after {
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-4, .dl_theme nav.navigation > ul > li > a, .dl_theme nav.navigation > ul > li ul li a {
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-all-4 *, .dl_theme div.resp_menu div.resp_menu_toggle *, .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus * {
  transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1); }
  .dl_theme .trans-all-4 *:before, .dl_theme div.resp_menu div.resp_menu_toggle *:before, .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus *:before, .dl_theme .trans-all-4 *:after, .dl_theme div.resp_menu div.resp_menu_toggle *:after, .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus *:after {
    transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-5 {
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-all-5 * {
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1); }
  .dl_theme .trans-all-5 *:before, .dl_theme .trans-all-5 *:after {
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1); }

.dl_theme .trans-no {
  transition: none; }

.dl_theme .trans-no-all * {
  transition: none; }
  .dl_theme .trans-no-all *:before, .dl_theme .trans-no-all *:after {
    transition: none; }

.dl_theme ul.no-list, .dl_theme .address-style ul, .dl_theme div.socials ul, .dl_theme nav.navigation > ul, .dl_theme div.resp_menu div.resp_menu_ul > ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  .dl_theme ul.no-list ul, .dl_theme .address-style ul ul, .dl_theme div.socials ul ul, .dl_theme nav.navigation > ul ul, .dl_theme div.resp_menu div.resp_menu_ul > ul ul,
  .dl_theme ul.no-list ol,
  .dl_theme .address-style ul ol,
  .dl_theme div.socials ul ol,
  .dl_theme nav.navigation > ul ol,
  .dl_theme div.resp_menu div.resp_menu_ul > ul ol,
  .dl_theme ul.no-list li,
  .dl_theme .address-style ul li,
  .dl_theme div.socials ul li,
  .dl_theme nav.navigation > ul li,
  .dl_theme div.resp_menu div.resp_menu_ul > ul li {
    margin: 0;
    padding: 0;
    list-style: none; }

.dl_theme ol.no-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .dl_theme ol.no-list ol,
  .dl_theme ol.no-list ul,
  .dl_theme ol.no-list li {
    margin: 0;
    padding: 0;
    list-style: none; }

.dl_theme .flex, .dl_theme div.socials ul li a, .dl_theme button.gilaki,
.dl_theme a.gilaki {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }

.dl_theme img.fit-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover; }

.dl_theme .no-select {
  user-select: none; }

.dl_theme .no-select-all * {
  user-select: none; }

.dl_theme .overlay {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.dl_theme .address-style ul li {
  display: flex;
  margin-bottom: 10px;
  color: #111111; }
  .dl_theme .address-style ul li:last-child {
    margin-bottom: 0; }
  .dl_theme .address-style ul li em {
    display: flex;
    justify-content: center;
    width: 20px;
    margin-right: 10px; }
  .dl_theme .address-style ul li span {
    flex: 1; }
    .dl_theme .address-style ul li span a {
      color: #111111; }
      .dl_theme .address-style ul li span a:hover {
        color: #FBB50D; }

.dl_theme div.grecaptcha-badge {
  opacity: 0; }

.dl_theme .firago {
  font-family: "Rubik", Helvetica, sans-serif;
  font-weight: normal;
  font-feature-settings: 'case' on;
  text-transform: uppercase; }

div.dl_container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }
  @media (min-width: 544px) {
    div.dl_container {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 768px) {
    div.dl_container {
      max-width: 720px;
      padding: 0; } }
  @media (min-width: 992px) {
    div.dl_container {
      max-width: 960px;
      padding: 0; } }
  @media (min-width: 1200px) {
    div.dl_container {
      max-width: 1170px;
      padding: 0; } }
  @media (min-width: 1380px) {
    div.dl_container {
      max-width: 1350px;
      padding: 0; } }

.dl_theme {
  color: #111111;
  font-size: 14px;
  font-family: "Rubik", Helvetica, sans-serif, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .dl_theme a {
    color: #FBB50D;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects; }
    .dl_theme a:hover, .dl_theme a:focus {
      text-decoration: none; }
    .dl_theme a:hover {
      color: #111111; }
    .dl_theme a:focus {
      color: #FBB50D; }
  .dl_theme div.map {
    width: 100%;
    height: 400px; }
  .dl_theme svg.dashed {
    fill: none; }
    .dl_theme svg.dashed path {
      transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1); }
    .dl_theme svg.dashed:hover path {
      stroke-dashoffset: 0 !important; }
  .dl_theme div.socials ul {
    display: flex; }
    .dl_theme div.socials ul li {
      margin-right: 15px; }
      .dl_theme div.socials ul li:last-child {
        margin-right: 0; }
      .dl_theme div.socials ul li a {
        width: 20px;
        height: 20px;
        color: #FBB50D;
        font-size: 15px; }
        .dl_theme div.socials ul li a:hover {
          color: #111111; }
  .dl_theme .morecontent span {
    display: none; }
  .dl_theme .morelink {
    display: block;
    color: #111111;
    font-size: 12px; }
    .dl_theme .morelink:hover {
      color: #FF3632; }
  .dl_theme .mobile_fixed_button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: none;
    z-index: 9999; }
    @media (max-width: 1024px) {
      .dl_theme .mobile_fixed_button {
        display: block; } }
    .dl_theme .mobile_fixed_button a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      width: 100%;
      padding: 10px 0;
      background: #FBB50D; }
  .dl_theme .section_title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: 700; }
    @media (max-width: 1024px) {
      .dl_theme .section_title {
        font-size: 20px;
        align-items: flex-start; } }
    @media (max-width: 1024px) {
      .dl_theme .section_title img {
        width: 25px; } }

@font-face {
  font-family: remixicon;
  src: url(../fonts/remixicon.woff2) format("woff2");
  font-display: swap; }

[class*=" ri-"], [class^=ri-] {
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ri-lg {
  font-size: 1.3333em;
  line-height: .75em;
  vertical-align: -.0667em; }

.ri-xl {
  font-size: 1.5em;
  line-height: .6666em;
  vertical-align: -.075em; }

.ri-xxs {
  font-size: .5em; }

.ri-xs {
  font-size: .75em; }

.ri-sm {
  font-size: .875em; }

.ri-1x {
  font-size: 1em; }

.ri-2x {
  font-size: 2em; }

.ri-3x {
  font-size: 3em; }

.ri-4x {
  font-size: 4em; }

.ri-5x {
  font-size: 5em; }

.ri-6x {
  font-size: 6em; }

.ri-7x {
  font-size: 7em; }

.ri-8x {
  font-size: 8em; }

.ri-9x {
  font-size: 9em; }

.ri-10x {
  font-size: 10em; }

.ri-fw {
  text-align: center;
  width: 1.25em; }

.ri-24-hours-fill:before {
  content: "\ea01"; }

.ri-24-hours-line:before {
  content: "\ea02"; }

.ri-4k-fill:before {
  content: "\ea03"; }

.ri-4k-line:before {
  content: "\ea04"; }

.ri-a-b:before {
  content: "\ea05"; }

.ri-account-box-fill:before {
  content: "\ea06"; }

.ri-account-box-line:before {
  content: "\ea07"; }

.ri-account-circle-fill:before {
  content: "\ea08"; }

.ri-account-circle-line:before {
  content: "\ea09"; }

.ri-account-pin-box-fill:before {
  content: "\ea0a"; }

.ri-account-pin-box-line:before {
  content: "\ea0b"; }

.ri-account-pin-circle-fill:before {
  content: "\ea0c"; }

.ri-account-pin-circle-line:before {
  content: "\ea0d"; }

.ri-add-box-fill:before {
  content: "\ea0e"; }

.ri-add-box-line:before {
  content: "\ea0f"; }

.ri-add-circle-fill:before {
  content: "\ea10"; }

.ri-add-circle-line:before {
  content: "\ea11"; }

.ri-add-fill:before {
  content: "\ea12"; }

.ri-add-line:before {
  content: "\ea13"; }

.ri-admin-fill:before {
  content: "\ea14"; }

.ri-admin-line:before {
  content: "\ea15"; }

.ri-advertisement-fill:before {
  content: "\ea16"; }

.ri-advertisement-line:before {
  content: "\ea17"; }

.ri-airplay-fill:before {
  content: "\ea18"; }

.ri-airplay-line:before {
  content: "\ea19"; }

.ri-alarm-fill:before {
  content: "\ea1a"; }

.ri-alarm-line:before {
  content: "\ea1b"; }

.ri-alarm-warning-fill:before {
  content: "\ea1c"; }

.ri-alarm-warning-line:before {
  content: "\ea1d"; }

.ri-album-fill:before {
  content: "\ea1e"; }

.ri-album-line:before {
  content: "\ea1f"; }

.ri-alert-fill:before {
  content: "\ea20"; }

.ri-alert-line:before {
  content: "\ea21"; }

.ri-aliens-fill:before {
  content: "\ea22"; }

.ri-aliens-line:before {
  content: "\ea23"; }

.ri-align-bottom:before {
  content: "\ea24"; }

.ri-align-center:before {
  content: "\ea25"; }

.ri-align-justify:before {
  content: "\ea26"; }

.ri-align-left:before {
  content: "\ea27"; }

.ri-align-right:before {
  content: "\ea28"; }

.ri-align-top:before {
  content: "\ea29"; }

.ri-align-vertically:before {
  content: "\ea2a"; }

.ri-alipay-fill:before {
  content: "\ea2b"; }

.ri-alipay-line:before {
  content: "\ea2c"; }

.ri-amazon-fill:before {
  content: "\ea2d"; }

.ri-amazon-line:before {
  content: "\ea2e"; }

.ri-anchor-fill:before {
  content: "\ea2f"; }

.ri-anchor-line:before {
  content: "\ea30"; }

.ri-ancient-gate-fill:before {
  content: "\ea31"; }

.ri-ancient-gate-line:before {
  content: "\ea32"; }

.ri-ancient-pavilion-fill:before {
  content: "\ea33"; }

.ri-ancient-pavilion-line:before {
  content: "\ea34"; }

.ri-android-fill:before {
  content: "\ea35"; }

.ri-android-line:before {
  content: "\ea36"; }

.ri-angularjs-fill:before {
  content: "\ea37"; }

.ri-angularjs-line:before {
  content: "\ea38"; }

.ri-anticlockwise-2-fill:before {
  content: "\ea39"; }

.ri-anticlockwise-2-line:before {
  content: "\ea3a"; }

.ri-anticlockwise-fill:before {
  content: "\ea3b"; }

.ri-anticlockwise-line:before {
  content: "\ea3c"; }

.ri-app-store-fill:before {
  content: "\ea3d"; }

.ri-app-store-line:before {
  content: "\ea3e"; }

.ri-apple-fill:before {
  content: "\ea3f"; }

.ri-apple-line:before {
  content: "\ea40"; }

.ri-apps-2-fill:before {
  content: "\ea41"; }

.ri-apps-2-line:before {
  content: "\ea42"; }

.ri-apps-fill:before {
  content: "\ea43"; }

.ri-apps-line:before {
  content: "\ea44"; }

.ri-archive-drawer-fill:before {
  content: "\ea45"; }

.ri-archive-drawer-line:before {
  content: "\ea46"; }

.ri-archive-fill:before {
  content: "\ea47"; }

.ri-archive-line:before {
  content: "\ea48"; }

.ri-arrow-down-circle-fill:before {
  content: "\ea49"; }

.ri-arrow-down-circle-line:before {
  content: "\ea4a"; }

.ri-arrow-down-fill:before {
  content: "\ea4b"; }

.ri-arrow-down-line:before {
  content: "\ea4c"; }

.ri-arrow-down-s-fill:before {
  content: "\ea4d"; }

.ri-arrow-down-s-line:before {
  content: "\ea4e"; }

.ri-arrow-drop-down-fill:before {
  content: "\ea4f"; }

.ri-arrow-drop-down-line:before {
  content: "\ea50"; }

.ri-arrow-drop-left-fill:before {
  content: "\ea51"; }

.ri-arrow-drop-left-line:before {
  content: "\ea52"; }

.ri-arrow-drop-right-fill:before {
  content: "\ea53"; }

.ri-arrow-drop-right-line:before {
  content: "\ea54"; }

.ri-arrow-drop-up-fill:before {
  content: "\ea55"; }

.ri-arrow-drop-up-line:before {
  content: "\ea56"; }

.ri-arrow-go-back-fill:before {
  content: "\ea57"; }

.ri-arrow-go-back-line:before {
  content: "\ea58"; }

.ri-arrow-go-forward-fill:before {
  content: "\ea59"; }

.ri-arrow-go-forward-line:before {
  content: "\ea5a"; }

.ri-arrow-left-circle-fill:before {
  content: "\ea5b"; }

.ri-arrow-left-circle-line:before {
  content: "\ea5c"; }

.ri-arrow-left-down-fill:before {
  content: "\ea5d"; }

.ri-arrow-left-down-line:before {
  content: "\ea5e"; }

.ri-arrow-left-fill:before {
  content: "\ea5f"; }

.ri-arrow-left-line:before {
  content: "\ea60"; }

.ri-arrow-left-right-fill:before {
  content: "\ea61"; }

.ri-arrow-left-right-line:before {
  content: "\ea62"; }

.ri-arrow-left-s-fill:before {
  content: "\ea63"; }

.ri-arrow-left-s-line:before {
  content: "\ea64"; }

.ri-arrow-left-up-fill:before {
  content: "\ea65"; }

.ri-arrow-left-up-line:before {
  content: "\ea66"; }

.ri-arrow-right-circle-fill:before {
  content: "\ea67"; }

.ri-arrow-right-circle-line:before {
  content: "\ea68"; }

.ri-arrow-right-down-fill:before {
  content: "\ea69"; }

.ri-arrow-right-down-line:before {
  content: "\ea6a"; }

.ri-arrow-right-fill:before {
  content: "\ea6b"; }

.ri-arrow-right-line:before {
  content: "\ea6c"; }

.ri-arrow-right-s-fill:before {
  content: "\ea6d"; }

.ri-arrow-right-s-line:before {
  content: "\ea6e"; }

.ri-arrow-right-up-fill:before {
  content: "\ea6f"; }

.ri-arrow-right-up-line:before {
  content: "\ea70"; }

.ri-arrow-up-circle-fill:before {
  content: "\ea71"; }

.ri-arrow-up-circle-line:before {
  content: "\ea72"; }

.ri-arrow-up-down-fill:before {
  content: "\ea73"; }

.ri-arrow-up-down-line:before {
  content: "\ea74"; }

.ri-arrow-up-fill:before {
  content: "\ea75"; }

.ri-arrow-up-line:before {
  content: "\ea76"; }

.ri-arrow-up-s-fill:before {
  content: "\ea77"; }

.ri-arrow-up-s-line:before {
  content: "\ea78"; }

.ri-artboard-2-fill:before {
  content: "\ea79"; }

.ri-artboard-2-line:before {
  content: "\ea7a"; }

.ri-artboard-fill:before {
  content: "\ea7b"; }

.ri-artboard-line:before {
  content: "\ea7c"; }

.ri-article-fill:before {
  content: "\ea7d"; }

.ri-article-line:before {
  content: "\ea7e"; }

.ri-aspect-ratio-fill:before {
  content: "\ea7f"; }

.ri-aspect-ratio-line:before {
  content: "\ea80"; }

.ri-asterisk:before {
  content: "\ea81"; }

.ri-at-fill:before {
  content: "\ea82"; }

.ri-at-line:before {
  content: "\ea83"; }

.ri-attachment-2:before {
  content: "\ea84"; }

.ri-attachment-fill:before {
  content: "\ea85"; }

.ri-attachment-line:before {
  content: "\ea86"; }

.ri-auction-fill:before {
  content: "\ea87"; }

.ri-auction-line:before {
  content: "\ea88"; }

.ri-award-fill:before {
  content: "\ea89"; }

.ri-award-line:before {
  content: "\ea8a"; }

.ri-baidu-fill:before {
  content: "\ea8b"; }

.ri-baidu-line:before {
  content: "\ea8c"; }

.ri-ball-pen-fill:before {
  content: "\ea8d"; }

.ri-ball-pen-line:before {
  content: "\ea8e"; }

.ri-bank-card-2-fill:before {
  content: "\ea8f"; }

.ri-bank-card-2-line:before {
  content: "\ea90"; }

.ri-bank-card-fill:before {
  content: "\ea91"; }

.ri-bank-card-line:before {
  content: "\ea92"; }

.ri-bank-fill:before {
  content: "\ea93"; }

.ri-bank-line:before {
  content: "\ea94"; }

.ri-bar-chart-2-fill:before {
  content: "\ea95"; }

.ri-bar-chart-2-line:before {
  content: "\ea96"; }

.ri-bar-chart-box-fill:before {
  content: "\ea97"; }

.ri-bar-chart-box-line:before {
  content: "\ea98"; }

.ri-bar-chart-fill:before {
  content: "\ea99"; }

.ri-bar-chart-grouped-fill:before {
  content: "\ea9a"; }

.ri-bar-chart-grouped-line:before {
  content: "\ea9b"; }

.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c"; }

.ri-bar-chart-horizontal-line:before {
  content: "\ea9d"; }

.ri-bar-chart-line:before {
  content: "\ea9e"; }

.ri-barcode-box-fill:before {
  content: "\ea9f"; }

.ri-barcode-box-line:before {
  content: "\eaa0"; }

.ri-barcode-fill:before {
  content: "\eaa1"; }

.ri-barcode-line:before {
  content: "\eaa2"; }

.ri-barricade-fill:before {
  content: "\eaa3"; }

.ri-barricade-line:before {
  content: "\eaa4"; }

.ri-base-station-fill:before {
  content: "\eaa5"; }

.ri-base-station-line:before {
  content: "\eaa6"; }

.ri-basketball-fill:before {
  content: "\eaa7"; }

.ri-basketball-line:before {
  content: "\eaa8"; }

.ri-battery-2-charge-fill:before {
  content: "\eaa9"; }

.ri-battery-2-charge-line:before {
  content: "\eaaa"; }

.ri-battery-2-fill:before {
  content: "\eaab"; }

.ri-battery-2-line:before {
  content: "\eaac"; }

.ri-battery-charge-fill:before {
  content: "\eaad"; }

.ri-battery-charge-line:before {
  content: "\eaae"; }

.ri-battery-fill:before {
  content: "\eaaf"; }

.ri-battery-line:before {
  content: "\eab0"; }

.ri-battery-low-fill:before {
  content: "\eab1"; }

.ri-battery-low-line:before {
  content: "\eab2"; }

.ri-battery-saver-fill:before {
  content: "\eab3"; }

.ri-battery-saver-line:before {
  content: "\eab4"; }

.ri-battery-share-fill:before {
  content: "\eab5"; }

.ri-battery-share-line:before {
  content: "\eab6"; }

.ri-bear-smile-fill:before {
  content: "\eab7"; }

.ri-bear-smile-line:before {
  content: "\eab8"; }

.ri-behance-fill:before {
  content: "\eab9"; }

.ri-behance-line:before {
  content: "\eaba"; }

.ri-bell-fill:before {
  content: "\eabb"; }

.ri-bell-line:before {
  content: "\eabc"; }

.ri-bike-fill:before {
  content: "\eabd"; }

.ri-bike-line:before {
  content: "\eabe"; }

.ri-bilibili-fill:before {
  content: "\eabf"; }

.ri-bilibili-line:before {
  content: "\eac0"; }

.ri-bill-fill:before {
  content: "\eac1"; }

.ri-bill-line:before {
  content: "\eac2"; }

.ri-billiards-fill:before {
  content: "\eac3"; }

.ri-billiards-line:before {
  content: "\eac4"; }

.ri-bit-coin-fill:before {
  content: "\eac5"; }

.ri-bit-coin-line:before {
  content: "\eac6"; }

.ri-blaze-fill:before {
  content: "\eac7"; }

.ri-blaze-line:before {
  content: "\eac8"; }

.ri-bluetooth-connect-fill:before {
  content: "\eac9"; }

.ri-bluetooth-connect-line:before {
  content: "\eaca"; }

.ri-bluetooth-fill:before {
  content: "\eacb"; }

.ri-bluetooth-line:before {
  content: "\eacc"; }

.ri-blur-off-fill:before {
  content: "\eacd"; }

.ri-blur-off-line:before {
  content: "\eace"; }

.ri-body-scan-fill:before {
  content: "\eacf"; }

.ri-body-scan-line:before {
  content: "\ead0"; }

.ri-bold:before {
  content: "\ead1"; }

.ri-book-2-fill:before {
  content: "\ead2"; }

.ri-book-2-line:before {
  content: "\ead3"; }

.ri-book-3-fill:before {
  content: "\ead4"; }

.ri-book-3-line:before {
  content: "\ead5"; }

.ri-book-fill:before {
  content: "\ead6"; }

.ri-book-line:before {
  content: "\ead7"; }

.ri-book-mark-fill:before {
  content: "\ead8"; }

.ri-book-mark-line:before {
  content: "\ead9"; }

.ri-book-open-fill:before {
  content: "\eada"; }

.ri-book-open-line:before {
  content: "\eadb"; }

.ri-book-read-fill:before {
  content: "\eadc"; }

.ri-book-read-line:before {
  content: "\eadd"; }

.ri-booklet-fill:before {
  content: "\eade"; }

.ri-booklet-line:before {
  content: "\eadf"; }

.ri-bookmark-2-fill:before {
  content: "\eae0"; }

.ri-bookmark-2-line:before {
  content: "\eae1"; }

.ri-bookmark-3-fill:before {
  content: "\eae2"; }

.ri-bookmark-3-line:before {
  content: "\eae3"; }

.ri-bookmark-fill:before {
  content: "\eae4"; }

.ri-bookmark-line:before {
  content: "\eae5"; }

.ri-boxing-fill:before {
  content: "\eae6"; }

.ri-boxing-line:before {
  content: "\eae7"; }

.ri-braces-fill:before {
  content: "\eae8"; }

.ri-braces-line:before {
  content: "\eae9"; }

.ri-brackets-fill:before {
  content: "\eaea"; }

.ri-brackets-line:before {
  content: "\eaeb"; }

.ri-briefcase-2-fill:before {
  content: "\eaec"; }

.ri-briefcase-2-line:before {
  content: "\eaed"; }

.ri-briefcase-3-fill:before {
  content: "\eaee"; }

.ri-briefcase-3-line:before {
  content: "\eaef"; }

.ri-briefcase-4-fill:before {
  content: "\eaf0"; }

.ri-briefcase-4-line:before {
  content: "\eaf1"; }

.ri-briefcase-5-fill:before {
  content: "\eaf2"; }

.ri-briefcase-5-line:before {
  content: "\eaf3"; }

.ri-briefcase-fill:before {
  content: "\eaf4"; }

.ri-briefcase-line:before {
  content: "\eaf5"; }

.ri-bring-forward:before {
  content: "\eaf6"; }

.ri-bring-to-front:before {
  content: "\eaf7"; }

.ri-broadcast-fill:before {
  content: "\eaf8"; }

.ri-broadcast-line:before {
  content: "\eaf9"; }

.ri-brush-2-fill:before {
  content: "\eafa"; }

.ri-brush-2-line:before {
  content: "\eafb"; }

.ri-brush-3-fill:before {
  content: "\eafc"; }

.ri-brush-3-line:before {
  content: "\eafd"; }

.ri-brush-4-fill:before {
  content: "\eafe"; }

.ri-brush-4-line:before {
  content: "\eaff"; }

.ri-brush-fill:before {
  content: "\eb00"; }

.ri-brush-line:before {
  content: "\eb01"; }

.ri-bubble-chart-fill:before {
  content: "\eb02"; }

.ri-bubble-chart-line:before {
  content: "\eb03"; }

.ri-bug-2-fill:before {
  content: "\eb04"; }

.ri-bug-2-line:before {
  content: "\eb05"; }

.ri-bug-fill:before {
  content: "\eb06"; }

.ri-bug-line:before {
  content: "\eb07"; }

.ri-building-2-fill:before {
  content: "\eb08"; }

.ri-building-2-line:before {
  content: "\eb09"; }

.ri-building-3-fill:before {
  content: "\eb0a"; }

.ri-building-3-line:before {
  content: "\eb0b"; }

.ri-building-4-fill:before {
  content: "\eb0c"; }

.ri-building-4-line:before {
  content: "\eb0d"; }

.ri-building-fill:before {
  content: "\eb0e"; }

.ri-building-line:before {
  content: "\eb0f"; }

.ri-bus-2-fill:before {
  content: "\eb10"; }

.ri-bus-2-line:before {
  content: "\eb11"; }

.ri-bus-fill:before {
  content: "\eb12"; }

.ri-bus-line:before {
  content: "\eb13"; }

.ri-bus-wifi-fill:before {
  content: "\eb14"; }

.ri-bus-wifi-line:before {
  content: "\eb15"; }

.ri-cactus-fill:before {
  content: "\eb16"; }

.ri-cactus-line:before {
  content: "\eb17"; }

.ri-cake-2-fill:before {
  content: "\eb18"; }

.ri-cake-2-line:before {
  content: "\eb19"; }

.ri-cake-3-fill:before {
  content: "\eb1a"; }

.ri-cake-3-line:before {
  content: "\eb1b"; }

.ri-cake-fill:before {
  content: "\eb1c"; }

.ri-cake-line:before {
  content: "\eb1d"; }

.ri-calculator-fill:before {
  content: "\eb1e"; }

.ri-calculator-line:before {
  content: "\eb1f"; }

.ri-calendar-2-fill:before {
  content: "\eb20"; }

.ri-calendar-2-line:before {
  content: "\eb21"; }

.ri-calendar-check-fill:before {
  content: "\eb22"; }

.ri-calendar-check-line:before {
  content: "\eb23"; }

.ri-calendar-event-fill:before {
  content: "\eb24"; }

.ri-calendar-event-line:before {
  content: "\eb25"; }

.ri-calendar-fill:before {
  content: "\eb26"; }

.ri-calendar-line:before {
  content: "\eb27"; }

.ri-calendar-todo-fill:before {
  content: "\eb28"; }

.ri-calendar-todo-line:before {
  content: "\eb29"; }

.ri-camera-2-fill:before {
  content: "\eb2a"; }

.ri-camera-2-line:before {
  content: "\eb2b"; }

.ri-camera-3-fill:before {
  content: "\eb2c"; }

.ri-camera-3-line:before {
  content: "\eb2d"; }

.ri-camera-fill:before {
  content: "\eb2e"; }

.ri-camera-lens-fill:before {
  content: "\eb2f"; }

.ri-camera-lens-line:before {
  content: "\eb30"; }

.ri-camera-line:before {
  content: "\eb31"; }

.ri-camera-off-fill:before {
  content: "\eb32"; }

.ri-camera-off-line:before {
  content: "\eb33"; }

.ri-camera-switch-fill:before {
  content: "\eb34"; }

.ri-camera-switch-line:before {
  content: "\eb35"; }

.ri-capsule-fill:before {
  content: "\eb36"; }

.ri-capsule-line:before {
  content: "\eb37"; }

.ri-car-fill:before {
  content: "\eb38"; }

.ri-car-line:before {
  content: "\eb39"; }

.ri-car-washing-fill:before {
  content: "\eb3a"; }

.ri-car-washing-line:before {
  content: "\eb3b"; }

.ri-caravan-fill:before {
  content: "\eb3c"; }

.ri-caravan-line:before {
  content: "\eb3d"; }

.ri-cast-fill:before {
  content: "\eb3e"; }

.ri-cast-line:before {
  content: "\eb3f"; }

.ri-cellphone-fill:before {
  content: "\eb40"; }

.ri-cellphone-line:before {
  content: "\eb41"; }

.ri-celsius-fill:before {
  content: "\eb42"; }

.ri-celsius-line:before {
  content: "\eb43"; }

.ri-centos-fill:before {
  content: "\eb44"; }

.ri-centos-line:before {
  content: "\eb45"; }

.ri-character-recognition-fill:before {
  content: "\eb46"; }

.ri-character-recognition-line:before {
  content: "\eb47"; }

.ri-charging-pile-2-fill:before {
  content: "\eb48"; }

.ri-charging-pile-2-line:before {
  content: "\eb49"; }

.ri-charging-pile-fill:before {
  content: "\eb4a"; }

.ri-charging-pile-line:before {
  content: "\eb4b"; }

.ri-chat-1-fill:before {
  content: "\eb4c"; }

.ri-chat-1-line:before {
  content: "\eb4d"; }

.ri-chat-2-fill:before {
  content: "\eb4e"; }

.ri-chat-2-line:before {
  content: "\eb4f"; }

.ri-chat-3-fill:before {
  content: "\eb50"; }

.ri-chat-3-line:before {
  content: "\eb51"; }

.ri-chat-4-fill:before {
  content: "\eb52"; }

.ri-chat-4-line:before {
  content: "\eb53"; }

.ri-chat-check-fill:before {
  content: "\eb54"; }

.ri-chat-check-line:before {
  content: "\eb55"; }

.ri-chat-delete-fill:before {
  content: "\eb56"; }

.ri-chat-delete-line:before {
  content: "\eb57"; }

.ri-chat-download-fill:before {
  content: "\eb58"; }

.ri-chat-download-line:before {
  content: "\eb59"; }

.ri-chat-follow-up-fill:before {
  content: "\eb5a"; }

.ri-chat-follow-up-line:before {
  content: "\eb5b"; }

.ri-chat-forward-fill:before {
  content: "\eb5c"; }

.ri-chat-forward-line:before {
  content: "\eb5d"; }

.ri-chat-heart-fill:before {
  content: "\eb5e"; }

.ri-chat-heart-line:before {
  content: "\eb5f"; }

.ri-chat-history-fill:before {
  content: "\eb60"; }

.ri-chat-history-line:before {
  content: "\eb61"; }

.ri-chat-new-fill:before {
  content: "\eb62"; }

.ri-chat-new-line:before {
  content: "\eb63"; }

.ri-chat-off-fill:before {
  content: "\eb64"; }

.ri-chat-off-line:before {
  content: "\eb65"; }

.ri-chat-poll-fill:before {
  content: "\eb66"; }

.ri-chat-poll-line:before {
  content: "\eb67"; }

.ri-chat-private-fill:before {
  content: "\eb68"; }

.ri-chat-private-line:before {
  content: "\eb69"; }

.ri-chat-quote-fill:before {
  content: "\eb6a"; }

.ri-chat-quote-line:before {
  content: "\eb6b"; }

.ri-chat-settings-fill:before {
  content: "\eb6c"; }

.ri-chat-settings-line:before {
  content: "\eb6d"; }

.ri-chat-smile-2-fill:before {
  content: "\eb6e"; }

.ri-chat-smile-2-line:before {
  content: "\eb6f"; }

.ri-chat-smile-3-fill:before {
  content: "\eb70"; }

.ri-chat-smile-3-line:before {
  content: "\eb71"; }

.ri-chat-smile-fill:before {
  content: "\eb72"; }

.ri-chat-smile-line:before {
  content: "\eb73"; }

.ri-chat-upload-fill:before {
  content: "\eb74"; }

.ri-chat-upload-line:before {
  content: "\eb75"; }

.ri-chat-voice-fill:before {
  content: "\eb76"; }

.ri-chat-voice-line:before {
  content: "\eb77"; }

.ri-check-double-fill:before {
  content: "\eb78"; }

.ri-check-double-line:before {
  content: "\eb79"; }

.ri-check-fill:before {
  content: "\eb7a"; }

.ri-check-line:before {
  content: "\eb7b"; }

.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c"; }

.ri-checkbox-blank-circle-line:before {
  content: "\eb7d"; }

.ri-checkbox-blank-fill:before {
  content: "\eb7e"; }

.ri-checkbox-blank-line:before {
  content: "\eb7f"; }

.ri-checkbox-circle-fill:before {
  content: "\eb80"; }

.ri-checkbox-circle-line:before {
  content: "\eb81"; }

.ri-checkbox-fill:before {
  content: "\eb82"; }

.ri-checkbox-indeterminate-fill:before {
  content: "\eb83"; }

.ri-checkbox-indeterminate-line:before {
  content: "\eb84"; }

.ri-checkbox-line:before {
  content: "\eb85"; }

.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86"; }

.ri-checkbox-multiple-blank-line:before {
  content: "\eb87"; }

.ri-checkbox-multiple-fill:before {
  content: "\eb88"; }

.ri-checkbox-multiple-line:before {
  content: "\eb89"; }

.ri-china-railway-fill:before {
  content: "\eb8a"; }

.ri-china-railway-line:before {
  content: "\eb8b"; }

.ri-chrome-fill:before {
  content: "\eb8c"; }

.ri-chrome-line:before {
  content: "\eb8d"; }

.ri-clapperboard-fill:before {
  content: "\eb8e"; }

.ri-clapperboard-line:before {
  content: "\eb8f"; }

.ri-clipboard-fill:before {
  content: "\eb90"; }

.ri-clipboard-line:before {
  content: "\eb91"; }

.ri-clockwise-2-fill:before {
  content: "\eb92"; }

.ri-clockwise-2-line:before {
  content: "\eb93"; }

.ri-clockwise-fill:before {
  content: "\eb94"; }

.ri-clockwise-line:before {
  content: "\eb95"; }

.ri-close-circle-fill:before {
  content: "\eb96"; }

.ri-close-circle-line:before {
  content: "\eb97"; }

.ri-close-fill:before {
  content: "\eb98"; }

.ri-close-line:before {
  content: "\eb99"; }

.ri-closed-captioning-fill:before {
  content: "\eb9a"; }

.ri-closed-captioning-line:before {
  content: "\eb9b"; }

.ri-cloud-fill:before {
  content: "\eb9c"; }

.ri-cloud-line:before {
  content: "\eb9d"; }

.ri-cloud-off-fill:before {
  content: "\eb9e"; }

.ri-cloud-off-line:before {
  content: "\eb9f"; }

.ri-cloud-windy-fill:before {
  content: "\eba0"; }

.ri-cloud-windy-line:before {
  content: "\eba1"; }

.ri-cloudy-2-fill:before {
  content: "\eba2"; }

.ri-cloudy-2-line:before {
  content: "\eba3"; }

.ri-cloudy-fill:before {
  content: "\eba4"; }

.ri-cloudy-line:before {
  content: "\eba5"; }

.ri-code-box-fill:before {
  content: "\eba6"; }

.ri-code-box-line:before {
  content: "\eba7"; }

.ri-code-fill:before {
  content: "\eba8"; }

.ri-code-line:before {
  content: "\eba9"; }

.ri-code-s-fill:before {
  content: "\ebaa"; }

.ri-code-s-line:before {
  content: "\ebab"; }

.ri-code-s-slash-fill:before {
  content: "\ebac"; }

.ri-code-s-slash-line:before {
  content: "\ebad"; }

.ri-code-view:before {
  content: "\ebae"; }

.ri-codepen-fill:before {
  content: "\ebaf"; }

.ri-codepen-line:before {
  content: "\ebb0"; }

.ri-coin-fill:before {
  content: "\ebb1"; }

.ri-coin-line:before {
  content: "\ebb2"; }

.ri-coins-fill:before {
  content: "\ebb3"; }

.ri-coins-line:before {
  content: "\ebb4"; }

.ri-collage-fill:before {
  content: "\ebb5"; }

.ri-collage-line:before {
  content: "\ebb6"; }

.ri-command-fill:before {
  content: "\ebb7"; }

.ri-command-line:before {
  content: "\ebb8"; }

.ri-community-fill:before {
  content: "\ebb9"; }

.ri-community-line:before {
  content: "\ebba"; }

.ri-compass-2-fill:before {
  content: "\ebbb"; }

.ri-compass-2-line:before {
  content: "\ebbc"; }

.ri-compass-3-fill:before {
  content: "\ebbd"; }

.ri-compass-3-line:before {
  content: "\ebbe"; }

.ri-compass-4-fill:before {
  content: "\ebbf"; }

.ri-compass-4-line:before {
  content: "\ebc0"; }

.ri-compass-discover-fill:before {
  content: "\ebc1"; }

.ri-compass-discover-line:before {
  content: "\ebc2"; }

.ri-compass-fill:before {
  content: "\ebc3"; }

.ri-compass-line:before {
  content: "\ebc4"; }

.ri-compasses-2-fill:before {
  content: "\ebc5"; }

.ri-compasses-2-line:before {
  content: "\ebc6"; }

.ri-compasses-fill:before {
  content: "\ebc7"; }

.ri-compasses-line:before {
  content: "\ebc8"; }

.ri-computer-fill:before {
  content: "\ebc9"; }

.ri-computer-line:before {
  content: "\ebca"; }

.ri-contacts-book-2-fill:before {
  content: "\ebcb"; }

.ri-contacts-book-2-line:before {
  content: "\ebcc"; }

.ri-contacts-book-fill:before {
  content: "\ebcd"; }

.ri-contacts-book-line:before {
  content: "\ebce"; }

.ri-contacts-book-upload-fill:before {
  content: "\ebcf"; }

.ri-contacts-book-upload-line:before {
  content: "\ebd0"; }

.ri-contacts-fill:before {
  content: "\ebd1"; }

.ri-contacts-line:before {
  content: "\ebd2"; }

.ri-contrast-2-fill:before {
  content: "\ebd3"; }

.ri-contrast-2-line:before {
  content: "\ebd4"; }

.ri-contrast-drop-2-fill:before {
  content: "\ebd5"; }

.ri-contrast-drop-2-line:before {
  content: "\ebd6"; }

.ri-contrast-drop-fill:before {
  content: "\ebd7"; }

.ri-contrast-drop-line:before {
  content: "\ebd8"; }

.ri-contrast-fill:before {
  content: "\ebd9"; }

.ri-contrast-line:before {
  content: "\ebda"; }

.ri-copper-coin-fill:before {
  content: "\ebdb"; }

.ri-copper-coin-line:before {
  content: "\ebdc"; }

.ri-copper-diamond-fill:before {
  content: "\ebdd"; }

.ri-copper-diamond-line:before {
  content: "\ebde"; }

.ri-copyleft-fill:before {
  content: "\ebdf"; }

.ri-copyleft-line:before {
  content: "\ebe0"; }

.ri-copyright-fill:before {
  content: "\ebe1"; }

.ri-copyright-line:before {
  content: "\ebe2"; }

.ri-coreos-fill:before {
  content: "\ebe3"; }

.ri-coreos-line:before {
  content: "\ebe4"; }

.ri-coupon-2-fill:before {
  content: "\ebe5"; }

.ri-coupon-2-line:before {
  content: "\ebe6"; }

.ri-coupon-3-fill:before {
  content: "\ebe7"; }

.ri-coupon-3-line:before {
  content: "\ebe8"; }

.ri-coupon-4-fill:before {
  content: "\ebe9"; }

.ri-coupon-4-line:before {
  content: "\ebea"; }

.ri-coupon-5-fill:before {
  content: "\ebeb"; }

.ri-coupon-5-line:before {
  content: "\ebec"; }

.ri-coupon-fill:before {
  content: "\ebed"; }

.ri-coupon-line:before {
  content: "\ebee"; }

.ri-cpu-fill:before {
  content: "\ebef"; }

.ri-cpu-line:before {
  content: "\ebf0"; }

.ri-creative-commons-by-fill:before {
  content: "\ebf1"; }

.ri-creative-commons-by-line:before {
  content: "\ebf2"; }

.ri-creative-commons-fill:before {
  content: "\ebf3"; }

.ri-creative-commons-line:before {
  content: "\ebf4"; }

.ri-creative-commons-nc-fill:before {
  content: "\ebf5"; }

.ri-creative-commons-nc-line:before {
  content: "\ebf6"; }

.ri-creative-commons-nd-fill:before {
  content: "\ebf7"; }

.ri-creative-commons-nd-line:before {
  content: "\ebf8"; }

.ri-creative-commons-sa-fill:before {
  content: "\ebf9"; }

.ri-creative-commons-sa-line:before {
  content: "\ebfa"; }

.ri-creative-commons-zero-fill:before {
  content: "\ebfb"; }

.ri-creative-commons-zero-line:before {
  content: "\ebfc"; }

.ri-criminal-fill:before {
  content: "\ebfd"; }

.ri-criminal-line:before {
  content: "\ebfe"; }

.ri-crop-2-fill:before {
  content: "\ebff"; }

.ri-crop-2-line:before {
  content: "\ec00"; }

.ri-crop-fill:before {
  content: "\ec01"; }

.ri-crop-line:before {
  content: "\ec02"; }

.ri-css3-fill:before {
  content: "\ec03"; }

.ri-css3-line:before {
  content: "\ec04"; }

.ri-cup-fill:before {
  content: "\ec05"; }

.ri-cup-line:before {
  content: "\ec06"; }

.ri-currency-fill:before {
  content: "\ec07"; }

.ri-currency-line:before {
  content: "\ec08"; }

.ri-cursor-fill:before {
  content: "\ec09"; }

.ri-cursor-line:before {
  content: "\ec0a"; }

.ri-customer-service-2-fill:before {
  content: "\ec0b"; }

.ri-customer-service-2-line:before {
  content: "\ec0c"; }

.ri-customer-service-fill:before {
  content: "\ec0d"; }

.ri-customer-service-line:before {
  content: "\ec0e"; }

.ri-dashboard-2-fill:before {
  content: "\ec0f"; }

.ri-dashboard-2-line:before {
  content: "\ec10"; }

.ri-dashboard-3-fill:before {
  content: "\ec11"; }

.ri-dashboard-3-line:before {
  content: "\ec12"; }

.ri-dashboard-fill:before {
  content: "\ec13"; }

.ri-dashboard-line:before {
  content: "\ec14"; }

.ri-database-2-fill:before {
  content: "\ec15"; }

.ri-database-2-line:before {
  content: "\ec16"; }

.ri-database-fill:before {
  content: "\ec17"; }

.ri-database-line:before {
  content: "\ec18"; }

.ri-delete-back-2-fill:before {
  content: "\ec19"; }

.ri-delete-back-2-line:before {
  content: "\ec1a"; }

.ri-delete-back-fill:before {
  content: "\ec1b"; }

.ri-delete-back-line:before {
  content: "\ec1c"; }

.ri-delete-bin-2-fill:before {
  content: "\ec1d"; }

.ri-delete-bin-2-line:before {
  content: "\ec1e"; }

.ri-delete-bin-3-fill:before {
  content: "\ec1f"; }

.ri-delete-bin-3-line:before {
  content: "\ec20"; }

.ri-delete-bin-4-fill:before {
  content: "\ec21"; }

.ri-delete-bin-4-line:before {
  content: "\ec22"; }

.ri-delete-bin-5-fill:before {
  content: "\ec23"; }

.ri-delete-bin-5-line:before {
  content: "\ec24"; }

.ri-delete-bin-6-fill:before {
  content: "\ec25"; }

.ri-delete-bin-6-line:before {
  content: "\ec26"; }

.ri-delete-bin-7-fill:before {
  content: "\ec27"; }

.ri-delete-bin-7-line:before {
  content: "\ec28"; }

.ri-delete-bin-fill:before {
  content: "\ec29"; }

.ri-delete-bin-line:before {
  content: "\ec2a"; }

.ri-delete-column:before {
  content: "\ec2b"; }

.ri-delete-row:before {
  content: "\ec2c"; }

.ri-device-fill:before {
  content: "\ec2d"; }

.ri-device-line:before {
  content: "\ec2e"; }

.ri-device-recover-fill:before {
  content: "\ec2f"; }

.ri-device-recover-line:before {
  content: "\ec30"; }

.ri-dingding-fill:before {
  content: "\ec31"; }

.ri-dingding-line:before {
  content: "\ec32"; }

.ri-direction-fill:before {
  content: "\ec33"; }

.ri-direction-line:before {
  content: "\ec34"; }

.ri-disc-fill:before {
  content: "\ec35"; }

.ri-disc-line:before {
  content: "\ec36"; }

.ri-discord-fill:before {
  content: "\ec37"; }

.ri-discord-line:before {
  content: "\ec38"; }

.ri-discuss-fill:before {
  content: "\ec39"; }

.ri-discuss-line:before {
  content: "\ec3a"; }

.ri-dislike-fill:before {
  content: "\ec3b"; }

.ri-dislike-line:before {
  content: "\ec3c"; }

.ri-disqus-fill:before {
  content: "\ec3d"; }

.ri-disqus-line:before {
  content: "\ec3e"; }

.ri-divide-fill:before {
  content: "\ec3f"; }

.ri-divide-line:before {
  content: "\ec40"; }

.ri-donut-chart-fill:before {
  content: "\ec41"; }

.ri-donut-chart-line:before {
  content: "\ec42"; }

.ri-door-closed-fill:before {
  content: "\ec43"; }

.ri-door-closed-line:before {
  content: "\ec44"; }

.ri-door-fill:before {
  content: "\ec45"; }

.ri-door-line:before {
  content: "\ec46"; }

.ri-door-lock-box-fill:before {
  content: "\ec47"; }

.ri-door-lock-box-line:before {
  content: "\ec48"; }

.ri-door-lock-fill:before {
  content: "\ec49"; }

.ri-door-lock-line:before {
  content: "\ec4a"; }

.ri-door-open-fill:before {
  content: "\ec4b"; }

.ri-door-open-line:before {
  content: "\ec4c"; }

.ri-dossier-fill:before {
  content: "\ec4d"; }

.ri-dossier-line:before {
  content: "\ec4e"; }

.ri-douban-fill:before {
  content: "\ec4f"; }

.ri-douban-line:before {
  content: "\ec50"; }

.ri-double-quotes-l:before {
  content: "\ec51"; }

.ri-double-quotes-r:before {
  content: "\ec52"; }

.ri-download-2-fill:before {
  content: "\ec53"; }

.ri-download-2-line:before {
  content: "\ec54"; }

.ri-download-cloud-2-fill:before {
  content: "\ec55"; }

.ri-download-cloud-2-line:before {
  content: "\ec56"; }

.ri-download-cloud-fill:before {
  content: "\ec57"; }

.ri-download-cloud-line:before {
  content: "\ec58"; }

.ri-download-fill:before {
  content: "\ec59"; }

.ri-download-line:before {
  content: "\ec5a"; }

.ri-draft-fill:before {
  content: "\ec5b"; }

.ri-draft-line:before {
  content: "\ec5c"; }

.ri-drag-drop-fill:before {
  content: "\ec5d"; }

.ri-drag-drop-line:before {
  content: "\ec5e"; }

.ri-drag-move-2-fill:before {
  content: "\ec5f"; }

.ri-drag-move-2-line:before {
  content: "\ec60"; }

.ri-drag-move-fill:before {
  content: "\ec61"; }

.ri-drag-move-line:before {
  content: "\ec62"; }

.ri-dribbble-fill:before {
  content: "\ec63"; }

.ri-dribbble-line:before {
  content: "\ec64"; }

.ri-drive-fill:before {
  content: "\ec65"; }

.ri-drive-line:before {
  content: "\ec66"; }

.ri-drizzle-fill:before {
  content: "\ec67"; }

.ri-drizzle-line:before {
  content: "\ec68"; }

.ri-drop-fill:before {
  content: "\ec69"; }

.ri-drop-line:before {
  content: "\ec6a"; }

.ri-dropbox-fill:before {
  content: "\ec6b"; }

.ri-dropbox-line:before {
  content: "\ec6c"; }

.ri-dual-sim-1-fill:before {
  content: "\ec6d"; }

.ri-dual-sim-1-line:before {
  content: "\ec6e"; }

.ri-dual-sim-2-fill:before {
  content: "\ec6f"; }

.ri-dual-sim-2-line:before {
  content: "\ec70"; }

.ri-dv-fill:before {
  content: "\ec71"; }

.ri-dv-line:before {
  content: "\ec72"; }

.ri-dvd-fill:before {
  content: "\ec73"; }

.ri-dvd-line:before {
  content: "\ec74"; }

.ri-e-bike-2-fill:before {
  content: "\ec75"; }

.ri-e-bike-2-line:before {
  content: "\ec76"; }

.ri-e-bike-fill:before {
  content: "\ec77"; }

.ri-e-bike-line:before {
  content: "\ec78"; }

.ri-earth-fill:before {
  content: "\ec79"; }

.ri-earth-line:before {
  content: "\ec7a"; }

.ri-earthquake-fill:before {
  content: "\ec7b"; }

.ri-earthquake-line:before {
  content: "\ec7c"; }

.ri-edge-fill:before {
  content: "\ec7d"; }

.ri-edge-line:before {
  content: "\ec7e"; }

.ri-edit-2-fill:before {
  content: "\ec7f"; }

.ri-edit-2-line:before {
  content: "\ec80"; }

.ri-edit-box-fill:before {
  content: "\ec81"; }

.ri-edit-box-line:before {
  content: "\ec82"; }

.ri-edit-circle-fill:before {
  content: "\ec83"; }

.ri-edit-circle-line:before {
  content: "\ec84"; }

.ri-edit-fill:before {
  content: "\ec85"; }

.ri-edit-line:before {
  content: "\ec86"; }

.ri-eject-fill:before {
  content: "\ec87"; }

.ri-eject-line:before {
  content: "\ec88"; }

.ri-emotion-2-fill:before {
  content: "\ec89"; }

.ri-emotion-2-line:before {
  content: "\ec8a"; }

.ri-emotion-fill:before {
  content: "\ec8b"; }

.ri-emotion-happy-fill:before {
  content: "\ec8c"; }

.ri-emotion-happy-line:before {
  content: "\ec8d"; }

.ri-emotion-laugh-fill:before {
  content: "\ec8e"; }

.ri-emotion-laugh-line:before {
  content: "\ec8f"; }

.ri-emotion-line:before {
  content: "\ec90"; }

.ri-emotion-normal-fill:before {
  content: "\ec91"; }

.ri-emotion-normal-line:before {
  content: "\ec92"; }

.ri-emotion-sad-fill:before {
  content: "\ec93"; }

.ri-emotion-sad-line:before {
  content: "\ec94"; }

.ri-emotion-unhappy-fill:before {
  content: "\ec95"; }

.ri-emotion-unhappy-line:before {
  content: "\ec96"; }

.ri-empathize-fill:before {
  content: "\ec97"; }

.ri-empathize-line:before {
  content: "\ec98"; }

.ri-emphasis-cn:before {
  content: "\ec99"; }

.ri-emphasis:before {
  content: "\ec9a"; }

.ri-english-input:before {
  content: "\ec9b"; }

.ri-equalizer-fill:before {
  content: "\ec9c"; }

.ri-equalizer-line:before {
  content: "\ec9d"; }

.ri-eraser-fill:before {
  content: "\ec9e"; }

.ri-eraser-line:before {
  content: "\ec9f"; }

.ri-error-warning-fill:before {
  content: "\eca0"; }

.ri-error-warning-line:before {
  content: "\eca1"; }

.ri-evernote-fill:before {
  content: "\eca2"; }

.ri-evernote-line:before {
  content: "\eca3"; }

.ri-exchange-box-fill:before {
  content: "\eca4"; }

.ri-exchange-box-line:before {
  content: "\eca5"; }

.ri-exchange-cny-fill:before {
  content: "\eca6"; }

.ri-exchange-cny-line:before {
  content: "\eca7"; }

.ri-exchange-dollar-fill:before {
  content: "\eca8"; }

.ri-exchange-dollar-line:before {
  content: "\eca9"; }

.ri-exchange-fill:before {
  content: "\ecaa"; }

.ri-exchange-funds-fill:before {
  content: "\ecab"; }

.ri-exchange-funds-line:before {
  content: "\ecac"; }

.ri-exchange-line:before {
  content: "\ecad"; }

.ri-external-link-fill:before {
  content: "\ecae"; }

.ri-external-link-line:before {
  content: "\ecaf"; }

.ri-eye-2-fill:before {
  content: "\ecb0"; }

.ri-eye-2-line:before {
  content: "\ecb1"; }

.ri-eye-close-fill:before {
  content: "\ecb2"; }

.ri-eye-close-line:before {
  content: "\ecb3"; }

.ri-eye-fill:before {
  content: "\ecb4"; }

.ri-eye-line:before {
  content: "\ecb5"; }

.ri-eye-off-fill:before {
  content: "\ecb6"; }

.ri-eye-off-line:before {
  content: "\ecb7"; }

.ri-facebook-box-fill:before {
  content: "\ecb8"; }

.ri-facebook-box-line:before {
  content: "\ecb9"; }

.ri-facebook-circle-fill:before {
  content: "\ecba"; }

.ri-facebook-circle-line:before {
  content: "\ecbb"; }

.ri-facebook-fill:before {
  content: "\ecbc"; }

.ri-facebook-line:before {
  content: "\ecbd"; }

.ri-fahrenheit-fill:before {
  content: "\ecbe"; }

.ri-fahrenheit-line:before {
  content: "\ecbf"; }

.ri-feedback-fill:before {
  content: "\ecc0"; }

.ri-feedback-line:before {
  content: "\ecc1"; }

.ri-file-2-fill:before {
  content: "\ecc2"; }

.ri-file-2-line:before {
  content: "\ecc3"; }

.ri-file-3-fill:before {
  content: "\ecc4"; }

.ri-file-3-line:before {
  content: "\ecc5"; }

.ri-file-4-fill:before {
  content: "\ecc6"; }

.ri-file-4-line:before {
  content: "\ecc7"; }

.ri-file-add-fill:before {
  content: "\ecc8"; }

.ri-file-add-line:before {
  content: "\ecc9"; }

.ri-file-chart-2-fill:before {
  content: "\ecca"; }

.ri-file-chart-2-line:before {
  content: "\eccb"; }

.ri-file-chart-fill:before {
  content: "\eccc"; }

.ri-file-chart-line:before {
  content: "\eccd"; }

.ri-file-cloud-fill:before {
  content: "\ecce"; }

.ri-file-cloud-line:before {
  content: "\eccf"; }

.ri-file-code-fill:before {
  content: "\ecd0"; }

.ri-file-code-line:before {
  content: "\ecd1"; }

.ri-file-copy-2-fill:before {
  content: "\ecd2"; }

.ri-file-copy-2-line:before {
  content: "\ecd3"; }

.ri-file-copy-fill:before {
  content: "\ecd4"; }

.ri-file-copy-line:before {
  content: "\ecd5"; }

.ri-file-damage-fill:before {
  content: "\ecd6"; }

.ri-file-damage-line:before {
  content: "\ecd7"; }

.ri-file-download-fill:before {
  content: "\ecd8"; }

.ri-file-download-line:before {
  content: "\ecd9"; }

.ri-file-edit-fill:before {
  content: "\ecda"; }

.ri-file-edit-line:before {
  content: "\ecdb"; }

.ri-file-excel-2-fill:before {
  content: "\ecdc"; }

.ri-file-excel-2-line:before {
  content: "\ecdd"; }

.ri-file-excel-fill:before {
  content: "\ecde"; }

.ri-file-excel-line:before {
  content: "\ecdf"; }

.ri-file-fill:before {
  content: "\ece0"; }

.ri-file-forbid-fill:before {
  content: "\ece1"; }

.ri-file-forbid-line:before {
  content: "\ece2"; }

.ri-file-gif-fill:before {
  content: "\ece3"; }

.ri-file-gif-line:before {
  content: "\ece4"; }

.ri-file-history-fill:before {
  content: "\ece5"; }

.ri-file-history-line:before {
  content: "\ece6"; }

.ri-file-hwp-fill:before {
  content: "\ece7"; }

.ri-file-hwp-line:before {
  content: "\ece8"; }

.ri-file-info-fill:before {
  content: "\ece9"; }

.ri-file-info-line:before {
  content: "\ecea"; }

.ri-file-line:before {
  content: "\eceb"; }

.ri-file-list-2-fill:before {
  content: "\ecec"; }

.ri-file-list-2-line:before {
  content: "\eced"; }

.ri-file-list-3-fill:before {
  content: "\ecee"; }

.ri-file-list-3-line:before {
  content: "\ecef"; }

.ri-file-list-fill:before {
  content: "\ecf0"; }

.ri-file-list-line:before {
  content: "\ecf1"; }

.ri-file-lock-fill:before {
  content: "\ecf2"; }

.ri-file-lock-line:before {
  content: "\ecf3"; }

.ri-file-mark-fill:before {
  content: "\ecf4"; }

.ri-file-mark-line:before {
  content: "\ecf5"; }

.ri-file-music-fill:before {
  content: "\ecf6"; }

.ri-file-music-line:before {
  content: "\ecf7"; }

.ri-file-paper-2-fill:before {
  content: "\ecf8"; }

.ri-file-paper-2-line:before {
  content: "\ecf9"; }

.ri-file-paper-fill:before {
  content: "\ecfa"; }

.ri-file-paper-line:before {
  content: "\ecfb"; }

.ri-file-pdf-fill:before {
  content: "\ecfc"; }

.ri-file-pdf-line:before {
  content: "\ecfd"; }

.ri-file-ppt-2-fill:before {
  content: "\ecfe"; }

.ri-file-ppt-2-line:before {
  content: "\ecff"; }

.ri-file-ppt-fill:before {
  content: "\ed00"; }

.ri-file-ppt-line:before {
  content: "\ed01"; }

.ri-file-reduce-fill:before {
  content: "\ed02"; }

.ri-file-reduce-line:before {
  content: "\ed03"; }

.ri-file-search-fill:before {
  content: "\ed04"; }

.ri-file-search-line:before {
  content: "\ed05"; }

.ri-file-settings-fill:before {
  content: "\ed06"; }

.ri-file-settings-line:before {
  content: "\ed07"; }

.ri-file-shield-2-fill:before {
  content: "\ed08"; }

.ri-file-shield-2-line:before {
  content: "\ed09"; }

.ri-file-shield-fill:before {
  content: "\ed0a"; }

.ri-file-shield-line:before {
  content: "\ed0b"; }

.ri-file-shred-fill:before {
  content: "\ed0c"; }

.ri-file-shred-line:before {
  content: "\ed0d"; }

.ri-file-text-fill:before {
  content: "\ed0e"; }

.ri-file-text-line:before {
  content: "\ed0f"; }

.ri-file-transfer-fill:before {
  content: "\ed10"; }

.ri-file-transfer-line:before {
  content: "\ed11"; }

.ri-file-unknow-fill:before {
  content: "\ed12"; }

.ri-file-unknow-line:before {
  content: "\ed13"; }

.ri-file-upload-fill:before {
  content: "\ed14"; }

.ri-file-upload-line:before {
  content: "\ed15"; }

.ri-file-user-fill:before {
  content: "\ed16"; }

.ri-file-user-line:before {
  content: "\ed17"; }

.ri-file-warning-fill:before {
  content: "\ed18"; }

.ri-file-warning-line:before {
  content: "\ed19"; }

.ri-file-word-2-fill:before {
  content: "\ed1a"; }

.ri-file-word-2-line:before {
  content: "\ed1b"; }

.ri-file-word-fill:before {
  content: "\ed1c"; }

.ri-file-word-line:before {
  content: "\ed1d"; }

.ri-file-zip-fill:before {
  content: "\ed1e"; }

.ri-file-zip-line:before {
  content: "\ed1f"; }

.ri-film-fill:before {
  content: "\ed20"; }

.ri-film-line:before {
  content: "\ed21"; }

.ri-filter-2-fill:before {
  content: "\ed22"; }

.ri-filter-2-line:before {
  content: "\ed23"; }

.ri-filter-3-fill:before {
  content: "\ed24"; }

.ri-filter-3-line:before {
  content: "\ed25"; }

.ri-filter-fill:before {
  content: "\ed26"; }

.ri-filter-line:before {
  content: "\ed27"; }

.ri-filter-off-fill:before {
  content: "\ed28"; }

.ri-filter-off-line:before {
  content: "\ed29"; }

.ri-find-replace-fill:before {
  content: "\ed2a"; }

.ri-find-replace-line:before {
  content: "\ed2b"; }

.ri-finder-fill:before {
  content: "\ed2c"; }

.ri-finder-line:before {
  content: "\ed2d"; }

.ri-fingerprint-2-fill:before {
  content: "\ed2e"; }

.ri-fingerprint-2-line:before {
  content: "\ed2f"; }

.ri-fingerprint-fill:before {
  content: "\ed30"; }

.ri-fingerprint-line:before {
  content: "\ed31"; }

.ri-fire-fill:before {
  content: "\ed32"; }

.ri-fire-line:before {
  content: "\ed33"; }

.ri-firefox-fill:before {
  content: "\ed34"; }

.ri-firefox-line:before {
  content: "\ed35"; }

.ri-first-aid-kit-fill:before {
  content: "\ed36"; }

.ri-first-aid-kit-line:before {
  content: "\ed37"; }

.ri-flag-2-fill:before {
  content: "\ed38"; }

.ri-flag-2-line:before {
  content: "\ed39"; }

.ri-flag-fill:before {
  content: "\ed3a"; }

.ri-flag-line:before {
  content: "\ed3b"; }

.ri-flashlight-fill:before {
  content: "\ed3c"; }

.ri-flashlight-line:before {
  content: "\ed3d"; }

.ri-flask-fill:before {
  content: "\ed3e"; }

.ri-flask-line:before {
  content: "\ed3f"; }

.ri-flight-land-fill:before {
  content: "\ed40"; }

.ri-flight-land-line:before {
  content: "\ed41"; }

.ri-flight-takeoff-fill:before {
  content: "\ed42"; }

.ri-flight-takeoff-line:before {
  content: "\ed43"; }

.ri-flood-fill:before {
  content: "\ed44"; }

.ri-flood-line:before {
  content: "\ed45"; }

.ri-flow-chart:before {
  content: "\ed46"; }

.ri-flutter-fill:before {
  content: "\ed47"; }

.ri-flutter-line:before {
  content: "\ed48"; }

.ri-focus-2-fill:before {
  content: "\ed49"; }

.ri-focus-2-line:before {
  content: "\ed4a"; }

.ri-focus-3-fill:before {
  content: "\ed4b"; }

.ri-focus-3-line:before {
  content: "\ed4c"; }

.ri-focus-fill:before {
  content: "\ed4d"; }

.ri-focus-line:before {
  content: "\ed4e"; }

.ri-foggy-fill:before {
  content: "\ed4f"; }

.ri-foggy-line:before {
  content: "\ed50"; }

.ri-folder-2-fill:before {
  content: "\ed51"; }

.ri-folder-2-line:before {
  content: "\ed52"; }

.ri-folder-3-fill:before {
  content: "\ed53"; }

.ri-folder-3-line:before {
  content: "\ed54"; }

.ri-folder-4-fill:before {
  content: "\ed55"; }

.ri-folder-4-line:before {
  content: "\ed56"; }

.ri-folder-5-fill:before {
  content: "\ed57"; }

.ri-folder-5-line:before {
  content: "\ed58"; }

.ri-folder-add-fill:before {
  content: "\ed59"; }

.ri-folder-add-line:before {
  content: "\ed5a"; }

.ri-folder-chart-2-fill:before {
  content: "\ed5b"; }

.ri-folder-chart-2-line:before {
  content: "\ed5c"; }

.ri-folder-chart-fill:before {
  content: "\ed5d"; }

.ri-folder-chart-line:before {
  content: "\ed5e"; }

.ri-folder-download-fill:before {
  content: "\ed5f"; }

.ri-folder-download-line:before {
  content: "\ed60"; }

.ri-folder-fill:before {
  content: "\ed61"; }

.ri-folder-forbid-fill:before {
  content: "\ed62"; }

.ri-folder-forbid-line:before {
  content: "\ed63"; }

.ri-folder-history-fill:before {
  content: "\ed64"; }

.ri-folder-history-line:before {
  content: "\ed65"; }

.ri-folder-info-fill:before {
  content: "\ed66"; }

.ri-folder-info-line:before {
  content: "\ed67"; }

.ri-folder-keyhole-fill:before {
  content: "\ed68"; }

.ri-folder-keyhole-line:before {
  content: "\ed69"; }

.ri-folder-line:before {
  content: "\ed6a"; }

.ri-folder-lock-fill:before {
  content: "\ed6b"; }

.ri-folder-lock-line:before {
  content: "\ed6c"; }

.ri-folder-music-fill:before {
  content: "\ed6d"; }

.ri-folder-music-line:before {
  content: "\ed6e"; }

.ri-folder-open-fill:before {
  content: "\ed6f"; }

.ri-folder-open-line:before {
  content: "\ed70"; }

.ri-folder-received-fill:before {
  content: "\ed71"; }

.ri-folder-received-line:before {
  content: "\ed72"; }

.ri-folder-reduce-fill:before {
  content: "\ed73"; }

.ri-folder-reduce-line:before {
  content: "\ed74"; }

.ri-folder-settings-fill:before {
  content: "\ed75"; }

.ri-folder-settings-line:before {
  content: "\ed76"; }

.ri-folder-shared-fill:before {
  content: "\ed77"; }

.ri-folder-shared-line:before {
  content: "\ed78"; }

.ri-folder-shield-2-fill:before {
  content: "\ed79"; }

.ri-folder-shield-2-line:before {
  content: "\ed7a"; }

.ri-folder-shield-fill:before {
  content: "\ed7b"; }

.ri-folder-shield-line:before {
  content: "\ed7c"; }

.ri-folder-transfer-fill:before {
  content: "\ed7d"; }

.ri-folder-transfer-line:before {
  content: "\ed7e"; }

.ri-folder-unknow-fill:before {
  content: "\ed7f"; }

.ri-folder-unknow-line:before {
  content: "\ed80"; }

.ri-folder-upload-fill:before {
  content: "\ed81"; }

.ri-folder-upload-line:before {
  content: "\ed82"; }

.ri-folder-user-fill:before {
  content: "\ed83"; }

.ri-folder-user-line:before {
  content: "\ed84"; }

.ri-folder-warning-fill:before {
  content: "\ed85"; }

.ri-folder-warning-line:before {
  content: "\ed86"; }

.ri-folder-zip-fill:before {
  content: "\ed87"; }

.ri-folder-zip-line:before {
  content: "\ed88"; }

.ri-folders-fill:before {
  content: "\ed89"; }

.ri-folders-line:before {
  content: "\ed8a"; }

.ri-font-color:before {
  content: "\ed8b"; }

.ri-font-size-2:before {
  content: "\ed8c"; }

.ri-font-size:before {
  content: "\ed8d"; }

.ri-football-fill:before {
  content: "\ed8e"; }

.ri-football-line:before {
  content: "\ed8f"; }

.ri-footprint-fill:before {
  content: "\ed90"; }

.ri-footprint-line:before {
  content: "\ed91"; }

.ri-forbid-2-fill:before {
  content: "\ed92"; }

.ri-forbid-2-line:before {
  content: "\ed93"; }

.ri-forbid-fill:before {
  content: "\ed94"; }

.ri-forbid-line:before {
  content: "\ed95"; }

.ri-format-clear:before {
  content: "\ed96"; }

.ri-fridge-fill:before {
  content: "\ed97"; }

.ri-fridge-line:before {
  content: "\ed98"; }

.ri-fullscreen-exit-fill:before {
  content: "\ed99"; }

.ri-fullscreen-exit-line:before {
  content: "\ed9a"; }

.ri-fullscreen-fill:before {
  content: "\ed9b"; }

.ri-fullscreen-line:before {
  content: "\ed9c"; }

.ri-function-fill:before {
  content: "\ed9d"; }

.ri-function-line:before {
  content: "\ed9e"; }

.ri-functions:before {
  content: "\ed9f"; }

.ri-funds-box-fill:before {
  content: "\eda0"; }

.ri-funds-box-line:before {
  content: "\eda1"; }

.ri-funds-fill:before {
  content: "\eda2"; }

.ri-funds-line:before {
  content: "\eda3"; }

.ri-gallery-fill:before {
  content: "\eda4"; }

.ri-gallery-line:before {
  content: "\eda5"; }

.ri-gallery-upload-fill:before {
  content: "\eda6"; }

.ri-gallery-upload-line:before {
  content: "\eda7"; }

.ri-game-fill:before {
  content: "\eda8"; }

.ri-game-line:before {
  content: "\eda9"; }

.ri-gamepad-fill:before {
  content: "\edaa"; }

.ri-gamepad-line:before {
  content: "\edab"; }

.ri-gas-station-fill:before {
  content: "\edac"; }

.ri-gas-station-line:before {
  content: "\edad"; }

.ri-gatsby-fill:before {
  content: "\edae"; }

.ri-gatsby-line:before {
  content: "\edaf"; }

.ri-genderless-fill:before {
  content: "\edb0"; }

.ri-genderless-line:before {
  content: "\edb1"; }

.ri-ghost-2-fill:before {
  content: "\edb2"; }

.ri-ghost-2-line:before {
  content: "\edb3"; }

.ri-ghost-fill:before {
  content: "\edb4"; }

.ri-ghost-line:before {
  content: "\edb5"; }

.ri-ghost-smile-fill:before {
  content: "\edb6"; }

.ri-ghost-smile-line:before {
  content: "\edb7"; }

.ri-gift-2-fill:before {
  content: "\edb8"; }

.ri-gift-2-line:before {
  content: "\edb9"; }

.ri-gift-fill:before {
  content: "\edba"; }

.ri-gift-line:before {
  content: "\edbb"; }

.ri-git-branch-fill:before {
  content: "\edbc"; }

.ri-git-branch-line:before {
  content: "\edbd"; }

.ri-git-commit-fill:before {
  content: "\edbe"; }

.ri-git-commit-line:before {
  content: "\edbf"; }

.ri-git-merge-fill:before {
  content: "\edc0"; }

.ri-git-merge-line:before {
  content: "\edc1"; }

.ri-git-pull-request-fill:before {
  content: "\edc2"; }

.ri-git-pull-request-line:before {
  content: "\edc3"; }

.ri-git-repository-commits-fill:before {
  content: "\edc4"; }

.ri-git-repository-commits-line:before {
  content: "\edc5"; }

.ri-git-repository-fill:before {
  content: "\edc6"; }

.ri-git-repository-line:before {
  content: "\edc7"; }

.ri-git-repository-private-fill:before {
  content: "\edc8"; }

.ri-git-repository-private-line:before {
  content: "\edc9"; }

.ri-github-fill:before {
  content: "\edca"; }

.ri-github-line:before {
  content: "\edcb"; }

.ri-gitlab-fill:before {
  content: "\edcc"; }

.ri-gitlab-line:before {
  content: "\edcd"; }

.ri-global-fill:before {
  content: "\edce"; }

.ri-global-line:before {
  content: "\edcf"; }

.ri-globe-fill:before {
  content: "\edd0"; }

.ri-globe-line:before {
  content: "\edd1"; }

.ri-goblet-fill:before {
  content: "\edd2"; }

.ri-goblet-line:before {
  content: "\edd3"; }

.ri-google-fill:before {
  content: "\edd4"; }

.ri-google-line:before {
  content: "\edd5"; }

.ri-google-play-fill:before {
  content: "\edd6"; }

.ri-google-play-line:before {
  content: "\edd7"; }

.ri-government-fill:before {
  content: "\edd8"; }

.ri-government-line:before {
  content: "\edd9"; }

.ri-gps-fill:before {
  content: "\edda"; }

.ri-gps-line:before {
  content: "\eddb"; }

.ri-gradienter-fill:before {
  content: "\eddc"; }

.ri-gradienter-line:before {
  content: "\eddd"; }

.ri-grid-fill:before {
  content: "\edde"; }

.ri-grid-line:before {
  content: "\eddf"; }

.ri-group-2-fill:before {
  content: "\ede0"; }

.ri-group-2-line:before {
  content: "\ede1"; }

.ri-group-fill:before {
  content: "\ede2"; }

.ri-group-line:before {
  content: "\ede3"; }

.ri-guide-fill:before {
  content: "\ede4"; }

.ri-guide-line:before {
  content: "\ede5"; }

.ri-h-1:before {
  content: "\ede6"; }

.ri-h-2:before {
  content: "\ede7"; }

.ri-h-3:before {
  content: "\ede8"; }

.ri-h-4:before {
  content: "\ede9"; }

.ri-h-5:before {
  content: "\edea"; }

.ri-h-6:before {
  content: "\edeb"; }

.ri-hail-fill:before {
  content: "\edec"; }

.ri-hail-line:before {
  content: "\eded"; }

.ri-hammer-fill:before {
  content: "\edee"; }

.ri-hammer-line:before {
  content: "\edef"; }

.ri-hand-coin-fill:before {
  content: "\edf0"; }

.ri-hand-coin-line:before {
  content: "\edf1"; }

.ri-hand-heart-fill:before {
  content: "\edf2"; }

.ri-hand-heart-line:before {
  content: "\edf3"; }

.ri-hand-sanitizer-fill:before {
  content: "\edf4"; }

.ri-hand-sanitizer-line:before {
  content: "\edf5"; }

.ri-handbag-fill:before {
  content: "\edf6"; }

.ri-handbag-line:before {
  content: "\edf7"; }

.ri-hard-drive-2-fill:before {
  content: "\edf8"; }

.ri-hard-drive-2-line:before {
  content: "\edf9"; }

.ri-hard-drive-fill:before {
  content: "\edfa"; }

.ri-hard-drive-line:before {
  content: "\edfb"; }

.ri-hashtag:before {
  content: "\edfc"; }

.ri-haze-2-fill:before {
  content: "\edfd"; }

.ri-haze-2-line:before {
  content: "\edfe"; }

.ri-haze-fill:before {
  content: "\edff"; }

.ri-haze-line:before {
  content: "\ee00"; }

.ri-hd-fill:before {
  content: "\ee01"; }

.ri-hd-line:before {
  content: "\ee02"; }

.ri-heading:before {
  content: "\ee03"; }

.ri-headphone-fill:before {
  content: "\ee04"; }

.ri-headphone-line:before {
  content: "\ee05"; }

.ri-health-book-fill:before {
  content: "\ee06"; }

.ri-health-book-line:before {
  content: "\ee07"; }

.ri-heart-2-fill:before {
  content: "\ee08"; }

.ri-heart-2-line:before {
  content: "\ee09"; }

.ri-heart-3-fill:before {
  content: "\ee0a"; }

.ri-heart-3-line:before {
  content: "\ee0b"; }

.ri-heart-add-fill:before {
  content: "\ee0c"; }

.ri-heart-add-line:before {
  content: "\ee0d"; }

.ri-heart-fill:before {
  content: "\ee0e"; }

.ri-heart-line:before {
  content: "\ee0f"; }

.ri-heart-pulse-fill:before {
  content: "\ee10"; }

.ri-heart-pulse-line:before {
  content: "\ee11"; }

.ri-hearts-fill:before {
  content: "\ee12"; }

.ri-hearts-line:before {
  content: "\ee13"; }

.ri-heavy-showers-fill:before {
  content: "\ee14"; }

.ri-heavy-showers-line:before {
  content: "\ee15"; }

.ri-history-fill:before {
  content: "\ee16"; }

.ri-history-line:before {
  content: "\ee17"; }

.ri-home-2-fill:before {
  content: "\ee18"; }

.ri-home-2-line:before {
  content: "\ee19"; }

.ri-home-3-fill:before {
  content: "\ee1a"; }

.ri-home-3-line:before {
  content: "\ee1b"; }

.ri-home-4-fill:before {
  content: "\ee1c"; }

.ri-home-4-line:before {
  content: "\ee1d"; }

.ri-home-5-fill:before {
  content: "\ee1e"; }

.ri-home-5-line:before {
  content: "\ee1f"; }

.ri-home-6-fill:before {
  content: "\ee20"; }

.ri-home-6-line:before {
  content: "\ee21"; }

.ri-home-7-fill:before {
  content: "\ee22"; }

.ri-home-7-line:before {
  content: "\ee23"; }

.ri-home-8-fill:before {
  content: "\ee24"; }

.ri-home-8-line:before {
  content: "\ee25"; }

.ri-home-fill:before {
  content: "\ee26"; }

.ri-home-gear-fill:before {
  content: "\ee27"; }

.ri-home-gear-line:before {
  content: "\ee28"; }

.ri-home-heart-fill:before {
  content: "\ee29"; }

.ri-home-heart-line:before {
  content: "\ee2a"; }

.ri-home-line:before {
  content: "\ee2b"; }

.ri-home-smile-2-fill:before {
  content: "\ee2c"; }

.ri-home-smile-2-line:before {
  content: "\ee2d"; }

.ri-home-smile-fill:before {
  content: "\ee2e"; }

.ri-home-smile-line:before {
  content: "\ee2f"; }

.ri-home-wifi-fill:before {
  content: "\ee30"; }

.ri-home-wifi-line:before {
  content: "\ee31"; }

.ri-honor-of-kings-fill:before {
  content: "\ee32"; }

.ri-honor-of-kings-line:before {
  content: "\ee33"; }

.ri-honour-fill:before {
  content: "\ee34"; }

.ri-honour-line:before {
  content: "\ee35"; }

.ri-hospital-fill:before {
  content: "\ee36"; }

.ri-hospital-line:before {
  content: "\ee37"; }

.ri-hotel-bed-fill:before {
  content: "\ee38"; }

.ri-hotel-bed-line:before {
  content: "\ee39"; }

.ri-hotel-fill:before {
  content: "\ee3a"; }

.ri-hotel-line:before {
  content: "\ee3b"; }

.ri-hotspot-fill:before {
  content: "\ee3c"; }

.ri-hotspot-line:before {
  content: "\ee3d"; }

.ri-hq-fill:before {
  content: "\ee3e"; }

.ri-hq-line:before {
  content: "\ee3f"; }

.ri-html5-fill:before {
  content: "\ee40"; }

.ri-html5-line:before {
  content: "\ee41"; }

.ri-ie-fill:before {
  content: "\ee42"; }

.ri-ie-line:before {
  content: "\ee43"; }

.ri-image-2-fill:before {
  content: "\ee44"; }

.ri-image-2-line:before {
  content: "\ee45"; }

.ri-image-add-fill:before {
  content: "\ee46"; }

.ri-image-add-line:before {
  content: "\ee47"; }

.ri-image-edit-fill:before {
  content: "\ee48"; }

.ri-image-edit-line:before {
  content: "\ee49"; }

.ri-image-fill:before {
  content: "\ee4a"; }

.ri-image-line:before {
  content: "\ee4b"; }

.ri-inbox-archive-fill:before {
  content: "\ee4c"; }

.ri-inbox-archive-line:before {
  content: "\ee4d"; }

.ri-inbox-fill:before {
  content: "\ee4e"; }

.ri-inbox-line:before {
  content: "\ee4f"; }

.ri-inbox-unarchive-fill:before {
  content: "\ee50"; }

.ri-inbox-unarchive-line:before {
  content: "\ee51"; }

.ri-increase-decrease-fill:before {
  content: "\ee52"; }

.ri-increase-decrease-line:before {
  content: "\ee53"; }

.ri-indent-decrease:before {
  content: "\ee54"; }

.ri-indent-increase:before {
  content: "\ee55"; }

.ri-indeterminate-circle-fill:before {
  content: "\ee56"; }

.ri-indeterminate-circle-line:before {
  content: "\ee57"; }

.ri-information-fill:before {
  content: "\ee58"; }

.ri-information-line:before {
  content: "\ee59"; }

.ri-infrared-thermometer-fill:before {
  content: "\ee5a"; }

.ri-infrared-thermometer-line:before {
  content: "\ee5b"; }

.ri-ink-bottle-fill:before {
  content: "\ee5c"; }

.ri-ink-bottle-line:before {
  content: "\ee5d"; }

.ri-input-cursor-move:before {
  content: "\ee5e"; }

.ri-input-method-fill:before {
  content: "\ee5f"; }

.ri-input-method-line:before {
  content: "\ee60"; }

.ri-insert-column-left:before {
  content: "\ee61"; }

.ri-insert-column-right:before {
  content: "\ee62"; }

.ri-insert-row-bottom:before {
  content: "\ee63"; }

.ri-insert-row-top:before {
  content: "\ee64"; }

.ri-instagram-fill:before {
  content: "\ee65"; }

.ri-instagram-line:before {
  content: "\ee66"; }

.ri-install-fill:before {
  content: "\ee67"; }

.ri-install-line:before {
  content: "\ee68"; }

.ri-invision-fill:before {
  content: "\ee69"; }

.ri-invision-line:before {
  content: "\ee6a"; }

.ri-italic:before {
  content: "\ee6b"; }

.ri-kakao-talk-fill:before {
  content: "\ee6c"; }

.ri-kakao-talk-line:before {
  content: "\ee6d"; }

.ri-key-2-fill:before {
  content: "\ee6e"; }

.ri-key-2-line:before {
  content: "\ee6f"; }

.ri-key-fill:before {
  content: "\ee70"; }

.ri-key-line:before {
  content: "\ee71"; }

.ri-keyboard-box-fill:before {
  content: "\ee72"; }

.ri-keyboard-box-line:before {
  content: "\ee73"; }

.ri-keyboard-fill:before {
  content: "\ee74"; }

.ri-keyboard-line:before {
  content: "\ee75"; }

.ri-keynote-fill:before {
  content: "\ee76"; }

.ri-keynote-line:before {
  content: "\ee77"; }

.ri-knife-blood-fill:before {
  content: "\ee78"; }

.ri-knife-blood-line:before {
  content: "\ee79"; }

.ri-knife-fill:before {
  content: "\ee7a"; }

.ri-knife-line:before {
  content: "\ee7b"; }

.ri-landscape-fill:before {
  content: "\ee7c"; }

.ri-landscape-line:before {
  content: "\ee7d"; }

.ri-layout-2-fill:before {
  content: "\ee7e"; }

.ri-layout-2-line:before {
  content: "\ee7f"; }

.ri-layout-3-fill:before {
  content: "\ee80"; }

.ri-layout-3-line:before {
  content: "\ee81"; }

.ri-layout-4-fill:before {
  content: "\ee82"; }

.ri-layout-4-line:before {
  content: "\ee83"; }

.ri-layout-5-fill:before {
  content: "\ee84"; }

.ri-layout-5-line:before {
  content: "\ee85"; }

.ri-layout-6-fill:before {
  content: "\ee86"; }

.ri-layout-6-line:before {
  content: "\ee87"; }

.ri-layout-bottom-2-fill:before {
  content: "\ee88"; }

.ri-layout-bottom-2-line:before {
  content: "\ee89"; }

.ri-layout-bottom-fill:before {
  content: "\ee8a"; }

.ri-layout-bottom-line:before {
  content: "\ee8b"; }

.ri-layout-column-fill:before {
  content: "\ee8c"; }

.ri-layout-column-line:before {
  content: "\ee8d"; }

.ri-layout-fill:before {
  content: "\ee8e"; }

.ri-layout-grid-fill:before {
  content: "\ee8f"; }

.ri-layout-grid-line:before {
  content: "\ee90"; }

.ri-layout-left-2-fill:before {
  content: "\ee91"; }

.ri-layout-left-2-line:before {
  content: "\ee92"; }

.ri-layout-left-fill:before {
  content: "\ee93"; }

.ri-layout-left-line:before {
  content: "\ee94"; }

.ri-layout-line:before {
  content: "\ee95"; }

.ri-layout-masonry-fill:before {
  content: "\ee96"; }

.ri-layout-masonry-line:before {
  content: "\ee97"; }

.ri-layout-right-2-fill:before {
  content: "\ee98"; }

.ri-layout-right-2-line:before {
  content: "\ee99"; }

.ri-layout-right-fill:before {
  content: "\ee9a"; }

.ri-layout-right-line:before {
  content: "\ee9b"; }

.ri-layout-row-fill:before {
  content: "\ee9c"; }

.ri-layout-row-line:before {
  content: "\ee9d"; }

.ri-layout-top-2-fill:before {
  content: "\ee9e"; }

.ri-layout-top-2-line:before {
  content: "\ee9f"; }

.ri-layout-top-fill:before {
  content: "\eea0"; }

.ri-layout-top-line:before {
  content: "\eea1"; }

.ri-leaf-fill:before {
  content: "\eea2"; }

.ri-leaf-line:before {
  content: "\eea3"; }

.ri-lifebuoy-fill:before {
  content: "\eea4"; }

.ri-lifebuoy-line:before {
  content: "\eea5"; }

.ri-lightbulb-fill:before {
  content: "\eea6"; }

.ri-lightbulb-flash-fill:before {
  content: "\eea7"; }

.ri-lightbulb-flash-line:before {
  content: "\eea8"; }

.ri-lightbulb-line:before {
  content: "\eea9"; }

.ri-line-chart-fill:before {
  content: "\eeaa"; }

.ri-line-chart-line:before {
  content: "\eeab"; }

.ri-line-fill:before {
  content: "\eeac"; }

.ri-line-height:before {
  content: "\eead"; }

.ri-line-line:before {
  content: "\eeae"; }

.ri-link-m:before {
  content: "\eeaf"; }

.ri-link-unlink-m:before {
  content: "\eeb0"; }

.ri-link-unlink:before {
  content: "\eeb1"; }

.ri-link:before {
  content: "\eeb2"; }

.ri-linkedin-box-fill:before {
  content: "\eeb3"; }

.ri-linkedin-box-line:before {
  content: "\eeb4"; }

.ri-linkedin-fill:before {
  content: "\eeb5"; }

.ri-linkedin-line:before {
  content: "\eeb6"; }

.ri-links-fill:before {
  content: "\eeb7"; }

.ri-links-line:before {
  content: "\eeb8"; }

.ri-list-check-2:before {
  content: "\eeb9"; }

.ri-list-check:before {
  content: "\eeba"; }

.ri-list-ordered:before {
  content: "\eebb"; }

.ri-list-settings-fill:before {
  content: "\eebc"; }

.ri-list-settings-line:before {
  content: "\eebd"; }

.ri-list-unordered:before {
  content: "\eebe"; }

.ri-live-fill:before {
  content: "\eebf"; }

.ri-live-line:before {
  content: "\eec0"; }

.ri-loader-2-fill:before {
  content: "\eec1"; }

.ri-loader-2-line:before {
  content: "\eec2"; }

.ri-loader-3-fill:before {
  content: "\eec3"; }

.ri-loader-3-line:before {
  content: "\eec4"; }

.ri-loader-4-fill:before {
  content: "\eec5"; }

.ri-loader-4-line:before {
  content: "\eec6"; }

.ri-loader-5-fill:before {
  content: "\eec7"; }

.ri-loader-5-line:before {
  content: "\eec8"; }

.ri-loader-fill:before {
  content: "\eec9"; }

.ri-loader-line:before {
  content: "\eeca"; }

.ri-lock-2-fill:before {
  content: "\eecb"; }

.ri-lock-2-line:before {
  content: "\eecc"; }

.ri-lock-fill:before {
  content: "\eecd"; }

.ri-lock-line:before {
  content: "\eece"; }

.ri-lock-password-fill:before {
  content: "\eecf"; }

.ri-lock-password-line:before {
  content: "\eed0"; }

.ri-lock-unlock-fill:before {
  content: "\eed1"; }

.ri-lock-unlock-line:before {
  content: "\eed2"; }

.ri-login-box-fill:before {
  content: "\eed3"; }

.ri-login-box-line:before {
  content: "\eed4"; }

.ri-login-circle-fill:before {
  content: "\eed5"; }

.ri-login-circle-line:before {
  content: "\eed6"; }

.ri-logout-box-fill:before {
  content: "\eed7"; }

.ri-logout-box-line:before {
  content: "\eed8"; }

.ri-logout-box-r-fill:before {
  content: "\eed9"; }

.ri-logout-box-r-line:before {
  content: "\eeda"; }

.ri-logout-circle-fill:before {
  content: "\eedb"; }

.ri-logout-circle-line:before {
  content: "\eedc"; }

.ri-logout-circle-r-fill:before {
  content: "\eedd"; }

.ri-logout-circle-r-line:before {
  content: "\eede"; }

.ri-luggage-cart-fill:before {
  content: "\eedf"; }

.ri-luggage-cart-line:before {
  content: "\eee0"; }

.ri-luggage-deposit-fill:before {
  content: "\eee1"; }

.ri-luggage-deposit-line:before {
  content: "\eee2"; }

.ri-lungs-fill:before {
  content: "\eee3"; }

.ri-lungs-line:before {
  content: "\eee4"; }

.ri-mac-fill:before {
  content: "\eee5"; }

.ri-mac-line:before {
  content: "\eee6"; }

.ri-macbook-fill:before {
  content: "\eee7"; }

.ri-macbook-line:before {
  content: "\eee8"; }

.ri-magic-fill:before {
  content: "\eee9"; }

.ri-magic-line:before {
  content: "\eeea"; }

.ri-mail-add-fill:before {
  content: "\eeeb"; }

.ri-mail-add-line:before {
  content: "\eeec"; }

.ri-mail-check-fill:before {
  content: "\eeed"; }

.ri-mail-check-line:before {
  content: "\eeee"; }

.ri-mail-close-fill:before {
  content: "\eeef"; }

.ri-mail-close-line:before {
  content: "\eef0"; }

.ri-mail-download-fill:before {
  content: "\eef1"; }

.ri-mail-download-line:before {
  content: "\eef2"; }

.ri-mail-fill:before {
  content: "\eef3"; }

.ri-mail-forbid-fill:before {
  content: "\eef4"; }

.ri-mail-forbid-line:before {
  content: "\eef5"; }

.ri-mail-line:before {
  content: "\eef6"; }

.ri-mail-lock-fill:before {
  content: "\eef7"; }

.ri-mail-lock-line:before {
  content: "\eef8"; }

.ri-mail-open-fill:before {
  content: "\eef9"; }

.ri-mail-open-line:before {
  content: "\eefa"; }

.ri-mail-send-fill:before {
  content: "\eefb"; }

.ri-mail-send-line:before {
  content: "\eefc"; }

.ri-mail-settings-fill:before {
  content: "\eefd"; }

.ri-mail-settings-line:before {
  content: "\eefe"; }

.ri-mail-star-fill:before {
  content: "\eeff"; }

.ri-mail-star-line:before {
  content: "\ef00"; }

.ri-mail-unread-fill:before {
  content: "\ef01"; }

.ri-mail-unread-line:before {
  content: "\ef02"; }

.ri-mail-volume-fill:before {
  content: "\ef03"; }

.ri-mail-volume-line:before {
  content: "\ef04"; }

.ri-map-2-fill:before {
  content: "\ef05"; }

.ri-map-2-line:before {
  content: "\ef06"; }

.ri-map-fill:before {
  content: "\ef07"; }

.ri-map-line:before {
  content: "\ef08"; }

.ri-map-pin-2-fill:before {
  content: "\ef09"; }

.ri-map-pin-2-line:before {
  content: "\ef0a"; }

.ri-map-pin-3-fill:before {
  content: "\ef0b"; }

.ri-map-pin-3-line:before {
  content: "\ef0c"; }

.ri-map-pin-4-fill:before {
  content: "\ef0d"; }

.ri-map-pin-4-line:before {
  content: "\ef0e"; }

.ri-map-pin-5-fill:before {
  content: "\ef0f"; }

.ri-map-pin-5-line:before {
  content: "\ef10"; }

.ri-map-pin-add-fill:before {
  content: "\ef11"; }

.ri-map-pin-add-line:before {
  content: "\ef12"; }

.ri-map-pin-fill:before {
  content: "\ef13"; }

.ri-map-pin-line:before {
  content: "\ef14"; }

.ri-map-pin-range-fill:before {
  content: "\ef15"; }

.ri-map-pin-range-line:before {
  content: "\ef16"; }

.ri-map-pin-time-fill:before {
  content: "\ef17"; }

.ri-map-pin-time-line:before {
  content: "\ef18"; }

.ri-map-pin-user-fill:before {
  content: "\ef19"; }

.ri-map-pin-user-line:before {
  content: "\ef1a"; }

.ri-mark-pen-fill:before {
  content: "\ef1b"; }

.ri-mark-pen-line:before {
  content: "\ef1c"; }

.ri-markdown-fill:before {
  content: "\ef1d"; }

.ri-markdown-line:before {
  content: "\ef1e"; }

.ri-markup-fill:before {
  content: "\ef1f"; }

.ri-markup-line:before {
  content: "\ef20"; }

.ri-mastercard-fill:before {
  content: "\ef21"; }

.ri-mastercard-line:before {
  content: "\ef22"; }

.ri-mastodon-fill:before {
  content: "\ef23"; }

.ri-mastodon-line:before {
  content: "\ef24"; }

.ri-medal-2-fill:before {
  content: "\ef25"; }

.ri-medal-2-line:before {
  content: "\ef26"; }

.ri-medal-fill:before {
  content: "\ef27"; }

.ri-medal-line:before {
  content: "\ef28"; }

.ri-medicine-bottle-fill:before {
  content: "\ef29"; }

.ri-medicine-bottle-line:before {
  content: "\ef2a"; }

.ri-medium-fill:before {
  content: "\ef2b"; }

.ri-medium-line:before {
  content: "\ef2c"; }

.ri-men-fill:before {
  content: "\ef2d"; }

.ri-men-line:before {
  content: "\ef2e"; }

.ri-mental-health-fill:before {
  content: "\ef2f"; }

.ri-mental-health-line:before {
  content: "\ef30"; }

.ri-menu-2-fill:before {
  content: "\ef31"; }

.ri-menu-2-line:before {
  content: "\ef32"; }

.ri-menu-3-fill:before {
  content: "\ef33"; }

.ri-menu-3-line:before {
  content: "\ef34"; }

.ri-menu-4-fill:before {
  content: "\ef35"; }

.ri-menu-4-line:before {
  content: "\ef36"; }

.ri-menu-5-fill:before {
  content: "\ef37"; }

.ri-menu-5-line:before {
  content: "\ef38"; }

.ri-menu-add-fill:before {
  content: "\ef39"; }

.ri-menu-add-line:before {
  content: "\ef3a"; }

.ri-menu-fill:before {
  content: "\ef3b"; }

.ri-menu-fold-fill:before {
  content: "\ef3c"; }

.ri-menu-fold-line:before {
  content: "\ef3d"; }

.ri-menu-line:before {
  content: "\ef3e"; }

.ri-menu-unfold-fill:before {
  content: "\ef3f"; }

.ri-menu-unfold-line:before {
  content: "\ef40"; }

.ri-merge-cells-horizontal:before {
  content: "\ef41"; }

.ri-merge-cells-vertical:before {
  content: "\ef42"; }

.ri-message-2-fill:before {
  content: "\ef43"; }

.ri-message-2-line:before {
  content: "\ef44"; }

.ri-message-3-fill:before {
  content: "\ef45"; }

.ri-message-3-line:before {
  content: "\ef46"; }

.ri-message-fill:before {
  content: "\ef47"; }

.ri-message-line:before {
  content: "\ef48"; }

.ri-messenger-fill:before {
  content: "\ef49"; }

.ri-messenger-line:before {
  content: "\ef4a"; }

.ri-meteor-fill:before {
  content: "\ef4b"; }

.ri-meteor-line:before {
  content: "\ef4c"; }

.ri-mic-2-fill:before {
  content: "\ef4d"; }

.ri-mic-2-line:before {
  content: "\ef4e"; }

.ri-mic-fill:before {
  content: "\ef4f"; }

.ri-mic-line:before {
  content: "\ef50"; }

.ri-mic-off-fill:before {
  content: "\ef51"; }

.ri-mic-off-line:before {
  content: "\ef52"; }

.ri-mickey-fill:before {
  content: "\ef53"; }

.ri-mickey-line:before {
  content: "\ef54"; }

.ri-microscope-fill:before {
  content: "\ef55"; }

.ri-microscope-line:before {
  content: "\ef56"; }

.ri-microsoft-fill:before {
  content: "\ef57"; }

.ri-microsoft-line:before {
  content: "\ef58"; }

.ri-mind-map:before {
  content: "\ef59"; }

.ri-mini-program-fill:before {
  content: "\ef5a"; }

.ri-mini-program-line:before {
  content: "\ef5b"; }

.ri-mist-fill:before {
  content: "\ef5c"; }

.ri-mist-line:before {
  content: "\ef5d"; }

.ri-money-cny-box-fill:before {
  content: "\ef5e"; }

.ri-money-cny-box-line:before {
  content: "\ef5f"; }

.ri-money-cny-circle-fill:before {
  content: "\ef60"; }

.ri-money-cny-circle-line:before {
  content: "\ef61"; }

.ri-money-dollar-box-fill:before {
  content: "\ef62"; }

.ri-money-dollar-box-line:before {
  content: "\ef63"; }

.ri-money-dollar-circle-fill:before {
  content: "\ef64"; }

.ri-money-dollar-circle-line:before {
  content: "\ef65"; }

.ri-money-euro-box-fill:before {
  content: "\ef66"; }

.ri-money-euro-box-line:before {
  content: "\ef67"; }

.ri-money-euro-circle-fill:before {
  content: "\ef68"; }

.ri-money-euro-circle-line:before {
  content: "\ef69"; }

.ri-money-pound-box-fill:before {
  content: "\ef6a"; }

.ri-money-pound-box-line:before {
  content: "\ef6b"; }

.ri-money-pound-circle-fill:before {
  content: "\ef6c"; }

.ri-money-pound-circle-line:before {
  content: "\ef6d"; }

.ri-moon-clear-fill:before {
  content: "\ef6e"; }

.ri-moon-clear-line:before {
  content: "\ef6f"; }

.ri-moon-cloudy-fill:before {
  content: "\ef70"; }

.ri-moon-cloudy-line:before {
  content: "\ef71"; }

.ri-moon-fill:before {
  content: "\ef72"; }

.ri-moon-foggy-fill:before {
  content: "\ef73"; }

.ri-moon-foggy-line:before {
  content: "\ef74"; }

.ri-moon-line:before {
  content: "\ef75"; }

.ri-more-2-fill:before {
  content: "\ef76"; }

.ri-more-2-line:before {
  content: "\ef77"; }

.ri-more-fill:before {
  content: "\ef78"; }

.ri-more-line:before {
  content: "\ef79"; }

.ri-motorbike-fill:before {
  content: "\ef7a"; }

.ri-motorbike-line:before {
  content: "\ef7b"; }

.ri-mouse-fill:before {
  content: "\ef7c"; }

.ri-mouse-line:before {
  content: "\ef7d"; }

.ri-movie-2-fill:before {
  content: "\ef7e"; }

.ri-movie-2-line:before {
  content: "\ef7f"; }

.ri-movie-fill:before {
  content: "\ef80"; }

.ri-movie-line:before {
  content: "\ef81"; }

.ri-music-2-fill:before {
  content: "\ef82"; }

.ri-music-2-line:before {
  content: "\ef83"; }

.ri-music-fill:before {
  content: "\ef84"; }

.ri-music-line:before {
  content: "\ef85"; }

.ri-mv-fill:before {
  content: "\ef86"; }

.ri-mv-line:before {
  content: "\ef87"; }

.ri-navigation-fill:before {
  content: "\ef88"; }

.ri-navigation-line:before {
  content: "\ef89"; }

.ri-netease-cloud-music-fill:before {
  content: "\ef8a"; }

.ri-netease-cloud-music-line:before {
  content: "\ef8b"; }

.ri-netflix-fill:before {
  content: "\ef8c"; }

.ri-netflix-line:before {
  content: "\ef8d"; }

.ri-newspaper-fill:before {
  content: "\ef8e"; }

.ri-newspaper-line:before {
  content: "\ef8f"; }

.ri-node-tree:before {
  content: "\ef90"; }

.ri-notification-2-fill:before {
  content: "\ef91"; }

.ri-notification-2-line:before {
  content: "\ef92"; }

.ri-notification-3-fill:before {
  content: "\ef93"; }

.ri-notification-3-line:before {
  content: "\ef94"; }

.ri-notification-4-fill:before {
  content: "\ef95"; }

.ri-notification-4-line:before {
  content: "\ef96"; }

.ri-notification-badge-fill:before {
  content: "\ef97"; }

.ri-notification-badge-line:before {
  content: "\ef98"; }

.ri-notification-fill:before {
  content: "\ef99"; }

.ri-notification-line:before {
  content: "\ef9a"; }

.ri-notification-off-fill:before {
  content: "\ef9b"; }

.ri-notification-off-line:before {
  content: "\ef9c"; }

.ri-npmjs-fill:before {
  content: "\ef9d"; }

.ri-npmjs-line:before {
  content: "\ef9e"; }

.ri-number-0:before {
  content: "\ef9f"; }

.ri-number-1:before {
  content: "\efa0"; }

.ri-number-2:before {
  content: "\efa1"; }

.ri-number-3:before {
  content: "\efa2"; }

.ri-number-4:before {
  content: "\efa3"; }

.ri-number-5:before {
  content: "\efa4"; }

.ri-number-6:before {
  content: "\efa5"; }

.ri-number-7:before {
  content: "\efa6"; }

.ri-number-8:before {
  content: "\efa7"; }

.ri-number-9:before {
  content: "\efa8"; }

.ri-numbers-fill:before {
  content: "\efa9"; }

.ri-numbers-line:before {
  content: "\efaa"; }

.ri-nurse-fill:before {
  content: "\efab"; }

.ri-nurse-line:before {
  content: "\efac"; }

.ri-oil-fill:before {
  content: "\efad"; }

.ri-oil-line:before {
  content: "\efae"; }

.ri-omega:before {
  content: "\efaf"; }

.ri-open-arm-fill:before {
  content: "\efb0"; }

.ri-open-arm-line:before {
  content: "\efb1"; }

.ri-open-source-fill:before {
  content: "\efb2"; }

.ri-open-source-line:before {
  content: "\efb3"; }

.ri-opera-fill:before {
  content: "\efb4"; }

.ri-opera-line:before {
  content: "\efb5"; }

.ri-order-play-fill:before {
  content: "\efb6"; }

.ri-order-play-line:before {
  content: "\efb7"; }

.ri-organization-chart:before {
  content: "\efb8"; }

.ri-outlet-2-fill:before {
  content: "\efb9"; }

.ri-outlet-2-line:before {
  content: "\efba"; }

.ri-outlet-fill:before {
  content: "\efbb"; }

.ri-outlet-line:before {
  content: "\efbc"; }

.ri-page-separator:before {
  content: "\efbd"; }

.ri-pages-fill:before {
  content: "\efbe"; }

.ri-pages-line:before {
  content: "\efbf"; }

.ri-paint-brush-fill:before {
  content: "\efc0"; }

.ri-paint-brush-line:before {
  content: "\efc1"; }

.ri-paint-fill:before {
  content: "\efc2"; }

.ri-paint-line:before {
  content: "\efc3"; }

.ri-palette-fill:before {
  content: "\efc4"; }

.ri-palette-line:before {
  content: "\efc5"; }

.ri-pantone-fill:before {
  content: "\efc6"; }

.ri-pantone-line:before {
  content: "\efc7"; }

.ri-paragraph:before {
  content: "\efc8"; }

.ri-parent-fill:before {
  content: "\efc9"; }

.ri-parent-line:before {
  content: "\efca"; }

.ri-parentheses-fill:before {
  content: "\efcb"; }

.ri-parentheses-line:before {
  content: "\efcc"; }

.ri-parking-box-fill:before {
  content: "\efcd"; }

.ri-parking-box-line:before {
  content: "\efce"; }

.ri-parking-fill:before {
  content: "\efcf"; }

.ri-parking-line:before {
  content: "\efd0"; }

.ri-passport-fill:before {
  content: "\efd1"; }

.ri-passport-line:before {
  content: "\efd2"; }

.ri-patreon-fill:before {
  content: "\efd3"; }

.ri-patreon-line:before {
  content: "\efd4"; }

.ri-pause-circle-fill:before {
  content: "\efd5"; }

.ri-pause-circle-line:before {
  content: "\efd6"; }

.ri-pause-fill:before {
  content: "\efd7"; }

.ri-pause-line:before {
  content: "\efd8"; }

.ri-pause-mini-fill:before {
  content: "\efd9"; }

.ri-pause-mini-line:before {
  content: "\efda"; }

.ri-paypal-fill:before {
  content: "\efdb"; }

.ri-paypal-line:before {
  content: "\efdc"; }

.ri-pen-nib-fill:before {
  content: "\efdd"; }

.ri-pen-nib-line:before {
  content: "\efde"; }

.ri-pencil-fill:before {
  content: "\efdf"; }

.ri-pencil-line:before {
  content: "\efe0"; }

.ri-pencil-ruler-2-fill:before {
  content: "\efe1"; }

.ri-pencil-ruler-2-line:before {
  content: "\efe2"; }

.ri-pencil-ruler-fill:before {
  content: "\efe3"; }

.ri-pencil-ruler-line:before {
  content: "\efe4"; }

.ri-percent-fill:before {
  content: "\efe5"; }

.ri-percent-line:before {
  content: "\efe6"; }

.ri-phone-camera-fill:before {
  content: "\efe7"; }

.ri-phone-camera-line:before {
  content: "\efe8"; }

.ri-phone-fill:before {
  content: "\efe9"; }

.ri-phone-find-fill:before {
  content: "\efea"; }

.ri-phone-find-line:before {
  content: "\efeb"; }

.ri-phone-line:before {
  content: "\efec"; }

.ri-phone-lock-fill:before {
  content: "\efed"; }

.ri-phone-lock-line:before {
  content: "\efee"; }

.ri-picture-in-picture-2-fill:before {
  content: "\efef"; }

.ri-picture-in-picture-2-line:before {
  content: "\eff0"; }

.ri-picture-in-picture-exit-fill:before {
  content: "\eff1"; }

.ri-picture-in-picture-exit-line:before {
  content: "\eff2"; }

.ri-picture-in-picture-fill:before {
  content: "\eff3"; }

.ri-picture-in-picture-line:before {
  content: "\eff4"; }

.ri-pie-chart-2-fill:before {
  content: "\eff5"; }

.ri-pie-chart-2-line:before {
  content: "\eff6"; }

.ri-pie-chart-box-fill:before {
  content: "\eff7"; }

.ri-pie-chart-box-line:before {
  content: "\eff8"; }

.ri-pie-chart-fill:before {
  content: "\eff9"; }

.ri-pie-chart-line:before {
  content: "\effa"; }

.ri-pin-distance-fill:before {
  content: "\effb"; }

.ri-pin-distance-line:before {
  content: "\effc"; }

.ri-ping-pong-fill:before {
  content: "\effd"; }

.ri-ping-pong-line:before {
  content: "\effe"; }

.ri-pinterest-fill:before {
  content: "\efff"; }

.ri-pinterest-line:before {
  content: "\f000"; }

.ri-pinyin-input:before {
  content: "\f001"; }

.ri-pixelfed-fill:before {
  content: "\f002"; }

.ri-pixelfed-line:before {
  content: "\f003"; }

.ri-plane-fill:before {
  content: "\f004"; }

.ri-plane-line:before {
  content: "\f005"; }

.ri-plant-fill:before {
  content: "\f006"; }

.ri-plant-line:before {
  content: "\f007"; }

.ri-play-circle-fill:before {
  content: "\f008"; }

.ri-play-circle-line:before {
  content: "\f009"; }

.ri-play-fill:before {
  content: "\f00a"; }

.ri-play-line:before {
  content: "\f00b"; }

.ri-play-list-2-fill:before {
  content: "\f00c"; }

.ri-play-list-2-line:before {
  content: "\f00d"; }

.ri-play-list-add-fill:before {
  content: "\f00e"; }

.ri-play-list-add-line:before {
  content: "\f00f"; }

.ri-play-list-fill:before {
  content: "\f010"; }

.ri-play-list-line:before {
  content: "\f011"; }

.ri-play-mini-fill:before {
  content: "\f012"; }

.ri-play-mini-line:before {
  content: "\f013"; }

.ri-playstation-fill:before {
  content: "\f014"; }

.ri-playstation-line:before {
  content: "\f015"; }

.ri-plug-2-fill:before {
  content: "\f016"; }

.ri-plug-2-line:before {
  content: "\f017"; }

.ri-plug-fill:before {
  content: "\f018"; }

.ri-plug-line:before {
  content: "\f019"; }

.ri-polaroid-2-fill:before {
  content: "\f01a"; }

.ri-polaroid-2-line:before {
  content: "\f01b"; }

.ri-polaroid-fill:before {
  content: "\f01c"; }

.ri-polaroid-line:before {
  content: "\f01d"; }

.ri-police-car-fill:before {
  content: "\f01e"; }

.ri-police-car-line:before {
  content: "\f01f"; }

.ri-price-tag-2-fill:before {
  content: "\f020"; }

.ri-price-tag-2-line:before {
  content: "\f021"; }

.ri-price-tag-3-fill:before {
  content: "\f022"; }

.ri-price-tag-3-line:before {
  content: "\f023"; }

.ri-price-tag-fill:before {
  content: "\f024"; }

.ri-price-tag-line:before {
  content: "\f025"; }

.ri-printer-cloud-fill:before {
  content: "\f026"; }

.ri-printer-cloud-line:before {
  content: "\f027"; }

.ri-printer-fill:before {
  content: "\f028"; }

.ri-printer-line:before {
  content: "\f029"; }

.ri-product-hunt-fill:before {
  content: "\f02a"; }

.ri-product-hunt-line:before {
  content: "\f02b"; }

.ri-profile-fill:before {
  content: "\f02c"; }

.ri-profile-line:before {
  content: "\f02d"; }

.ri-projector-2-fill:before {
  content: "\f02e"; }

.ri-projector-2-line:before {
  content: "\f02f"; }

.ri-projector-fill:before {
  content: "\f030"; }

.ri-projector-line:before {
  content: "\f031"; }

.ri-psychotherapy-fill:before {
  content: "\f032"; }

.ri-psychotherapy-line:before {
  content: "\f033"; }

.ri-pulse-fill:before {
  content: "\f034"; }

.ri-pulse-line:before {
  content: "\f035"; }

.ri-pushpin-2-fill:before {
  content: "\f036"; }

.ri-pushpin-2-line:before {
  content: "\f037"; }

.ri-pushpin-fill:before {
  content: "\f038"; }

.ri-pushpin-line:before {
  content: "\f039"; }

.ri-qq-fill:before {
  content: "\f03a"; }

.ri-qq-line:before {
  content: "\f03b"; }

.ri-qr-code-fill:before {
  content: "\f03c"; }

.ri-qr-code-line:before {
  content: "\f03d"; }

.ri-qr-scan-2-fill:before {
  content: "\f03e"; }

.ri-qr-scan-2-line:before {
  content: "\f03f"; }

.ri-qr-scan-fill:before {
  content: "\f040"; }

.ri-qr-scan-line:before {
  content: "\f041"; }

.ri-question-answer-fill:before {
  content: "\f042"; }

.ri-question-answer-line:before {
  content: "\f043"; }

.ri-question-fill:before {
  content: "\f044"; }

.ri-question-line:before {
  content: "\f045"; }

.ri-question-mark:before {
  content: "\f046"; }

.ri-questionnaire-fill:before {
  content: "\f047"; }

.ri-questionnaire-line:before {
  content: "\f048"; }

.ri-quill-pen-fill:before {
  content: "\f049"; }

.ri-quill-pen-line:before {
  content: "\f04a"; }

.ri-radar-fill:before {
  content: "\f04b"; }

.ri-radar-line:before {
  content: "\f04c"; }

.ri-radio-2-fill:before {
  content: "\f04d"; }

.ri-radio-2-line:before {
  content: "\f04e"; }

.ri-radio-button-fill:before {
  content: "\f04f"; }

.ri-radio-button-line:before {
  content: "\f050"; }

.ri-radio-fill:before {
  content: "\f051"; }

.ri-radio-line:before {
  content: "\f052"; }

.ri-rainbow-fill:before {
  content: "\f053"; }

.ri-rainbow-line:before {
  content: "\f054"; }

.ri-rainy-fill:before {
  content: "\f055"; }

.ri-rainy-line:before {
  content: "\f056"; }

.ri-reactjs-fill:before {
  content: "\f057"; }

.ri-reactjs-line:before {
  content: "\f058"; }

.ri-record-circle-fill:before {
  content: "\f059"; }

.ri-record-circle-line:before {
  content: "\f05a"; }

.ri-record-mail-fill:before {
  content: "\f05b"; }

.ri-record-mail-line:before {
  content: "\f05c"; }

.ri-recycle-fill:before {
  content: "\f05d"; }

.ri-recycle-line:before {
  content: "\f05e"; }

.ri-red-packet-fill:before {
  content: "\f05f"; }

.ri-red-packet-line:before {
  content: "\f060"; }

.ri-reddit-fill:before {
  content: "\f061"; }

.ri-reddit-line:before {
  content: "\f062"; }

.ri-refresh-fill:before {
  content: "\f063"; }

.ri-refresh-line:before {
  content: "\f064"; }

.ri-refund-2-fill:before {
  content: "\f065"; }

.ri-refund-2-line:before {
  content: "\f066"; }

.ri-refund-fill:before {
  content: "\f067"; }

.ri-refund-line:before {
  content: "\f068"; }

.ri-registered-fill:before {
  content: "\f069"; }

.ri-registered-line:before {
  content: "\f06a"; }

.ri-remixicon-fill:before {
  content: "\f06b"; }

.ri-remixicon-line:before {
  content: "\f06c"; }

.ri-remote-control-2-fill:before {
  content: "\f06d"; }

.ri-remote-control-2-line:before {
  content: "\f06e"; }

.ri-remote-control-fill:before {
  content: "\f06f"; }

.ri-remote-control-line:before {
  content: "\f070"; }

.ri-repeat-2-fill:before {
  content: "\f071"; }

.ri-repeat-2-line:before {
  content: "\f072"; }

.ri-repeat-fill:before {
  content: "\f073"; }

.ri-repeat-line:before {
  content: "\f074"; }

.ri-repeat-one-fill:before {
  content: "\f075"; }

.ri-repeat-one-line:before {
  content: "\f076"; }

.ri-reply-all-fill:before {
  content: "\f077"; }

.ri-reply-all-line:before {
  content: "\f078"; }

.ri-reply-fill:before {
  content: "\f079"; }

.ri-reply-line:before {
  content: "\f07a"; }

.ri-reserved-fill:before {
  content: "\f07b"; }

.ri-reserved-line:before {
  content: "\f07c"; }

.ri-rest-time-fill:before {
  content: "\f07d"; }

.ri-rest-time-line:before {
  content: "\f07e"; }

.ri-restart-fill:before {
  content: "\f07f"; }

.ri-restart-line:before {
  content: "\f080"; }

.ri-restaurant-2-fill:before {
  content: "\f081"; }

.ri-restaurant-2-line:before {
  content: "\f082"; }

.ri-restaurant-fill:before {
  content: "\f083"; }

.ri-restaurant-line:before {
  content: "\f084"; }

.ri-rewind-fill:before {
  content: "\f085"; }

.ri-rewind-line:before {
  content: "\f086"; }

.ri-rewind-mini-fill:before {
  content: "\f087"; }

.ri-rewind-mini-line:before {
  content: "\f088"; }

.ri-rhythm-fill:before {
  content: "\f089"; }

.ri-rhythm-line:before {
  content: "\f08a"; }

.ri-riding-fill:before {
  content: "\f08b"; }

.ri-riding-line:before {
  content: "\f08c"; }

.ri-road-map-fill:before {
  content: "\f08d"; }

.ri-road-map-line:before {
  content: "\f08e"; }

.ri-roadster-fill:before {
  content: "\f08f"; }

.ri-roadster-line:before {
  content: "\f090"; }

.ri-robot-fill:before {
  content: "\f091"; }

.ri-robot-line:before {
  content: "\f092"; }

.ri-rocket-2-fill:before {
  content: "\f093"; }

.ri-rocket-2-line:before {
  content: "\f094"; }

.ri-rocket-fill:before {
  content: "\f095"; }

.ri-rocket-line:before {
  content: "\f096"; }

.ri-rotate-lock-fill:before {
  content: "\f097"; }

.ri-rotate-lock-line:before {
  content: "\f098"; }

.ri-rounded-corner:before {
  content: "\f099"; }

.ri-route-fill:before {
  content: "\f09a"; }

.ri-route-line:before {
  content: "\f09b"; }

.ri-router-fill:before {
  content: "\f09c"; }

.ri-router-line:before {
  content: "\f09d"; }

.ri-rss-fill:before {
  content: "\f09e"; }

.ri-rss-line:before {
  content: "\f09f"; }

.ri-ruler-2-fill:before {
  content: "\f0a0"; }

.ri-ruler-2-line:before {
  content: "\f0a1"; }

.ri-ruler-fill:before {
  content: "\f0a2"; }

.ri-ruler-line:before {
  content: "\f0a3"; }

.ri-run-fill:before {
  content: "\f0a4"; }

.ri-run-line:before {
  content: "\f0a5"; }

.ri-safari-fill:before {
  content: "\f0a6"; }

.ri-safari-line:before {
  content: "\f0a7"; }

.ri-safe-2-fill:before {
  content: "\f0a8"; }

.ri-safe-2-line:before {
  content: "\f0a9"; }

.ri-safe-fill:before {
  content: "\f0aa"; }

.ri-safe-line:before {
  content: "\f0ab"; }

.ri-sailboat-fill:before {
  content: "\f0ac"; }

.ri-sailboat-line:before {
  content: "\f0ad"; }

.ri-save-2-fill:before {
  content: "\f0ae"; }

.ri-save-2-line:before {
  content: "\f0af"; }

.ri-save-3-fill:before {
  content: "\f0b0"; }

.ri-save-3-line:before {
  content: "\f0b1"; }

.ri-save-fill:before {
  content: "\f0b2"; }

.ri-save-line:before {
  content: "\f0b3"; }

.ri-scales-2-fill:before {
  content: "\f0b4"; }

.ri-scales-2-line:before {
  content: "\f0b5"; }

.ri-scales-3-fill:before {
  content: "\f0b6"; }

.ri-scales-3-line:before {
  content: "\f0b7"; }

.ri-scales-fill:before {
  content: "\f0b8"; }

.ri-scales-line:before {
  content: "\f0b9"; }

.ri-scan-2-fill:before {
  content: "\f0ba"; }

.ri-scan-2-line:before {
  content: "\f0bb"; }

.ri-scan-fill:before {
  content: "\f0bc"; }

.ri-scan-line:before {
  content: "\f0bd"; }

.ri-scissors-2-fill:before {
  content: "\f0be"; }

.ri-scissors-2-line:before {
  content: "\f0bf"; }

.ri-scissors-cut-fill:before {
  content: "\f0c0"; }

.ri-scissors-cut-line:before {
  content: "\f0c1"; }

.ri-scissors-fill:before {
  content: "\f0c2"; }

.ri-scissors-line:before {
  content: "\f0c3"; }

.ri-screenshot-2-fill:before {
  content: "\f0c4"; }

.ri-screenshot-2-line:before {
  content: "\f0c5"; }

.ri-screenshot-fill:before {
  content: "\f0c6"; }

.ri-screenshot-line:before {
  content: "\f0c7"; }

.ri-sd-card-fill:before {
  content: "\f0c8"; }

.ri-sd-card-line:before {
  content: "\f0c9"; }

.ri-sd-card-mini-fill:before {
  content: "\f0ca"; }

.ri-sd-card-mini-line:before {
  content: "\f0cb"; }

.ri-search-2-fill:before {
  content: "\f0cc"; }

.ri-search-2-line:before {
  content: "\f0cd"; }

.ri-search-eye-fill:before {
  content: "\f0ce"; }

.ri-search-eye-line:before {
  content: "\f0cf"; }

.ri-search-fill:before {
  content: "\f0d0"; }

.ri-search-line:before {
  content: "\f0d1"; }

.ri-secure-payment-fill:before {
  content: "\f0d2"; }

.ri-secure-payment-line:before {
  content: "\f0d3"; }

.ri-seedling-fill:before {
  content: "\f0d4"; }

.ri-seedling-line:before {
  content: "\f0d5"; }

.ri-send-backward:before {
  content: "\f0d6"; }

.ri-send-plane-2-fill:before {
  content: "\f0d7"; }

.ri-send-plane-2-line:before {
  content: "\f0d8"; }

.ri-send-plane-fill:before {
  content: "\f0d9"; }

.ri-send-plane-line:before {
  content: "\f0da"; }

.ri-send-to-back:before {
  content: "\f0db"; }

.ri-sensor-fill:before {
  content: "\f0dc"; }

.ri-sensor-line:before {
  content: "\f0dd"; }

.ri-separator:before {
  content: "\f0de"; }

.ri-server-fill:before {
  content: "\f0df"; }

.ri-server-line:before {
  content: "\f0e0"; }

.ri-service-fill:before {
  content: "\f0e1"; }

.ri-service-line:before {
  content: "\f0e2"; }

.ri-settings-2-fill:before {
  content: "\f0e3"; }

.ri-settings-2-line:before {
  content: "\f0e4"; }

.ri-settings-3-fill:before {
  content: "\f0e5"; }

.ri-settings-3-line:before {
  content: "\f0e6"; }

.ri-settings-4-fill:before {
  content: "\f0e7"; }

.ri-settings-4-line:before {
  content: "\f0e8"; }

.ri-settings-5-fill:before {
  content: "\f0e9"; }

.ri-settings-5-line:before {
  content: "\f0ea"; }

.ri-settings-6-fill:before {
  content: "\f0eb"; }

.ri-settings-6-line:before {
  content: "\f0ec"; }

.ri-settings-fill:before {
  content: "\f0ed"; }

.ri-settings-line:before {
  content: "\f0ee"; }

.ri-shape-2-fill:before {
  content: "\f0ef"; }

.ri-shape-2-line:before {
  content: "\f0f0"; }

.ri-shape-fill:before {
  content: "\f0f1"; }

.ri-shape-line:before {
  content: "\f0f2"; }

.ri-share-box-fill:before {
  content: "\f0f3"; }

.ri-share-box-line:before {
  content: "\f0f4"; }

.ri-share-circle-fill:before {
  content: "\f0f5"; }

.ri-share-circle-line:before {
  content: "\f0f6"; }

.ri-share-fill:before {
  content: "\f0f7"; }

.ri-share-forward-2-fill:before {
  content: "\f0f8"; }

.ri-share-forward-2-line:before {
  content: "\f0f9"; }

.ri-share-forward-box-fill:before {
  content: "\f0fa"; }

.ri-share-forward-box-line:before {
  content: "\f0fb"; }

.ri-share-forward-fill:before {
  content: "\f0fc"; }

.ri-share-forward-line:before {
  content: "\f0fd"; }

.ri-share-line:before {
  content: "\f0fe"; }

.ri-shield-check-fill:before {
  content: "\f0ff"; }

.ri-shield-check-line:before {
  content: "\f100"; }

.ri-shield-cross-fill:before {
  content: "\f101"; }

.ri-shield-cross-line:before {
  content: "\f102"; }

.ri-shield-fill:before {
  content: "\f103"; }

.ri-shield-flash-fill:before {
  content: "\f104"; }

.ri-shield-flash-line:before {
  content: "\f105"; }

.ri-shield-keyhole-fill:before {
  content: "\f106"; }

.ri-shield-keyhole-line:before {
  content: "\f107"; }

.ri-shield-line:before {
  content: "\f108"; }

.ri-shield-star-fill:before {
  content: "\f109"; }

.ri-shield-star-line:before {
  content: "\f10a"; }

.ri-shield-user-fill:before {
  content: "\f10b"; }

.ri-shield-user-line:before {
  content: "\f10c"; }

.ri-ship-2-fill:before {
  content: "\f10d"; }

.ri-ship-2-line:before {
  content: "\f10e"; }

.ri-ship-fill:before {
  content: "\f10f"; }

.ri-ship-line:before {
  content: "\f110"; }

.ri-shirt-fill:before {
  content: "\f111"; }

.ri-shirt-line:before {
  content: "\f112"; }

.ri-shopping-bag-2-fill:before {
  content: "\f113"; }

.ri-shopping-bag-2-line:before {
  content: "\f114"; }

.ri-shopping-bag-3-fill:before {
  content: "\f115"; }

.ri-shopping-bag-3-line:before {
  content: "\f116"; }

.ri-shopping-bag-fill:before {
  content: "\f117"; }

.ri-shopping-bag-line:before {
  content: "\f118"; }

.ri-shopping-basket-2-fill:before {
  content: "\f119"; }

.ri-shopping-basket-2-line:before {
  content: "\f11a"; }

.ri-shopping-basket-fill:before {
  content: "\f11b"; }

.ri-shopping-basket-line:before {
  content: "\f11c"; }

.ri-shopping-cart-2-fill:before {
  content: "\f11d"; }

.ri-shopping-cart-2-line:before {
  content: "\f11e"; }

.ri-shopping-cart-fill:before {
  content: "\f11f"; }

.ri-shopping-cart-line:before {
  content: "\f120"; }

.ri-showers-fill:before {
  content: "\f121"; }

.ri-showers-line:before {
  content: "\f122"; }

.ri-shuffle-fill:before {
  content: "\f123"; }

.ri-shuffle-line:before {
  content: "\f124"; }

.ri-shut-down-fill:before {
  content: "\f125"; }

.ri-shut-down-line:before {
  content: "\f126"; }

.ri-side-bar-fill:before {
  content: "\f127"; }

.ri-side-bar-line:before {
  content: "\f128"; }

.ri-signal-tower-fill:before {
  content: "\f129"; }

.ri-signal-tower-line:before {
  content: "\f12a"; }

.ri-signal-wifi-1-fill:before {
  content: "\f12b"; }

.ri-signal-wifi-1-line:before {
  content: "\f12c"; }

.ri-signal-wifi-2-fill:before {
  content: "\f12d"; }

.ri-signal-wifi-2-line:before {
  content: "\f12e"; }

.ri-signal-wifi-3-fill:before {
  content: "\f12f"; }

.ri-signal-wifi-3-line:before {
  content: "\f130"; }

.ri-signal-wifi-error-fill:before {
  content: "\f131"; }

.ri-signal-wifi-error-line:before {
  content: "\f132"; }

.ri-signal-wifi-fill:before {
  content: "\f133"; }

.ri-signal-wifi-line:before {
  content: "\f134"; }

.ri-signal-wifi-off-fill:before {
  content: "\f135"; }

.ri-signal-wifi-off-line:before {
  content: "\f136"; }

.ri-sim-card-2-fill:before {
  content: "\f137"; }

.ri-sim-card-2-line:before {
  content: "\f138"; }

.ri-sim-card-fill:before {
  content: "\f139"; }

.ri-sim-card-line:before {
  content: "\f13a"; }

.ri-single-quotes-l:before {
  content: "\f13b"; }

.ri-single-quotes-r:before {
  content: "\f13c"; }

.ri-sip-fill:before {
  content: "\f13d"; }

.ri-sip-line:before {
  content: "\f13e"; }

.ri-skip-back-fill:before {
  content: "\f13f"; }

.ri-skip-back-line:before {
  content: "\f140"; }

.ri-skip-back-mini-fill:before {
  content: "\f141"; }

.ri-skip-back-mini-line:before {
  content: "\f142"; }

.ri-skip-forward-fill:before {
  content: "\f143"; }

.ri-skip-forward-line:before {
  content: "\f144"; }

.ri-skip-forward-mini-fill:before {
  content: "\f145"; }

.ri-skip-forward-mini-line:before {
  content: "\f146"; }

.ri-skull-2-fill:before {
  content: "\f147"; }

.ri-skull-2-line:before {
  content: "\f148"; }

.ri-skull-fill:before {
  content: "\f149"; }

.ri-skull-line:before {
  content: "\f14a"; }

.ri-skype-fill:before {
  content: "\f14b"; }

.ri-skype-line:before {
  content: "\f14c"; }

.ri-slack-fill:before {
  content: "\f14d"; }

.ri-slack-line:before {
  content: "\f14e"; }

.ri-slice-fill:before {
  content: "\f14f"; }

.ri-slice-line:before {
  content: "\f150"; }

.ri-slideshow-2-fill:before {
  content: "\f151"; }

.ri-slideshow-2-line:before {
  content: "\f152"; }

.ri-slideshow-3-fill:before {
  content: "\f153"; }

.ri-slideshow-3-line:before {
  content: "\f154"; }

.ri-slideshow-4-fill:before {
  content: "\f155"; }

.ri-slideshow-4-line:before {
  content: "\f156"; }

.ri-slideshow-fill:before {
  content: "\f157"; }

.ri-slideshow-line:before {
  content: "\f158"; }

.ri-smartphone-fill:before {
  content: "\f159"; }

.ri-smartphone-line:before {
  content: "\f15a"; }

.ri-snapchat-fill:before {
  content: "\f15b"; }

.ri-snapchat-line:before {
  content: "\f15c"; }

.ri-snowy-fill:before {
  content: "\f15d"; }

.ri-snowy-line:before {
  content: "\f15e"; }

.ri-sort-asc:before {
  content: "\f15f"; }

.ri-sort-desc:before {
  content: "\f160"; }

.ri-sound-module-fill:before {
  content: "\f161"; }

.ri-sound-module-line:before {
  content: "\f162"; }

.ri-soundcloud-fill:before {
  content: "\f163"; }

.ri-soundcloud-line:before {
  content: "\f164"; }

.ri-space-ship-fill:before {
  content: "\f165"; }

.ri-space-ship-line:before {
  content: "\f166"; }

.ri-space:before {
  content: "\f167"; }

.ri-spam-2-fill:before {
  content: "\f168"; }

.ri-spam-2-line:before {
  content: "\f169"; }

.ri-spam-3-fill:before {
  content: "\f16a"; }

.ri-spam-3-line:before {
  content: "\f16b"; }

.ri-spam-fill:before {
  content: "\f16c"; }

.ri-spam-line:before {
  content: "\f16d"; }

.ri-speaker-2-fill:before {
  content: "\f16e"; }

.ri-speaker-2-line:before {
  content: "\f16f"; }

.ri-speaker-3-fill:before {
  content: "\f170"; }

.ri-speaker-3-line:before {
  content: "\f171"; }

.ri-speaker-fill:before {
  content: "\f172"; }

.ri-speaker-line:before {
  content: "\f173"; }

.ri-spectrum-fill:before {
  content: "\f174"; }

.ri-spectrum-line:before {
  content: "\f175"; }

.ri-speed-fill:before {
  content: "\f176"; }

.ri-speed-line:before {
  content: "\f177"; }

.ri-speed-mini-fill:before {
  content: "\f178"; }

.ri-speed-mini-line:before {
  content: "\f179"; }

.ri-split-cells-horizontal:before {
  content: "\f17a"; }

.ri-split-cells-vertical:before {
  content: "\f17b"; }

.ri-spotify-fill:before {
  content: "\f17c"; }

.ri-spotify-line:before {
  content: "\f17d"; }

.ri-spy-fill:before {
  content: "\f17e"; }

.ri-spy-line:before {
  content: "\f17f"; }

.ri-stack-fill:before {
  content: "\f180"; }

.ri-stack-line:before {
  content: "\f181"; }

.ri-stack-overflow-fill:before {
  content: "\f182"; }

.ri-stack-overflow-line:before {
  content: "\f183"; }

.ri-stackshare-fill:before {
  content: "\f184"; }

.ri-stackshare-line:before {
  content: "\f185"; }

.ri-star-fill:before {
  content: "\f186"; }

.ri-star-half-fill:before {
  content: "\f187"; }

.ri-star-half-line:before {
  content: "\f188"; }

.ri-star-half-s-fill:before {
  content: "\f189"; }

.ri-star-half-s-line:before {
  content: "\f18a"; }

.ri-star-line:before {
  content: "\f18b"; }

.ri-star-s-fill:before {
  content: "\f18c"; }

.ri-star-s-line:before {
  content: "\f18d"; }

.ri-star-smile-fill:before {
  content: "\f18e"; }

.ri-star-smile-line:before {
  content: "\f18f"; }

.ri-steam-fill:before {
  content: "\f190"; }

.ri-steam-line:before {
  content: "\f191"; }

.ri-steering-2-fill:before {
  content: "\f192"; }

.ri-steering-2-line:before {
  content: "\f193"; }

.ri-steering-fill:before {
  content: "\f194"; }

.ri-steering-line:before {
  content: "\f195"; }

.ri-stethoscope-fill:before {
  content: "\f196"; }

.ri-stethoscope-line:before {
  content: "\f197"; }

.ri-sticky-note-2-fill:before {
  content: "\f198"; }

.ri-sticky-note-2-line:before {
  content: "\f199"; }

.ri-sticky-note-fill:before {
  content: "\f19a"; }

.ri-sticky-note-line:before {
  content: "\f19b"; }

.ri-stock-fill:before {
  content: "\f19c"; }

.ri-stock-line:before {
  content: "\f19d"; }

.ri-stop-circle-fill:before {
  content: "\f19e"; }

.ri-stop-circle-line:before {
  content: "\f19f"; }

.ri-stop-fill:before {
  content: "\f1a0"; }

.ri-stop-line:before {
  content: "\f1a1"; }

.ri-stop-mini-fill:before {
  content: "\f1a2"; }

.ri-stop-mini-line:before {
  content: "\f1a3"; }

.ri-store-2-fill:before {
  content: "\f1a4"; }

.ri-store-2-line:before {
  content: "\f1a5"; }

.ri-store-3-fill:before {
  content: "\f1a6"; }

.ri-store-3-line:before {
  content: "\f1a7"; }

.ri-store-fill:before {
  content: "\f1a8"; }

.ri-store-line:before {
  content: "\f1a9"; }

.ri-strikethrough-2:before {
  content: "\f1aa"; }

.ri-strikethrough:before {
  content: "\f1ab"; }

.ri-subscript-2:before {
  content: "\f1ac"; }

.ri-subscript:before {
  content: "\f1ad"; }

.ri-subtract-fill:before {
  content: "\f1ae"; }

.ri-subtract-line:before {
  content: "\f1af"; }

.ri-subway-fill:before {
  content: "\f1b0"; }

.ri-subway-line:before {
  content: "\f1b1"; }

.ri-subway-wifi-fill:before {
  content: "\f1b2"; }

.ri-subway-wifi-line:before {
  content: "\f1b3"; }

.ri-suitcase-2-fill:before {
  content: "\f1b4"; }

.ri-suitcase-2-line:before {
  content: "\f1b5"; }

.ri-suitcase-3-fill:before {
  content: "\f1b6"; }

.ri-suitcase-3-line:before {
  content: "\f1b7"; }

.ri-suitcase-fill:before {
  content: "\f1b8"; }

.ri-suitcase-line:before {
  content: "\f1b9"; }

.ri-sun-cloudy-fill:before {
  content: "\f1ba"; }

.ri-sun-cloudy-line:before {
  content: "\f1bb"; }

.ri-sun-fill:before {
  content: "\f1bc"; }

.ri-sun-foggy-fill:before {
  content: "\f1bd"; }

.ri-sun-foggy-line:before {
  content: "\f1be"; }

.ri-sun-line:before {
  content: "\f1bf"; }

.ri-superscript-2:before {
  content: "\f1c0"; }

.ri-superscript:before {
  content: "\f1c1"; }

.ri-surgical-mask-fill:before {
  content: "\f1c2"; }

.ri-surgical-mask-line:before {
  content: "\f1c3"; }

.ri-surround-sound-fill:before {
  content: "\f1c4"; }

.ri-surround-sound-line:before {
  content: "\f1c5"; }

.ri-survey-fill:before {
  content: "\f1c6"; }

.ri-survey-line:before {
  content: "\f1c7"; }

.ri-swap-box-fill:before {
  content: "\f1c8"; }

.ri-swap-box-line:before {
  content: "\f1c9"; }

.ri-swap-fill:before {
  content: "\f1ca"; }

.ri-swap-line:before {
  content: "\f1cb"; }

.ri-switch-fill:before {
  content: "\f1cc"; }

.ri-switch-line:before {
  content: "\f1cd"; }

.ri-sword-fill:before {
  content: "\f1ce"; }

.ri-sword-line:before {
  content: "\f1cf"; }

.ri-syringe-fill:before {
  content: "\f1d0"; }

.ri-syringe-line:before {
  content: "\f1d1"; }

.ri-t-box-fill:before {
  content: "\f1d2"; }

.ri-t-box-line:before {
  content: "\f1d3"; }

.ri-t-shirt-2-fill:before {
  content: "\f1d4"; }

.ri-t-shirt-2-line:before {
  content: "\f1d5"; }

.ri-t-shirt-air-fill:before {
  content: "\f1d6"; }

.ri-t-shirt-air-line:before {
  content: "\f1d7"; }

.ri-t-shirt-fill:before {
  content: "\f1d8"; }

.ri-t-shirt-line:before {
  content: "\f1d9"; }

.ri-table-2:before {
  content: "\f1da"; }

.ri-table-alt-fill:before {
  content: "\f1db"; }

.ri-table-alt-line:before {
  content: "\f1dc"; }

.ri-table-fill:before {
  content: "\f1dd"; }

.ri-table-line:before {
  content: "\f1de"; }

.ri-tablet-fill:before {
  content: "\f1df"; }

.ri-tablet-line:before {
  content: "\f1e0"; }

.ri-takeaway-fill:before {
  content: "\f1e1"; }

.ri-takeaway-line:before {
  content: "\f1e2"; }

.ri-taobao-fill:before {
  content: "\f1e3"; }

.ri-taobao-line:before {
  content: "\f1e4"; }

.ri-tape-fill:before {
  content: "\f1e5"; }

.ri-tape-line:before {
  content: "\f1e6"; }

.ri-task-fill:before {
  content: "\f1e7"; }

.ri-task-line:before {
  content: "\f1e8"; }

.ri-taxi-fill:before {
  content: "\f1e9"; }

.ri-taxi-line:before {
  content: "\f1ea"; }

.ri-taxi-wifi-fill:before {
  content: "\f1eb"; }

.ri-taxi-wifi-line:before {
  content: "\f1ec"; }

.ri-team-fill:before {
  content: "\f1ed"; }

.ri-team-line:before {
  content: "\f1ee"; }

.ri-telegram-fill:before {
  content: "\f1ef"; }

.ri-telegram-line:before {
  content: "\f1f0"; }

.ri-temp-cold-fill:before {
  content: "\f1f1"; }

.ri-temp-cold-line:before {
  content: "\f1f2"; }

.ri-temp-hot-fill:before {
  content: "\f1f3"; }

.ri-temp-hot-line:before {
  content: "\f1f4"; }

.ri-terminal-box-fill:before {
  content: "\f1f5"; }

.ri-terminal-box-line:before {
  content: "\f1f6"; }

.ri-terminal-fill:before {
  content: "\f1f7"; }

.ri-terminal-line:before {
  content: "\f1f8"; }

.ri-terminal-window-fill:before {
  content: "\f1f9"; }

.ri-terminal-window-line:before {
  content: "\f1fa"; }

.ri-test-tube-fill:before {
  content: "\f1fb"; }

.ri-test-tube-line:before {
  content: "\f1fc"; }

.ri-text-direction-l:before {
  content: "\f1fd"; }

.ri-text-direction-r:before {
  content: "\f1fe"; }

.ri-text-spacing:before {
  content: "\f1ff"; }

.ri-text-wrap:before {
  content: "\f200"; }

.ri-text:before {
  content: "\f201"; }

.ri-thermometer-fill:before {
  content: "\f202"; }

.ri-thermometer-line:before {
  content: "\f203"; }

.ri-thumb-down-fill:before {
  content: "\f204"; }

.ri-thumb-down-line:before {
  content: "\f205"; }

.ri-thumb-up-fill:before {
  content: "\f206"; }

.ri-thumb-up-line:before {
  content: "\f207"; }

.ri-thunderstorms-fill:before {
  content: "\f208"; }

.ri-thunderstorms-line:before {
  content: "\f209"; }

.ri-ticket-2-fill:before {
  content: "\f20a"; }

.ri-ticket-2-line:before {
  content: "\f20b"; }

.ri-ticket-fill:before {
  content: "\f20c"; }

.ri-ticket-line:before {
  content: "\f20d"; }

.ri-time-fill:before {
  content: "\f20e"; }

.ri-time-line:before {
  content: "\f20f"; }

.ri-timer-2-fill:before {
  content: "\f210"; }

.ri-timer-2-line:before {
  content: "\f211"; }

.ri-timer-fill:before {
  content: "\f212"; }

.ri-timer-flash-fill:before {
  content: "\f213"; }

.ri-timer-flash-line:before {
  content: "\f214"; }

.ri-timer-line:before {
  content: "\f215"; }

.ri-todo-fill:before {
  content: "\f216"; }

.ri-todo-line:before {
  content: "\f217"; }

.ri-toggle-fill:before {
  content: "\f218"; }

.ri-toggle-line:before {
  content: "\f219"; }

.ri-tools-fill:before {
  content: "\f21a"; }

.ri-tools-line:before {
  content: "\f21b"; }

.ri-tornado-fill:before {
  content: "\f21c"; }

.ri-tornado-line:before {
  content: "\f21d"; }

.ri-trademark-fill:before {
  content: "\f21e"; }

.ri-trademark-line:before {
  content: "\f21f"; }

.ri-traffic-light-fill:before {
  content: "\f220"; }

.ri-traffic-light-line:before {
  content: "\f221"; }

.ri-train-fill:before {
  content: "\f222"; }

.ri-train-line:before {
  content: "\f223"; }

.ri-train-wifi-fill:before {
  content: "\f224"; }

.ri-train-wifi-line:before {
  content: "\f225"; }

.ri-translate-2:before {
  content: "\f226"; }

.ri-translate:before {
  content: "\f227"; }

.ri-travesti-fill:before {
  content: "\f228"; }

.ri-travesti-line:before {
  content: "\f229"; }

.ri-treasure-map-fill:before {
  content: "\f22a"; }

.ri-treasure-map-line:before {
  content: "\f22b"; }

.ri-trello-fill:before {
  content: "\f22c"; }

.ri-trello-line:before {
  content: "\f22d"; }

.ri-trophy-fill:before {
  content: "\f22e"; }

.ri-trophy-line:before {
  content: "\f22f"; }

.ri-truck-fill:before {
  content: "\f230"; }

.ri-truck-line:before {
  content: "\f231"; }

.ri-tumblr-fill:before {
  content: "\f232"; }

.ri-tumblr-line:before {
  content: "\f233"; }

.ri-tv-2-fill:before {
  content: "\f234"; }

.ri-tv-2-line:before {
  content: "\f235"; }

.ri-tv-fill:before {
  content: "\f236"; }

.ri-tv-line:before {
  content: "\f237"; }

.ri-twitch-fill:before {
  content: "\f238"; }

.ri-twitch-line:before {
  content: "\f239"; }

.ri-twitter-fill:before {
  content: "\f23a"; }

.ri-twitter-line:before {
  content: "\f23b"; }

.ri-typhoon-fill:before {
  content: "\f23c"; }

.ri-typhoon-line:before {
  content: "\f23d"; }

.ri-u-disk-fill:before {
  content: "\f23e"; }

.ri-u-disk-line:before {
  content: "\f23f"; }

.ri-ubuntu-fill:before {
  content: "\f240"; }

.ri-ubuntu-line:before {
  content: "\f241"; }

.ri-umbrella-fill:before {
  content: "\f242"; }

.ri-umbrella-line:before {
  content: "\f243"; }

.ri-underline:before {
  content: "\f244"; }

.ri-uninstall-fill:before {
  content: "\f245"; }

.ri-uninstall-line:before {
  content: "\f246"; }

.ri-unsplash-fill:before {
  content: "\f247"; }

.ri-unsplash-line:before {
  content: "\f248"; }

.ri-upload-2-fill:before {
  content: "\f249"; }

.ri-upload-2-line:before {
  content: "\f24a"; }

.ri-upload-cloud-2-fill:before {
  content: "\f24b"; }

.ri-upload-cloud-2-line:before {
  content: "\f24c"; }

.ri-upload-cloud-fill:before {
  content: "\f24d"; }

.ri-upload-cloud-line:before {
  content: "\f24e"; }

.ri-upload-fill:before {
  content: "\f24f"; }

.ri-upload-line:before {
  content: "\f250"; }

.ri-usb-fill:before {
  content: "\f251"; }

.ri-usb-line:before {
  content: "\f252"; }

.ri-user-2-fill:before {
  content: "\f253"; }

.ri-user-2-line:before {
  content: "\f254"; }

.ri-user-3-fill:before {
  content: "\f255"; }

.ri-user-3-line:before {
  content: "\f256"; }

.ri-user-4-fill:before {
  content: "\f257"; }

.ri-user-4-line:before {
  content: "\f258"; }

.ri-user-5-fill:before {
  content: "\f259"; }

.ri-user-5-line:before {
  content: "\f25a"; }

.ri-user-6-fill:before {
  content: "\f25b"; }

.ri-user-6-line:before {
  content: "\f25c"; }

.ri-user-add-fill:before {
  content: "\f25d"; }

.ri-user-add-line:before {
  content: "\f25e"; }

.ri-user-fill:before {
  content: "\f25f"; }

.ri-user-follow-fill:before {
  content: "\f260"; }

.ri-user-follow-line:before {
  content: "\f261"; }

.ri-user-heart-fill:before {
  content: "\f262"; }

.ri-user-heart-line:before {
  content: "\f263"; }

.ri-user-line:before {
  content: "\f264"; }

.ri-user-location-fill:before {
  content: "\f265"; }

.ri-user-location-line:before {
  content: "\f266"; }

.ri-user-received-2-fill:before {
  content: "\f267"; }

.ri-user-received-2-line:before {
  content: "\f268"; }

.ri-user-received-fill:before {
  content: "\f269"; }

.ri-user-received-line:before {
  content: "\f26a"; }

.ri-user-search-fill:before {
  content: "\f26b"; }

.ri-user-search-line:before {
  content: "\f26c"; }

.ri-user-settings-fill:before {
  content: "\f26d"; }

.ri-user-settings-line:before {
  content: "\f26e"; }

.ri-user-shared-2-fill:before {
  content: "\f26f"; }

.ri-user-shared-2-line:before {
  content: "\f270"; }

.ri-user-shared-fill:before {
  content: "\f271"; }

.ri-user-shared-line:before {
  content: "\f272"; }

.ri-user-smile-fill:before {
  content: "\f273"; }

.ri-user-smile-line:before {
  content: "\f274"; }

.ri-user-star-fill:before {
  content: "\f275"; }

.ri-user-star-line:before {
  content: "\f276"; }

.ri-user-unfollow-fill:before {
  content: "\f277"; }

.ri-user-unfollow-line:before {
  content: "\f278"; }

.ri-user-voice-fill:before {
  content: "\f279"; }

.ri-user-voice-line:before {
  content: "\f27a"; }

.ri-video-add-fill:before {
  content: "\f27b"; }

.ri-video-add-line:before {
  content: "\f27c"; }

.ri-video-chat-fill:before {
  content: "\f27d"; }

.ri-video-chat-line:before {
  content: "\f27e"; }

.ri-video-download-fill:before {
  content: "\f27f"; }

.ri-video-download-line:before {
  content: "\f280"; }

.ri-video-fill:before {
  content: "\f281"; }

.ri-video-line:before {
  content: "\f282"; }

.ri-video-upload-fill:before {
  content: "\f283"; }

.ri-video-upload-line:before {
  content: "\f284"; }

.ri-vidicon-2-fill:before {
  content: "\f285"; }

.ri-vidicon-2-line:before {
  content: "\f286"; }

.ri-vidicon-fill:before {
  content: "\f287"; }

.ri-vidicon-line:before {
  content: "\f288"; }

.ri-vimeo-fill:before {
  content: "\f289"; }

.ri-vimeo-line:before {
  content: "\f28a"; }

.ri-vip-crown-2-fill:before {
  content: "\f28b"; }

.ri-vip-crown-2-line:before {
  content: "\f28c"; }

.ri-vip-crown-fill:before {
  content: "\f28d"; }

.ri-vip-crown-line:before {
  content: "\f28e"; }

.ri-vip-diamond-fill:before {
  content: "\f28f"; }

.ri-vip-diamond-line:before {
  content: "\f290"; }

.ri-vip-fill:before {
  content: "\f291"; }

.ri-vip-line:before {
  content: "\f292"; }

.ri-virus-fill:before {
  content: "\f293"; }

.ri-virus-line:before {
  content: "\f294"; }

.ri-visa-fill:before {
  content: "\f295"; }

.ri-visa-line:before {
  content: "\f296"; }

.ri-voice-recognition-fill:before {
  content: "\f297"; }

.ri-voice-recognition-line:before {
  content: "\f298"; }

.ri-voiceprint-fill:before {
  content: "\f299"; }

.ri-voiceprint-line:before {
  content: "\f29a"; }

.ri-volume-down-fill:before {
  content: "\f29b"; }

.ri-volume-down-line:before {
  content: "\f29c"; }

.ri-volume-mute-fill:before {
  content: "\f29d"; }

.ri-volume-mute-line:before {
  content: "\f29e"; }

.ri-volume-off-vibrate-fill:before {
  content: "\f29f"; }

.ri-volume-off-vibrate-line:before {
  content: "\f2a0"; }

.ri-volume-up-fill:before {
  content: "\f2a1"; }

.ri-volume-up-line:before {
  content: "\f2a2"; }

.ri-volume-vibrate-fill:before {
  content: "\f2a3"; }

.ri-volume-vibrate-line:before {
  content: "\f2a4"; }

.ri-vuejs-fill:before {
  content: "\f2a5"; }

.ri-vuejs-line:before {
  content: "\f2a6"; }

.ri-walk-fill:before {
  content: "\f2a7"; }

.ri-walk-line:before {
  content: "\f2a8"; }

.ri-wallet-2-fill:before {
  content: "\f2a9"; }

.ri-wallet-2-line:before {
  content: "\f2aa"; }

.ri-wallet-3-fill:before {
  content: "\f2ab"; }

.ri-wallet-3-line:before {
  content: "\f2ac"; }

.ri-wallet-fill:before {
  content: "\f2ad"; }

.ri-wallet-line:before {
  content: "\f2ae"; }

.ri-water-flash-fill:before {
  content: "\f2af"; }

.ri-water-flash-line:before {
  content: "\f2b0"; }

.ri-webcam-fill:before {
  content: "\f2b1"; }

.ri-webcam-line:before {
  content: "\f2b2"; }

.ri-wechat-2-fill:before {
  content: "\f2b3"; }

.ri-wechat-2-line:before {
  content: "\f2b4"; }

.ri-wechat-fill:before {
  content: "\f2b5"; }

.ri-wechat-line:before {
  content: "\f2b6"; }

.ri-wechat-pay-fill:before {
  content: "\f2b7"; }

.ri-wechat-pay-line:before {
  content: "\f2b8"; }

.ri-weibo-fill:before {
  content: "\f2b9"; }

.ri-weibo-line:before {
  content: "\f2ba"; }

.ri-whatsapp-fill:before {
  content: "\f2bb"; }

.ri-whatsapp-line:before {
  content: "\f2bc"; }

.ri-wheelchair-fill:before {
  content: "\f2bd"; }

.ri-wheelchair-line:before {
  content: "\f2be"; }

.ri-wifi-fill:before {
  content: "\f2bf"; }

.ri-wifi-line:before {
  content: "\f2c0"; }

.ri-wifi-off-fill:before {
  content: "\f2c1"; }

.ri-wifi-off-line:before {
  content: "\f2c2"; }

.ri-window-2-fill:before {
  content: "\f2c3"; }

.ri-window-2-line:before {
  content: "\f2c4"; }

.ri-window-fill:before {
  content: "\f2c5"; }

.ri-window-line:before {
  content: "\f2c6"; }

.ri-windows-fill:before {
  content: "\f2c7"; }

.ri-windows-line:before {
  content: "\f2c8"; }

.ri-windy-fill:before {
  content: "\f2c9"; }

.ri-windy-line:before {
  content: "\f2ca"; }

.ri-wireless-charging-fill:before {
  content: "\f2cb"; }

.ri-wireless-charging-line:before {
  content: "\f2cc"; }

.ri-women-fill:before {
  content: "\f2cd"; }

.ri-women-line:before {
  content: "\f2ce"; }

.ri-wubi-input:before {
  content: "\f2cf"; }

.ri-xbox-fill:before {
  content: "\f2d0"; }

.ri-xbox-line:before {
  content: "\f2d1"; }

.ri-xing-fill:before {
  content: "\f2d2"; }

.ri-xing-line:before {
  content: "\f2d3"; }

.ri-youtube-fill:before {
  content: "\f2d4"; }

.ri-youtube-line:before {
  content: "\f2d5"; }

.ri-zcool-fill:before {
  content: "\f2d6"; }

.ri-zcool-line:before {
  content: "\f2d7"; }

.ri-zhihu-fill:before {
  content: "\f2d8"; }

.ri-zhihu-line:before {
  content: "\f2d9"; }

.ri-zoom-in-fill:before {
  content: "\f2da"; }

.ri-zoom-in-line:before {
  content: "\f2db"; }

.ri-zoom-out-fill:before {
  content: "\f2dc"; }

.ri-zoom-out-line:before {
  content: "\f2dd"; }

.ri-zzz-fill:before {
  content: "\f2de"; }

.ri-zzz-line:before {
  content: "\f2df"; }

@media (max-width: 1024px) {
  .dl_theme nav.navigation {
    display: none; } }

.dl_theme nav.navigation > ul {
  display: flex;
  margin-right: 100px; }
  .dl_theme nav.navigation > ul > li {
    margin-right: 55px;
    padding: 10px 0; }
    .dl_theme nav.navigation > ul > li:last-child {
      margin-right: 0; }
    .dl_theme nav.navigation > ul > li > a {
      color: #fff;
      font-size: 16px;
      font-family: "Rubik", Helvetica, sans-serif;
      font-weight: 400; }
    .dl_theme nav.navigation > ul > li.menu-item-has-children {
      display: flex;
      align-items: center; }
      .dl_theme nav.navigation > ul > li.menu-item-has-children:after {
        content: "\ea4d";
        display: block;
        margin-left: 8px;
        font-family: "remixicon";
        font-weight: 400;
        font-size: 16px;
        color: #fff; }
    .dl_theme nav.navigation > ul > li.current-menu-item > a, .dl_theme nav.navigation > ul > li.current-menu-ancestor > a, .dl_theme nav.navigation > ul > li:hover > a {
      color: #FF3632; }
    .dl_theme nav.navigation > ul > li.current-menu-item:after, .dl_theme nav.navigation > ul > li.current-menu-ancestor:after, .dl_theme nav.navigation > ul > li:hover:after {
      color: #FF3632; }
    .dl_theme nav.navigation > ul > li ul {
      position: absolute;
      width: 220px;
      left: -10px;
      top: 100%;
      padding: 15px 20px;
      transition-delay: 0.2s;
      background-color: #fafafa;
      border-radius: 5px;
      visibility: hidden;
      opacity: 0; }
      @media (max-width: 1200px) {
        .dl_theme nav.navigation > ul > li ul {
          padding: 15px 20px; } }
      .dl_theme nav.navigation > ul > li ul.rtl {
        left: auto;
        right: -10px; }
      .dl_theme nav.navigation > ul > li ul li {
        position: relative;
        padding: 0; }
        .dl_theme nav.navigation > ul > li ul li a {
          display: block;
          position: relative;
          padding: 10px 0;
          color: #111111;
          font-size: 14px; }
        .dl_theme nav.navigation > ul > li ul li.menu-item-has-children {
          display: flex;
          align-items: center; }
          .dl_theme nav.navigation > ul > li ul li.menu-item-has-children:after {
            content: "\ea4d";
            display: block;
            margin-left: 8px;
            font-family: "remixicon";
            font-weight: 400;
            font-size: 16px;
            color: #111111;
            transform: rotate(-90deg); }
        .dl_theme nav.navigation > ul > li ul li.current-menu-item > a, .dl_theme nav.navigation > ul > li ul li.current-menu-ancestor > a, .dl_theme nav.navigation > ul > li ul li:hover > a {
          color: #FBB50D; }
        .dl_theme nav.navigation > ul > li ul li ul {
          left: 100%;
          top: -15px; }
          .dl_theme nav.navigation > ul > li ul li ul.rtl {
            left: auto;
            right: 100%; }
        .dl_theme nav.navigation > ul > li ul li:hover ul {
          visibility: visible;
          opacity: 1; }
    .dl_theme nav.navigation > ul > li:hover > ul {
      visibility: visible;
      opacity: 1; }

.dl_theme div.resp_menu {
  display: none;
  position: fixed;
  width: 35px;
  height: 35px;
  top: 20px;
  right: 15px;
  z-index: 1000; }
  @media (max-width: 1024px) {
    .dl_theme div.resp_menu {
      display: block; } }
  .dl_theme div.resp_menu div.resp_menu_toggle {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #FBB50D;
    border-radius: 5px;
    cursor: pointer; }
    .dl_theme div.resp_menu div.resp_menu_toggle div {
      position: absolute;
      width: 25px;
      height: 2px;
      top: 50%;
      right: 10px;
      background-color: #fff; }
      .dl_theme div.resp_menu div.resp_menu_toggle div:first-child {
        margin-top: -7px; }
      .dl_theme div.resp_menu div.resp_menu_toggle div:last-child {
        margin-top: 7px; }
    .dl_theme div.resp_menu div.resp_menu_toggle.resp_menu_toggled {
      border-radius: 5px 5px 0px 0px; }
      .dl_theme div.resp_menu div.resp_menu_toggle.resp_menu_toggled div:first-child, .dl_theme div.resp_menu div.resp_menu_toggle.resp_menu_toggled div:last-child {
        opacity: 0; }
      .dl_theme div.resp_menu div.resp_menu_toggle.resp_menu_toggled div:nth-child(2) {
        transform: rotate(45deg); }
      .dl_theme div.resp_menu div.resp_menu_toggle.resp_menu_toggled div:nth-child(3) {
        transform: rotate(-45deg); }
  .dl_theme div.resp_menu div.resp_menu_ul {
    position: fixed;
    width: 90vw;
    max-height: 80vh;
    top: 75px;
    right: 0;
    transform: translateX(100%);
    padding: 10px 25px;
    background-color: #FBB50D;
    border-radius: 5px 0px 5px 5px;
    transition: 0.6s;
    overflow-y: scroll; }
    .dl_theme div.resp_menu div.resp_menu_ul.is-active {
      transform: translateX(0); }
    .dl_theme div.resp_menu div.resp_menu_ul > ul > li {
      position: relative;
      padding: 20px 5px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .dl_theme div.resp_menu div.resp_menu_ul > ul > li:last-child {
        border-bottom: none; }
      .dl_theme div.resp_menu div.resp_menu_ul > ul > li > em {
        display: none; }
      .dl_theme div.resp_menu div.resp_menu_ul > ul > li > a {
        display: block;
        color: #fff; }
      .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 45px;
        height: 45px;
        top: 9px;
        right: 0;
        background-color: #FBB50D;
        cursor: pointer; }
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus em {
          color: #fff;
          font-size: 30px;
          transition: 0.4s; }
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus div.plus,
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus div.minus {
          position: absolute;
          width: 11px;
          height: 1px;
          background-color: #fff;
          left: 50%;
          top: 15px;
          transform: translateX(0%) rotate(130deg); }
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus div.minus {
          transform: translateX(-55%) rotate(55deg); }
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li div.plus_minus.plus_minus_toggled em {
          transform: rotate(180deg); }
      .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul {
        display: none;
        padding: 10px 0 0 5px; }
        .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li {
          position: relative;
          padding: 15px 0; }
          .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li:before {
            display: block;
            position: absolute;
            content: "";
            left: 0;
            top: 25px;
            width: 5px;
            height: 1px;
            background-color: #fff; }
          .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li a {
            display: block;
            margin-left: 15px;
            color: #fff; }
          .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li ul {
            padding-left: 15px; }
          .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li div.plus_minus {
            height: 20px;
            top: 15px; }
            .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li div.plus_minus div.plus,
            .dl_theme div.resp_menu div.resp_menu_ul > ul > li ul li div.plus_minus div.minus {
              top: 10px; }

.dl_theme input[type="date"],
.dl_theme input[type="datetime"],
.dl_theme input[type="datetime-local"],
.dl_theme input[type="email"],
.dl_theme input[type="month"],
.dl_theme input[type="number"],
.dl_theme input[type="password"],
.dl_theme input[type="range"],
.dl_theme input[type="search"],
.dl_theme input[type="tel"],
.dl_theme input[type="text"],
.dl_theme input[type="time"],
.dl_theme input[type="url"],
.dl_theme input[type="week"],
.dl_theme textarea {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  color: #111111;
  background-color: #fff;
  border: 1px solid #dee7ec;
  border-radius: 5px; }
  .dl_theme input[type="date"]:focus,
  .dl_theme input[type="datetime"]:focus,
  .dl_theme input[type="datetime-local"]:focus,
  .dl_theme input[type="email"]:focus,
  .dl_theme input[type="month"]:focus,
  .dl_theme input[type="number"]:focus,
  .dl_theme input[type="password"]:focus,
  .dl_theme input[type="range"]:focus,
  .dl_theme input[type="search"]:focus,
  .dl_theme input[type="tel"]:focus,
  .dl_theme input[type="text"]:focus,
  .dl_theme input[type="time"]:focus,
  .dl_theme input[type="url"]:focus,
  .dl_theme input[type="week"]:focus,
  .dl_theme textarea:focus {
    border-color: #c7d7df; }
  .dl_theme input[type="date"]:disabled,
  .dl_theme input[type="datetime"]:disabled,
  .dl_theme input[type="datetime-local"]:disabled,
  .dl_theme input[type="email"]:disabled,
  .dl_theme input[type="month"]:disabled,
  .dl_theme input[type="number"]:disabled,
  .dl_theme input[type="password"]:disabled,
  .dl_theme input[type="range"]:disabled,
  .dl_theme input[type="search"]:disabled,
  .dl_theme input[type="tel"]:disabled,
  .dl_theme input[type="text"]:disabled,
  .dl_theme input[type="time"]:disabled,
  .dl_theme input[type="url"]:disabled,
  .dl_theme input[type="week"]:disabled,
  .dl_theme textarea:disabled {
    color: rgba(0, 0, 0, 0.3);
    background-color: #eee;
    border-color: transparent; }
    .dl_theme input[type="date"]:disabled::placeholder,
    .dl_theme input[type="datetime"]:disabled::placeholder,
    .dl_theme input[type="datetime-local"]:disabled::placeholder,
    .dl_theme input[type="email"]:disabled::placeholder,
    .dl_theme input[type="month"]:disabled::placeholder,
    .dl_theme input[type="number"]:disabled::placeholder,
    .dl_theme input[type="password"]:disabled::placeholder,
    .dl_theme input[type="range"]:disabled::placeholder,
    .dl_theme input[type="search"]:disabled::placeholder,
    .dl_theme input[type="tel"]:disabled::placeholder,
    .dl_theme input[type="text"]:disabled::placeholder,
    .dl_theme input[type="time"]:disabled::placeholder,
    .dl_theme input[type="url"]:disabled::placeholder,
    .dl_theme input[type="week"]:disabled::placeholder,
    .dl_theme textarea:disabled::placeholder {
      color: rgba(0, 0, 0, 0.2); }

.dl_theme button.gilaki,
.dl_theme a.gilaki {
  height: 45px;
  padding: 0 20px;
  color: #fff;
  text-align: center;
  background-color: #FBB50D;
  border-radius: 5px;
  cursor: pointer; }
  .dl_theme button.gilaki.red,
  .dl_theme a.gilaki.red {
    background-color: #d9534f; }
  .dl_theme button.gilaki.green,
  .dl_theme a.gilaki.green {
    background-color: #5cb85c; }
  .dl_theme button.gilaki.blue,
  .dl_theme a.gilaki.blue {
    background-color: #5bc0de; }
  .dl_theme button.gilaki.orange,
  .dl_theme a.gilaki.orange {
    background-color: #f0ad4e; }
  .dl_theme button.gilaki:hover,
  .dl_theme a.gilaki:hover {
    background-color: #111111; }
  .dl_theme button.gilaki.transparent,
  .dl_theme a.gilaki.transparent {
    color: #FBB50D;
    background-color: transparent;
    border: 1px solid #FBB50D; }
    .dl_theme button.gilaki.transparent.red,
    .dl_theme a.gilaki.transparent.red {
      color: #d9534f;
      border: 1px solid #d9534f; }
    .dl_theme button.gilaki.transparent.green,
    .dl_theme a.gilaki.transparent.green {
      color: #5cb85c;
      border: 1px solid #5cb85c; }
    .dl_theme button.gilaki.transparent.blue,
    .dl_theme a.gilaki.transparent.blue {
      color: #5bc0de;
      border: 1px solid #5bc0de; }
    .dl_theme button.gilaki.transparent.orange,
    .dl_theme a.gilaki.transparent.orange {
      color: #f0ad4e;
      border: 1px solid #f0ad4e; }
    .dl_theme button.gilaki.transparent:hover,
    .dl_theme a.gilaki.transparent:hover {
      color: #111111;
      border-color: #111111; }
  .dl_theme button.gilaki.empty,
  .dl_theme a.gilaki.empty {
    color: #7A7A7A;
    background-color: transparent; }
    .dl_theme button.gilaki.empty:hover,
    .dl_theme a.gilaki.empty:hover {
      color: #111111; }
  .dl_theme button.gilaki.small,
  .dl_theme a.gilaki.small {
    height: 35px;
    padding: 0 15px;
    font-size: 12px;
    border-radius: 3px; }
  .dl_theme button.gilaki.large,
  .dl_theme a.gilaki.large {
    height: 55px;
    padding: 0 25px;
    font-size: 16px; }

.dl_theme button.gilaki:disabled {
  color: rgba(0, 0, 0, 0.3);
  background-color: #eee;
  border-color: transparent;
  cursor: default; }
  .dl_theme button.gilaki:disabled:hover {
    color: rgba(0, 0, 0, 0.3);
    background-color: #eee;
    border-color: transparent;
    cursor: default; }

.dl_theme input[type="checkbox"],
.dl_theme input[type="radio"] {
  width: 15px;
  height: 15px; }

.dl_theme textarea {
  height: auto;
  padding: 15px;
  vertical-align: top;
  line-height: 1.5;
  overflow: auto;
  resize: none; }

.dl_theme select[multiple] {
  vertical-align: top; }

.dl_theme button {
  width: auto; }

.dl_theme ::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-family: #111111;
  opacity: 1; }

.dl_theme label {
  display: block;
  line-height: 1.5;
  cursor: pointer; }

.dl_theme select {
  width: 100%; }

.dl_theme div.artform_wrap {
  display: inline-block;
  width: 20px;
  height: 20px; }
  .dl_theme div.artform_wrap input,
  .dl_theme div.artform_wrap span {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    border: 1px solid #dee7ec;
    cursor: pointer; }
  .dl_theme div.artform_wrap input {
    opacity: 0;
    z-index: 2; }
    .dl_theme div.artform_wrap input[type="radio"] + span {
      border-radius: 50%; }
      .dl_theme div.artform_wrap input[type="radio"] + span:before {
        display: block;
        position: absolute;
        content: "";
        left: 5px;
        top: 5px;
        right: 5px;
        bottom: 5px;
        transform: scale(0);
        background-color: #fff;
        border-radius: 50%; }
    .dl_theme div.artform_wrap input[type="radio"]:checked + span {
      background-color: #FBB50D;
      border-color: transparent; }
      .dl_theme div.artform_wrap input[type="radio"]:checked + span:before {
        transform: scale(1); }
    .dl_theme div.artform_wrap input[type="radio"]:disabled {
      cursor: default; }
      .dl_theme div.artform_wrap input[type="radio"]:disabled + span {
        background-color: #eee;
        border-color: transparent;
        cursor: default; }
    .dl_theme div.artform_wrap input[type="checkbox"] + span {
      border-radius: 3px; }
      .dl_theme div.artform_wrap input[type="checkbox"] + span:before {
        display: block;
        position: absolute;
        content: "";
        width: 7px;
        height: 9px;
        left: 51%;
        top: 12%;
        transform: rotate(45deg);
        transform-origin: 0 0;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        opacity: 0; }
    .dl_theme div.artform_wrap input[type="checkbox"]:checked + span {
      background-color: #FBB50D;
      border-color: transparent; }
      .dl_theme div.artform_wrap input[type="checkbox"]:checked + span:before {
        opacity: 1; }
    .dl_theme div.artform_wrap input[type="checkbox"]:disabled {
      cursor: default; }
      .dl_theme div.artform_wrap input[type="checkbox"]:disabled + span {
        background-color: #eee;
        border-color: transparent;
        cursor: default; }
        .dl_theme div.artform_wrap input[type="checkbox"]:disabled + span:before {
          border-color: #F5F5F6; }

.dl_theme span.select2 span.selection span.select2-selection {
  height: 45px;
  background-color: #fff;
  border: 1px solid #dee7ec;
  border-radius: 5px;
  transition: none; }
  .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__rendered {
    height: 100%;
    padding-left: 15px;
    padding-right: 30px;
    color: #7A7A7A;
    line-height: 45px;
    vertical-align: middle; }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__rendered span.select2-selection__placeholder {
      color: #7A7A7A; }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__rendered span.select2-selection__clear {
      color: #7A7A7A;
      font-size: 20px; }
  .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow {
    width: 30px;
    height: 100%;
    top: 0;
    right: 0; }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow:before, .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow:after {
      display: block;
      position: absolute;
      content: "";
      width: 7px;
      height: 2px;
      left: 50%;
      top: 50%;
      background-color: #7A7A7A;
      transform: translate(-50%, -50%) rotate(45deg); }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow:before {
      margin-left: -4px; }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .dl_theme span.select2 span.selection span.select2-selection span.select2-selection__arrow b {
      display: none; }

.dl_theme span.select2.select2-container--open span.selection span.select2-selection span.select2-selection__arrow:before {
  transform: translate(-50%, -50%) rotate(-45deg); }

.dl_theme span.select2.select2-container--open span.selection span.select2-selection span.select2-selection__arrow:after {
  transform: translate(-50%, -50%) rotate(45deg); }

.dl_theme span.select2.select2-container--below.select2-container--open span.selection span.select2-selection {
  border-color: #c7d7df;
  border-bottom-color: transparent;
  border-radius: 5px 5px 0px 0px; }

.dl_theme span.select2.select2-container--above.select2-container--open span.selection span.select2-selection {
  border-color: #c7d7df;
  border-top-color: transparent;
  border-radius: 0px 0px 5px 5px; }

.dl_theme span.select2.select2-container--disabled span.selection span.select2-selection {
  background-color: #eee;
  border-color: transparent; }
  .dl_theme span.select2.select2-container--disabled span.selection span.select2-selection span.select2-selection__rendered {
    color: rgba(0, 0, 0, 0.3); }
    .dl_theme span.select2.select2-container--disabled span.selection span.select2-selection span.select2-selection__rendered span.select2-selection__placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .dl_theme span.select2.select2-container--disabled span.selection span.select2-selection span.select2-selection__rendered span.select2-selection__clear {
      color: rgba(0, 0, 0, 0.2); }
  .dl_theme span.select2.select2-container--disabled span.selection span.select2-selection span.select2-selection__arrow:before, .dl_theme span.select2.select2-container--disabled span.selection span.select2-selection span.select2-selection__arrow:after {
    background-color: rgba(0, 0, 0, 0.3); }

.dl_theme span.select2-container span.select2-dropdown {
  background-color: #fff; }
  .dl_theme span.select2-container span.select2-dropdown span.select2-results ul li {
    padding: 7px 10px;
    font-size: 12px;
    border-radius: 3px; }
    .dl_theme span.select2-container span.select2-dropdown span.select2-results ul li.select2-results__option--highlighted {
      color: rgba(0, 0, 0, 0.6);
      background-color: #f2f5f7; }
    .dl_theme span.select2-container span.select2-dropdown span.select2-results ul li.select2-results__option[aria-selected="true"] {
      background-color: #dee7ec; }
  .dl_theme span.select2-container span.select2-dropdown.select2-dropdown--below {
    border-color: #c7d7df;
    border-top-color: transparent;
    border-radius: 0px 0px 5px 5px; }
    .dl_theme span.select2-container span.select2-dropdown.select2-dropdown--below span.select2-results ul {
      padding: 0 10px 10px; }
  .dl_theme span.select2-container span.select2-dropdown.select2-dropdown--above {
    border-color: #c7d7df;
    border-bottom-color: transparent;
    border-radius: 5px 5px 0px 0px; }
    .dl_theme span.select2-container span.select2-dropdown.select2-dropdown--above span.select2-results ul {
      padding: 10px 10px 0; }

.dl_theme div.file_uploader label {
  height: 45px; }
  .dl_theme div.file_uploader label span {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 90px;
    bottom: 0;
    padding-left: 15px;
    color: #111111;
    background-color: #f8fafb;
    border: 1px solid #dee7ec;
    border-radius: 5px 0px 0px 5px; }
  .dl_theme div.file_uploader label strong {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    width: 90px;
    height: 100%;
    top: 0;
    right: 0;
    color: #fff;
    font-weight: normal;
    background-color: #FBB50D;
    border-radius: 0px 5px 5px 0px; }

.dl_theme div.file_uploader input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0; }
  .dl_theme div.file_uploader input[type="file"]:disabled + label {
    cursor: default; }
    .dl_theme div.file_uploader input[type="file"]:disabled + label span {
      color: rgba(0, 0, 0, 0.3);
      background-color: #eee;
      border-color: transparent; }
    .dl_theme div.file_uploader input[type="file"]:disabled + label strong {
      color: #fff;
      background-color: #F5F5F6; }

.dl_theme div.checker_wrap {
  display: flex; }
  .dl_theme div.checker_wrap div.artform_wrap {
    margin-right: 10px; }

.dl_theme div.form_item {
  margin-bottom: 20px; }
  .dl_theme div.form_item div.form_checkers {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .dl_theme div.form_item div.form_checkers div.checker_wrap {
      margin-right: 15px;
      margin-bottom: 10px; }
  .dl_theme div.form_item div.error_message {
    display: none;
    margin: 5px 0 0 10px;
    color: #d9534f;
    font-size: 10px; }
  .dl_theme div.form_item.error div.error_message {
    display: block; }

section.hero {
  margin-bottom: 40px; }
  section.hero .hero_grid {
    width: 100%;
    display: flex;
    grid-gap: 16px;
    min-height: 400px; }
    @media (max-width: 1024px) {
      section.hero .hero_grid {
        flex-direction: column;
        gap: 30px; } }
    section.hero .hero_grid .hero_banner {
      flex: 1;
      width: calc(24% - 32px);
      border-radius: 12px;
      overflow: hidden; }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banner {
          width: 100%;
          height: max-content; } }
      section.hero .hero_grid .hero_banner figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        section.hero .hero_grid .hero_banner figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      section.hero .hero_grid .hero_banner .banner_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px 15px;
        height: 100%; }
        @media (max-width: 1024px) {
          section.hero .hero_grid .hero_banner .banner_content {
            padding: 15px; } }
        section.hero .hero_grid .hero_banner .banner_content .text:not(.banner_button) {
          color: #fff;
          font-size: 19px; }
          section.hero .hero_grid .hero_banner .banner_content .text:not(.banner_button) span {
            font-size: 27px; }
          section.hero .hero_grid .hero_banner .banner_content .text:not(.banner_button).title {
            font-size: 34px;
            line-height: 34px; }
            @media (max-width: 1024px) {
              section.hero .hero_grid .hero_banner .banner_content .text:not(.banner_button).title {
                font-size: 26px;
                line-height: 26px; } }
        section.hero .hero_grid .hero_banner .banner_content .banner_button {
          display: flex;
          justify-content: center; }
          section.hero .hero_grid .hero_banner .banner_content .banner_button a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: #020202;
            border-radius: 5px;
            padding: 2px 10px;
            font-size: 15px;
            transition: 0.4s; }
            @media (max-width: 1024px) {
              section.hero .hero_grid .hero_banner .banner_content .banner_button a {
                padding: 2px 4px;
                width: max-content;
                height: max-content; } }
            section.hero .hero_grid .hero_banner .banner_content .banner_button a:hover {
              color: #fff;
              background-color: #111111; }
          section.hero .hero_grid .hero_banner .banner_content .banner_button.text {
            width: 100%; }
            section.hero .hero_grid .hero_banner .banner_content .banner_button.text a {
              justify-content: space-between;
              width: 100%;
              background-color: transparent;
              color: #fff;
              padding: 0; }
              @media (max-width: 1024px) {
                section.hero .hero_grid .hero_banner .banner_content .banner_button.text a {
                  justify-content: flex-end;
                  gap: 10px;
                  font-size: 13px; } }
              section.hero .hero_grid .hero_banner .banner_content .banner_button.text a:hover {
                color: #111111; }
                section.hero .hero_grid .hero_banner .banner_content .banner_button.text a:hover::after {
                  color: #111111; }
              section.hero .hero_grid .hero_banner .banner_content .banner_button.text a:after {
                content: "\ea6e";
                font-family: "remixicon";
                color: #fff;
                font-size: 15px;
                transition: 0.4s; }
                @media (max-width: 1024px) {
                  section.hero .hero_grid .hero_banner .banner_content .banner_button.text a:after {
                    font-size: 13px; } }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banner.left .banner_content {
          display: flex;
          gap: 15px;
          align-items: center;
          flex-direction: row; } }
      section.hero .hero_grid .hero_banner.left .banner_content .text.title {
        flex: 1; }
      section.hero .hero_grid .hero_banner.left .banner_content .wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        @media (max-width: 1024px) {
          section.hero .hero_grid .hero_banner.left .banner_content .wrap {
            gap: 10px;
            align-items: flex-end; } }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banner.left .banner_content .text:not(.banner_button):not(.title) {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          flex-direction: row;
          font-size: 11px;
          background-color: #005BFF;
          color: #fff;
          border-radius: 5px;
          text-transform: uppercase;
          padding: 1px 4px; }
          section.hero .hero_grid .hero_banner.left .banner_content .text:not(.banner_button):not(.title) br {
            display: none; } }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banner.left .banner_content .text:not(.banner_button):not(.title) span {
          font-size: 12px; } }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banner.left .banner_content .text:not(.banner_button).title {
          font-size: 21px;
          line-height: 21px; } }
      section.hero .hero_grid .hero_banner.right .banner_content {
        padding-bottom: 65px; }
        @media (max-width: 1024px) {
          section.hero .hero_grid .hero_banner.right .banner_content {
            padding-bottom: 15px;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            flex-direction: row; } }
    section.hero .hero_grid .hero_banners_slider_wrap {
      flex: 1;
      min-width: calc(52% - 32px); }
      @media (max-width: 1024px) {
        section.hero .hero_grid .hero_banners_slider_wrap {
          width: 100%; } }
      section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider {
        height: 100%; }
        section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-wrapper-outer {
          height: 100%; }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-wrapper-outer .owl-wrapper {
            height: 100%; }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-wrapper-outer .owl-wrapper .owl-item {
              height: 100%; }
        section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-controls {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 40px;
          width: 100%; }
          @media (max-width: 1024px) {
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-controls {
              bottom: 30px; } }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-controls .owl-pagination {
            bottom: 0; }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-controls .owl-pagination .owl-page span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: #FBB50D;
              border-radius: 50%;
              opacity: 0.7; }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .owl-controls .owl-pagination .owl-page.active span {
              opacity: 1; }
        section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item {
          width: 100%;
          border-radius: 12px;
          height: 100%;
          overflow: hidden;
          padding: 25px 20px 100px; }
          @media (max-width: 1024px) {
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item {
              padding: 15px 15px 80px; } }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item figure img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_title {
            display: flex;
            flex-direction: column;
            font-size: 45px;
            gap: 25px;
            margin-bottom: 50px;
            color: #fff;
            font-weight: 700;
            line-height: 34px; }
            @media (max-width: 1024px) {
              section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_title {
                font-size: 30px;
                margin-bottom: 20px;
                line-height: 34px; } }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_title span {
              color: #FBB50D; }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_subtitle {
            margin-bottom: 20px;
            font-size: 27px;
            color: #fff; }
            @media (max-width: 1024px) {
              section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_subtitle {
                font-size: 20px;
                margin-bottom: 40px; } }
          section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_points ul {
            display: grid;
            gap: 20px 50px;
            grid-template-columns: repeat(2, max-content);
            margin: 0; }
            @media (max-width: 1024px) {
              section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_points ul {
                grid-gap: 12px;
                grid-template-columns: 1fr; } }
            section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_points ul li {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #fff;
              font-size: 17px; }
              @media (max-width: 1024px) {
                section.hero .hero_grid .hero_banners_slider_wrap .hero_banners_slider .hero_slider_item .banner_content .banner_points ul li {
                  font-size: 16px; } }

section.benefits {
  margin-bottom: 40px; }
  section.benefits .dl_container {
    background-color: #FFDF93;
    border-radius: 5px; }
    section.benefits .dl_container .section_title {
      padding-top: 30px; }
      @media (max-width: 1024px) {
        section.benefits .dl_container .section_title {
          padding-top: 15px; } }
    section.benefits .dl_container .block_wrap {
      padding: 0 25px 30px; }
      section.benefits .dl_container .block_wrap .benefits_list {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
        counter-reset: benefitCounter; }
        @media (max-width: 1024px) {
          section.benefits .dl_container .block_wrap .benefits_list {
            grid-template-columns: 1fr; } }
        section.benefits .dl_container .block_wrap .benefits_list .benefit_item {
          display: flex;
          flex-direction: column;
          padding: 20px;
          background-color: #fff;
          border-radius: 5px;
          transition: 0.4s; }
          @media (max-width: 1024px) {
            section.benefits .dl_container .block_wrap .benefits_list .benefit_item {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              padding: 15px;
              gap: 15px; } }
          section.benefits .dl_container .block_wrap .benefits_list .benefit_item:hover {
            background-color: #F5F5F5; }
          section.benefits .dl_container .block_wrap .benefits_list .benefit_item figure {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px; }
            @media (max-width: 1024px) {
              section.benefits .dl_container .block_wrap .benefits_list .benefit_item figure {
                width: 35px;
                margin-bottom: 0;
                position: static; } }
            section.benefits .dl_container .block_wrap .benefits_list .benefit_item figure:after {
              counter-increment: benefitCounter;
              content: "0" counter(benefitCounter);
              color: #929292;
              font-size: 15px;
              font-weight: 700; }
              @media (max-width: 1024px) {
                section.benefits .dl_container .block_wrap .benefits_list .benefit_item figure:after {
                  position: absolute;
                  right: 15px;
                  top: 15px; } }
          section.benefits .dl_container .block_wrap .benefits_list .benefit_item .benefit_title {
            margin-bottom: 5px;
            font-size: 14px; }
            @media (max-width: 1024px) {
              section.benefits .dl_container .block_wrap .benefits_list .benefit_item .benefit_title {
                flex: 1;
                padding-right: 40px; } }
          section.benefits .dl_container .block_wrap .benefits_list .benefit_item .benefit_text {
            font-size: 13px;
            color: #7A7A7A;
            line-height: 24px; }
            @media (max-width: 1024px) {
              section.benefits .dl_container .block_wrap .benefits_list .benefit_item .benefit_text {
                width: 100%; } }

.product_list_with_tabs .customNavigation {
  display: none; }

.product_list_with_tabs .owl-buttons {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  right: 0;
  top: -56px;
  z-index: 1;
  bottom: auto; }
  .product_list_with_tabs .owl-buttons .owl-prev {
    opacity: 1 !important;
    right: 42px;
    left: auto;
    text-align: center;
    padding: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    height: 30px;
    width: 30px; }
    .product_list_with_tabs .owl-buttons .owl-prev::before {
      content: "\f104";
      color: #363636;
      text-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      position: absolute;
      margin: auto;
      font-size: 25px;
      font-family: FontAwesome;
      line-height: 30px;
      font-weight: 400; }
  .product_list_with_tabs .owl-buttons .owl-next {
    opacity: 1 !important;
    right: 15px;
    left: auto;
    text-align: center;
    padding: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    height: 30px;
    width: 30px; }
    .product_list_with_tabs .owl-buttons .owl-next::before {
      content: "\f105";
      color: #666;
      text-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      position: absolute;
      margin: auto;
      font-size: 25px;
      font-family: FontAwesome;
      line-height: 30px;
      color: #363636;
      font-weight: 400; }

.product_list_with_tabs .tab-head {
  background-color: transparent; }

.product_list_with_tabs .category_tabs {
  border-bottom: 2px solid rgba(0, 0, 0, 0.6); }
  .product_list_with_tabs .category_tabs .etabs {
    display: flex;
    align-items: center;
    gap: 55px;
    margin-bottom: 15px;
    float: unset; }
    @media (max-width: 1024px) {
      .product_list_with_tabs .category_tabs .etabs {
        grid-gap: 30px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        overflow-x: auto; } }
    @media (max-width: 1024px) {
      .product_list_with_tabs .category_tabs .etabs li.tab {
        width: max-content; } }
    .product_list_with_tabs .category_tabs .etabs li.tab a {
      display: flex;
      width: max-content;
      position: relative;
      font-size: 20px;
      color: rgba(2, 2, 2, 0.6);
      font-weight: 700; }
      @media (max-width: 1024px) {
        .product_list_with_tabs .category_tabs .etabs li.tab a {
          font-size: 18px;
          display: flex;
          width: max-content; } }
      .product_list_with_tabs .category_tabs .etabs li.tab a.selected {
        color: #FBB50D; }
      .product_list_with_tabs .category_tabs .etabs li.tab a:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        transform: translateY(18.5px);
        height: 2px;
        background-color: #FBB50D;
        display: block;
        opacity: 0;
        transition: 0.4s; }
        @media (max-width: 1024px) {
          .product_list_with_tabs .category_tabs .etabs li.tab a:after {
            transform: translateY(13.5px); } }
      .product_list_with_tabs .category_tabs .etabs li.tab a:hover:after {
        opacity: 1; }

.product_list_with_tabs .tab-content {
  opacity: 1 !important;
  width: 100%; }
  .product_list_with_tabs .tab-content .product-carousel-custom {
    overflow: visible; }
  .product_list_with_tabs .tab-content .owl-wrapper-outer {
    width: 100% !important; }
    .product_list_with_tabs .tab-content .owl-wrapper-outer .owl-wrapper .owl-item {
      min-width: 240px; }

section.categories_list {
  margin-bottom: 40px; }
  section.categories_list .list_row {
    display: flex;
    margin-bottom: 25px; }
    @media (max-width: 1024px) {
      section.categories_list .list_row {
        flex-wrap: wrap;
        grid-gap: 20px;
        margin-bottom: 20px; } }
    section.categories_list .list_row:last-child {
      margin-bottom: 0; }
    section.categories_list .list_row .grid {
      padding: 0 5px; }
      @media (max-width: 1024px) {
        section.categories_list .list_row .grid {
          width: 100% !important; } }
      section.categories_list .list_row .grid:first-child {
        padding-left: 0; }
      section.categories_list .list_row .grid:last-child {
        padding-right: 0; }
    section.categories_list .list_row .grid-1 {
      width: calc(100% / 12); }
    section.categories_list .list_row .grid-2 {
      width: calc((100% / 12) * 2); }
    section.categories_list .list_row .grid-3 {
      width: calc((100% / 12) * 3); }
    section.categories_list .list_row .grid-3-5 {
      width: calc((100% / 12) * 3.5); }
    section.categories_list .list_row .grid-4 {
      width: calc((100% / 12) * 4); }
    section.categories_list .list_row .grid-4-5 {
      width: calc((100% / 12) * 4.5); }
    section.categories_list .list_row .grid-5 {
      width: calc((100% / 12) * 5); }
    section.categories_list .list_row .grid-5-5 {
      width: calc((100% / 12) * 5.5); }
    section.categories_list .list_row .grid-6 {
      width: calc((100% / 12) * 6); }
    section.categories_list .list_row .grid-6-5 {
      width: calc((100% / 12) * 6.5); }
    section.categories_list .list_row .grid-7 {
      width: calc((100% / 12) * 7); }
    section.categories_list .list_row .grid-7-5 {
      width: calc((100% / 12) * 7.5); }
    section.categories_list .list_row .grid-8 {
      width: calc((100% / 12) * 8); }
    section.categories_list .list_row .grid-8-5 {
      width: calc((100% / 12) * 8.5); }
    section.categories_list .list_row .grid-9 {
      width: calc((100% / 12) * 9); }
    section.categories_list .list_row .grid-9-5 {
      width: calc((100% / 12) * 9.5); }
    section.categories_list .list_row .grid-10 {
      width: calc((100% / 12) * 10); }
    section.categories_list .list_row .grid-11 {
      width: calc((100% / 12) * 11); }
    section.categories_list .list_row .grid-12 {
      width: calc((100% / 12) * 12); }
    section.categories_list .list_row.scroll_on_mobile {
      margin-bottom: 0; }
      @media (max-width: 1024px) {
        section.categories_list .list_row.scroll_on_mobile {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow-x: auto;
          width: max-content !important;
          gap: 10px; }
          section.categories_list .list_row.scroll_on_mobile .grid {
            width: 80% !important;
            min-width: 80% !important; } }
  section.categories_list .category_item {
    background-color: #F5F5F6;
    border: 2px solid #F5F5F6;
    border-radius: 10px;
    overflow: hidden;
    height: 100%; }
    section.categories_list .category_item .category_item_image {
      height: 100%; }
      section.categories_list .category_item .category_item_image a {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        padding-bottom: 30px;
        height: 100%;
        justify-content: center; }
        section.categories_list .category_item .category_item_image a img {
          max-width: 100%; }
        section.categories_list .category_item .category_item_image a span {
          font-size: 20px;
          font-weight: 700;
          color: #111111; }
          @media (max-width: 1024px) {
            section.categories_list .category_item .category_item_image a span {
              font-size: 15px; } }
        section.categories_list .category_item .category_item_image a:hover {
          background-color: #FBB50D; }
          section.categories_list .category_item .category_item_image a:hover span {
            color: #fff; }
      section.categories_list .category_item .category_item_image.filled {
        background-color: #FBB50D;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        section.categories_list .category_item .category_item_image.filled a {
          padding-bottom: 0; }
          section.categories_list .category_item .category_item_image.filled a:hover span {
            color: #fff; }
      section.categories_list .category_item .category_item_image.title_first a {
        padding-bottom: 0;
        padding-top: 30px; }
        section.categories_list .category_item .category_item_image.title_first a span {
          order: -1; }
    section.categories_list .category_item .category_item_list {
      height: 100%; }
      section.categories_list .category_item .category_item_list .list_title {
        text-align: center;
        padding: 10px 15px; }
        @media (max-width: 1024px) {
          section.categories_list .category_item .category_item_list .list_title {
            font-size: 15px; } }
        section.categories_list .category_item .category_item_list .list_title a {
          font-size: 20px;
          font-weight: 700;
          color: #111111; }
          section.categories_list .category_item .category_item_list .list_title a:hover {
            color: #FBB50D; }
      section.categories_list .category_item .category_item_list ul {
        margin: 0;
        padding: 0; }
        section.categories_list .category_item .category_item_list ul li a {
          display: flex;
          color: #111111;
          padding: 10px 15px;
          border-bottom: 1px solid #fff;
          transition: 0.4s;
          font-size: 15px; }
          section.categories_list .category_item .category_item_list ul li a:hover {
            background-color: #fff; }
        section.categories_list .category_item .category_item_list ul li:last-child a {
          border-bottom: none; }
      section.categories_list .category_item .category_item_list.filled {
        background-color: #FBB50D; }
      section.categories_list .category_item .category_item_list.image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px; }
        section.categories_list .category_item .category_item_list.image ul li a {
          font-size: 20px; }

section.custom_banners {
  margin-bottom: 40px; }
  section.custom_banners .banner_slider_wrap .dl_banner_slider {
    padding-bottom: 40px; }
    section.custom_banners .banner_slider_wrap .dl_banner_slider .banner {
      width: 100%; }
      section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_image {
        overflow: hidden;
        width: 100%; }
        section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_image figure {
          width: 100%;
          min-height: 150px; }
          section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_image figure img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            min-height: 150px; }
        section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_image picture {
          position: absolute;
          top: -40px;
          left: 20px; }
          @media (max-width: 1024px) {
            section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_image picture {
              left: unset;
              right: 0;
              top: -20px;
              height: 100%; } }
      section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content {
        position: absolute;
        right: 40px;
        top: 25px; }
        @media (max-width: 1024px) {
          section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content {
            right: unset;
            left: 15px;
            top: 15px;
            width: 80%; } }
        section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content .banner_text {
          font-size: 30px;
          color: #fff;
          font-weight: 700;
          line-height: 34px; }
          @media (max-width: 1024px) {
            section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content .banner_text {
              font-size: 15px; } }
          section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content .banner_text span {
            color: #FBB50D;
            font-size: 45px; }
            @media (max-width: 1024px) {
              section.custom_banners .banner_slider_wrap .dl_banner_slider .banner .banner_content .banner_text span {
                font-size: 23px; } }
    section.custom_banners .banner_slider_wrap .dl_banner_slider .owl-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      width: 100%; }
      section.custom_banners .banner_slider_wrap .dl_banner_slider .owl-controls .owl-pagination {
        bottom: 0; }
        section.custom_banners .banner_slider_wrap .dl_banner_slider .owl-controls .owl-pagination .owl-page span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          background: #FBB50D;
          border-radius: 50%;
          opacity: 0.7; }
        section.custom_banners .banner_slider_wrap .dl_banner_slider .owl-controls .owl-pagination .owl-page.active span {
          opacity: 1; }

section.brands {
  margin-bottom: 40px; }
  section.brands .brands_list {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr); }
    @media (max-width: 1024px) {
      section.brands .brands_list {
        grid-template-columns: 1fr; } }
    section.brands .brands_list .brand_item {
      padding: 15px;
      background-color: #F5F5F6;
      border-radius: 5px; }
      @media (max-width: 1024px) {
        section.brands .brands_list .brand_item {
          padding: 23px; } }
      section.brands .brands_list .brand_item figure {
        width: 100%; }
        section.brands .brands_list .brand_item figure img {
          width: 100%;
          height: 65px; }
          @media (max-width: 1024px) {
            section.brands .brands_list .brand_item figure img {
              height: 95px; } }
