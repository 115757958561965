.dl_theme{
  .trans-1{
    transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .trans-all-1{
    *{
      transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
      &:before,
      &:after{
        transition: all 0.1s cubic-bezier(0.7, 0, 0.3, 1);
      }
    }
  }
  .trans-2{
    transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .trans-all-2{
    *{
      transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      &:before,
      &:after{
        transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      }
    }
  }
  .trans-3{
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .trans-all-3{
    *{
      transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
      &:before,
      &:after{
        transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
      }
    }
  }
  .trans-4{
    transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .trans-all-4{
    *{
      transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
      &:before,
      &:after{
        transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);
      }
    }
  }
  .trans-5{
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .trans-all-5{
    *{
      transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
      &:before,
      &:after{
        transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
      }
    }
  }
  .trans-no{
    transition: none;
  }
  .trans-no-all{
    *{
      transition: none;
      &:before,
      &:after{
        transition: none;
      }
    }
  }
  
  ul.no-list{
    margin: 0;
    padding: 0;
    list-style: none;
    ul,
    ol,
    li{
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  
  ol.no-list{
    margin: 0;
    padding: 0;
    list-style: none;
    ol,
    ul,
    li{
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  img.fit-cover{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  .no-select{
    user-select: none;
  }
  .no-select-all{
    *{
      user-select: none;
    }
  }
  .overlay{
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .address-style{
    ul{
      @extend .no-list;
      li{
        display: flex;
        margin-bottom: 10px;
        color: $cbase;
        &:last-child{
          margin-bottom: 0;
        }
        em{
          display: flex;
          justify-content: center;
          width: 20px;
          margin-right: 10px;
        }
        span{
          flex: 1;
          a{
            color: $cbase;
            &:hover{
              color: $ccorp;
            }
          }
        }
      }
    }
  }
  div.grecaptcha-badge{
    opacity: 0;
  }
  .firago{
    font-family: $ffmain;
    font-weight: normal;
    font-feature-settings: 'case' on;
    text-transform: uppercase;
  }
}