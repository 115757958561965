body {
	color: #666666;
    font-family: 'Rubik',Helvetica,sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.6px;
	
}
select:focus{ outline: none !important; }
h1, h2, h3, h4, h5, h6 {
	color: #333;
}
li{
	list-style: none;
}

/* default font size */
.fa {
	font-size: 14px;
}
.fa-stack{width: 12px;
    font-size: 9px;
    height: 11px;
}
.fa-stack .fa{font-size:13px;}
i.fa.fa-star-o.fa-stack-2x:before {
    content: "\f005";
}
/* Override the bootstrap defaults */
h1 {
	font-size: 33px;
}
h2 {
	font-size: 27px;
}
h3 {
	font-size: 21px;
}
h4 {
	font-size: 15px;
}
h5 {
	font-size: 12px;
}
h6 {
	font-size: 10.2px;
}
a {
	color: #333333;
}

a:hover, a:focus {
	text-decoration: none;
}
legend {
	font-size: 18px;
	padding: 7px 0px
}
label {
	font-weight:500;
	font-size: 14px;
}
.top-links-toggle {
	display: none;
}
.top-links .links-item:first-child::before {
    display: none;
}
.top-links .links-item {
	padding-left: 30px;
	position: relative;
	color: #7a7a7a;
}
.top-links .links-item:hover{ color: #f6d000; }
.top-links .links-item::before {
	content: "\\";
	position: absolute;
	left: 12px;
	top: -2px;
	color: #7a7a7a;
}
.input-group .input-group-addon {
	font-size: 13px;
	height: 30px;
}
.blog_page .blog_top_content {
  float: left;
  width: 100%;
}
.blog_page .blog_top_content .blog-image {
  float: left;
  position: relative;
}
.quickview-container #content .col-sm-6.product-right{
	margin: 0px 0;
	width: 50%;
}
.mfp-iframe-scaler iframe{ background: #fff !important; }
#content .col-sm-6.product-right {
	margin-left: 0;
}
/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
	display: inline;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
	color: #000 ;
}
.pagination > li > a, .pagination > li > span {
	border-color: #e8e9eb !important;
	border-radius:5px;
	-webkit-border-radius:5px;
	-khtml-border-radius:5px;
	-moz-border-radius:5px;
	color: #7a7a7a;
	padding: 7px 12px ;
	height: 35px;
	width: 35px;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
	background: #fbb50d none repeat scroll 0 0 !important;
	border: 1px solid #fbb50d !important;
	color: #ffffff !important;
}
div.required .control-label:before {
	content: '* ';
	color: #F00;
	font-weight: bold;
}
.banners-slider-carousel .customNavigation {
	top: 11px;
	right: 30px;
}

/* counter*/
.product-block:hover .countdown {
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	-webkit-opacity: 0;
}
.countdown {
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	-webkit-opacity: 1;
	left: 10px;
	padding: 0;
	position: absolute;
	right: 10px;
	text-align: center;
	bottom: 10px;
	vertical-align: top;
	transition: all 400ms ease-in-out 0s;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	top: auto;
}

.countbox .timebox {
	margin: 0 4px 0 0;
	display: inline-block;
	position: relative;
	background: #fff;
	height: 40px;
	min-width: 43px;
	overflow: hidden;
	border-radius: 5px;
	text-align: center;
}

.countbox .timebox:last-child::after {
	display: none;
}
.countbox.hastime {
	width: auto;
	border-radius: 25px;
	padding: 2px 6px;
}
.countbox .second .timebox-inner::after {
	display:  none;
}
.countbox .timebox .time {
	padding: 0;
	color: #777;
	display: block;
	text-align: center;
	margin: -23px 0 0 0;
	font-size: 10px;
}
.count-down .countbox strong {
	display: block;
	font-size: 13px;
	padding: 0 0;
	font-weight: 500;
    text-align: center;
	height: 40px;
    line-height: 25px;
	color: #111;
	margin: 0;
}
.timebox.second span.timebox-inner strong {
	color: #fbb50d;
}
.countbox .timebox:last-child {
	margin: 0;
}
.product-content .count-down .countbox strong {
	font-size: 16px;
}
.box.special .product-block:hover .product-details .quickbox,
.box.special .product-block:hover .product_hover_block button.compare_button {
	opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
	transform: translateX(0);
}

/* end of counter*/
.special-tag {
	font-weight: 400;
    position: absolute;
    top: 20px;
    left: 10px;
    text-transform: inherit;
    padding: 0 8px;
    background: #d32122;
    font-size: 10px;
    color: #fff;
    border-radius: 25px;
    line-height: 20px;
}

.stock_status {
	text-transform: uppercase;
	color: #f00;
	position: absolute;
	top: 41%;
	left: 0;
	right: 0;
	text-align: center;
	background: #fff;
	padding: 10px 10px;
	box-shadow: 0 0px 5px 0px #ddd;
}
.outstock .img-responsive.hover-image {
	display: none !important;
}
.outstock .img-responsive{filter: opacity(30%);}
.footer-blocks .col-sm-3 {	
	float: right;
	width: 20%;
}
.footer-area ul li {
	display: inline-block;
}
#block_1 .list-unstyled li:first-child {
    margin: 0 10px 0 0;
}
.listing {
	min-height: 798px;
	display: block;
	text-align: center;
	font-size: 20px;
	padding-top: 50%;
	font-weight: 500;
	text-transform: inherit;
}
.listing::before {
	content: "\f071";
	position: absolute;
	font-family: fontawesome;
	margin-top: -50px;
	font-size: 35px;
	left: 45%;
}
/* mouse grab icon */
.grabbing {
	cursor: url(../stylesheet/grabbing.png) 8 8, move;
}
/* OpenCart Code */

#paysocialcmsblock {
	float: right;
	width: 30%;
}
.social-block {
	float: left;
    width: 100%;
}
.col-sm-3.column.footerleft {
	width: 100%;
	float: left;
	padding: 0;
}
.payment-block{
	float: right;
}
.social-block ul li {
    float: left;
}
.social-block ul li a {
	width: 40px;
    height: 40px;
   float: left;
    background: transparent;
    outline: none;
	 color: #fff;
	 text-align: center;
}
.footer-blocks .footerleft .social-block ul li a .fa {
	color: #999;
	font-size: 18px;
}
.social-block ul li a .fa {
    font-size: 17px;
	line-height: 40px;
    float: left;
    height: 100%;
    width: 100%;
}
.social-block ul li:last-child a{ margin: 0; }
.social-block ul li a:hover, .social-block ul li a:active, .social-block ul li a:focus{ color: #fbb50d; }
#paysocialcmsblock ul{ padding: 0; margin: 0; float: right;}

.main-slider .swiper-viewport:hover .swiper-button-next {opacity:1;-khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1;right:20px}
.main-slider .swiper-viewport:hover .swiper-button-prev {opacity:1; -khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1; left: 20px;}
#form-currency .currency-select {
	text-align: left;
	width:100%;	
	text-transform: inherit;

}

.dropdown-menu-right{border-radius: 0 !important;
 -webkit-border-radius:0 !important;
  -khtml-border-radius:0 !important;
   -moz-border-radius:0 !important;
   box-shadow: none;
   }
.language-menu .btn.btn-link.btn-block.language-select {text-transform: inherit;}
#form-language .dropdown-menu {
	border: none;
	box-shadow: none;
	margin: 2px 0 0;
	padding: 10px 10px 12px;
	right: auto;
	top: 19px;
	left: 10px;
}
.dropdown-menu.currency-menu {
	border: none;
	box-shadow: none;
	margin: 2px 0 0;
	padding: 10px 10px 12px;
	right: auto;
	top: 19px;
	left: 10px;
}

.lang-curr-wrapper .language-title, .lang-curr-wrapper .currency-title {
	font-weight: 600;
	padding: 3px 20px;
	display: inline-block;
	width: 100%;
	font-size: 13px;
	color: #000;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
}
.dropdown-menu.currency-menu .fa.fa-angle-down ,.dropdown-menu.language-menu .fa.fa-angle-down {
	display: none;
}
.productpage .special-price {
	font-size: 40px;
    display: inline-block;
    font-weight: 600;
    line-height: 20px;
    color: #111;
}
.productpage .description {
	line-height: 40px;
	font-size: 20px;
    margin-bottom: 10px;
    padding: 15px 5px 20px 0;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
}
.product-description td span {
	font-weight: 500;
	padding: 2px;
}

.description{
	color: #000;
}

.description a{
	color: #0089fa;
}

.description a:hover{
	color: #75bdf8!important;
}

.product-description td { padding: 2px;}
.productpage .description .product-description .description-right {padding-left: 50px;}
#form-language .dropdown-menu > li > .btn { padding: 5px;color:#000;}
#form-currency .dropdown-menu .btn { padding: 6px !important; color:#333333; }
#form-language .dropdown-menu > li > .btn:hover, #form-currency .dropdown-menu .btn:hover{color: #111111; background: transparent;}
.box-content-category ul {
	padding: 0;
	margin: 0;
}
.content_headercms_top #nav-one a {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}
.box-content-category a {
    font-size: 13px;
    display: inline-block;
    line-height: 30px;
    font-weight: 400;
    width: 95%;
	 color: #111;
	 position: relative;
    padding:11px 35px 11px 20px;
}
.box-content-category ul li a:before {
	content: "";
	background-image: url(../image/webdigify/cat-icon.png);
	background-repeat: no-repeat;
	height: 40px;
	width: 40px;
	position: absolute;
	left: 0;
	top: 0;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
}
.box-content-category ul li.main.menu-1 a:before {
	background-position:16px 10px;
}
.box-content-category  ul li.main.menu-1 a:hover::before {
	background-position: 16px -31px;
}
.box-content-category  ul li.main.menu-2 a::before {
	background-position: 16px -70px;
}
.box-content-category ul li.main.menu-2 a:hover::before {
	background-position:16px -108px;
}
.box-content-category  ul li.main.menu-3 a::before {
	background-position: 16px -146px;
}
.box-content-category ul li.main.menu-3 a:hover::before {
	background-position: 16px -186px;
}
.box-content-category  ul li.main.menu-4 a::before {
	background-position: 16px -227px;
}
.box-content-category ul li.main.menu-4 a:hover::before {
	background-position: 16px -267px;
}
.box-content-category  ul li.main.menu-5 a::before {
	background-position: 15px -308px;
}
.box-content-category ul li.main.menu-5 a:hover::before {
	background-position: 15px -348px;
}
.box-content-category  ul li.main.menu-6 a::before {
	background-position:15px -386px;
}
.box-content-category ul li.main.menu-6 a:hover::before {
	background-position: 15px -422px;
}
.box-content-category  ul li.main.menu-7 a::before {
	background-position: 15px -457px;
}
.box-content-category ul li.main.menu-7 a:hover::before {
	background-position: 15px -494px;
}
.box-content-category  ul li.main.menu-8 a::before {
	background-position:15px -532px;
}
.box-content-category ul li.main.menu-8 a:hover::before {
	background-position: 15px -571px;
}
.box-content-category  ul li.main.menu-9 a::before {
	background-position:15px -611px;
}
.box-content-category ul li.main.menu-9 a:hover::before {
	background-position: 15px -652px;
}
.box-content-category  ul li.main.menu-10 a::before {
	background-position:14px -688px;
}
.box-content-category ul li.main.menu-10 a:hover::before {
	background-position: 14px -723px;
}

.box-content-category a:hover,
.box-content-category a:focus {
	color: #fbb50d;
}
.box-content-category li.top_level:after {
	content: "";
	height: 1px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background:rgba(0,0,0,.1);
	transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	margin: 0 auto;
	top: auto;
	width: 85%;
}
#review-count {
	cursor: pointer;
}
.site-map li {
	list-style: inherit;
	line-height: 28px;
}
.site-map ul {
	padding: 0 0 0 20px;
}
#review-count:hover{text-decoration: underline;}

/* #nav-one .dropdown a::after, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown a::after {
	content: "\f105";
	right: 20px;
	position: absolute;
	font-family: fontawesome;
	font-size: 16px;
	display: inline-block;
} */
#nav-one li .megamenu ul.list-unstyled > li.dropdown > a::after, #nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a:after, #nav-one .hiden_menu .dropdown-menu a:after{
	display: none;
}

.header_cart .cart-menu .text-left a {font-size: 13px;	font-weight: 500;}
.header_bottom .row {
	position: relative;
}
#nav-one > li > .dropdown-menu.megamenu.column1, #nav-one .hiden_menu .dropdown-menu {
	padding: 20px 0 20px 20px;
	width: auto;
	height: auto;
	min-width: 200px;
	top: 0;
	left: 271px;
	border-radius: 0;
}
.box-content-category ul li.top_level a {
	text-transform: uppercase;
}
.quickview .header-container {display: none;}
/* header cms start*/
.content_headercms_top {
   float: left;
	width: 16%;
	margin-top:5px;
	text-align:left;
}
.content_header_topleft {
    padding:0;
    position: relative;
    text-transform: uppercase;
	font-weight:500;
}
.content_header_top .single-banner { margin: 0;}
.static_links {
    list-style: outside none none;
    margin: 0;
    padding: 2px 0 0 0;
	padding-left:8px;
}
.static_links li {
    position: relative;
	padding:0;
    float: left;
}
.static_links .hiden_menu .top_level > a {
    padding: 10px 20px !important;
	margin-right:0;
}
.childs_2{
	width:auto!important;
}
.static_links li .megamenu li.list-unstyled.childs_1 li{padding:0;}
#content .col-sm-6 h2 {font-size: 16px;}
.static_links .myaccount ul{ height:auto; border:1px solid #ebebeb;padding:10px 5px; } 
.static_links ul.nav li .megamenu.column1 ul.childs_1 {border: medium none;}
 .static_links ul.nav li .megamenu.column1 ul.list-unstyled li > a {
  color: #000;
  display: inline-block;
  font-size: 14px;
  padding: 8px 7px !important;
  position: relative;
  text-transform: inherit;
  font-weight:400;
}
 .static_links ul.nav li .megamenu.column1 ul.list-unstyled li > a:hover {color:#111111;}
.navbar-nav > li > .dropdown-menu { padding: 15px 0 15px 15px;top:56px;}
.static_links .dropdown-menu .list-unstyled.childs_1 > li{padding-bottom:0;}
.static_links ul { text-align: left;}
 .static_links .cart-menu > li {padding: 0;}
.header-cart .dropdown-menu {
  border: 1px solid #ebebeb;
  min-width: 280px;
   padding:10px;
  top: 53px;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
  padding:10px 0 10px 0;
  margin-top: 24px !important;
}
.static_links ul li ul{ display:table-cell; float:none;}
.nav .navbar-nav .top-level.dropdown li a:hover{display:inline-block;}
.static_links li.hiden_menu div {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  display: none;
  min-width: 155px;
  padding: 15px 0;
  position: absolute;
  text-align: left;
  border-radius:0 0 5px 5px;
-webkit-border-radius:0 0 5px 5px;
-khtml-border-radius:0 0 5px 5px;
-moz-border-radius:0 0 5px 5px;
  top:56px;
}
.static_links li.hiden_menu:hover div{display:block;color:#767676;}
.static_links li.hiden_menu div li a::before{background:none;}
.static_links li.hiden_menu .top_level a:hover{background:none;}
.static_links .dropdown-inner .top_level li a::before{background:none;}
.static_links li a::before {
	content: "";
	display: block;
	height: 13px;
	position: absolute;
	right: 7px;
	border-left: 1px solid #ededed;
}
.static_links .dropdown.myaccount li a::before{background:none;} 
.login_acc {
	display: inline-block;
	padding: 0px;
	position: relative;
	width: 100%;
}


.lang-curr-wrapper .language-title .fa.fa-caret-down {
	float: right;
	padding: 5px 10px;
}
.lang-curr-wrapper .currency-title .fa.fa-caret-down {
	float: right;
	padding: 5px 10px;
}
.lang-curr-wrapper .language-title:hover {
	color: #111111;
}
.lang-curr-wrapper .currency-title:hover {
	color: #111111;
}
.login_acc li {
	padding: 3px 40px;
	position: relative;
	text-align: left;
}
.login_acc li::after {
	content: " ";
	position: absolute;
	right: auto;
	top: 10px;
	left: 15px;
	background: rgba(0, 0, 0, 0) url("../image/webdigify/myaccountArrow.png") no-repeat scroll center 0;
	width: 10px;
	height: 10px;
}
.login_acc li:hover::after {
	background-position: center -56px;
}
.checkout:hover {
	color: #111111;
}
.myaccount .account:hover {
	background: none;
	color: #000 !important;
	border: none;
	font-size: 12px;
	margin: 0;
	padding: 0;
}

.drop_account {
  color: #000000;
}
.myaccount .account {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #111111 ;
  color: #111111 ;
  float: left;
  margin: 5px;
  padding: 8px 18px;
  text-align: center;
  border-radius:5px;
  -webkit-border-radius:5px;
-khtml-border-radius:5px;
-moz-border-radius:5px;
  font-weight:400;
}
.static_links .dropdown.myaccount li a:hover{
background:none;
color:#111111;
}
.static_links li:first-child a::before {
	background:none;
}
.static_links li a {
	color: #000;
    display: block;
    font-size: 13px;
  	font-weight:600;
    line-height: 10px;
   	margin: 1px 11px 0px 0;
    padding:15px 20px !important;
    position: relative;
    text-transform: inherit;
    z-index: 6;
}
.list-unstyled.childs_2 > li{ padding:0; width:100%; margin:0 0 0 0}
.static_links li:last-child a {
    padding-right: 8px;
}
.btn-link:hover, .btn-link:focus {
  color: #111111 !important;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background: none;
}
#nav-one > li.top_level.dropdown .list-unstyled.childs_1 li a::before {
	background: none;
}
#top-links li{padding:0; list-style:none}
header .btn-link:hover, header .btn-link:focus, #top-links a:hover, #top-links a:focus {
	color: #fff ;
	background: none;
	text-decoration: none;
}
#top-links .dropdown-menu a {
	text-shadow: none;
}

#top-links .dropdown-menu a:hover,#top-links .dropdown-menu a:focus {
	color: #2196f3;
}
#top .btn-link strong {
	font-size: 14px;
	font-weight: normal;
}
#top-links {
	padding-top: 0px;
}
#top-links a + a {
	margin-left: 15px; 
}
.dropdown.myaccount:hover a.account::after, .dropdown.myaccount:hover a.account1::after , .dropdown.myaccount:hover a.account_order1::after, .dropdown.myaccount:hover a.account_transaction1::after, .dropdown.myaccount:hover a.account_download1::after, .dropdown.myaccount:hover a.account_logout1::after{display:none;}
.swiper-viewport  .swiper-button-next {
	left: auto;
	right: 40px;
	width: 60px;
    height: 60px;
	transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}
.swiper-viewport  .swiper-button-prev {
	right: auto;
	left: 40px;
	transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}

#top-links ul.list-inline{float:left; margin-right: 5px; margin-bottom:0;}

#top-links ul.list-inline li{background:transparent; font-weight:300}
#top-links #form-language li {
	padding: 0;
}
.lang-curr { 
	margin:0;
	display:inline-block;
}
.container .container-top a{
color:#000;
}
.nav.pull-right {
	display: inline-block;
	padding: 0;
}
.container .result-search-autocomplete ul li{
float:left;
}
.right-links ul li{ float:left; margin-left:10px; font-size:12px;}
.container .container-top a:hover {
  color: #111111;
}
.right-myaccount-menu > li #wishlist-total {
  border-right: 1px solid;
  padding: 0 10px 0 0;
}
.right-links .right-myaccount-menu ul li{
margin-right:10px;
}
.content-top {
	float: left;
	width: 100%;
}
#form-language .fa-angle-down, #form-currency .fa-angle-down{
	float: none;
    display: inline-block;
    vertical-align: middle;
    color: #111;
    width: auto;
    padding-left: 5px;
}
#form-currency:hover .btn-link.dropdown-toggle, #form-language:hover .btn-link.dropdown-toggle  {
	margin: 0px;
}

#form-currency .btn-link, #form-language .btn-link {
	color: #111;
	font-weight: normal;
    font-size: 13px;
    padding: 0;
    line-height: 22px;
	 letter-spacing: 0.6px;
	 border: 0px;
}

.nav2 {
	 float: left;
	 width: 100%;
    text-align: center;
}
.lang-curr-wrapper {
    float: right;
}
#form-language .btn-link.btn-block.language-select ,
#form-currency .currency-select{
    color: #666;
}
.language-menu li {
	text-align: left;
	padding: 2px 0;
}
.wd-currency ul.currency-menu li{padding: 2px 0;}
#form-currency .btn-link strong {
	font-weight: normal;
	padding: 0 3px 0 0;
	font-size: 13px;
}
.pull-left #form-language .btn-group span img {
	padding:0px 8px 0 0;
}
.dropdown.myaccount {
	float: left;
	position: relative;
	margin: 5px 0 5px 10px;
	width: 40px;
    height: 40px;
}
.myaccount > a {
	position: absolute;
	width: 100%;
	height: 100%;
	background:rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -314px;
	display: block;
	transition: all 600ms ease-in-out 0s;
    -webkit-transition: all 600ms ease-in-out 0s;
    -moz-transition: all 600ms ease-in-out 0s;
    -o-transition: all 600ms ease-in-out 0s;
    -ms-transition: all 600ms ease-in-out 0s;
}
.myaccount > a:hover {
	transform: rotateY(
360deg
);
    -webkit-transform: rotateY(
360deg
);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
}
.language-menu ,
.currency-menu{
	padding: 10px 15px;
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid #eaeaea;
    top: 40px;
    left: auto;
    right: 0px;
    min-width: 160px;
    z-index: 1000;
    margin: 0px;
	 border-radius: 5px;
	 -moz-border-radius: 5px;
	 -webkit-border-radius: 5px;
	 -khtml-border-radius: 5px;
}

.header_top {
	width: 100%;
	padding: 25px 0;
	float: left;
}
.header_bottom {
	width: 100%;
	background:#020202; 
	padding:0px;
	z-index: 1000;
	clear: both;
}

.common-home .header_bottom{border-bottom: none;}

.content_headercms_bottom {
	clear: both;
	float: left;
	text-align: center;
	width: 100%;
}
.header-right .head{
	float:right;
	margin:5px 5px 0 0;
	padding:2px;
}
.header-center {
	float:left;
	padding:10px 0 16px 0;
    width: 100%;
}
/**************Start Contact Link***********/
.category_list.box .category_img {
    width: 80px;
    float: left;
}
#_desktop_contact_link {
	 float: left;
	 padding: 5px 0px;
}

span.service-title {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
	 color: #111;
}
span.content a.contact-info {
    color: #111111;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
}
#_desktop_contact_link .wdicon {
	   height: 30px;
	   width: 30px;
	   float: left;
	   margin: 0 10px 0 0;
	   text-align: center;
	   font-size: 0;
		background:rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 0px 0px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
#_desktop_contact_link span.content {
	float: left;
	padding: 4px 0px;
}
@media (max-width: 991px){
	.header_top .header_right {
		display: none;
	}
#_desktop_contact_link .wdicon {
    display: none;}
	span.content {
		width: 100%;
	}
	span.service-title {
		color: #111;
		display: inline-block;
		font-weight: 500;
		padding: 10px 0px;
		font-size: 14px;
	}

	span.content a.contact-info {
		padding: 0px;
		display: inline-block;
		color: #111111;
		font-size: 16px;
		margin: 0 0 0 10px;
	}


}







/*************ENd Contact Link***************/
/* logo */
.header-logo{float:left;}
.header-cart{float:left;}
.header-main {
    display: block;
    float: left;
    position: relative;
    width: 100%;
	height:50px;
	background: #fff;
}
.static_links li:last-child a::before {
	border-left: none;
}
.header-left {
    float: left;
	width:auto;
	margin:0 79px 0 0;
}
.static_links li:first-child a {
	padding-left: 5px !important;
}
.header-right {
    float: right;
	width: 81%;
	position:relative;
	top:0;
	transition:top 800ms ease 0s;
	-webkit-transition:top 800ms ease 0s;
	-moz-transition:top 800ms ease 0s;
	-ms-transition:top 800ms ease 0s;
	-o-transition:top 800ms ease 0s;
	}
header .tags > a {
  font-weight: normal;
}
header{
	padding:0; 
	margin:0;
	clear:both;
	border-radius:0;
	height:auto;
	
}
@-webkit-keyframes fixedmenu {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}

@-moz-keyframes fixedmenu {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}

@keyframes fixedmenu {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}

.header_bottom.fixed {
	position: fixed;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	margin-top: -1px;
	animation: 1s ease 0s normal both 1 running fixedmenu;
}
#checkout-checkout .text-right.total {
	font-size: 17px;
	font-weight: 600;
}
a.banner_text::before, a.banner_text::after{
 	background:none !important;
}
.col-sm-4.header-logo {
	width: auto;
	padding: 0;
}
.header_center {
	float: left;
	width: auto;
}
.col-sm-3.sort{ padding:0; }
.col-sm-3.sort.category_dropdown span.customSelect{
	height: 38px;
	border-radius: 5px;
	padding: 8px 15px;
}
#searchbox .col-sm-3.sort { padding:0 15px; }
.col-sm-3.sort select.form-control{
	padding:0;
    height:auto;
    text-transform: inherit;
	background-color:#fff;
	color:#333333;
	border:none;
	overflow:hidden;
	left:0;
	width:100% !important;
}
.head-right-bottom {
    float:right;
	position:relative;
}
/*header-cms*/
.quickview {  padding:15px 0; display:inline-block; }
.quickview .container { width:100%; background:#FFFFFF; padding:0 10px; }
.quickview .container .row { margin:0; }
.quickview .top_button { display:none; }
.quickview #product2 { float:left; width:100%; }
.quickview #product2 h3 { width:100%; } 
.productpage-quickview #product2 h3 {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 10px;
    padding: 0;
    text-transform: none;
	color:#333333;
}

.menu_toggle {
    background: #ffffff none repeat scroll 0 0;
    display: none;
    left: 0;
    position: absolute;
    top: 62px;
    width: 100%;
    z-index: 99;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
	border-radius:5px;
	-webkit-border-radius:5px;
	-khtml-border-radius:5px;
	-moz-border-radius:5px;
}
/* search */
.col-sm-5.header_search {
	float: none;
    width: auto;
    display: inline-block;
	 vertical-align: top;
	 padding: 5px 0;
}
#searchbox {
	position: relative;
	float: left;
	width: 100%;
	border-radius: 30px;
}
.search_box {
	float: left;
	position: relative;
}
.search_box .form-control-select {
	height: 40px;
	position: relative;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-radius: 0;
	border: 1px solid #eaeaea;
	padding:5px 4px 5px 17px;
	border-radius: 5px 0 0 5px;
	font-size: 13px;
    color: #666;
	line-height: 1.25;
	letter-spacing: 0.6px;
	width: 160px;
}
.search_box:after {
    /* content: ""; */
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
	right: -11px;
    top: 0;
    z-index: 1;
    cursor: pointer;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background: #fbb50d;
    border: 2px solid #fff;
    border-radius: 50%;
    line-height: 16px;
	 color: #fff;
    text-align: center;
    box-shadow: 0 0 8px rgba(0,0,0,8%);
}
.header_search .btn.btn-default.btn-lg .fa.fa-search:before {
    content: "\f002";
	 font-size: 14px;
    padding-right: 7px;
}
.header_search .form-control.input-lg {
	height: 40px;
    width: 280px;
    padding:8px 15px 8px 18px;
    font-size: 13px;
    color: #666;
	 display: block;
	 border-left: 2px solid #eaeaea;
}
.header_search .input-group-btn {
	width: auto;
}
#searchbox  .input-group input {
	font-size: 12px;
}
.header_search .btn.btn-default.btn-lg {
	border: none;
	text-transform: inherit;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.6px;
	padding: 11px 24px 11px 24px;
	border-radius: 0 5px 5px 0px;
	height: 40px;
	line-height: 18px;
	background: #fbb50d;
	color: #ffffff;
}

/* end of search */
/* end header top block */

/****************Start Cms Top Banner********************/
#wdcmstopbanner {
	float: left;
	width: 100%;
	margin: 0px 0 35px;
}
#wdcmstopbanner .wdcmstopbanner{
	margin: 0 -15px;
}
#wdcmstopbanner .one-third {
    padding: 0px 15px;
    float: left;
    width: 33.33%;
    position: relative;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
	font-size: 16px;
	line-height: 22px;
	font-weight: 500;
	text-transform: inherit;
	color: #111111;
	margin: 0 0 10px;
	font-family: 'Poppins',Helvetica,sans-serif;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
	font-size: 24px;
    line-height: 30px;
    font-weight: 600;
	text-transform: uppercase;
	color: #111111;
	margin: 0 0 20px;	
	font-family: 'Poppins',Helvetica,sans-serif;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
	background: #020202;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #fff;
	text-transform: inherit;
	padding: 0 12px;
	border-radius: 5px;
	margin: 0 0 10px;
}
#wdcmstopbanner .wdtopbanner .wdright-banner-content {
	float: left;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
	text-align: left;
	position: absolute;
	left: 30px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
	display: inline-block;
    font: 500 14px/20px 'Rubik',Helvetica,sans-serif;
    text-transform: inherit;
    background: #fbb50d;
    color: #fff;
    padding: 9px 20px;
    position: relative;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    vertical-align: top;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    z-index: 1;
    overflow: hidden;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:hover{
	color: #ffffff;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:hover:before{
	height: 100%;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a:before {
	content: "";
	position: absolute;
	background: #020202;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	height: 0;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;

}
#wdcmstopbanner .wdtopbanner .wdright-banner-content > a {
	display: block;
	overflow: hidden;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius:5px;
    -khtml-border-radius:5px;
}
#wdcmstopbanner .wdtopbanner-img2.wdtopbanner .wdtopbanner-content .title {
	color: #111;
}
#wdcmstopbanner .wdtopbanner-img2.wdtopbanner .wdtopbanner-content .toptitle {
	color: #111;
}
#wdcmstopbanner .wdtopbanner:hover .wdright-banner-content > a img {
	transform: scale(1.04);
	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
}
#wdcmstopbanner .wdtopbanner .wdright-banner-content > a img {
	transition: all 300ms ease-in-out;
	-webkit-transition: all 300ms ease-in-out 0s;
	-moz-transition: all 300ms ease-in-out 0s;
	-o-transition: all 300ms ease-in-out 0s;
	-ms-transition: all 300ms ease-in-out 0s;
	max-width: 100%;
}
@media (max-width:1199px){
#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
	left: 20px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
	margin: 0 0 5px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
	font-size: 14px;
	line-height: 18px;
	margin: 0 0 5px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle{
	font-size: 22px;
    line-height: 26px;
    margin: 0 0 10px;
}
}
@media (max-width:991px) {
	#wdcmstopbanner {
		margin:0px 0 25px;
	}
}
@media (max-width:991px){
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
		left: 15px;
  }
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label {
	font-size: 10px;
	line-height: 18px;
	padding: 0 8px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
	font-size: 12px;
    line-height: 16px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
	font-size: 16px;
	line-height: 18px;
	margin: 0 0 6px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
	font-size: 12px;
	line-height: 16px;
	padding: 6px 12px;
}

}
@media(max-width:767px){
	#wdcmstopbanner .one-third {
		padding: 0px 10px;
  }
 
}
@media(max-width:767px) and (min-width:544px){
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content .offer a {
		font-size: 12px;
		line-height: 16px;
		padding: 0;
		background: none;
		color: #fbb50d;
		text-decoration: underline;
	}
}
@media(max-width:677px) and (min-width:544px) {
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label{
		display: none;
	}
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title {
		font-size: 12px;
		line-height: 16px;
		margin: 0 0 5px;
	}
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle {
		font-size: 14px;
		line-height: 18px;
		margin: 0 0 5px;
	}

}

@media(max-width:543px){

#wdcmstopbanner {
	margin: 0px 0 15px;
}
#wdcmstopbanner .one-third.topbanner-part1 {
	padding-top: 0px;
}
#wdcmstopbanner .one-third {
    padding:15px 10px 0px;
    width: 100%;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .label{
	font-size: 12px;
	line-height: 22px;
	margin: 0 0 8px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .title{
	font-size: 14px;
	line-height: 18px;
	margin: 0 0 5px;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content .toptitle{
	font-size: 20px;
	line-height: 24px;
	margin: 0 0 15px;
}

#wdcmstopbanner .wdtopbanner .wdright-banner-content {
	float: none;
	width: auto;
	position: relative;
	display: inline-block;
	text-align: center;
}
#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
    left:9%;
    top: 27%;
}
#wdcmstopbanner .wdcmstopbanner {
	text-align: center;
}

}
@media(max-width:480px){
	#wdcmstopbanner {
		margin: 0px 0 0px;
  }
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
		left: 5%;
		top: 23%;
	}

}
@media(max-width:380px){
	#wdcmstopbanner .one-third {
		padding:15px 10px 0px 10px;
	}
	#wdcmstopbanner .wdtopbanner .wdtopbanner-content {
		top: 17%;
	}
}



/********************End Cms Top Bannner***********************/
.footer-middle {
    background: #f1f4fa;
    float: left;
    width: 100%;
}



/********** End Testimonial Cms BLock  **********/


/********************Start Cms Sub Banner**********************/

#wdcmssubbanner {
	float: left;
	width: 100%;
	margin: 35px 0;
	text-align: center;
}

#wdcmssubbanner .wdcmssubbanner .title {
	font:400 22px/28px 'Rubik',Helvetica,sans-serif;
	color: #ffffff;
	margin: 0 0 10px;
}
#wdcmssubbanner .wdcmssubbanner .desc {
	font: 500 36px/40px 'Rubik',Helvetica,sans-serif;
	color: #fff;
	margin: 0 0 20px;
}
#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content {
	display: flex;
	align-items: center;
	position: relative;
}
#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content .subbanner-content {
	position: absolute;
	left: 14%;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn {
	padding: 0;
}
#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content a {
	overflow: hidden;
	position: relative;
	display: block;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
}
#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content img {
	transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    max-width: 100%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
#wdcmssubbanner .wdcmssubbanner:hover .wdright-wdsubbanner-content img {
	transform: scale(1.04);
	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
	background: #fbb50d;
    color: #fff;
    padding: 9px 20px;
    display: inline-block;
    font: 500 14px/22px 'Rubik',Helvetica,sans-serif;
    text-transform: inherit;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    vertical-align: top;
    z-index: 1;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover {
	color: #111111;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:after {
	content: "";
	position: absolute;
	background: #fbb50d;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	height: 0;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover:after{
	height: 100%;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:after{
	background: #fff;
}

@media(max-width:1499px){
	#wdcmssubbanner {
		margin: 0px 0 40px;
	}
}
@media(max-width:1400px) {
	.header_right{
		display: none;
	}
}

@media(max-width:991px) {
#wdcmssubbanner {
	margin:30px 0 25px;
}
#wdcmssubbanner .wdcmssubbanner .title {
	font-size: 16px;
	line-height: 20px;
	margin: 0 0 5px;
}
#wdcmssubbanner .wdcmssubbanner .desc {
	font-size: 26px;
    line-height: 30px;
    margin: 0 0 10px;
}
#wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
	font-size: 13px;
	line-height: 16px;
	padding: 6px 12px;
}
}
@media (max-width: 767px) and (min-width: 544px){
	#wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    background: none;
    color:#fbb50d;
    text-decoration: underline;
}
}
@media(max-width:677px){
	#wdcmssubbanner {
		padding: 0 0px;
	}
	#wdcmssubbanner .wdcmssubbanner .title {
		font-size: 14px;
		line-height: 18px;
		margin: 0 0 5px;
  }
  #wdcmssubbanner .wdcmssubbanner .desc {
	font-size: 22px;
    line-height: 26px;
    margin: 0 0 5px;
}
}
@media(max-width:543px) {
	#wdcmssubbanner .wdcmssubbanner .title {
		display: none;
	}
	#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content .subbanner-content {
		position: static;
		margin: 15px 0 0px;
		background: #f5f5f5;
		padding: 20px 10px;
		float: left;
		width: 100%;
	}
	#wdcmssubbanner .wdcmssubbanner .desc {
		font-size: 14px;
    line-height: 18px;
    color: #222;
	}
	#wdcmssubbanner .wdcmssubbanner .shop-now.btn a {
		font-size: 12px;
		line-height: 16px;
		padding: 6px 12px;
		background: #020202;
		color: #fff;
  }
	#wdcmssubbanner .wdcmssubbanner .wdright-wdsubbanner-content {
		display: block;
	}
	#wdcmssubbanner {
		margin: 10px 0;
	}
	#wdcmssubbanner .wdcmssubbanner .shop-now.btn a:hover {
		background: #fbb50d;
		color:#111;
	}
}



/********************End Cms Sub Banner*****************************/


/***********************Start Cms Bottom Banner*******************/

#wdcmsbottombanner {
	float: left;
	width: 100%;
	margin: 15px 0 40px;
}
#wdcmsbottombanner .wdcmsbottombanner{
	margin: 0 -15px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content {
	display: flex;
	align-items: center;
	position: relative;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content .wdbottombanner-content {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-img1.wdbottombanner .wdbottombanner-content.left {
	right: 15%;
	left: auto;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
	font: 400 16px/20px 'Rubik',Helvetica,sans-serif;
	color: #111;
	margin: 0 0 15px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
	font: 500 30px/38px 'Rubik',Helvetica,sans-serif;
	color: #111;
	margin: 0 0 20px;
}

#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
	display: inline-block;
	font: 500 14px/20px 'Rubik',Helvetica,sans-serif;
	text-transform: inherit;
	background: #020202;
	color: #ffffff;
	vertical-align: top;
	padding: 10px 21px;
	transition: all 400ms ease-in-out 0s;
	-webkit-transition: all 400ms ease-in-out 0s;
	-moz-transition: all 400ms ease-in-out 0s;
	-o-transition: all 400ms ease-in-out 0s;
	-ms-transition: all 400ms ease-in-out 0s;
	z-index: 1;
	position: relative;
	border-radius: 25px;
	-moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	-khtml-border-radius: 25px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:hover{
	color: #ffffff;
	background: #fbb50d;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:before {
	content: "";
	position: absolute;
	background: #fbb50d;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	height: 0;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a:hover:before {
	height: 100%;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content img {
	transition: all 300ms ease-in-out;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	max-width: 100%;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content a{
	overflow: hidden;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
}
#wdcmsbottombanner .wdbottombanner:hover .wdbottom-banner-content img {
	transform: scale(1.04);
	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content .wdbottombanner-content .label {
	font: 500 14px/24px 'Rubik',Helvetica,sans-serif;
	background: #fff;
	display: inline-block;
	vertical-align: top;
	border-radius: 5px;
	padding: 0 12px;
	text-transform: inherit;
	color: #111;
	margin: 0 0 15px;
}

@media(max-width:991px){
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
	font-size: 14px;
	line-height: 18px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
	font-size: 26px;
	line-height: 30px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
	font-size: 14px;
	line-height: 18px;
	padding: 10px 18px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-img1.wdbottombanner .wdbottombanner-content.left {
	right: 8%;
	left: auto;
}
}
@media(max-width:991px){
	#wdcmsbottombanner {
		margin: 25px 0 20px;
  }
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
	font-size: 14px;
	line-height: 18px;
	margin: 0 0 5px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
	font-size: 22px;
	line-height: 26px;
	margin: 0 0 10px;
}
#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a {
	font-size: 14px;
	line-height: 18px;
	padding: 8px 15px;
}
}

@media(max-width:767px){
	.wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
		width: 50%;
	}
	
	#wdcmsbottombanner .wdcmsbottombanner {
		margin: 0px;
	}
	#wdcmsbottombanner .container {
		padding: 0px;
  }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .offer a{
	font-size: 12px;
	line-height: 16px;
	padding: 0;
	background: no-repeat;
	color:#fbb50d;
	text-decoration: underline;
}
}
@media(max-width:677px){
	#wdcmssubbanner .container {
		padding: 0 10px;
  }
	.wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
		padding: 0 10px;
	}
	#wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .title {
		font-size: 12px;
    line-height: 16px;
    margin: 0 0 5px;
  }
  #wdcmsbottombanner .wdcmsbottombanner .wdbottombanner-content .subtitle {
	font-size: 18px;
    line-height: 22px;
    margin: 0 0 10px;
}

}
@media(max-width:543px){
	.wdbottombanner.col-sm-6.col-md-6.col-xs-12 {
		width: 100%;
	}
	#wdcmsbottombanner .wdcmsbottombanner .wdbottom-banner-content {
		padding:10px 0px;
	}
	#wdcmsbottombanner {
		margin: 10px 0 8px;
	}

}




/************************End Cms Bottom Banner*********************/


/********************Start Cms Sub Banner**********************/
.wdcmsbanner {
	float: left;
	width: 100%;
	overflow: hidden;
}
.banners {
	position: relative;
	vertical-align: top;
	margin: 30px -15px 0px;
}
 .banners .container {
	position: relative;
}
.owl-wrapper-outer{
	float: left;
}
 .banners .products .owl-stage-outer {
    padding: 0;
}
 .banners .brand-image {
	text-align: center;
	float: none;
	width: auto;
	display: inline-block;
	vertical-align: top;
	padding: 0 15px;
	height: 80px;
}
#wdcmsbanner-carousel .item {
	float: left;
	width: 100%;
	padding: 0 15px;
}
.offer-desc > div {
	float: left;
	width: 100%;
}
 .banners #wdcmsbanner-carousel .cmsbanner-title-desc .offer-img a {
	float: left;
	width: 100%;
	overflow: hidden;
	border: 3px solid #ffffff;
	border-radius: 5px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner:hover .cmsbanner-title-desc .offer-img img {
	transform: scale(1.04);
	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
}
 .banners #wdcmsbanner-carousel .cmsbanner-title-desc .offer-img img {
	transition: all 300ms ease-in-out;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	overflow: hidden;
	max-width: 100%;
}
 .banners #wdcmsbanner-carousel .brand-title a:hover {
	text-decoration: underline;
}
 .banners #wdcmsbanner-carousel .brand-title {
    font: 500 14px/18px 'Rubik',Helvetica,sans-serif;
    margin: 2px 0 15px;
    float: left;
    width: 100%;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner {
	float: left;
	width: 100%;
	padding: 12px;
	background: #f1f4fa;
	margin: 0 0 30px;
}
 .banners #wdcmsbanner-carousel .brand-title a{
	color: #df1612;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn:hover {
	text-decoration: underline;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn::before {
	content: '+';
	margin: 0 3px 0 0;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn {
	padding: 0;
	background: none;
	font: 500 14px/18px 'Rubik',Helvetica,sans-serif;
	color: #111111;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-title {
	font: 400 13px/21px 'Rubik',Helvetica,sans-serif;
	margin:15px 0 10px;
	color: #111111;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
    font: 600 20px/28px 'Rubik',Helvetica,sans-serif;
    color: #111111;
    margin: 0 0 12px;
}
 .banners .products-section-title a {
    color: #111111;
}
 .banners #wdcmsbanner-carousel {
    float: left;
    width: 100%;
    margin: 0;
    position: relative;
}
 .banners #wdcmsbanner-carousel {
	float: left;
	text-align: center;
	width: 100% !important;
}
 .banners #wdcmsbanner-carousel .brand-image img {
    max-width: 100%;
    transition: all 500ms ease 0s;
    -webkit-transition: all 500ms ease 0s;
    -moz-transition: all 500ms ease 0s;
    -o-transition: all 500ms ease 0s;
    -ms-transition: all 500ms ease 0s;
    width: auto;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc .offer-img {
	padding: 0 0 20px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc {
	float: left;
	width: 100%;
	padding:0px 0 13px;
}
@media (max-width: 1199px) {
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text{
    font-size: 20px;
    line-height: 24px;
}
}
@media (max-width:991px) {
 .banners {
	margin: 10px 0;
}

 .banners #wdcmsbanner-carousel .cmsbanner-inner{
	margin: 0 0 20px;
}
}
@media (max-width:630px) {
 .banners #wdcmsbanner-carousel .brand-title{
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 10px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
	font-size: 16px;
	line-height: 20px;
	margin: 0 0 5px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-btn .btn{
    font-size: 12px;
    line-height: 16px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner {
	padding: 8px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc {
	padding:0px 0 7px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner .cmsbanner-title-desc .offer-img {
	padding: 0 0 15px;
}
}
@media (max-width:543px) {
	 .banners #wdcmsbanner-carousel {
	padding: 0 5px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-inner{
	margin: 0 0 10px;
}
 .banners .row {
	margin: 0 -5px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-text {
	font-size: 14px;
	line-height: 18px;
}
 .banners #wdcmsbanner-carousel .cmsbanner-desc .offer-title{
	font-size: 12px;
	line-height: 16px;
}
}

@media (max-width:479px) {
	#wdcmsbanner-carousel .item {
		padding: 0 5px;
  }
}


/********************End Cms Sub Banner*****************************/



/************************Start Category List Cms********************/
.category_list_carousel.owl-carousel.owl-theme {
    margin: 30px 0px;
}
.category_list.box {
	float: left;
    width: 100%;
}
.category_list.box  .category_img img {
    border-radius: 50%;
    transition: all 600ms ease-in-out 0s;
    -webkit-transition: all 600ms ease-in-out 0s;
    -moz-transition: all 600ms ease-in-out 0s;
    -o-transition: all 600ms ease-in-out 0s;
    -ms-transition: all 600ms ease-in-out 0s;
	margin-bottom:5px;
}
.category_list_inner {
    text-align: left;
    float: left;
	width: 60%;
    margin: 0;
    padding: 20px 0 0 15px;
}
.category_list_inner ul.subcategory {
    margin: 0;
    float: left;
    width: 100%;
    position: relative;
}
.category_list_inner a{
    font: 600 13px/17px 'Rubik',Helvetica,sans-serif;
    margin: 0;
    position: relative;
    text-transform: inherit;
    padding: 0;
    color: #222;
    float: left;
    width: 100%;
	border: none;
    transition: 400ms ease-in-out all;
    -webkit-transition: 400ms ease-in-out all;
    -moz-transition: 400ms ease-in-out all;
    -o-transition: 400ms ease-in-out all;
    -ms-transition: 400ms ease-in-out all;
}
.content-top .owl-controls.clickable {
    display: none;
}
.category_list.box:hover .category_img img {
    transform: rotateY(
360deg
);
}
.view a {
    color: #777;
    font: 400 12px/24px 'Rubik',Helvetica,sans-serif;
    text-transform: inherit;
    position: relative;
    padding: 0;
    display: inline-block;
}
.view a:hover {
    text-decoration: underline;
}
@media(max-width:1499px){
	.category_list_inner {
		width: 56%;
	}
}	
@media(max-width:991px){
.category_list_inner {
    width: 49%;
}
.category_list.box {
    padding: 0 10px;
}
}
@media(max-width:575px){
	.category_list_inner {
		padding: 0px;
		width: 100%;
		text-align: center;
	}
	.category_list_carousel.owl-carousel.owl-theme {
		margin: 15px 0px;
	}
	.category_list.box .category_img {
		width: 100%;
		float: left;
	}
	.category_list.box {
		float: left;
		width: auto;
		display: block;
		text-align: center;
	}
}

/************************End Category List Cms**********************/

/***********************Homepage Special Product********************/
#content .product-carousel .product-block-inner,#content .product-carousel-custom .product-block-inner, #content .box-product .product-block-inner, .category_tab .tab-pane .product-block-inner {
    text-align: center;
    overflow: visible;
    display: inline-block;
}

.product-thumb .image {
    text-align: center;
    position: relative;
    overflow: hidden;
	 background: #fff;
	 float: left;
	 width: 100%;
}
.product-grid .product-thumb .image {
	float: none;
}
.box.special .product-block .product-details {
	width: 100%;
    float: none;
    text-align: left;
    padding:15px 0px 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.tabfeatured_default_width{ width:240px;}
.tabbestseller_default_width{ width:240px;}
.tablatest_default_width{ width:240px;}
.tabspecial_default_width{ width:240px;}
.featured_default_width{ width:240px;}
.tab-content .tab {
    display: none;
}
.static_links ul.nav li .megamenu ul.list-unstyled li .dropdown-menu {
	border: medium none;
	margin: 0;
	padding:0 0 0 0;
	position: relative;
}
.static_links ul.nav li .megamenu ul.list-unstyled li.dropdown.dropdown-menu{
	border:1px solid #000;
	margin: 0;
	padding:10px;
	position:absolute;
	left:188px;
	top:0;
}
.static_links ul.nav li .megamenu ul.list-unstyled li .dropdown-menu{
display:block;
}
.static_links ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
  border: 1px solid #d9d9d9;
  display: none;
  left: 165px;
  padding: 10px;
  position: absolute;
  top: 0;
  border-radius:5px;
  -webkit-border-radius:5px;
-khtml-border-radius:5px;
-moz-border-radius:5px;
}
.static_links ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown:hover .dropdown-menu{display:block;} 
.main-slider .swiper-viewport {
  border: medium none;
  box-shadow: none;
  margin:0;
  border-radius: 0;
}
.swiper-viewport .swiper-button-prev::before,.swiper-viewport .swiper-button-next::before {
	font-size:25px;
	opacity: 1;
	-khtml-opacity: 1;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	text-align: center;
	padding: 6px 0;
	display: inline-block;
	width: 60px;
	height: 60px;
	content: "\f104";
	font-family: "fontawesome";
	font-weight: 400;
	border-radius: 50%;
    border: 2px solid #fff;
	background: #fbb50d;
	 color: #ffffff;
	 transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}
.swiper-viewport .swiper-button-next::before {
	content: "\f105";
}
.swiper-viewport .swiper-button-next:hover::before, .swiper-viewport .swiper-button-prev:hover::before {
	color: #fff;
	background-color: #020202;
}
.main-slider .swiper-pagination {display: none;}
.main-slider.swiper-button-next, .main-slider.swiper-button-prev{width: 70px;}
.header-cart .cart .btn-lg .dropdown-toggle:before{
  content:'\f291';
  font-family:'FontAwesome';
  font-size:12px;
}
#search .input-group-btn{
float:right; 
width:auto; 
position:absolute; 
right:0;
}

#search .btn-lg {
  	font-size: 12px;
    line-height: 18px;
    background: #7e5cdd; /* Old browsers */
  	border:none;
  	color:#ffffff !important;
    padding:9px 30px 8px;
    height:42px;
    font-weight:700;
    text-transform:uppercase;
    border-radius:0 5px 5px 0;
	-webkit-border-radius:0 5px 5px 0;
-khtml-border-radius:0 5px 5px 0;
-moz-border-radius:0 5px 5px 0;
	width:100%;
}
.show-result {
    overflow: hidden;
}
.product-search #content > p{margin-top:10px;}
header .dropdown.myaccount a .fa{
	background-color: transparent;
    padding: 10px 8px;
}
header .header-cart a .fa{
	background:transparent;
}
.header-container {
    height: auto; 
    width: 100%;
    z-index: 99;
  	background:#232f3e none repeat scroll 0 0;
}
.header-container.smaller{z-index:999;}
.header-logo img {
	display: inline-block;
	max-width: 100%;
}
.content_header_top .box.webdigifycategory{
    left:0;
    position: absolute;
    top:0;
    width: 277px;
    z-index: 9;
}
/*end webdigify category */


.header_right {
	width: auto;
	float: right;
	cursor: pointer;
	padding: 9px 0px;
}
/* header top cms block*/

.offer-title {
	float: left;
	text-transform: inherit;
	color: #7a7a7a
}
.offer-content {
	display: flex;
	text-align: center;
}
.offer-inner {
	text-align: center;
	padding: 12px 0;
}
.offer_title {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	line-height: 18px;
	padding: 0 0 0 10px;
}

/*-------------------------- Start Home page Service CMS Block -------------------------------*/



#wdservicecmsblock {
   clear: both;
	margin: 0;
	padding: 40px 0;
    float: left;
    width: 100%;
}
#wdservicecmsblock .service_container {
    color: #FFF;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px auto;
}
#wdservicecmsblock .service_container .service-area-carousel {
    padding:0px;
    float: left;
    width: 100%;
	
 }
#wdservicecmsblock .service_container .service-area-carousel .service-fourth {
    float: left;
    width: 100%;
    line-height: 20px;
    color: #333333;
    padding:0px 15px;
    position:relative;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 {
    padding-left: 0px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
    position: relative;
    background: url(../image/webdigify/service-icon.png) no-repeat scroll;
    float: left;
    margin-top: 0px;
    height: 60px;
    width: 65px;
	margin-right: 10px;
    -webkit-transition: all 600ms ease 0s;
    -moz-transition: all 600ms ease 0s;
    -o-transition: all 600ms ease 0s;
    transition: all 600ms ease 0s;
}


#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 .icon1 {
    background-position:-24px -24px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1:hover .icon1 {
    background-position:-133px -24px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 .icon2 {
    background-position: -24px -137px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2:hover .icon2 {
    background-position:-132px -137px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 .icon3 {
    background-position: -24px -244px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3:hover .icon3 {
    background-position:-131px -244px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 .icon4 {
    background-position: -24px -365px;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4:hover .icon4 {
    background-position:-131px -365px;
}

#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-heading {
	font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111;
}
#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-description {
	color: #777;
    margin-top: 0px;
    font-size: 14px;
    line-height: 24px;
}
@media(max-width:1499px){
	#wdservicecmsblock .service_container .service-area-carousel {
		padding: 30px 22px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
		height: 60px;
		width: 60px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
		margin: 4px 0 0;
		float: left;
		width: 74%;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth {
		padding: 0px 0px;
	}
}
@media(max-width:1259px) and (min-width:1200px){
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 {
        padding-right: 0px;
    }
	
}
@media(max-width:1199px) {
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth:after {
        top: 25px;
        right: 5px;
    }
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon:after{
        display: none;
    }
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 {
        padding-left: 0px;
    }
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 {
        padding-right: 0px;
    }
	#wdservicecmsblock .service_container .service-area-carousel {
		padding: 40px 30px;
	}
    
}
@media(max-width:991px) {
	#wdservicecmsblock {
		margin: 0px 0px;
	}
    
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth {
        padding-left: 10px;
        padding-right: 10px;
    }
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth:after {
        border: 0;
    } 
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1,
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 {
        margin-bottom: 0px;
        padding: 0;
    }
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3,
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4{
        padding: 0px;
    }
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
		background-size: 180px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1 .icon1 {
		background-position: -24px -19px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service1:hover .icon1 {
		background-position:-112px -19px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2 .icon2{	
		background-repeat: no-repeat;
		background-position: -14px -109px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service2:hover .icon2{
		background-position: -106px -109px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3 .icon3{
		background-size: 180px;
		background-repeat: no-repeat;
		background-position: -19px -197px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service3:hover .icon3{
		background-position: -106px -197px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4 .icon4{
		background-position:-19px -290px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth.service4:hover .icon4{
		background-size: 180px;
		background-repeat: no-repeat;
		background-position: -106px -297px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-icon {
		height: 50px;
		width: 55px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
		width: 72%;
		margin: 7px 0 0;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-description,
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content .service-heading {
		font-size: 12px;
		line-height: 19px;
	}
	#wdservicecmsblock .service_container .service-area-carousel {
		padding:10px 10px;
	}
}
@media(max-width:767px){
	#wdservicecmsblock {
		padding:20px 10px 20px;
	}
	#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
		width: 72%;
	}
	.service-area-carousel .service-fourth .service-icon {
		margin-right: 5px !important;
	}
	#wdservicecmsblock .service_container .service-area-carousel {
		padding: 4px 0px;
  }
}

@media(max-width:600px){
#wdservicecmsblock .service_container .service-area-carousel .service-fourth .service-content {
    width: 74%;
}
}
@media(max-width:450px){
    #wdservicecmsblock .service_container .service-area-carousel .service-fourth{
           width:100%;
    }
}
@media (max-width: 399px) {  
	#wdservicecmsblock {
		margin: 0px 0px;
	}
}
/**************************End Home page Service CMS Block -------------------------------*/



/* CMS block */
.cmsbanner-block {
	margin: 30px 0 70px;
	float: left;
	position: relative;
	width: 100%;
}
.cms-inner-block1, .cms-inner-block2 {
	position: relative;
	float: left;
	overflow: hidden;
	border-radius: 4px;
}
.cms-inner-block1{ margin-right: 30px; }
.cms-inner-block1 .cms1_img .cms_image1, .cms-inner-block2 .cms2_img .cms2_image2 {
	max-width: 100%;
	width: 100%;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}
.cms-inner-block1:hover .cms1_img .cms_image1, .cms-inner-block2:hover .cms2_img .cms2_image2{
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	-moz-transform: scale(1.1);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}
.cmsbanner-block .cms-inner-block1 .description, .cmsbanner-block .cms-inner-block2 .description {
	left: 50px;
	position: absolute;
	top: 22%;
	bottom: auto;
	right: auto;
}
.cmsbanner-block .description .title {
	font-size: 24px;
	color: #000;
	margin: 0 0 15px 0;
}
.cmsbanner-block .description .toptitle {
	font-size: 24px;
	letter-spacing: 0.4px;
	color: #000;
	text-transform: inherit;
	font-weight: 600;
}
.cmsbanner-block .description .banner_text {
	color: #ff3300;
	margin: 50px 0 0 0;
	text-transform: inherit;
}
/* end of cms block */
/* homepage sub banner2*/
.webdigify-tab-text {
     background: #2196f3 none repeat scroll 0 0;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 20px 0;
    text-align: center;
}
.webdigify-tab-text > span {
   color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
/*end homepage sub banner2*/
/* cart */
#cart .item-count {
	background: #fbb50d;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	line-height: 18px;
	font-size: 12px;
	color: #ffffff;
	display: inline-block;
	margin: 0 5px 0 0;
	vertical-align: middle;
	font-weight: 400;
}
#cart .mycart {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.6px;
}
#cart .price {
	font-size: 14px;
    font-weight: 600;
    /* display: block; */
    line-height: 22px;
    text-align: left;
    letter-spacing:0.8px;
}
.col-sm-3.header_cart {
	width: auto;
	float: right;
	padding: 0 0 0 15px;

}
.fa.fa-heart-o {
  font-size: 25px;
  padding: 7px 25px 0 10px;
}
.header-cart .cart-menu li > a > img{width:100%;}
.col-sm-3.header-cart{
    float: left;
    width: auto;
    padding:0;
  	margin-left:0;
}
.cart-menu .img-thumbnail {
	max-width: none;
	width: auto;
	border: 1px solid #e8e9eb;
}
#cart .dropdown-menu .table {
  border: medium none;
  margin-bottom: 10px;
}
#cart button > h4 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    text-transform: inherit;
	line-height: 16px;
	padding: 0 0 0 21px;
	 margin: -20px 0 0 10px;
	 color:#fff;
}
.header-container .dropdown.myaccount .dropdown-toggle .fa.fa-user {
  color: #ef9d2a;
  font-size: 17px; 
}
.header-container .dropdown.myaccount {
  background: #394d66 none repeat scroll 0 0;
  border-radius: 5px;
  -webkit-border-radius:5px;
-khtml-border-radius:5px;
-moz-border-radius:5px;
  height: 40px;
  padding: 12px 17px;
}
.header_wishlist {
	float: right;
	margin:5px 10px;
	position: relative;
	height: 40px;
    width: 40px;
}

.header_wishlist > a{ 
    height: 100%;
    width: 100%;
	background:rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -153px;
	display: block;
	transition: all 600ms ease-in-out 0s;
    -webkit-transition: all 600ms ease-in-out 0s;
    -moz-transition: all 600ms ease-in-out 0s;
    -o-transition: all 600ms ease-in-out 0s;
    -ms-transition: all 600ms ease-in-out 0s;
	
 }

.header_wishlist > a:hover{ transform: rotateY(
	360deg
	);
		-webkit-transform: rotateY(
	360deg
	);
		-moz-transform: rotateY(360deg);
		-o-transform: rotateY(360deg);
		-ms-transform: rotateY(360deg);
}

.header_wishlist span{
	background: #fbb50d;
	height: 18px;
	position: absolute;
	width: 18px;
	text-align: center;
	border-radius: 50%;
	font-size: 12px;
	right: -6px;
	top: -5px;
	color: #ffffff;
	font-weight: 400;
	line-height: 18px;
}

#cart-total {
	float: left;
	color: #111;
	margin-top: 9px;
}
.cart_image {
	background:rgba(0, 0, 0, 0) url("../image/webdigify/header-icon.png") no-repeat scroll 5px -75px;
	height: 40px;
	width: 40px;
	float: left;
	margin:0 0px 0 0;
	transition: all 600ms ease-in-out 0s;
    -webkit-transition: all 600ms ease-in-out 0s;
    -moz-transition: all 600ms ease-in-out 0s;
    -o-transition: all 600ms ease-in-out 0s;
    -ms-transition: all 600ms ease-in-out 0s;
}

#cart:hover .cart_image{ 
    transform: rotateY(
360deg
);
    -webkit-transform: rotateY(
360deg
);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
}

#cart-text {
	color: #fff;
	font-size: 13px;
	font-weight: 300;
}
.header_cart .dropdown-toggle {
	border: 1px solid #111111;
}
#cart > .btn {
	background: none;
	width: 100%;
	padding: 0 !important;
	border: none;
	-webkit-transition: all 300ms ease 0s;
	-moz-transition: all 300ms ease 0s;
	-ms-transition: all 300ms ease 0s;
	-o-transition: all 300ms ease 0s;
	transition: all 300ms ease 0s;
}
#cart{
    text-transform: inherit;
	text-align:right;
	margin: 0;
	padding: 5px 0px;
}
.shopping-cart .btn.btn-danger {border-radius: 0;}
#cart h4 {
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: -2px;
  z-index: 999;
}
.pull-left #form-language .btn-group span, .pull-left #form-currency .btn-group span {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.6px;
	color: #111;
}
#nav-top {
	border-bottom: 1px solid #eaeaea;
}
.table.table-bordered.shopping-cart.responsive{ display: none; }
.pull-left #form-language .fa.fa-caret-down{
	padding: 0 10px;
}
.pull-left #form-currency .fa.fa-caret-down {
	padding: 0 12px;
}
#top-links .fa {display: inline-block;}
#top-links .fa.fa-angle-down {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0 -10px 0 10px;
}
#top-links .list-inline a {
	color: #fff;
	border-right: 1px solid #55ABA4;
	padding: 0 10px 0 10px;
}
#checkout-cart .text-right.total {
	font-size: 15px;
	font-weight: 700;
}
#cart .dropdown-menu {
	width: 330px;
    padding: 10px;
    border: none;
    overflow: hidden; 
     display: none;
    margin: 0;
	height: auto;
    box-shadow: 1px 1px 2px 2px rgba(0,0,0,10%);
	 top:130px;
	 border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
	 -khtml-border-radius: 5px;
	 position: absolute;


}

#cart .dropdown-menu li{float:none}
#cart .dropdown-menu li:first-child {
    max-height: 270px;
    overflow-y: auto;
	overflow-x: hidden;
}
#cart .dropdown-menu li + li {
    border-top: 1px solid #e5e5e5;
    padding: 5px 0 0;
}
#cart .dropdown-menu li::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
#cart .dropdown-menu li::-webkit-scrollbar-thumb {
    border: 4px solid #888;
}
#cart .dropdown-menu li::-webkit-scrollbar-track {
    background: #cccccc;
}

.header_cart .text-right.button-container > a {
	padding: 9px 25px;
	background: #fbb50d;
	display: inline-block;
	border-radius: 25px;
	color: #fff;
}
.header_cart .text-right.button-container > a.addtocart {
    background: #111111;
    color: #fff;
}
.header_cart .text-right.button-container > a.addtocart:hover {
    background: #fbb50d;
    color: #fff;
}
.header_cart .text-right.button-container > a.checkout:hover {
    background: #111111;
    color: #ffffff;
}
#cart .dropdown-menu table {
	margin-bottom: 10px;
	border:none;
	font-size:13px;
	}
#cart .dropdown-menu table td{ border:none; background:none; }
#cart .dropdown-menu table.total-table td{border:medium none;}
.productpage .image a.elevatezoom-gallery{
border:1px solid #e0e0e0;
}
.cart-menu .table td {
  padding: 10px 0 5px 5px;
}
.table-responsive .table.table-bordered.shopping-cart .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding:15px;
  vertical-align: top;
  width: 25%;
}
#account-wishlist #content tr td {
	padding: 9px;
}
#checkout-cart span.customSelect {
	border-radius: 5px;
	height: 40px;
	padding: 9px 15px 10px 15px;
}
.dropdown-menu .cart-menu .table.table-striped >tr > td .text-center > img{width:100%;}
.text-right > strong {
	font-weight: 600;
	color: #111;
}
.content-bottom > .container{width:100%; background-color: #fff;}
.serach-inner {
    float: left;
    margin-top: 25px;
    width: 100%;
}
#content .panel{
background-color:#fff ;
}
.panel-default>.panel-heading:hover {
    border-color: #111111;
}
/* menu */
.nav-responsive { display:none;background: none !important;}
.nav-inner.container{
	background: #ffffff none repeat scroll 0 0;
    padding:35px 45px 30px;
    top: auto;
	box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
	-webkit-box-shadow:0 0px 15px 0 rgba(0, 0, 0, 0.2);
	position:relative;
	margin-bottom:30px;
	display:none;
}
#res-menu {
	display: none;
}
.responsive-menu, .main-menu {
	float: left;
	width: auto;
}
.lang-curr .fa.fa-angle-down {
  vertical-align: middle;
}
.main-menu ul {
	list-style: none;
	margin: 0;
	padding: 0; 
}
#menu ul li .megamenu ul.list-unstyled {
    padding: 0px;
    border: none;
}
.main-menu > ul > li {
	position: relative;
	float: left;
	z-index: 20;
	background-color:transparent;
}
.main-menu ul > li {
	background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    float: none;
    position: inherit;
}
.main-menu ul > li.dropdown:hover .dropdown-menu{ display: block; }
.main-menu a {
	font-size: 14px;
    font-weight: 400;
    padding:21px 20px;
    display: block;
    color: #fff;
    line-height: 18px;
}
.more-brand {
	float: right;
}
.more-brand > a {
	background:#fbb50d;
	color: #fff;
	padding: 5px 12px 5px 15px;
	margin: 0 -15px -15px 0;
	border-radius: 5px 0px 5px 0;
	font-size: 12px;
	font-weight: 400;
}
.more-brand > a > i {
	font-size: 14px;
	margin-left: 5px;
}
.static-menu {
	margin-left: 15px;
}
.main-menu a:hover {
	text-decoration: none;
	color: #ffffff;
}
.main-menu > ul > li:hover > a {
	background: transparent;
	color: #111111;
}
.main-menu ul > li.top_level.dropdown:hover > a, .main-menu ul > li.top_level:hover > a {
    color: #111111;
}
#menu ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
    display:block;
    padding:0 0 0 10px;
    position:relative;
    top: 0;
	display:block;
	width:100%;
	 margin: 0 !important;
}
.main-menu > ul > li ul > li > a {	
	padding: 8px;
	color: #1f2022;
	display: block;
	white-space: nowrap; 
}
.responsive-menu .main-navigation li a{	
	padding: 5px 10px;
	color: #333333;
	display: block;
	white-space: normal; 
}
.main-menu ul > li.dropdown:hover > a,.main-menu ul > li.dropdown > a:focus{color:#000000;}
.main-menu > ul > li ul > li > .dropdown-inner ul li a{color:#adadad;}
.main-menu ul li ul .list-unstyled a{color: #808080;    font-weight: normal;    padding:8px 0;    text-transform: inherit;line-height: 22px;}
.main-menu > ul > li ul > li > a:hover  , .responsive-menu .main-navigation li a:hover  {
	color:#111111;
	background-color:#fff;
}
#menu ul.nav li .megamenu.column1 ul.list-unstyled > li.dropdown:hover > .dropdown-menu {display: block;}
#menu .nav li .list-unstyled .dropdown {float: left;}
#menu .dropdown .dropdown-menu {
	padding: 15px;
	background: #fff none repeat scroll 0 0;
	border: none;
	width: 100%;
	top: 60px;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
#menu ul li.hiden_menu .dropdown-menu{
	visibility: visible !important;
	display:block;
}
#menu ul.nav li .megamenu ul.list-unstyled li .dropdown-menu {
    border: medium none;
    margin: 0;
    padding: 5px 0;
    position: relative;
}
#menu ul li .megamenu.column1, #menu ul li.hiden_menu .dropdown-menu{
    border: medium none;
    margin: 0 !important;
    padding: 0;
    position: relative;
}
#menu li.hiden_menu .dropdown-menu.megamenu {
    display: none;
}
#menu ul li .megamenu.column1 ul.list-unstyled {
     display: block;
    padding: 0;
    width: 100%;
    border: none;
}
	#menu ul li .megamenu.column1 ul.list-unstyled li, .hiden_menu li.top_level {
		float: left;
		padding: 0;
		width: 100%;
	}
#menu li.hiden_menu:hover .dropdown-menu.megamenu{display:none;}
 /* Webdigify Category CSS */
.catgory-left { width:24%; float:left; padding-right:30px; }
#nav-one .dropdown:hover .dropdown-menu, #nav-one .hiden_menu li.dropdown:hover .dropdown-menu {display: block;}
#nav-one .hiden_menu .dropdown-menu li.dropdown .dropdown-menu { display:none; }   

#nav-one > li > .dropdown-menu {
	background: #fff none repeat scroll 0 0;
	margin-top: 0;
	padding: 20px;
	left: 270px;
	top: 0;
	border-radius: 0;
	box-shadow:0 0 10px rgba(0,0,0,15%);
	border: none;
}

.box-content-category ul .childs_1 li {
	padding: 0 0;
	border: none;
}
.box-content-category ul li {
	line-height: 21px;
	position: relative;
}
.dropdown {
	position: relative;
}
#nav-one > li.hiden_menu > .dropdown-menu{padding:22px;}
.top_level.hiden_menu{position:relative;}
#nav-one li .megamenu ul.childs_1 {
	padding: 0 10px;
}
#nav-one li .megamenu.column1 ul.childs_1 {
	padding: 0;
	display: inline-block;
	width: 100%;
	border: medium none;
}
#nav-one > li > .dropdown-menu.megamenu.column1 a, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown > a, #nav-one .hiden_menu .dropdown-menu a{
	border: none;
	padding: 0px 0 0px 0px;
	font-weight: 400;
	font-size: 13px;
	letter-spacing: inherit;
	color: #666;
	text-transform: inherit;
}
#nav-one > li > .dropdown-menu.megamenu.column1 a:hover,
 #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown > a:hover,
  #nav-one .hiden_menu .dropdown-menu a:hover{
	  color: #111;

}
#nav-one > li > .dropdown-menu.megamenu.column1 li a:after{ display: none; }
#nav-one li ul {
    display: table-cell;
    float: none;
}
#nav-one ul {
    display: inline-block;
    float: none;
    margin: 0 auto;
    text-align: left;
}
#nav-one li .megamenu ul.list-unstyled > li.dropdown > a {
	font-weight: 500;
	margin: 0 0 0 0;
	padding:0px;
	border: none;
	letter-spacing: 0.5px;
	font-size: 14px;
	color: #111111;

}
.quickview-model .product-right .desc {
	font-weight: 500;
    padding: 0 10px 0 0;
    color: #414141;
}
.quickview-model h4.special-price {
    color: #111;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
}
.productpage-quickview .rating-wrapper {margin-bottom: 10px;}
.quickview-model .page-title {display: none;}
.box-content-category ul .childs_1 li img {margin: 10px 0 10px 0;}
#product2 hr { margin: 10px;}
.form-group.cart-block {margin-top: 20px;}
/* Megamenu*/
#nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown:hover > .dropdown-menu{
	display: block;
}
/* more menu */
.dropmenu li:hover ul, .dropmenu li div li:hover div {
	display: block;
}
/* end of more menu */
#nav-one li .megamenu ul.list-unstyled li .dropdown-menu {
	background: #fff none repeat scroll 0 0;
	margin: 5px 0 0 0;
	padding: 0;
	position: relative;
	border: none;
	box-shadow: none;
}
#nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a {
	padding: 0px 0 0px 0px;
	border:none;
	color: #666;
	font-weight: 400;
	font-size: 13px;
	text-transform: inherit;
}
#nav-one li .megamenu ul.list-unstyled li .dropdown-menu ul.list-unstyled li > a:hover{
	color: #111111;
}
.box-content-category .list-unstyled.childs_2 {padding: 0;}
.main-menu > ul > .dropdown .megamenu.column1 ul > li.dropdown:last-child > a {    border-bottom: 1px dashed #d9d9d9;}

#nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown .dropdown-menu {
	left: 100%;
	top: 0;
	position: absolute;
	border-radius: 0;
	padding: 15px 0 15px 15px;
	display: none;
	margin: 0;
	box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
	min-width: 200px;
}
.breadcrumb li:last-child a {
    color: #666;
}
.column_left_cms .box.last .list-group{
    border-bottom: medium none;
	margin-bottom:0;
	padding:4px 0 0;
}
.column_left_cms .box.last{margin-bottom:0;}

/* Webdigify Category CSS */
/* 1-col layout */
.layout-2.left-col #column-left .swiper-slide a img.img-responsive {
	margin: 0 auto;
}
.layout-1 #content{
	padding:15px;
}
.layout-2.left-col #column-left {
	padding: 0 15px 0 0px;
	width: 22%;
}
.layout-2.right-col #column-right {
    width:26%;
    padding: 0;
}
/* product info page */
#tabs_info.product-tab {
    float: left;
    margin: 50px 0 0px;
    padding: 0 0;
    width: 100%;
}
#tabs_info.product-tab ul.nav.nav-tabs {
    padding:10px 0 0px;
    margin: 0px;
	border-bottom: 3px solid #fbb50d;
}
.nav-tabs > li > a {
	color: #111111 ;
	font-weight: 400;
}
/* End of product info page */
#account-return #content{margin:0 0 20px 15px !important;}
#information-information #content,
#account-return #content,
#information-sitemap #content,
#account-account #content,
#account-voucher #content,
#account-wishlist #content,
#account-order #content,
#account-edit #content,
#account-newsletter #content,
#account-password #content,#account-address #content,
#account-recurring #content,#account-reward #content,
#account-transaction #content,#checkout-checkout #content,
#checkout-cart #content ,#account-register #content{
    padding: 20px 20px !important;
    border: 1px solid #eaeaea;
	margin: 0 0 0 15px;
	 width: 73%;
	 border-radius: 5px;
	 -webkit-border-radius: 5px;
	 -moz-border-radius: 5px;
	 -khtml-border-radius: 5px;

}
#information-information #content h3 {
    font-size: 14px;
    font-weight: 600;
}
#information-information .image1,
#information-information .image2,
#information-information .image3,
#information-information .image4 {
    border-right: 1px solid #ededed;
    margin: 0 18px 0 0;
}
#information-information h1, #information-information h2,
#account-return h1,#account-return h2,
#account-wishlist h1,#account-wishlist h2,
#account-order h1,#account-order h2,
#account-edit h1,#account-edit h2,
#account-password h1,#account-password h2 ,
#account-address h1,#account-address h2,
#account-recurring h1,#account-recurring h2,
#account-reward h1,#account-reward h2,
#account-transaction h1,#account-transaction h2,
#checkout-checkout h1,#checkout-checkout h2,
#checkout-cart h1,#checkout-cart h2,
#account-register h1,#account-register h2{
    margin: 0 0 20px 0;
}
#account-account h1,#account-account h2 {
    font-size: 18px;
    line-height: 24px;
}
.layout-2.left-col #content {
	padding: 0 0 0 15px;
	width: 78%;
}
.layout-2.right-col #content {
  padding:0 30px 0 0;
}
.layout-2 .content-bottom #content{width:100%; padding:0; margin-left:auto; margin-right:auto;}
#account-login .well p strong {
    font-weight: 400;
}
td.text-right a.btn.btn-info {
	background: #020202;
	border-color: #020202;
}
td.text-right a.btn.btn-info:hover,
td.text-right a.btn.btn-info:focus,
td.text-right a.btn.btn-info:active {
    background: #fbb50d;
    border-color: #fbb50d;
    color: #fff;
}
#account-register fieldset {
    padding: 20px;
    margin: 0 0 20px;
	 border: 1px solid #eaeaea;
	 border-radius: 5px;
	 -webkit-border-radius: 5px;
	 -moz-border-radius: 5px;
	 -khtml-border-radius: 5px;
}
/* 3-col layout */
.layout-3 #column-left{	width:24%;}
.layout-3 #column-right{width:24%;}
.layout-3 #content{	width:52%;}
/* content */
.layout-2.left-col #content.productpage-quickview {
	float: right;
	padding: 20px;
}
.common-home #content {
	padding:0;
	float: left;
	width: 100%;
}
.checkout-cart #accordion .panel-title > a, .checkout-checkout #accordion .panel-title {
    font-weight: 500;
    padding: 0px 0px;
}
/* footer */
.social-block ul {
    padding: 0px;
}
footer {
	position: relative;
	margin-top: 50px;
	float: left;
	width: 100%;
	overflow: hidden;

}
.common-home footer {
	margin-top: 30px;
}
footer h5 {
	font-size: 18px;
	margin: 0px 0 20px;
	position: relative;
	text-transform: inherit;
	color: #111;
	font-weight: 500;
	line-height: 24px;
}
.footer-blocks .footerleft .contact-block ul {
	 padding: 0;
	 margin: 20px 0 10px;
}
.footer-blocks .footerleft .social-block ul {
	margin: 10px 0 0;
}
.footer-blocks .footerleft .social-block ul li {
	margin: 0px;
	line-height: 20px;
}
.footer-blocks .footerleft li.block-call span {
	color: #fbb50d;
	font-size: 16px;
	font-weight: 600;
	line-height: 28px;
}
.footer-blocks .footerleft .social-block ul li a .fa:hover {
	color: #fbb50d;
}
i.fa.fa-phone {
	display: none;
}
.footer-blocks .footerleft li.block-call {
	margin: 0 0 20px 0;
}
.footer-blocks .footerleft .contact-block span {
   color: black;
	display: inline-block;
	 vertical-align: top;
	 font-size: 13px;
	 line-height: 20px;
	 margin: 0 0 5px;
}
.footer-blocks .footerleft .contact-block li {
    margin: 0px 0px 10px;
	line-height: 24px;
}
.footer-blocks .footerleft .contact-block .fa {
	 margin-right: 15px;
	 color: #777;
    font-size: 16px;
}
#footer .column li a {
	line-height: 30px;
	color: #777;
	font-size: 13px;
    transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}

#footer .column li a:hover{ color: #111111;}
#footer .footer-area li a::before{display: none; }
#footer .footer-area li a{ padding: 0; }
.footer-blocks {
	margin: 0 -15px;
	
}

#footer {
	padding: 60px 0px;
	float: left;
    position: relative;
	 width: 100%;
}

.footer-top {
	background: #fbb50d;
    padding:0px;
    width: 100%;
    float: left;
}
.input-group.date span.input-group-btn button.btn.btn-default {
    border-radius: 5px;
}
/*** Responsive part ***/
#footer .social-footer .block-social #social-follow {
	display: block;
}
#footer .social-footer {
	margin: 0;
	text-align: center;
}
#newslatter {
	float: left;
    padding: 38px 90px;
    width: 100%;
}
#newslatter::after {
	content: '';
	border-right: 1px solid rgba(255,255,255,0.1);
	position: absolute;
	right: 0;
	height: 160%;
	top: 0;
	bottom: 0;
	margin: auto;
}
.block_newsletter h3 span.desc {
	font: 400 13px/24px 'Rubik',Helvetica,sans-serif;;
	margin: 0 0 20px;
	float: left;
	width: 100%;
	line-height: 1.1;
    letter-spacing: 0.6px;
    text-transform: inherit;
}
.block_newsletter {
	padding: 0;
	float: left;
	width: 100%;
	display: flex;
	align-items: center;
}
.block_newsletter h3 .subtitle {
	font: 400 18px/20px 'Rubik',Helvetica,sans-serif;;
	float: left;
	width: 100%;
	margin: 0 0 10px;
}
.block_newsletter h3 .title {
	float: left;
	width: 100%;
	margin: 0 0 0px;
	color: #ffffff;
	text-transform: inherit;
	font: 500 24px/42px 'Rubik',Helvetica,sans-serif;
}
.block_newsletter h3 .title .material-icons {
	font-size: 0;
	height: 40px;
	width: 40px;
	margin: 0 10px 0 0;
	background-image: url(../image/webdigify/news_icon.png);
	background-position:center center ;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: top;
}
.newsletter.col-sm-6 {
	float: right;
}
.footer-appinner ul.list-unstyled {
    padding-top: 5px;
}

.block_newsletter form {
	position: relative;
	width: 100%;
	float: none;
	display: inline-block;
	vertical-align: top;
}
.block_newsletter h3 {
	font: 500 24px/42px 'Rubik',Helvetica,sans-serif;
    color: #111;
    margin: 0;
    display: block;
}
.block_newsletter form input[type="email"] {
	background: #ffffff none repeat scroll 0 0;
	border: none;
	float: left;
	padding: 0 125px 0 15px;
	width: 100%;
	font-size: 13px;
	border-radius: 25px;
	-moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	-khtml-border-radius:25px;
	font-weight: 400;
	height: 44px;
}
.block_newsletter form input[type=email]:focus+button .search {
	color: #111;
}

.block_newsletter form input[type=email]::-webkit-input-placeholder {
	color: #111;
	text-indent: 0.625rem;
}

.block_newsletter form input[type=email]::-moz-placeholder {
	color: #111;
	text-indent: 0.625rem;
}

.block_newsletter form input[type=email]:-moz-placeholder {
	color: #111;
	text-indent: 0.625rem;
}

.block_newsletter form input[type=email]:-ms-input-placeholder {
	color: #111;
	text-indent: 0.625rem;
}

.block_newsletter form button[type=submit] {
	position: absolute;
	background: none;
	border: none;
	bottom: 0.3125rem;
	right: 0.125rem;
	color: #666;
}

.block_newsletter form button[type=submit] .search:hover {
	color: #111111;
}

.block_newsletter p {
	padding: 10px;
	font-size: 13px;
	margin: 5px 0 0;
	float: left;
	width: 100%;
	font-style: italic;
	line-height: 18px;
}
.block_newsletter form .input-wrapper {
	overflow: hidden;
}

.block_newsletter form input {
	height: 42px;
}

.block-contact {
	color: #666666;
}

.block-contact .block-contact-title {
	color: #414141;
}

/* New CSS */

.product-block.product-thumb.transition {
	margin-bottom:0px;
	float: left;
	width: 100%;
	padding: 10px 15px;
	height: auto !important;
}
/*cartbit*/
.special .product-block.product-thumb.transition{ height: 100% !important; }
/*cartbit*/
.hometab .row, .ProductbyCategory .row, .featured .row{
	background: #fff;
}
.manufacture_block {
	float: left;
	padding: 50px 0 50px;
	width: 100%;
	text-align: left;
	border-top: 1px solid #e8e9eb;
}
.manufacturer_title {
	float: left;
	font-size: 16px;
	font-weight: 500;
	margin: 0;
	text-transform: inherit;
}
.manufacture_block ul {
    float: left;
    list-style: outside none none;
    margin: 0;
    padding-left: 15px;
}
.manufacture_block ul li {
    background: url("../image/webdigify/pipe.png") no-repeat scroll right 6px transparent;
    display: inline-block;
    padding: 0 15px 2px 10px;
}
.manufacturer_item a {color: #7a7a7a;}
.manufacture_block ul li:last-child {background: none repeat scroll 0 0 transparent;}
.newsletter label{display:none;}
.newsletter .input-news {
	width: 100%;
	position: relative;
}
.input-news .text-danger { color: #ff0000; position:absolute; left:0px; }
.subscribe-btn {
	width: auto;
	float: right;
	position: relative;
}
.newsletter .form-group{ 
	margin: 0;
    float: left;
    width: 100%;
}
.newsletter #txtemail {
	background-color: #ffffff;
	height: 44px;
	width: 100%;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
	-moz-border-radius: 5px;
	padding:10px 120px 10px 20px;
}
.special-vrstk{
	max-width: 276px;
}
.newsletter .form-control::placeholder {
	color: #111111;
}
.newsletter .btn.btn-lg {
	background: #020202;
    border-radius:0px 5px 5px 0px;
    border: none;
    position: absolute;
    right: 0;
    color: #ffffff;
    padding: 0px 20px;
    top: 0px;
    font-size: 14px;
    height: 44px;
    line-height: 16px;
	 font-weight: 500;
	 letter-spacing: 0.6px;
}
.newsletter .btn.btn-lg:hover{ background-color: #333;color:#fff }
#account-order .col-sm-6.text-right ,#account-transaction .col-sm-6.text-right{clear: both; margin-bottom: 10px; width: auto;}
#contact ul li,.newsletter ul li{line-height:22px;}
#contact li.call-num {margin-top: 15px;}
#product .form-group #input-quantity, .quickview-model #input-quantity {
	width: 60px;
	height: 38px;
	padding: 8px;
	text-align: center;
	margin-right: 10px;
    border-radius:20px;
	-webkit-border-radius:20px;
	-khtml-border-radius:20px;
	-moz-border-radius:20px;
	display: inline-block;
}
.form-group.qty #button-cart:hover,#button-cart2:hover{
	color: #ffffff;
}
.form-group.qty #button-cart,#button-cart2 {
	padding:11px 15px 11px 37px;
	border-radius: 5px;
	color: #ffffff;
	background: #fbb50d;
	font-size: 14px;
	line-height: 20px;
	border-radius: 5px;
	background-image: url(../image/webdigify/cart-hover.svg);
	background-repeat: no-repeat;
	background-position:9px 8px;
	background-size: 22px;
	 position: relative;
	 z-index: 1;
}
.form-group.qty #button-cart:hover:after,#button-cart2:hover:after {
	content: "";
	background-image: url(../image/webdigify/cart-hover.svg);
	background-repeat: no-repeat;
	height: 40px;
	width: 40px;
	position: absolute;
	background-size: 22px;
	background-position: 9px -3px;
	background-repeat: no-repeat;
	z-index: 9;
	left: 0;
}
label.control-label.qty {
    margin: 0px 10px;
}
.form-group.qty #button-cart:hover:before,
#button-cart2:hover:before{
	 height: 100%;
	 background: #111111;
	 color: #fff;
	 border-radius: 5px;
}
.form-group.qty #button-cart:before,
#button-cart2:before {
	content: "";
       position: absolute;
    background: #fbb50d;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    height: 0;
	
}
.box-category-top {
	float: left;
	width: auto;
	position: relative;
	padding: 13px 54px 13px 40px;
	cursor: pointer;
	background: #fbb50d;
	color: #fff;
	margin: 10px 0 0 0;
	border-radius: 5px 5px 0 0;
}
.box-category-top::before {
	position: absolute;
	content: "";
	background-image: url(../image/webdigify/menu.svg);
    background-repeat: no-repeat;
    background-position: center 0px;
    background-size: 20px;
    background-color: transparent;
	 left: 18px;
    width: 20px;
    height: 20px;
    top: 14px;
}
.quickview-container .wish-comp {
	margin-top: 20px;
	float: left;
	width: 100%;
}
.common-home .box-content-category {
	display: block;
}
.common-home .header_bottom.fixed .box-content-category {
	display: none;
}
.box-content-category {
	background-color: #fff;
	display: none;
	position: absolute;
	top: 60px;
	width: 270px;
	z-index: 99999;
	box-shadow: 0 0 8px rgba(0,0,0,8%);
}
#nav-one li.hiden_menu .dropdown-inner {
	display: none;
	margin: 0 !important;
	padding-left: 0;
	padding-right: 0;
}
.box-content-category .hiden_menu span.main {
	padding:15px 20px;
	display: inline-block;
	position: relative;
	cursor: pointer;
	width: 100%;
	color: #111;
	font-weight: 400;
	text-transform: uppercase;
}
.box-content-category .hiden_menu span.main::after {
	content: " ";
	position: absolute;
	right: 25px;
	top: 20px;
	left: auto;
	background: rgba(0, 0, 0, 0) url("../image/webdigify/more.png") no-repeat scroll center 2px;
	width: 10px;
	height: 10px;
}
.box-content-category .hiden_menu span.main.active::after {
	background-position: right -28px;
}
.box-content-category .hiden_menu span.main:hover::after {
	background-position: center 2px;
}
.box-content-category .hiden_menu span.main.active:hover::after {
	background-position: right -28px;
}
.box-content-category .hiden_menu span.main:hover::before {
	background: rgba(0, 0, 0, 0) url("../image/webdigify/myaccountArrow.png") no-repeat scroll center -54px;
}

#block_1 {
	width: 20%;
	float: left;
	position: relative;
	text-align: left;
}
.bottomfooter {
	float: left;
	padding: 23px 0px;
	width: 100%;
	background: #f5f5f5;
}
p.powered {
	float: left;
	margin: 0;
	color: #777;
	padding-top: 5px;
}
p.powered a {
	color: #777;
	text-decoration: underline;
}

.payment-block li img {
    margin: 0px 5px 0px 0px;
	cursor: pointer;
}
.payment-block ul {
    margin: 0px;
}
.footer-logo {
	float: left;
	margin-top: 10px;
}
.content-top-breadcum {
	float: left;
    width: 100%;
    background: #f5f5f5;
    margin: 0 0 20px;
}
.footer-appinner .title {
    font: 600 24px/42px 'Rubik',Helvetica,sans-serif;
    margin:-10px 0 10px;
    color: #fbb50d;
}
.footer-appinner .subtitle {
    font: 400 13px/24px 'Rubik',Helvetica,sans-serif;
    margin: 0 0 15px;
    color: #ffffff;
}
.common-home .content-top-breadcum { display: none; }
/* alert */
.alert {
	padding: 8px 14px 8px 14px;
}
.productpage-quickview .btn-group.prd_page {
	padding: 10px 0 0 0;
}
/* breadcrumb */
.breadcrumb {
	margin: 0 0 20px 0;
	padding: 8px 0;
}
.breadcrumb i {font-size: 15px;    color: #111;}
.breadcrumb > li {
	position: relative;
	white-space: nowrap;
	line-height: 14px;
}
.breadcrumb > li + li:before {
	content: '';
	padding: 0;
	color:#000 !important;
}
.col-xs-11.col-sm-3.alert span a:hover{ color:#111111;}
.dropdown.myaccount ul.dropdown-menu.dropdown-menu-right.myaccount-menu {
    top: 46px;
	margin: 0;
	border-radius: 5px !important;
}
.login_acc li a {
    color: #666;
}
.login_acc li a:hover {
    color: #111;
}
#cart .dropdown-menu li p.text-center {
    color: #666;
	margin: 20px auto;
}
.box .filterbox { border: medium none;}
.panel-footer {
  background-color: transparent !important;
  border-top: medium none !important;
  padding: 0 !important;
}
.product-layout.product-grid .action button.wishlist,
.product-layout.product-grid .action .quickview-button,
.product-layout.product-grid .action button.compare_button {
    display: none;
}
.manufacturer-list .manufacturer-content .col-sm-12 a {
    color: #666;
}
.panel-footer.text-right{text-align: right;}
a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover{background: transparent !important; color: #111111;}
.list-group-item{border: none;padding: 0 !important;}
#column-left .list-group-item.heading {
	width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
}
.list-group a {
	line-height: 30px;
	text-transform: inherit;
}
.fa.fa-pencil {
	margin-right: 5px;
}
#column-left .checkbox > label {
	color: #666;
    text-transform: inherit;
    font-size: 13px;
    line-height: 20px;
}
#column-left .checkbox > label:hover {color: #111111;}
.alert-success{width: 100%;float: right;}
.list-group-item.group-name {
    color: #1f2022;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 0 18px;
}
.filterbox .panel-footer {
    padding: 0 0 15px;
    text-align: left;
	border:medium none;
	background:#fff;
}
/* carousel */
.carousel-caption {color: #FFFFFF;}
.carousel-control .icon-prev:before {
	content: '\f053';
	font-family: FontAwesome;
}
.carousel-control .icon-next:before {
	content: '\f054';
	font-family: FontAwesome;
}
/* product list */



.product-layout.product-grid{   
	 float: none;
    min-height: 415px;
    padding: 0px 8px;
    width: 24.5%;
    display: inline-block;
    vertical-align: top;
}
.product-layout.product-list {
	padding: 10px 0 0;
    margin: 0 0 0px;
}
.product-layout.product-grid .product-block .product-details {
    padding: 10px 0px;
}
.product-list .product-block .product-block-inner{
	border-bottom: 1px solid #eaeaea;
}
#content .product-grid .product-block{ padding:0;}
.row.cat_prod{margin:0 -15px;}
.alert-success .fa.fa-check-circle{margin-right:5px;}
.product-thumb h4 {
	font-size: 13px;
    font-weight: 600;
    margin: 0;
    line-height: 22px;
	/* overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap; */
	color: #0a7ef0;
}
.product-thumb h4:hover {
    color: #111;
}
.product-thumb h4 a {
   color: #111111;
    word-wrap: break-word;
    font:500 13px/24px 'Rubik',Helvetica,sans-serif;
}
.product-thumb h4 a:hover {
    color: #111;
}
.product-list .product-thumb h4 {
	margin-bottom: 2px;
	text-transform: inherit;
	float: left;
	width: 100%;
}
.product-list .description {
	color: #7a7a7a;
	float: left;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 3px;
}
.product-list .product-block:hover .product-block-inner{
	box-shadow: none;
	padding: 0;
	margin: 0;
}
.product-brand {
	color: #9f9f9f;
	margin-bottom: 3px;
	float: left;
	width: 100%;
}
.product-details .rating {
	margin-top: 8px;
	float: left;
	width: 100%;
}
#column-left .product-thumb .price{width:100%;}
#column-left .product-thumb .price-new{float:left;}
#column-left .product-thumb .price-old{float:left;margin-left:8px;}
#column-left .product-block:hover .price {
	opacity: 1;
	-khtml-opacity: 1;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	transform: none;
	-webkit-transform: none;
	-ms-transform:none;
	-o-transform:none;
	-moz-transform:none;
}
#column-left .product-thumb .caption{min-height:0px;}
#special-carousel .product-carousel .product-thumb .caption,#special-carousel .product-carousel-custom .product-thumb .caption{padding:10px;}
.account-wishlist table .text-center img {border: 1px solid #e5e5e5;}
.pagination-wrapper .active > span {
	background: #020202 none repeat scroll 0 0 !important;
	border: 1px solid #020202 !important;
	border-radius:5px;
	color: #fff !important;
	padding: 6px 12px;
}
.product-layout.product-list .image button.wishlist {
    display: none;
}
.product-list .product-details .product_hover_block .action {
	float: none;
}
.product-layout.product-list .quickview-button a.quickbox {
	border-radius: 25px;
}
.product-list .product-details .product_hover_block button.wishlist,
.product-layout.product-list  .product-details .quickview-button {
	float: left;
	margin: 0 5px 0 0;
}
.product-layout.product-list .quickview-button {
    margin: 0 5px 0 0;
}
.product-layout.product-grid .product-details .product_hover_block {
	display: none;
}
.product-layout.product-list .product_hover_block .action button.cart_button {
    margin: 0 5px 0 0;
}

.col-sm-4.product-left {
    padding-left: 0px;
    width: 35%;
}
.col-sm-5.product-right {
    width: 40%;
}
.col-sm-3.product {
	 width: 22%;
	 padding: 0;
}
#column-left .box .caption p.price span.price-new, #column-right .box .caption p.price span.price-new,
#column-left .product-thumb .price, #column-right .product-thumb .price{font-size: 14px;font-weight: 600;line-height: 19px;}
#column-left .box .caption p.price .product-thumb .price-old,#column-right .box .caption p.price .product-thumb .price-old{font-size: 13px;}
#footer .row .product-block{border:none; }
#footer .row .product-block:hover{border:none;}
#carousel-0 .product-block:hover, #carousel-0 .product-block {border: medium none;}
.rating .fa-stack, #review .fa-stack {
	font-size:14px;
	width: 10px;
	color: #d9d9d9;
	height: 13px;
}
.rating .fa-star,#review .fa-star,.rating-wrapper .fa-star{
	color: #ffab00;
    font-size: 13px;
    height: 13px;
}
.rating .fa-star + .fa-star-o,#review .fa-star + .fa-star-o,.rating-wrapper .fa-star + .fa-star-o{color: #ffab00;}
.rating .fa-star.off,.rating-wrapper .fa-star.off, #review table .fa-star.off{color:#d9d9d9;}
h2.price {margin: 0;}
.productpage .nav-tabs > li.active > a {
	color: #000 !important;
	font-size: 20px;
	font-weight: 500;
}

#tabs_info.product-tab li.active a {
    background: #fbb50d;
    border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -khtml-border-radius: 5px 5px 0 0;
	font-weight: 500;
	color: #fff;
}
.nav-tabs > li.active::after {
	border-bottom: 3px solid #f6d000;
	bottom: 0;
}
.nav > li > a:focus, .nav > li > a:hover {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	color: #000;
}
#content #tabs_info .nav.nav-tabs {
	padding: 0;
	border-bottom: 2px solid #f0f0f0;
}
.product-thumb .price {
	color: #111;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 15px;
    float: left;
    width: 100%;
    line-height: 19px;
}
.product-thumb .price .price-new {
    color: #e02b40!important;
}
.product-thumb .list .price {
	font-size: 18px;
	margin: 0 0 20px 0;
}
.product-thumb .list .price-new{font-size:18px;}
.product-thumb .price-old {
	color: #666666;
    text-decoration: line-through;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 5px;
    line-height: 16px;
}
.product-thumb .price-tax {
	color: #808080;
    display: none;
    font-size: 12px;
    width: 100%;
}
.product-list .product-thumb .price-tax{display:block; clear:both; line-height:18px; margin:5px;}
.myaccount-menu.dropdown-menu > li > a{padding:6px 10px !important;margin-right:0; font-size: 12px;}
.product-list .product-block .product-details.list .compare { display: block;}
#product span.customSelect, #product .form-group select {
	border-radius: 5px;
	height: 40px;
	color: #7a7a7a;
	padding: 9px 15px 10px 15px;
	border: 1px solid #e8e9eb;
}

.product-list .product_hover_block {
	position: relative;
	right: 0px;
}
.product-list .product-details .action {
	float: left;
	margin: 0 5px 0 0;
}
.product-list .product-details .action button.cart_button {
	padding: 10px 15px 10px 35px;
}
.header-cart .cart-menu li a{
	margin:0;
	padding:0!important;
	height:auto;
	width:100%;
	float: right;
	text-align: center;
}
.cart-menu .total-table .text-right{ border:none;}
.cart-menu .total-table {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #dddddd currentcolor currentcolor;
  border-image: none;
  border-style: solid none none;
  border-width: 1px medium medium;
}
#column-left .product_hover_block, #column-right .product_hover_block {
	display: none;
}
.thumbnails {
	overflow: auto;
	clear: both;
	list-style: none;
	padding: 0;
	margin: 0;
}
.thumbnails > img {width: 100%;}
.thumbnails .image-additional {float: left;}
.product-info .image {
	margin-bottom: 25px;
}
.product-info .image .thumbnail{ margin: 0;}
.product-info .zoomContainer{ z-index:9; } /* It need for ie7 */
.product-info .additional-carousel {
	position: relative;
	padding:0 30px;
	overflow: hidden;
}
.product-info .image-additional {
	clear: both;
	overflow: hidden;
	width: 100%;
	float:none;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
}
.product-info .image-additional img {
	height: auto;
	max-width: 100%;
}

.product-right .btn-group .wishlist .fa, .product-right .btn-group .compare .fa {
	padding: 0;
	text-align: center;
	margin: 0;
	line-height: 25px;
}
#input-captcha, #input-payment-captcha{margin-bottom:10px}
/* .productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare, .quickview .form-group.qty .wishlist, .quickview .form-group.qty .compare {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding:7px 5px;
    text-transform: inherit;
} */
.productpage .form-group.qty .wishlist:hover, .productpage .form-group.qty .compare:hover,  .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:hover, .product-rightinfo .btn-group.prd_page .btn-default.compare:hover,
.productpage .form-group.qty .wishlist:hover:before,  .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:hover:before ,
.productpage .form-group.qty .compare:hover:before,.product-rightinfo .btn-group.prd_page .btn-default.compare:hover:before {
    color: #111;
}

.productpage #input-quantity, .quickview #input-quantity{
	float: left;
    margin-right: 15px;
    width: auto;
    height: 38px;
    text-align: center;
}
.form-group.qty .control-label{
	float: left;
    margin: 10px 10px 0 0;
}

/* box */  
.box {margin-bottom:0;}
.box.webdigifyblog  .box-heading,.banners-slider-carousel .box-heading {
	float: none;
	padding: 0;
	text-align: left;
	width: 100%;
	font-size: 24px;
	border-radius: 0;
	margin: 0;
	padding: 0 0 15px 0;
}
#column-left .box .box-heading, #column-right .box .box-heading {
	padding: 12px 15px;
    font-size: 16px;
    font-weight: 600;
    background: #f7f7f7;
    position: relative;
    line-height: 20px;
	color: #111;
	border-radius: 5px;
	text-transform: inherit;
}

.service-banner .box-heading{display:none;}
.box-heading .fa.fa-angle-down {
	margin: 0 0 0 2px;
	font-weight: 600;
	display: none;
}
.box-heading {
	font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    padding: 0 0 0 15px;
}
.hometab.box .customNavigation a:hover::before {color:#fff;}
.box.ProductbyCategory .box-content .customNavigation a.prev:hover ,
.box.ProductbyCategory .box-content .customNavigation a.next:hover {
	background: #fbb50d;
}

.box.special {
	position: relative;
	float: left;
	width: 100%;
	margin:30px 0 35px;
}
#content .box .box-heading, .box.related_prd .box-heading,.banners-slider-carousel .box-heading{
	font-size: 24px;
    font-weight: 500;
    position: relative;
    text-transform: inherit;
    line-height: 34px;
    float: left;
    text-align: left;
    color: #111;
    letter-spacing: 0.6px;
	 padding: 8px 15px 8px;
    font-family: 'Poppins',Helvetica,sans-serif;
    background: #f5f5f5;
}
#product-product .box-head .box-heading {
	 width: 100%;
	 margin: 0 0 20px 0;
}
.box.special .box-heading {
    width: 100%;
	margin-bottom: 10px;
	border-bottom: 0px;
}
.product-layout.product-grid .product_hover_block a.quickbox {
	border-radius: 5px;
}
.hometab-heading.box-heading {
	padding: 50px 32px 0 30px;
	border-bottom:none;
	line-height: 30px;
}
.header_top .account {
	float: right;
	width: auto;
	border: none;
	padding: 0;
	margin: 0;
}
#content .box .box-content, .related_prd .box-content{
	border:none;
	padding:0;
	clear: both;
	margin: 0 -15px;
	position: relative;

}
.box.special .customNavigation {
	top: -49px;
}
.row .additional-carousel .product-block:hover {border:none;}
#products-related {
	position: relative;
}
.box .box-content ul , #content .content ul { 
	padding:0px;
	margin:0px;
	list-style:none;
}
.all-blog .panel-default .panel .panel-body h5 {
	text-align: left;
	float: left;
	width: 100%;
	font-size: 18px;
	font-weight: 500;
	margin:0 0 15px 0;
}
.box .box-content ul li , #content .content ul li {
	line-height:22px;
	padding:4px 0;
	border-bottom: 1px dashed #e4e4e4;
}
.box .box-content ul li:last-child, #content .content ul li:last-child { border: medium none;}
.box .box-content ul li a + a , .box .box-content ul li a + a:hover{ background:none; padding-left:0; }
.box .box-content ul ul{margin-left:0px;}
.product-grid li,#content .box-product .product-items {
	float:left;
	position:relative;
}
#content .image-additional .slider-item .product-block{	
	margin:0 6px; 
	display: inline-block;
}
.slider-item {
	width: 130px;
	float: none;
	display: inline-block;
	vertical-align: top;
}
.product-block:hover .product-block-inner{
	z-index: 9;
}
.banners-slider-carousel .product-block:hover .product-block-inner{ box-shadow: none; }
.product-block-inner {
	position: relative;
	background: #fff;
	float: left;
	width: 100%;
}
.product-layout .product-block-inner{ margin: 0; }
.grid_default_width { width:225px;}
.module_default_width{ width:220px;}
.latest_default_width{ width:225px;}
.special_default_width {width:235px;}
.wdproductcategory {
    width: 240px;
}
.related_default_width{ width:240px;}
.bestseller_default_width {
	width: 240px;
}
.tabspecial_default_width{width:240px;}
.tabbestseller_default_width{width:240px;}
.additional_default_width{ width:100px;}
#content .banners-slider-carousel .product-carousel .product-block-inner,#content .banners-slider-carousel .product-carousel-custom .product-block-inner,#content .banners-slider-carousel .product-grid .product-block-inner{text-align:center;}
.product_hover_block {
	position: absolute;
	right: -10px;
	bottom: 0;
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	-webkit-opacity: 0;
	transition: all 300ms ease-in-out;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	z-index: 1;
	height: 130px;
	top: 0;
	margin: auto;
}
.product-block:hover .product_hover_block{
	opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
	 right: 10px;
}

.product_hover_block .action {
	float: left;
	width: 100%;
}
.product_hover_block button, .quickbox{
	background-position: center center;
	background-size: 16px;
	background-color: #111111;
	float: left;
	margin: 0;
	color: #333;
	border: none;
	position: relative;
	border-radius: 5px;
	text-align: center;
	height: 40px;
	width: 40px;
	z-index: 1;
	overflow: hidden;
}
.product_hover_block .action button.cart_button{ 
	background-position: 0px;
    font-size: 12px;
    font-weight: 600;
    display: block;
	padding:8px 12px;
	margin: 0 3px 0 0;
    width: auto;
    background: #fbb50d;
    border-radius: 50px;
    letter-spacing: 0.6px; 
	z-index: 9;
	transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}
.product_hover_block  button.wishlist{ 
    display: block;
    float: none;
    margin: 0 0 5px;
    background-image: url(../image/webdigify/wishlist.svg);
    background-repeat: no-repeat;
}
.product_hover_block  button.compare_button{ 
	background-image: url(../image/webdigify/compare.svg);
	background-repeat: no-repeat;
	 float: none;
    display: block;
 }
 .img-wrap {
	overflow: hidden;
	position: relative;
	float: left;
	width: 100%;
}

 .product-block:hover .product_hover_block  button.compare_button{
	 opacity: 1;
 }

.quickview-model .alert.alert-info {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}
.quickview-button {
	display: block;
	float: none;
}
.quickbox {
	display: inline-block;
	margin: 0 0 5px;
	background-image: url(../image/webdigify/quick-view.svg);
	background-repeat: no-repeat;
}
#productcategory-carousel .product_hover_block {
	left: 15px;
}
.quickbox .fa.fa-eye {
	font-size: 15px;
}
.product_hover_block button .fa {
	font-size: 14px;
}
.product-block .product-details {
	padding: 10px 0px;
    position: relative;
    float: left;
    width: 100%;
    text-align: left;
    height: 100%;
}
.product-grid .description {
	display: none;
}
.sale {
	color: #111111;
    display: block;
    font-size: 12px;
    left: 10px;
    line-height: 18px;
    position: absolute;
    right: auto;
    top: 15px !important;
    opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;

}
.product-block .product-block-inner .image span.sale:hover {
    opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    transition: 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    left: -90px;
}
.product-block .product-details:hover .action button.cart_button:after {
	content: "";
	background-image: url(../image/webdigify/cart-hover.svg);
	background-repeat: no-repeat;
	height: 40px;
	width:40px;
	position: absolute;
	background-size: 20px;
	background-position:10px 8px;
	background-repeat: no-repeat;
	z-index: 9;
	left: 0;
	top: 0;
}

.action button.cart_button {
	font-size: 14px;
	position: relative;
	font-weight: 500;
	display: inline-block;
	vertical-align: top;
	width: auto;
	padding: 9px 17px 9px 35px;
	text-transform: inherit;
	line-height: 20px;
	height: auto;
	border: none;
	border-radius: 25px;
    -webkit-border-radius: 25px;
    -khtml-border-radius: 25px;
    -moz-border-radius: 25px;
	color: #ffffff;
	background-color: #fbb50d;
	background-image: url(../image/webdigify/cart-hover.svg);
	background-size: 20px;
	background-position: 10px 8px;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: hidden;
	transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
}
.product-details:hover .action button.cart_button{
	color: #fff;
}
.product_hover_block button.wishlist:hover:after{
    background-image: url(../image/webdigify/wishlist.svg);
	 content: "";
	 height: 40px;
	 width:40px;
	 position: absolute;
	 border: red;
	 background-position: center;
	 top: 0;
	 background-repeat: no-repeat;
	 background-size: 16px;
	 z-index: 9;
	 left: 0;
}
.product_hover_block .quickbox:hover:after {
	background-image: url(../image/webdigify/quick-view.svg);
	content: "";
	height: 40px;
	width:40px;
	position: absolute;
	border: red;
	background-position: center;
	top: 0;
	background-repeat: no-repeat;
	background-size: 16px;
	z-index: 9;
	left: 0;
}
.product_hover_block button.compare_button:hover:after{
	background-image: url(../image/webdigify/compare.svg);
	content: "";
	height: 40px;
	width:40px;
	position: absolute;
	background-position: center;
	top: 0;
	background-repeat: no-repeat;
	background-size: 16px;
	z-index: 9;
	left: 0;
}
.product_hover_block button.wishlist:hover:before,
.product_hover_block .quickbox:hover:before ,
.product_hover_block button.compare_button:hover:before{
	height: 100%;
	
}
.product_hover_block button.wishlist:before ,
.product_hover_block .quickbox:before,
.product_hover_block button.compare_button:before{
	content: "";
	position: absolute;
	background: #fbb50d;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	height: 0;
	border-radius: 5px;
}
.product-details .action button.cart_button:before{
	content: "";
	position: absolute;
	background: #fbb50d;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	transition: all 400ms ease-in-out;
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-ms-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	height: 0;
	border-radius: 5px;
}
.product-details:hover .action button.cart_button:before{
	height: 100%;
	background: #020202;
	color: #fff;
	
}
.product-layout.product-grid .product-block-inner {
	margin: 0 0 20px 0;
}
#content .banners-slider-carousel .product-block {  cursor: pointer;  }
/* #content .banners-slider-carousel .product-block .product-block-inner { padding: 15px 0 !important; } */
.product-layout.product-list .img-wrap {
	float: left;
	width: 25%;
}
.product-layout.product-list .img-wrap .product_hover_block {
	display: none;
}
.product-list .product-details {
	width:75%;
	padding: 20px 0 15px 15px;
}
#content .banners-slider-carousel .product-block .product-block-inner a .img-responsive {
    display: inline-block;
}

.banners-slider-carousel .product-block .product-block-inner img{
	opacity: 0.8;
    filter: alpha(opacity=80);
}
.banners-slider-carousel .product-block:hover .product-block-inner img{
	opacity: 1;
    filter: alpha(opacity=100);
    animation: 3s ease-in 1s 2 reverse both paused bounceIn;
    animation: 3s linear 1s bounceIn;
    animation: bounceIn 1s;
}


@-webkit-keyframes slideDashXopp {
  from { -webkit-transform: translateX( 0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% );  }
  to   { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% );}
}


@-moz-keyframes slideDashXopp {
  from { -webkit-transform: translateX( 0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% );  }
  to   { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% );}
}

@keyframes slideDashXopp {
   from { -webkit-transform: translateX( 0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% );  }
  to   { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% );}
}
			
@-webkit-keyframes slideDash {
  from { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% ); }
  to   { -webkit-transform: translateX( 0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% ); }
}


@-moz-keyframes slideDash {
  from { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% ); }
  to   { -webkit-transform: translateX(   0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% ); }
}

@keyframes slideDash {
  from { -webkit-transform: translateX( -50% ); transform: translateX( -50% ); -moz-transform: translateX( -50% ); -ms-transform: translateX( -50% ); -o-transform: translateX( -50% ); }
  to   { -webkit-transform: translateX(   0% ); transform: translateX( 0% ); -moz-transform: translateX( 0% ); -ms-transform: translateX( 0% ); -o-transform: translateX( 0% ); }
}


@-webkit-keyframes slideDashY {
  from { -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% ); }
  to   { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
}


@-moz-keyframes slideDashY {
  from { -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% ); }
  to   { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
}

@keyframes slideDashY {
  from { -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% ); }
  to   { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
}


@-webkit-keyframes slideDashYopp {
  from { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
  to   {  -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% );}
}


@-moz-keyframes slideDashYopp {
  from { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
  to   {  -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% );}
}

@keyframes slideDashYopp {
  from { -webkit-transform: translateY( 0% ); transform: translateY( 0% ); -moz-transform: translateY( 0% ); -ms-transform: translateY( 0% ); -o-transform: translateY( 0% ); }
  to   {  -webkit-transform: translateY( -50% ); transform: translateY( -50% ); -moz-transform: translateY( -50% ); -ms-transform: translateY( -50% ); -o-transform: translateY( -50% );}
}


.image_content {
	position: absolute;
	height: 100%;
	width: 100%;
}
.product-info .product-image .customNavigation span.prev:hover::before, .product-info .product-image .customNavigation span.next:hover::before {
  background: #0a7ef0  none repeat scroll 0 0;
  border: 1px solid #0a7ef0;
  color: #ffffff;
}
#column-left .swiper-pagination.swiper-pagination-bullets {
  display: none;
}
#content .product-list .product-block .image { 
	float: left;
	width: auto;
} 
.banners-slider-carousel {
	margin:35px 0 50px;
	width: 100%;
	float: left;
}
.banners-slider-carousel .product-carousel .slider-wrapper-outer,.banners-slider-carousel .product-carousel-custom .slider-wrapper-outer{ padding: 0; }
#column-left .box-product,
#column-right .box-product {
	width: 100%;
	overflow: hidden;
	 padding: 0px 5px;
}
#column-left .box-product .product-block-inner, #column-right .box-product .product-block-inner {
	margin: 0;
	padding: 0;
	box-shadow: none;
	width:auto;
	border: none;
}
.product-layout.product-list .product_hover_block {
	right: 0px;
	opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
}
.product-layout.product-list  .product_hover_block button {
	border-radius: 25px;
}
#column-left .box-product > div,
#column-right .box-product > div  {
	display:block;
	margin-right:0px;
	margin-top: 0px;
	margin-bottom:0;	
	width:100%;
}
#column-left .box .box-content div.product-items:last-child > div, #column-right .box .box-content div.product-items:last-child > div,
#column-left .box .box-content div.slider-item:last-child > div, #column-right .box .box-content div.slider-item:last-child > div {
    border: 0 none;
}
#column-left .box-product .image, #column-right .box-product .image {
	display: block;
	margin-bottom: 10px;
	float: left;
	margin-right: 0px;
	margin-top: 0px;
	border: none;
	width: 25%;
}
#column-left .product-block:hover , #column-right .product-block:hover{box-shadow:none;
-moz-box-shadow: none;
-webkit-box-shadow: none;
}
#column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
	float: right;
    width: 70%;
    overflow: hidden;
    padding: 0;
}
#column-left .product-thumb .caption,
#column-right .product-thumb .caption{
	padding:0;
	border:none;
	float: left;
	width:100%;
	margin-top:0px;
}
#column-left .special .product-thumb .caption,
#column-right .special .product-thumb .caption{width:100%;}
.content_headercms_bottom .tags > a:last-child::after {border-right: medium none;}
#column-left .product-thumb .price, #column-right .product-thumb .price{ text-align:left;}
#column-left .box-product .name,
#column-right .box-product .name {display: block;}
#column-left .product-thumb, #column-right .product-thumb {
	border-bottom: 1px solid #eaeaea;
    margin-bottom: 0px;
    padding: 20px 0 5px;
    float: left;
    width: 100%;
}
#column-left .price-tax, #column-left .wishlist , #column-left .compare,
#column-right .price-tax, #column-right .wishlist , #column-right .compare {display:none !important;}
#column-left .container,#column-right .container{width:auto}

#column-left .box, #column-right .box {
	margin-bottom: 20px;
    position: relative;
    border: 1px solid #eaeaea;
    padding:5px 5px;
	 width: 100%;
	 border-radius: 5px;	
}
.top_button:hover{
	background-color: #020202;
}
.top_button {
	bottom: 10%;
    cursor: pointer;
    height: 45px;
    padding: 0px 12px;
    position: fixed;
    right: 8.5%;
    text-align: center;
    width: 45px;
    z-index: 99;
    font-size: 0px;
	border: 2px solid #ffffff;
    border-radius: 50%;
    background: #fbb50d;
    -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
    animation-name: hvr-icon-bob-float, hvr-icon-bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
	transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
}
.top_button:hover:before{
	color: #fff;
}
.top_button::before {
	color: #ffffff;
    content: "\f106";
    font-family: "FontAwesome";
    font-size: 22px;
    line-height: 37px;
    font-weight: 300;
	transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
}
.top_button:after {
    
    border-radius: 50%;
    bottom: -5px;
    height: 50px;
    opacity: 1;
    right: -5px;
    content: "";
    position: absolute;
    width: 50px;
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
#content ul.list-unstyled {  
    margin-bottom: 10px;
    overflow: auto;
    padding: 0;
}
#content ul.list-unstyled li {
    line-height: 20px;
    padding: 4px 0;
	font-size: 14px;
}
#content ul.list-unstyled li a:hover {
    color: #111;
}
.account-address .btn-info:hover {
    background: #fbb50d;
    border-color: #fbb50d;
    color: #111;
}
#content ul.list-unstyled li a {
    color: #666;
}
.manufacturer-list {
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
	 padding: 5px;
	 border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
}
.manufacturer-heading {
    background: none repeat scroll 0 0 #f8f8f8;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 6px;
	 padding: 5px 8px;
	 border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
}
.brand-name {
	float: left;
	border: 1px solid #e8e9eb;
	margin: 10px 18px;
}
.brand-name:hover {
    border-color: #111;
}
.brand-name a .img-responsive {
    width: 123px;
	height: 50px;
	opacity: 0.8;
    filter: alpha(opacity=80);
}
.brand-name a .img-responsive:hover{
	opacity: 1;
    filter: alpha(opacity=100);
}
.main-menu .brand-name a {
	padding: 0;
}
.main-menu .brand-name a span {
	width: 100%;
    color: #111;
    float: left;
    background: #fafafa;
    text-align: center;
    padding: 5px 14px 6px;
	 border-top: 1px solid #eaeaea;
	 font-weight: 500;
}
.manufacturer-content-home {
	float: left;
	width: 100%;
}
.manufacturer-content {padding: 8px;}
.manufacturer-list ul {
    float: left;
    list-style: outside none none;
    margin: 0 0 10px;
    padding: 0;
    width: 25%;
}
.productpage .box {margin-top:20px;}
.col-sm-4.total_amount { margin-top: 70px;}
.product-tag{margin:5px 0;}
.aboutus {
    clear: both;
	margin-bottom:60px;
}
#content h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #111;
}
.image1 {
    background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -165px;
    float: left;
    height: 50px;
    width: 60px;
}
.image2 {
    background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -116px;
    float: left;
    height: 50px;
    width: 60px;
}
.image3 {
    background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px -52px;
    float: left;
    height: 50px;
    width: 60px;
}
.image4 {
    background: url("../image/webdigify/cms-sprite.png") no-repeat scroll 7px 6px;
    float: left;
    height: 50px;
    width: 60px;
}
.aboutus h2{clear:both;font-size:20px;}
.about-content{overflow:hidden;}
.information-information .right{float:right;}
.category_filter #grid-view,.category_filter #list-view{
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-khtml-border-radius: 0;
	border:none;
	padding:6px 12px;
  box-shadow: none;
}
#column-left .swiper-viewport {
  border: medium none;
  box-shadow: none;
  margin: 0 0 30px;
  border-radius: 5px;
}
.category_filter #grid-view{
	background:url("../image/webdigify/sprite.png") no-repeat scroll -4px -165px !important;
	width:25px;
	height:25px;

}
.category_filter #list-view{
	background:url("../image/webdigify/sprite.png") no-repeat scroll  -37px -165px  !important;
	width:25px;
	height:25px;
}
.category_filter #list-view:hover, .category_filter #list-view.active {
  background-position: -38px -112px !important;
}
.category_filter #grid-view:hover, .category_filter #grid-view.active{background-position: -7px -113px !important;}
.category_filter #grid-view .fa, .category_filter #list-view .fa{display:none}
#list-view{margin-left:2px}
.category_filter #grid-view:hover .fa,.category_filter #list-view:hover .fa{color:#fff;}
.category_filter .btn-list-grid {
	float: left;
	width: auto;
	padding:0px;
}
.compare-total { float: left; margin: 7px 20px 0; }
.pagination-right { float: right;margin:0; width: auto;}
.category_filter .sort-by {float: left;margin: 7px 10px 0 0; width: auto;padding:0;}
.category_filter .show {float: left; margin: 7px 10px 0;padding:0; width:auto;}
.category_filter .sort { float: left; width: 150px;padding:0;}
.category_filter .limit {float: right; width: 100px;padding:0;}
.sort-by-wrapper,.show-wrapper{
    float: left;
	margin-right:15px;
}
.show-wrapper {margin: 0;}
.category_thumb .category_description {
	float: left;
	width: 100%;
	margin-bottom: 20px;
	color: #666;
}
.row.category_thumb {
    margin: 0 -15px;
    padding: 0;
}
.category_thumb .category_img, .category_thumb .category_description {
    float: left;
    width: 100%;
}
.category_img img{margin-bottom:10px; border:none; max-width:100%; border-radius: 5px;}
.category_list ul {
    padding: 0;
	display:inline-block;
	margin: 7px 0 0;
}
.category_above{
	overflow: hidden;
	max-height: 147px;
}
.category_above:after {
	content: "";
	text-align: right;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: 110px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
	pointer-events: none;
}
.showallbtn{
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 10px;
	border: none;
	background-color: inherit;
}
.showallbtnarrow{
	background-image: url(/catalog/view/theme/default/stylesheet/ocfilter/arrow-down-svgrepo-com.svg);
	background-size: 20px;
	padding: 0px 10px 0px 10px;
	border: none;
	background-color: inherit;
	margin-top: 1px;
	height: 15px;
}
.showallbuttons{
	display: flex;
	justify-content: center;
}
/* .cat_and_btns{
	
} */
#content .refine-search{margin-bottom:5px; margin-top:5px; text-transform:inherit; }
.refine-search ul{padding:0;}
.refine-search ul li{list-style:none;}
.category_list li a {
	display: block;
    padding: 8px 20px;
    border: 1px solid #eaeaea;
	 color: #666;
	 border-radius: 5px;
}
.compare-total #compare-total {
    color: #666;
}
.category_list li a:hover {
	color: #111111;
    border-color: #111111;
}
.category_list li {
    float: left;
    list-style: outside none none;
    margin: 5px 10px 0px 0;
}
.pagination-wrapper {
	border: 1px solid #eaeaea;
    display: inline-block;
    margin: 20px 0 0;
    padding: 10px;
    width: 100%;
	 background: none;
	 border-radius: 5px;
}
.pagination > li:last-child {margin: 0;}
.category_filter {
	display: inline-block;
    padding:10px 10px;
    width: 100%;
    margin-top: 14px;
	margin-bottom: 13px;
	 border: 1px solid #eaeaea;
	 border-radius: 5px;
}
.pagination-wrapper .page-link {
    float: right;
    padding: 0;
    width: auto;
}
.pagination-wrapper .page-result{
    float: left;
    padding: 6px 0;
    width: auto;
}
.information-contact #content {
	width: 100%;
	padding: 0;
	background: #fff;
	margin-bottom: -20px;
}
.left .address-detail {
	border-bottom: 1px solid #e8e9eb;
	padding-bottom: 20px;
	margin-bottom: 15px;
}
.address-detail1 a.btn.btn-info:hover {
	background: #020202;
	border: 1px solid #020202;
    color: #ffffff;
}
.left strong {
	font-size: 13px;
	text-transform: inherit;
	padding-bottom: 5px;
	display: block;
	color: #111111;
}
.left .btn.btn-info {
	margin-bottom: 20px;
	border-radius: 30px;
	color: #fff;
    background: #fbb50d;
	 border-color: #fbb50d;
	 font-weight: 500;
}
.row.contact-info{margin: 0;}
.information-contact .panel-body{padding:0}
.information-contact .panel {
    border: medium none;
    box-shadow: none;
    margin: 0 0 40px;
}
.map_button {
	clear: both;
	width: 40px;
	height: 40px;
	position: absolute;
	bottom: -20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: #f6d000;
	padding: 12px 15px;
	cursor: pointer;
}
.map_button > i {
	font-size: 18px;
}
.row.site-map ul a {
    color: #666;
}
.row.site-map ul a:hover {
    color: #111;
}
.contact-form-design .left {
	float: left;
	width: 28%;
	margin-right: 30px;
	padding:20px 20px;
	color: #666666;
	position: relative;
	z-index: 9;
	border: 1px solid #eaeaea;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
.contact-form fieldset {
    border: 1px solid #eaeaea;
	 padding: 20px;
	 border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
.contact-form .pull-right {
    padding: 30px 0px 0 0;
}
.contact-form-design.container {
	padding: 30px 0 70px 0;
	background: #fff;
	margin: 30px auto;
	position: relative;
}

.checkout-cart .alert.alert-danger {
	float: left;
	width: 100%;
	margin-top: 20px;
}
.address-detail .btn {
	clear: both;
	float: left;
	margin: 15px 0 0 37px;
	color: #000;
}

#spinner {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	background: url("../image/webdigify/ajax-loader.gif") 50% 50% no-repeat #fff;
}
.main-slider {
	position: relative;
	float: left;
	width: 100%;
}
.productpage .write-review:hover, .productpage .review-count:hover {
    color: #111;
}
.main-slider img { 
	border:none;
	width: 100%;
    height: auto;
} 
.information-sitemap .sitge-map ul{padding:0 0 0 20px;}
.forget-password {margin: 5px 0 0;}
.product-compare .btn-primary{margin:5px 0 5px;}
.productpage .write-review,.productpage .review-count {margin: 0 10px; text-transform: inherit;    color: #666;}
.productpage .rating-wrapper, .quickview .rating-wrapper { margin: 15px 0 15px;}
.productpage .productpage_details {  float: left;    margin-top:0px;    width: 100%;}
.productpage #tabs_info {
    background: #ffffff none repeat scroll 0 0;
    margin: 30px 0;
	padding:30px;
}
.productpage .productpage_details .product-left {
    padding: 0;
    width: 445px;
}
.productpage .productpage_details .product-right {
    margin-left: 30px;
    width: 45%;
}
#content .productpage_details #column-right {
    float: right;
    margin: 0;
    padding: 0;
    width: 238px;
}
.productpage #banner0.owl-carousel {
    overflow: visible;
}
.productpage #banner0 .owl-pagination{bottom:-30px;}
.productpage #banner0 .owl-page{border: 2px solid #000000;}
.productpage #banner0 .owl-page > span{
    background: #ffffff none repeat scroll 0 0;
    border: 2px solid #ffffff;
	 border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 10px;
    position: relative;
    text-indent: -9999px;
    width: 10px;
}
/* Product tab*/
.content_product_block {
	margin: 20px 0;
	float: right;
	width: 100%;
}
.product-detail-left hr {
    margin: 0px;
}
.addthis_toolbox.addthis_default_style {
    float: left;
}
#custom_tab.product-tabs {
	overflow: hidden;
}
#custom_tab > ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}
#custom_tab li {
	float: left;
	margin: 0;
}
#custom_tab.product-tabs a.selected {
	color: #111;
	font-weight: 500;
	background: #e8e9eb;
}
#custom_tab.product-tabs a {
	background: none repeat scroll 0 0;
	color: #7a7a7a;
	float: left;
	padding: 10px 15px;
	text-transform: inherit;
	margin-right: -1px;
}
#custom_tab.product-tabs a:first-child {
	padding: 10px 15px 10px 15px;
}
#custom_tab ul li a {
	color: #aaaaaa;
	float: left;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	padding: 6px 16px;
	transition: none;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
}
.content_product_block .tab_product {
	border: 3px solid #e8e9eb;
	padding: 20px;
	font-size: 13px;
	position: relative;
}
.productpage .tab_product b, .productpage .tab_product strong {
	font-weight: 500;
	color: #000;
	font-size: 13px;
}
.size_guide_table {
	border: 1px solid #f0f0f0;
	width: 100%;
}
.size_guide_table tbody th {
	background: #f0f0f0 none repeat scroll 0 0;
	border-bottom: 1px solid #f0f0f0;
}
.size_guide_table tbody th {
	color: #262626;
	font-weight: bold;
	padding: 7px 8px;
	vertical-align: middle;
	white-space: nowrap;
}
.size_guide_table tbody td {
	padding: 5px 8px;
}
/* end product tab*/
.col-sm-8.product-left {
	padding: 0;
	margin: 0;
	width: 40%;
	position: sticky;
	top: 80px;
}
.col-sm-4.product-right { padding: 0 0 0 40px; width: 60%;}
.quickview .review-count , .quickview .write-review { display:none; }
#content.productpage ul.list-unstyled{border-bottom:medium none;}
#content ul.list-unstyled.attr span{
	font-weight: 600;
	color:#333;
	font-size:14px;
}

.common-home .content-top-breadcum .container {display: none;}
#content ul.list-unstyled.attr{
	padding-bottom:15px;
    border-top: 1px solid #e0e0e0;
	border-bottom:1px solid #e0e0e0;
    padding-top: 15px;
	margin-bottom:20px;
}
#content ul.list-unstyled.attr li{clear:both}
.productpage .product-right ul.price,.productpage .product-right ul.price{padding-bottom:10px}
#content ul.list-unstyled.price li{float:left; padding:2px 0; font-size:13px; color:#000; }
.productpage .product-right ul li span.old-price, .productpage .product-right ul li span.special-price{ margin-left:10px; color: #666666; }
.productpage .product-right ul li.discount,.productpage .product-right ul li.rewardpoint,.productpage .product-right ul li.price-tax {clear: both;}
.quickview .page-title{display:none;}
.refine-search,.product-title,.product-option{
	font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #333333;
}
.copy-right {
    border-top: 1px solid #e5e5e5;
    padding: 10px 0;
	text-align:center;
}
.category_description > p { margin: 0;}
#bottom-footer li{display:inline; padding:1px 7px 1px 2px; background:url("../image/webdigify/pipe.gif") no-repeat scroll right center transparent;}
#bottom-footer li.contact{background:none;}
.account-address .table>tbody>tr>td{vertical-align:middle; padding:15px;}
.account-address .btn-info{margin:2px 0;}
.affiliate-account .btn-primary .list-group-item,.affiliate-account .btn-primary .list-group-item:hover{padding:0; background:none; color:#fff;}
.shopping-cart .img-thumbnail{width:auto;}
.shopping-cart .input-group .form-control {
    width: auto;
    height: 40px;
    text-align: center;
    border-radius: 5px !important;
}
#accordion label.col-sm-2.control-label {
	margin: 7px 0 0;
	padding: 0 13px 0 0;
}
#accordion #collapse-shipping label.col-sm-2.control-label{padding:0 15px;}
.checkout-checkout #accordion label.col-sm-2.control-label{padding-left:15px;}
.checkout-cart .input-group-btn:last-child > .btn-group{ font-size:12px;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-khtml-border-radius: 0;
}
.col-sm-3.search_subcategory {width:100%; margin-top:7px;}
#button-search {
	float: right;
}
.box .box-content .megamenu ul li ul li {padding: 0;}
#column-left .treeview-list .expandable .collapsable-hitarea::before, #column-left .treeview-list .expandable-hitarea::before,
#column-left .treeview-list .collapsable .expandable-hitarea::before, #column-left .treeview-list .collapsable-hitarea::before{position:relative;top:6px;}
.box .box-content ul , #content .content ul { 
	padding:0;
	margin:0px;
	list-style:none;
}
.box .box-content ul li{
	line-height:23px;
	padding:0px 25px;
	border-bottom:none	
}
.box .box-content ul ul{margin-left:10px;}
#column-left .box .box-content ul li, #column-right .box .box-content ul li {padding: 0;}
#column-left .box .box-content ul li a, #column-right .box .box-content ul li a {padding: 6px 0; color: #7a7a7a;}
.box .box-content ul li:last-child a{border: medium none;}
.box .box-content ul li ul li{
	padding:0 25px;
	border-bottom:none;
}
.panel-footer.text-right button#button-filter{
	color: #111;
	background: none;
	padding: 8px 25px 0;
}
.panel-footer.text-right button#button-filter:hover {
    text-decoration: underline;
}
#column-left .list-group, #column-right .list-group{
	padding:10px 15px;
	margin: 0px;
}

.box .box-content .box-category {
	padding: 15px 0;
}
#content .content ul li {
	line-height:22px;
	padding:5px;
}
.box .box-content ul li ul li + li{border-top:medium none;}
.box .box-content ul li a, #content .content ul li a {
	display: inline-block;
	text-transform: inherit;
}
.box .box-content ul li a + a , .box .box-content ul li a + a:hover{ background:none; padding-left:0; }
.box .box-content ul li a:hover, #content .content ul li a:hover {
	color: #111111 !important;
}
.box .box-content ul ul{  
	z-index:99;
	width:auto;
}
#column-left .treeview .hitarea.expandable-hitarea {
	color: #808080 !important;
}
#column-left .treeview .hitarea.expandable-hitarea {
	color: #808080 !important;
}

#column-right .single-banner{margin:0;}
.box .box-content ul ul li ul {padding:0;}
.box .box-content ul ul li:hover ul {display: block;}
.navbar-nav > ul > li > a:hover .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}
.box-category-heading {
    color: #fff;
    cursor: pointer;
    float: left;
    font-size: 14px;
    font-weight:600;
    letter-spacing: 0.5px;
    overflow: hidden;
    padding:12px 0px;
    position: relative;
    text-transform:uppercase;
    width: 200px;
	line-height:16px;
	display:none;
}
.box-category-heading .fa {
    float:left;
    font-size: 17px;
    font-weight: 600;
	 margin-right: 10px;
}
/* Tab cms */
.hometab, .ProductbyCategory, .featured {
	width: 100%;
	padding:35px 0 20px;
	float: left;
}
.box.webdigifyblog {
    margin-bottom: 40px;
}
.etabs {
	margin: 0;
	padding: 0 60px 0 0px;
	float: right;
}
.htabs .etabs li:last-child{background:none;display: none;}
.htabs a{
	display: inline-block;
	float: none;
	cursor: pointer;
	text-transform: inherit;
	width: 100%;
	border: 1px solid transparent;
	padding: 11px 20px;
	color: #111;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	margin: 8px 0 0;
	font-family: 'Poppins',Helvetica,sans-serif;
}
.etabs li.tab {
    display: inline-block;
}
.htabs a.selected, .htabs li.active a{
	position: relative;
    color: #fff;
    background: #fbb50d;
    border-radius: 5px 5px 0 0;
	
}

#tabs_info.product-tab .tab-content {
	margin-bottom: 15px;
	padding: 20px;
    float: left;
    border: 1px solid #eaeaea;
    border-top: 0px;
    width: 100%;
}
.nav-tabs > li {border:none !important;}
.tab-content .tab { display: none;}
.box .box-content ul li:last-child ul li a {border-bottom: 1px dashed #e5e5e5;}
.box .box-content ul li:last-child ul li:last-child a {border: medium none;}

.product-column {
	float: left;
	width: 100%;
}
.tab-head {
    width: 100%;
    float: left;
	 background: #f5f5f5;
    padding: 0 15px;
	margin-bottom: 20px;
}

/*Right banner*/
.collection-right-banner {
	float: right;
	width: 22%;
	position: relative;
	text-align: center;
}
.collection-right-banner .image-title {
	position: absolute;
	top: 54px;
	width: 100%;
}
.collection-right-banner .image-button {
	position: absolute;
	bottom: 60px;
	width: 100%;
}
#content .collection-right-banner .image-title > h3 {
	font-size: 34px;
	text-transform: inherit;
	font-weight: 700;
	margin: 0;
	line-height: 36px;
	padding: 0 10px;
}
.banner-btn {
	padding: 14px 45px;
	background:#f6d001;
	border-radius: 30px;
	font-size: 16px;
	font-weight: 500;
}
.banner-btn:hover{ background:#333; color: #fff; }

/*Right banner*/

.tab-head .sub-heading {
	float: left;
	margin: 10px 10px 0 30px;
	color: #7a7a7a;
	line-height: 24px;
}
.tab-head .coupon {
	float: left;
	margin-top: 40px;
	color: #0a7ef0;
	border: 2px dashed #f6d000;
	padding: 6px 20px;
	font-weight: 500;
}
.btn-danger:hover {
  background-color: #c9302c;
  border-color: #ac2925;
  color: #fff !important;
}
.btn-block + .btn-block {
  margin-top: 0;
}
.table-responsive{width: 100%;}

.table-responsive .shopping-cart .btn.btn-primary {
  margin: 0 10px;
}
.hometab .customNavigation a.prev:before:hover , .hometab .customNavigation a.next:before:hover{color:#fff}
/* End Tab cms */

/*Webdigify Blog CSS */ 
.webdigifyblog .bx-controls-direction .bx-prev::after {
  content: "\f107 ";
  cursor: pointer;
  font-family: "FontAwesome";
  font-size: 24px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 25px;
  text-align: right;
  top:0;
  vertical-align: middle;
}
.bx-controls-direction .bx-prev::after {
  font-size: 18px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: -1px;
  vertical-align: middle;
}

.webdigifyblog .bx-controls-direction .bx-next::after {
  content: "\f106";
  cursor: pointer;
  font-family: "FontAwesome";
  font-size: 24px;
  left: 0;
  line-height: 26px;
  margin: 0;
  position: absolute;
  right: 45px;
  text-align: right;
  top: 0;
  vertical-align: middle;
}
.bx-pager-item {
  display: none;
}
.content_footer_right .blog_stats {
  display: none;
}
#footer .blog_item .summary p {
  display: none;
}
#information-information .webdigifyblog {
	float: right;
	margin-top: 0;
	position: relative;
	text-align: center;
	width: 100%;
	display: none;
}
#content .webdigifyblog .box-heading,.banners-slider-carousel .box-heading {
	margin:0 0 15px 0;
}
.content_footer_right .post_hover {
  display: none;
}
.box.webdigifyblog {
  float: right;
  position: relative;
  width: 100%;
  margin: 35px 0;
}
.social-block ul {
	margin: 10px 0;
}
.webdigifyblog .box-head {
  float: left;
  padding-bottom: 10px;
  width: 100%;
}
.box-head .blog_tagline {
    color: #808080;
    float: left;
    font-size: 14px;
    margin-bottom: 12px;
	 display: none;
}
#content .box-product .blog-items { float:left; }
.blog-left {
	margin: 0;
	overflow: hidden;
	position: relative;
	vertical-align: top;
	width: auto;
	float: left;
}
.blog-image{
	position:relative; 
	overflow:hidden;
	border-radius:5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
}

.blog-left .blog-image img {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
}
.blog-right {
	float: left;
	text-align: center;
	width: auto;
	margin:15px 0 0;
}
.blog-right h4 a {
	float: left;
	font-size: 14px;
	line-height: 24px;
	text-transform: inherit;
	width: 100%;
	text-align: left;
	overflow: hidden;
	color: #333;
	font-weight: 500;
	margin-bottom: 12px;
}
.footer-blocks .blog-item .blog-desc {display: none;}
.content_footer_top .news-title3 {display: none;}

.view-blog {
	width:100%; 
	margin:0; 
}
.webdigifyblog .view-blog {
	width: auto;
	margin: 0;
}
.read-more{
	text-align: end;
}

.read-more a{
	text-transform: inherit;
    display: inline-block;
    vertical-align: top;
    position: relative;
    font:500 14px/24px 'Rubik',Helvetica,sans-serif;
    overflow: hidden;
    z-index: 1;
	 color: #fbb50d;
	 text-decoration: underline;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
}

.read-more .fa{display:none;}
.write-comment {
    display: inline-block;
    float: none;
    padding-left: 18px;
    position: relative;
    text-align: left;
}
.common-home .write-comment::before {
    background: #a8a8a8 none repeat scroll 0 0;
    border-radius: 50%;
	-webkit-border-radius:50%; 
	-moz-border-radius:50%; 
	-khtml-border-radius:50%;
    /* content: ""; */
    display: block;
    font-family: fontawesome;
    height: 4px;
    left: 9px;
    position: absolute;
    top: 11px;
    width: 4px;
}
.date-time {text-transform:inherit; }

.blog_default_width {
	width: 240px;
}
.blog-desc {
	font-family: Rubik;
	text-align: left;
	line-height: 22px;
	color: #7A7A7A;
	margin: 0px 0 15px;
	width: 100%;
	float: left;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0.6px;
}
.information-blogger-blogs  .blog-desc {
	width: 100%;
	margin: 3px 0 20px 0;
}
.blog-right h4 {
	margin: 0;
}
.blog-img { padding: 0px;}
.comment-wrapper {
	position:relative;
	float:right;
	margin:5px 0 0;
}
.comment-wrapper .write-comment a::before {
    color: #000;
    content: "\f0e5";
    float: left;
    font-family: "FontAwesome";
    font-size: 13px;
    margin-right: 5px;
}
.blog-img .img-thumbnail{border:none}
.blog-date {float: left;}
#content #blog-carousel, #content #blog-grid {
	padding: 0px;
	border: none;
	overflow: visible;
	float: left;
	width: 100%;
}
#content .box.webdigifyblog .box-content {padding:0; margin:0 -15px 0 ; }
#content #blog-carousel .blog-item { overflow: hidden;position: relative;float:left;}	 
#content #blog-carousel .blog-item {
	overflow: hidden;
	position: relative;
	float: left;
}
.owl-carousel .owl-buttons div i {
	display:none;
}
.owl-carousel .owl-buttons .owl-prev {
  left: 0;
  opacity: 1;
  -khtml-opacity:1;
  -webkit-opacity:1;
  -moz-opacity:1;
}
#blog-carousel .owl-controls .owl-buttons .owl-prev {
	right: auto;
	left: -60px;
	text-align: center;
	padding: 0;
	opacity: 1;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	line-height: 37px;
}
#blog-carousel .owl-controls .owl-buttons .owl-prev {
    right: 42px;
    left: auto;
    text-align: center;
    padding: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    height: 30px;
    width: 30px;
}
.owl-buttons {
    position: absolute;
    right: 0px;
    top:-56px;
    z-index: 1;
    bottom: auto;
}
#blog-carousel .owl-controls .owl-buttons .owl-prev::before {
    content: "\f104";
    color: #363636;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    position: absolute;
    margin: auto;
    font-size: 25px;
    font-family: FontAwesome;
    line-height: 30px;
    font-weight: 400;
}
#blog-carousel .owl-controls .owl-buttons .owl-next {
    right: 15px;
    left: auto;
    text-align: center;
    padding: 0;
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    height: 30px;
    width: 30px;
}
#blog-carousel .owl-controls .owl-buttons .owl-next::before {
    content: "\f105";
    color: #666666;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    position: absolute;
    margin: auto;
    font-size: 25px;
    font-family: FontAwesome;
    line-height: 30px;
    color: #363636;
    font-weight: 400;
}
#blog-carousel .product-block, #blog-grid .product-block {
    padding: 10px 15px 15px;
    background: #FFFFFF;
}
.single-blog .blog-img{position:relative; margin-bottom:15px;}
.single-blog .panel-default{border:medium none;float: left;width: 100%;margin: 0;}
#add-comment .col-sm-10.buttons {
    padding: 0 8px;
}
.single-blog .block-title {
    float: left;
    margin: 10px  0;
    width:99%;
}
.single-blog .block-title > legend { margin: 10px 0 0;}
.single-blog .blog-desc {
	padding: 0;
	text-align: justify;
	display: block;
}
.single-blog .block-title h3{  padding-top:10px; }
.single-blog .view-comment .panel-default {
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	padding: 15px !important;
	border: 2px solid #f0f0f0;
}

.single-blog h4 {
	font-size: 16px;
	display: inline-block;
	width: 100%;
}
.view-comment .form-group b {
	font-weight: 600;
}
.view-comment .col-sm-3 .name{font-weight:500; color:#333333;}
.view-comment .col-sm-3 .date{color:#b5b5b5;}
.user_icon {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    float: left;
    margin: 0px 15px 0 0;
    padding: 10px 15px;
	text-align:center;
}
.comment_info{float:left; width:85%; }
.comment_info .comment-text{margin-top:12px;}
.view-comment{clear:both;}
.view-comment .col-sm-8 {
	width: 100%;
	margin-top: 10px;
}
#add-comment .col-sm-8 {
	width: 100%;
}
/*Webdigify Blog light-box CSS */ 
.blog-left:hover .post-image-hover::before {
    opacity: 1;
	-webkit-opacity:1;
	-moz-opacity:1;
	-khtml-opacity:1;
    transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-moz-transform: translate3d(0px, 0px, 0px);
	-ms-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate3d(0px, 0px, 0px);
}
.post-image-hover::before {
	background-color:rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
	-webkit-opacity:0;
	-moz-opacity:0;
	-khtml-opacity:0;
    position: absolute;
    top: 0;
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
	-webkit-transition: opacity 0.35s ease 0s, -webkit-transform 0.35s ease 0s;
	-moz-transition: opacity 0.35s ease 0s, -moz-transform 0.35s ease 0s;
	-ms-transition: opacity 0.35s ease 0s, -ms-transform 0.35s ease 0s;
	-o-transition: opacity 0.35s ease 0s, -o-transform 0.35s ease 0s;
    width: 100%;
}
.post-image-hover {
    height: 100%;
    position: absolute;
    top: 0;
	left:0;
     transform: translate3d(0px, 0px, 0px);
	-webkit-transform: translate3d(0px, 0px, 0px);
	-moz-transform: translate3d(0px, 0px, 0px);
	-ms-transform: translate3d(0px, 0px, 0px);
	-o-transform: translate3d(0px, 0px, 0px);
    width: 100%;
}
.blog-image .post_hover {
	bottom: 0;
    height: 51px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    top: 0;
    width: 110px;
    opacity: 0;
    filter: alpha(opacity=0);
}
.product-block .blog-left:hover .post_hover{
	opacity:1;
	-webkit-opacity:1;
	-moz-opacity:1;
	-kkhtml-opacity:1; 
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
}
.product-block .blog-left:hover .zoom, .product-block .blog-left:hover .readmore_link,
.all-blog .blog-left-content:hover .zoom, .all-blog .blog-left-content:hover .readmore_link{
	opacity:1;
	-webkit-opacity:1;
	-moz-opacity:1;
	-kkhtml-opacity:1; 
	margin:0 5px;
    transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition:all 0.3s ease 0s;
}
.blog-left .icon.zoom .fa, .blog-left .icon.readmore_link .fa {
    font-size: 13px;
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
}

.blog-left .blog-image .zoom:hover, .blog-left .blog-image .readmore_link:hover {
	color: #ffffff;
	border-color: #fbb50d;
	background: #fbb50d;
}

.all-blog .blog-left-content {
	float: left;
	margin-bottom: 20px;
}
.all-blog .blog-left-content .blog-image { float:left; position:relative; width:100%;    border-radius: 0px;
}
.blog-right-content {
	float: left;
	padding-bottom: 30px;
}
.blog-right-content .blog-title {
   font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
    padding: 0;
    position: relative;
    text-transform: inherit;
}
.blog-right-content .read-more a {
	padding: 8px 20px;
	background: #020202;
	border-radius: 25px;
	text-transform: inherit;
	position: relative;
	text-decoration: none;
	color: #fff;
}
.blog-right-content .read-more a:hover {
	color: #fff;
    background: #fbb50d;
}
.blog-right-content .write-comment a {
    color: #0a7ef0;
    font-size: 13px;
}
.write-comment-count{float:left; margin-right:10px;color:#000;}
.information-blogger-blogs .write-comment {
    float: left;
}
.information-blogger-blogs .blog-image {
    margin-bottom: 0px;
    background-position: center center;
    height: auto;
    overflow: hidden;
    width: 100%;
    background-repeat: no-repeat;
}
.information-blogger-blogs .panel-body {
	padding: 0px;
}
body.information-blogger-blogs.layout-2.left-col .panel-inner {
    margin: 0px -15px;
}
.information-blogger-blogs .panel-default > .panel { margin-bottom:30px; border: none; }
.information-blogger-blogs .panel-default .panel-inner .panel-default {
    float: none;
    display: inline-block;
    vertical-align: top;
    width: 49.7%;
    margin-bottom: 5px;
    margin-top: 0px;
	border: 0px;
}

.blog-image img {
	border-radius: 15px ;
}

.panel-default{
	margin-top: 20px;
}

.write-comment a, .date-time i.fa.fa-calendar {
    float: left;
    padding: 3px 8px 4px 6px;
}
.blog-date {
    float: left;
    padding-top: 2px;
}
.blog-left-content:hover .post-image-hover::before {
    opacity:1;
	-moz-opacity:1;
	-webkit-opacity:1;
	-ms-opacity:1;
}
.blog-date .fa.fa-calendar {
	display: none;
}
#content.single-blog > legend {
    margin: 15px 0 20px;
}
#content #blog-carousel .product-block:hover .date-comment, #content #blog-grid .product-block:hover .date-comment{
	bottom: 0px;
}
.write-comment a, .date-time {
	color: #111;
    font-weight:400;
    font-size: 13px;
    margin:0 0 15px 0;
    float: left;
    background: #e0f1fb;
    padding:4px 7px;
	 border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}
.write-comment > a {
	color: #2b85f4;
	padding: 0 0 0 15px;
}
.write-comment a:hover, .write-comment a:focus{ color:#000; }
.comment-wrapper .write-comment a:hover, .comment-wrapper .write-comment a:focus, .comment-wrapper .write-comment a:hover::before{color:#111111;}
.information-blogger-blogs .read-more {
	float: left;
}
.static_links .cart-menu > li:first-child {
	max-height: 185px;
	overflow-x: auto;
	overflow-y: auto;
	padding-right:20px;
}
.blog_top_content:hover .post-image-hover {
  opacity: 1;
  -khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1;
}
.all-blog .panel-default .main_description {
  display: none;
}
.blog_top_content .post-image-hover {
  background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  -khtml-opacity:0;-webkit-opacity: 0;-moz-opacity:0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
-moz-transition: all 0.5s ease-out 0s;
-ms-transition: all 0.5s ease-out 0s;
-o-transition: all 0.5s ease-out 0s;
  width: 100%;
}
.product-block .blog-left:hover .zoom, .product-block .blog-left:hover .readmore_link, .blog_top_content:hover .blog-image .zoom, .blog_top_content:hover .blog-image .readmore_link {
  opacity: 1;
  -khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1;
}
.blog-left .blog-image .icon, .blog_top_content .blog-image .icon {
    display: inline-block;
    float: none;
    height: 45px;
    width: 45px;
    line-height: 14px;
    border: 2px solid white;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    padding: 14px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-align: center;
	margin: 0 5px;
}
.post-image-hover {
    background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
	height: 100%;
	left: 0;
	opacity: 0;
	-khtml-opacity:0;-webkit-opacity: 0;-moz-opacity:0;
	position: absolute;
	top: 0;
	transition: all 0.5s ease-out 0s;
	-webkit-transition: all 0.5s ease 0s;
-moz-transition: all 0.5s ease 0s;
-ms-transition: all 0.5s ease 0s;
-o-transition: all 0.5s ease 0s;
	width: 100%;
}
.blog_post .text-right .btn-primary {
	color: #F9BF39 ;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	border: 2px solid #F9BF39 ;
	border-radius: 5px;
	-webkit-border-radius:5px;
-khtml-border-radius:5px;
-moz-border-radius:5px;
	background: #fff;
	line-height:20px;
}
.blog-left-content .post_hover {
    left: 0;
    margin: 0 auto;
    opacity: 0;
	-khtml-opacity: 0;
	-moz-opacity: 0;
	-webkit-opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 44%;
}
.all-blog .blog-left-content:hover .post_hover {
	opacity:1;
	-webkit-opacity:1;
	-moz-opacity:1;
	-kkhtml-opacity:1; 
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
}

.blog-left-content .icon.zoom .fa, .blog-left-content .icon.readmore_link .fa {
    font-size: 14px;
	transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	color: #fff;
}
.blog-left-content .blog-image .zoom {
	border: 2px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    color: #fff;
    display: inline-block;
    margin-right: 10px;
    opacity: 0;
    -khtml-opacity: 0;
    height: 45px;
    width: 45px;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    padding: 11px 12px 9px 12px;
    transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    z-index: 10;
    background: none;
}
.blog-left-content .blog-image .zoom:hover,.blog-left-content .blog-image .readmore_link:hover{
	background:#fbb50d  none repeat scroll 0 0;
	color: #111 !important;
	border-color: #fbb50d;
}
.webdigifyblog .product-block:hover .post_hover {
    opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -kkhtml-opacity: 1;
}
.blog-item:hover .blog-left .blog-image img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.blog-left-content .blog-image .readmore_link {
    border: 2px solid #fff;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-khtml-border-radius: 50%;
	color: #ffffff;
    background: none;
    display: inline-block;
	height: 45px;
    width: 45px;
    opacity: 0;
	-khtml-opacity: 0;
	-moz-opacity: 0;
	-webkit-opacity: 0;
    padding: 11px 12px 9px 12px;
    transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
    z-index: 10;
}

#add-comment .col-sm-10 {
    width: 66.6667%;
}

#add-comment .col-sm-2 {
    width: 25%;
}

#content .webdigifyblog .buttons,#blog-carousel .owl-pagination{display:none}

/*Webdigify Blog light-box CSS */ 

/*Webdigify Blog CSS */ 

/* Datetimepicker Css */
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right {
	position: relative ;

}
.bootstrap-datetimepicker-widget {
	border-radius:0 !important;
    -webkit-border-radius:0 !important;
    -moz-border-radius:0 !important;
    -khtml-border-radius:0 !important;
}

.bootstrap-datetimepicker-widget td,
.bootstrap-datetimepicker-widget th {
border-radius:0 !important;
-webkit-border-radius:0 !important;
-moz-border-radius:0 !important;
-khtml-border-radius:0 !important;
}

.bootstrap-datetimepicker-widget td.day:hover,
.bootstrap-datetimepicker-widget td.hour:hover,
.bootstrap-datetimepicker-widget td.minute:hover,
.bootstrap-datetimepicker-widget td.second:hover{
	background:#f3f3f3 !important;
}

.bootstrap-datetimepicker-widget td.today:before {border-bottom: 7px solid #2196f3 !important;}

.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover{
	background-color:#2196f3 !important;
	color: #161b1e !important;
}

.bootstrap-datetimepicker-widget td.active.today:before {
	border-bottom-color:#161b1e !important;
}

.bootstrap-datetimepicker-widget td span {
    border-radius:0 !important;
    -webkit-border-radius:0 !important;
    -moz-border-radius:0 !important;
    -khtml-border-radius:0 !important;
    background-color: #f3f3f3 !important;
}

.bootstrap-datetimepicker-widget td span:hover {color:#111111 }

.bootstrap-datetimepicker-widget td span.active {
	background-color: #f6d000 !important;
    color: #0a7ef0 !important;
    text-shadow: none !important;
}

.bootstrap-datetimepicker-widget thead tr:first-child th:hover {
background:#f3f3f3 !important;
}

.bootstrap-datetimepicker-widget td span.old:hover{color:#fff !important}

.picker-switch.accordion-toggle .btn{border:none; background-color:#111111 ; color:#fff !important;}

.bootstrap-datetimepicker-widget td.day{position:relative;}

.bootstrap-datetimepicker-widget td.day.active:before {
    border-bottom: 7px solid #0a7ef0 !important;
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-top-color: rgba(0,0,0,.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}


.bootstrap-datetimepicker-widget td.active, .bootstrap-datetimepicker-widget td.active:hover {
	background-color: #f6d000 !important;
	color: #0a7ef0 !important;
	text-shadow: none !important;
}

.bootstrap-datetimepicker-widget a[data-action]{border:none;}
/* End Datetimepicker Css */

#blog-carousel .owl-controls .owl-buttons .owl-prev:hover::before, #blog-carousel .owl-controls .owl-buttons .owl-next:hover::before {
	color: #111111;
}
#blog-carousel .owl-controls .owl-buttons .owl-prev, #blog-carousel .owl-controls .owl-buttons .owl-next{ transition:none;
-webkit-transition:none;
-moz-transition: none;
-ms-transition: none;
-o-transition:none;}

.product-block .image .reg-image, #column-right .image .reg-image, #column-left .image .reg-image { 	
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	opacity:1;
	-webkit-opacity:1;
	-moz-opacity:1;
	-khtml-opacity:1;
 }
 .product-thumb .image img {
    margin-left: auto;
    margin-right: auto;
	max-width: 100%;
	vertical-align: middle;
}
.product-thumb .image a {
    display: block;
	text-align: center;
    position: relative;
}
#form-review .col-sm-2, #form-review .col-sm-10{width:100%;}
.container h1, .container h2 {
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	color: #111;
}
.category_thumb .category_description {
    padding: 0px;
}
#form-review > h3{font-size:16px; font-weight:500;}
#content.productpage .product-title {
	font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    margin: 0px 0 10px 0px;
    padding: 0px 0px 10px;
    text-transform: inherit;
    color: #222;
    border-bottom: 1px solid #eaeaea;
}
#content h3.product-option {
	text-transform: inherit;
	font-size: 16px;
	font-weight: 500;
}
.breadcrumb + .row {clear: both;}
#content table thead tr td{
	background:#f5f5f5 none repeat scroll 0 0;
	color:#303030;
	font-weight:500;
	font-size: 14px;
}
.account-account #content ul.list-unstyled{margin-bottom:25px;}
.breadcrumb {
	padding:23px 0;
    margin: 0;
    list-style: none;
    float: left;
    width: 100%;
    text-align: center;
}
#content #carousel-0 .box-content {margin: 0; padding: 0; position: relative;}
.pull-left.footer-block { width: 19%; text-align:right!important;}
#social_block .title_block {display: none;}
#social_block .toggle-footer {padding: 55px 0 0;}
/* QUCIK-VIEW */
.quickview .wish-comp .btn.btn-default.wishlist, .quickview .wish-comp .btn.btn-default.compare {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
	border: medium none;
	color: #808080 !important;
	font-weight: normal;
}
.productpage-quickview .product-left { width:50%; float:left; }
.productpage-quickview .product-right { width:50%; float:left; clear:none !important; }
.productpage-quickview h3.product-title {
	font-weight: 500;
    text-transform: inherit;
    font-size: 18px;
    border-bottom: 1px solid #eaeaea;
    margin: 0 auto 10px;
    padding: 0 0 10px;
}
.quickview .product-right ul li.discount, .quickview .product-right ul li.rewardpoint, .quickview .product-right ul li.price-tax { clear:both; }
.header-quickview .background_wrapper { display: none;}
.header-quickview .responsive-menu {display: none;}
.header-quickview .quickview { top: 0; padding: 20px;}
.image .quickview {opacity: 0; -khtml-opacity:0;-webkit-opacity: 0;-moz-opacity:0;}
#column-left .quickview {display: none;}
.quickview .fa.fa-heart {color:#808080;}
.quickview .btn.btn-primary.btn-lg.btn-block {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid #7e5cdd;
  color: #7e5cdd;
  font-weight: normal;
  padding: 7px 20px 6px !important;
  text-transform: inherit;
}
.quickview {
	 display: inline-block;
	left: 0%;
	right: auto;
	padding: 15px 0;
	position: absolute;
	opacity: 1;
	-khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1;
	z-index:99;
	right:0;
	top:50%;
	cursor:pointer;
}
.quickview .fa.fa-eye:hover { background: #111111 none repeat scroll 0 0; border-color: #111111;}
.quickview .fa.fa-eye {
  background: #111111 none repeat scroll 0 0;
  border: 1px solid #111111;
  border-radius: 50%;
  -webkit-border-radius:50%;
-khtml-border-radius:50%;
-moz-border-radius:50%;
  color: #ffffff;
  padding: 15px;
}
.product-quickview .quickview {
	bottom: 0;
	left: 10px;
	top: 10px;
	opacity: 1 !important;
	-khtml-opacity:1 !important;-webkit-opacity: 1 !important;-moz-opacity:1 !important;
}
.product-thumb:hover .quickview{opacity:1; -khtml-opacity:1;-webkit-opacity: 1;-moz-opacity:1;color:#000000 !important;}
h4:hover a, h4:focus a {
	color: #111111;
	text-decoration: none;
}
.quickview > a {color: #000;}
#column-left .quickview-button {display: none;}
.row .cat_prod .button-gr.lists {
   bottom: 50%;
   position: absolute;
   margin:0 auto;
   width:100%;
}
.quickview .container { width:100%; background:#FFFFFF; padding:0 10px; }
.quickview .container .row { margin:0; }
.quickview .top_button { display:none; }
.quickview #product2 { float:left; width:100%; }
.quickview #product2 h3 { width:100%; } 
.productpage-quickview #product2 h3 {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 10px;
    padding: 0;
    text-transform: none;
	color:#333333;
}
.box.related_prd {
  float: left;
  width: 100%;
  margin-top:70px; 
}
.alert.alert-success.animated {
	 width: 500px;
	height: 100px;
	text-align: center;
	vertical-align: middle;
	padding: 20px 20px;
	opacity: 0.95 !important;
	-webkit-opacity: 0.95 !important;
	-moz-opacity: 0.95 !important;
	-khtml-opacity: 0.95 !important;
	background-color: #ffffff !important;
	border-color: #ffffff !important;
	color: #111111 !important;
	right: 0 !important;
	left: auto !important;
}
#banner1 .swiper-pagination {
	display: none;
	cursor: pointer;
}
.alert.alert-success.animated span{font-size:15px !important;}
.alert.alert-success.animated span a{color:#111111; text-decoration:underline; }
.alert.alert-success.animated span .fa.fa-check-circle{color:#9bcf85;}
.alert.alert-success.animated button.close{
	opacity:1; 
	-khtml-opacity: 1;
	-webkit-opacity: 1;
	-moz-opacity:1;
	color:#111111;
}
.account-login .well .form-group a, .affiliate-login .well .form-group a{
	line-height: 34px;
    float: right;
    text-decoration: underline;
}

.form-group.qty {
	clear: both;
	overflow: hidden;
	position: relative;
	padding:15px 0px 20px;
	margin: 0;
}
.discount-per {
	color: #228B22;
}
.stock_msg {
	display: block;
	padding: 10px 0 0 5px;
	text-transform: inherit;
	font-weight: 500;
}
#related-carousel {
	padding-bottom: 20px;
}

.productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare,.product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist, .product-rightinfo .btn-group.prd_page .btn-default.compare {
    color: #666666;
    font-size: 13px;
    display: inline-block;
    padding: 5px 15px 5px 0;
    text-align: left;
    width: auto;
    transition: none;
    height: auto;
    background: none;
    border: 0;
	top: 0px;
	font-weight: 400;
}
.productpage .form-group.qty .compare:before, .product-rightinfo .btn-group.prd_page button.btn.btn-default.compare:before{
	content: "";
    float: left;
	background-image: url(../image/webdigify/sprite3.png);
    background-repeat: no-repeat;
	background-position: -36px -75px;
    background-size: 58px;
    margin: 0 0px;
    color: #111;
    border: none;
    position: relative;
	 border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    padding: 10px 12px;
}
.productpage .form-group.qty .wishlist, .quickview-model .form-group.qty .wishlist {
    margin: 0px;
}
#button-cart, #button-cart2, .productpage .form-group.qty .wishlist, .productpage .form-group.qty .compare, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist, .product-rightinfo .btn-group.prd_page .btn-default.compare {
    float: left;
}
#form-currency .btn-link:hover, #form-language .btn-link:hover,
#form-currency .btn-link:focus, #form-language .btn-link:focus {
	text-decoration: none;
}
.btn-group, .btn-group-vertical .btn-group.prd_page {
    width: 100%;
    float: left;
	 padding:9px 0px;
}
#product .btn-group.prd_page {
    padding-top: 20px;
}
.lang-curr-wrapper .wd-language {
	padding: 0 20px 0 0;
}
.productpage .form-group.qty .wishlist:before, .product-rightinfo .btn-group.prd_page button.btn.btn-default.wishlist:before {
    content: "";
	 float: left;
    background-image: url(../image/webdigify/sprite3.png);
    background-repeat: no-repeat;
	 background-position: -35px -38px;
    background-size: 57px;
    margin: 0 0px;
    color: #111;
    border: none;
    position: relative;
    border-radius: 50%;
    padding: 11px 12px;
}
.headertop { position:relative; }
#search .fa.fa-search{display: block;}
.input-group-btn .search {display: block;}
.input-group-btn button.btn.btn-default {
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
}


/********************* Responsive start ******************************/
@media (max-width: 1499px){

	#content .banners-slider-carousel .product-block {
		margin: 0px 15px;
	}
	.box.webdigifyblog {
		margin:15px 0 40px;
	}
	.ProductbyCategory{
		padding: 30px 0 15px;
	}
	.product-layout.product-grid {
		width: 24.5%;
	}
	
}
@media (max-width: 1459px){

	.product-layout.product-grid {
		width:24.5%;
	}

}
@media (max-width: 1249px){
	.cms-inner-block1 {margin-right: 23px;}
	.cms-inner-block1, .cms-inner-block2{width: 49%;}
	.countbox .timebox{margin: 0 3px 0 0;}
	.quickview-button {margin: 0 3px 0 0;}
	.product_hover_block {right: -50px;  }

}
@media (max-width: 1199px) and (min-width:768px){
	#nav-one > li > .dropdown-menu.megamenu.column1, #nav-one .hiden_menu .dropdown-menu{ overflow: unset;}
}
@media (max-width: 1199px){
	.footer-blocks{padding:0 15px;}
	.main-menu a {	padding: 21px 16px;	}
	.col-sm-4.header-logo{margin:0 15px 0 0;}
	.cms-inner-block1, .cms-inner-block2{width: 48%;}
	.collection-right-banner{ display: none; }
	.tab-head span {float: left;}
	.swiper-viewport .swiper-button-prev::before, .swiper-viewport .swiper-button-next::before {    height: 40px;
		width: 40px;    padding: 10px 0;}
	.main-slider .swiper-button-next, .main-slider .swiper-button-prev{margin:0; }
	.cmsbanner-block .description .banner_text{margin: 30px 0 0 0;}
	.product-list .product-details{width: 63%;}
	.hometab, .ProductbyCategory, .featured {	padding: 30px 0;	}
	.header_search .form-control.input-lg {width: 170px;}
	.header_search .btn.btn-default.btn-lg {padding:11px 16px 11px 13px;	}
	.header_right {width: 20%;	margin: 0 0 0 10px;	}
	#column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
		width: 100%;
	}
	#column-left .product-items .product-details, #column-left .product-items .product-details h4, #column-right .product-items .product-details h4 {
		padding-top: 7px;
	}
	.product-layout.product-grid {width: 32.8%;	}
	.search_box .form-control-select {width: 130px;	}
	.dropdown.myaccount {margin: 5px 0 5px 0px;  }
	#newslatter {padding: 38px 40px;  }

	
}
@media (min-width: 992px){
.col-sm-3.column.footerleft .contact-block h5 {
	display: none;
}
}
@media (max-width: 991px){
	
	#form-currency .btn-link strong {padding:0 11px 0 6px;}
	.col-sm-5.product-right {width: 50%;}
	.col-sm-4.product-left {width: 50%;	}
	.newsletter #txtemail {padding: 10px 120px 10px 20px;}
	.header_wishlist span {	top: -7px;    background: #fff;	color: #111;}
	.header_search .btn.btn-default.btn-lg {font-size: 0px;padding:10px 13px 3px;	}
	.header_search .form-control.input-lg { width: 190px;	padding: 8px 15px 8px 15px;}
	.search_box {width: 120px;}
	.header_search .btn.btn-default.btn-lg .fa.fa-search:before {padding-right: 0px;}
	.search_box .form-control-select, .search_box .form-control-select.active {width: 120px;	}
	span.res-menu-close {display: none;}
	#searchbox {width: auto;}
	.box-category-top {padding:7px 25px 7px 40px;margin-top: 2px;	display: none;	}
	.box-category-top::before {
		background-size: 19px;
		left: 12px;
		width: 16px;
		height: 17px;
		top: 9px;
	}
	.social-block ul li {
		padding: 0 !important;
  }
	.header_top .lang-curr-wrapper {display: none;}
	h5.toggle:hover {	background: rgba(255, 255, 255, .05);	}
	.dropdown.myaccount ul.dropdown-menu.dropdown-menu-right.myaccount-menu {top: 35px;}
	#cart .dropdown-menu {top: 68px;}
	.header_bottom_cover {height: 58px;}
	.login_acc {border-bottom: 1px solid #eaeaea;}
	.login_acc li {padding: 3px 40px 7px;}
	.pull-left #form-language .btn-group span img {margin-bottom: 2px;padding:0 11px 0 3px;}
	#footer {padding: 40px 0 30px;}
	#cart-total .mycart, #cart-total {display: none;}
	.nav-inner.container{margin:0 auto ;}
	.layout-2.left-col #content , .layout-2.right-col #content{width:100%;}
	.layout-2.left-col #column-left , .layout-2.right-col #column-right{width:100%; float: left; padding: 10px 0 10px; display: block;}
	#column-left .box, #column-right .box {margin: 0px 0 15px;
		/* border: none; */
		float: left;
		width: 100%;
		padding: 0px;}
	#column-left .swiper-viewport { display:none;}
	.layout-2.left-col #content {padding:0; margin-top:0;clear: both;
	}
	#column-left .box .box-content, #column-right .box .box-content, #column-left .box .filterbox, #column-right .box .filterbox{display:none;}
	#column-left .list-group, #column-right .list-group {display: none;}
	.menu_toggle{display:none !important;}
/*cartbit*/
	.static-menu{ display: none; }
	.col-sm-5.header_search {float: right;    padding: 9px 0;}
	.cmsbanner-block .description .title{ font-size: 20px; }
	.cmsbanner-block .description .banner_text {margin: 15px 0 0 0;}
	.footer-logo{width: 100%; text-align: center; margin-bottom: 15px;}
	.footer-logo img { display: inline-block; }
	.newsletter{ width: 50%; }
	.footer-blocks .col-sm-3 {width: 100%;    padding: 0;}
	#content ul.list-unstyled{ display: block; }
	.btn-group.prd_page {width: 100%;}
	.contact-form-design .left{width: 100%; float: left;}
	.contact-form-design .contact-form {float: left; width: 100%; margin-top: 20px;}
/*cartbit*/
.block_newsletter h3 .subtitle {
    font-size: 14px;
    line-height: 18px;
}
.block_newsletter h3 {    
	 margin: 0 0 10px;
	float: left;
	width: 100%;
}
.footer-appinner .title {font-size: 18px; line-height: 28px;}
.footer-appinner .subtitle { font-size: 12px; line-height: 20px;}
.social-block ul li a {margin-right: 0px;}
.dropdown.myaccount .lang-curr-wrapper{display: block;}
#cart {
	padding: 9px 0px;
}
.dropdown.myaccount .lang-curr-wrapper.active .pull-left #form-language .btn-group span,
.dropdown.myaccount .lang-curr-wrapper.active .pull-left #form-currency .btn-group span {
    font-size: 13px;
    color: #111;
	font-weight: 400;
}
.dropdown.myaccount .lang-curr-wrapper.active .language-menu, .dropdown.myaccount .lang-curr-wrapper.active .currency-menu{
    position: initial;
    border: 0px;
	padding: 0px 10px;
	width: 100%;
}
.search_box .form-control-select { padding: 5px 10px 5px 10px;}
#form-currency .btn-link, #form-language .btn-link { padding: 3px 10px;}
.box-content-category { top: 50px;}
.common-home .box-content-category{display: none;}
#nav-one li .megamenu .dropdown-inner ul li.dropdown:after{right: 0px;}
#nav-one .dropdown a::after, #nav-one li .megamenu.column1 ul.list-unstyled > li.dropdown a::after,
#nav-one li .megamenu .dropdown-inner ul li.dropdown:after {
    content: "\f107";
    right: 20px;
    position: absolute;
    font-family: 'fontawesome';
    font-size: 16px;
    display: inline-block;
}
#nav-one li .megamenu ul.childs_1 { display: block;padding-top: 0px;}
.responsive-menu ul > li{ display: block;    position: static; }
#res-menu.responsive-menu .main-navigation.active{left: 0px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;}
header .responsive-menu .main-navigation {
	position: fixed;
	z-index: 9999;
	display: block;
	margin-top: 0px;
	padding: 0px 10px 10px;
	width: 300px;
	height: 100%;
	max-height: initial;
	background-color: #ffffff;
	list-style: none;
	overflow: auto;
	left: -100%;
	top: 0;
	border: 1px solid #ededed;
	-webkit-box-shadow: 0 8px 15px 0 rgba(0,0,0,10%);
	box-shadow: 0 8px 15px 0 rgba(0,0,0,10%);
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.menu-active {
	overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;}
.menu-active::before {
	content: "";
    opacity: 0.7;
    filter: alpha(opacity=70);
    position: absolute;
    left: 300px;
    height: 100%;
    width: calc(100% - 300px);
    top: 0;
    z-index: 99999;
    overflow: hidden;
}
.nav-responsive span:before {
	content: "";
	background-image: url(../image/webdigify/menu.svg);
	background-repeat: no-repeat;
	background-position: -1px 0px;
	background-color: transparent;
	background-size: 20px;
	width: 18px;
	height: 22px;
	position: absolute;
	left: 0px;
	top: 17px 0 18px 10px;
	display: inline-block;
    vertical-align: top;
}
#res-menu{ display: block;padding: 6px 11px;}
ul.main-navigation.treeview.active .menu.expandable span {
    display: inline-block;
    vertical-align: top;
}
.header_center #res-menu.responsive-menu .main-navigation.active .menu.expandable {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
    padding: 12px 0px 12px 10px;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 99;
    cursor: pointer;
}
.header_center #res-menu.responsive-menu .main-navigation.active .menu.expandable span {
    font-size: 15px;
    text-align: left;
    line-height: 26px;
    font-weight: 600;
    color: #000000;
    display: block;
}
.responsive-menu .collapsable-hitarea {
    height: auto;
    width: auto;
    padding: 5px;
}
.responsive-menu .main-navigation li a {
    padding: 10px 10px;
	font-weight: 400;
	font-size: 13px;
	text-align: left;
}
.count-down .countbox strong { font-size: 11px;line-height: 22px;}
.countbox .timebox .time { font-size: 9px;}
.box.special .product-block .product-details { padding: 15px 10px;}
.blog_default_width{width: 50%;}
.footer-blocks .footerleft .contact-block ul {display: none;clear: both;}
.footer-blocks  ul.list-unstyled { display: none;clear: both;}
.product-list .product-details {width: 70%;}
#content.productpage .product-title { padding: 20px 0px 10px;}
#column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
    width: 60%;
    padding: 0px 10px;
    float: left;
}
.product_hover_block .action button.cart_button {
    font-size: 0px;
	background-image: url(../image/webdigify/sprite3.png);
    background-repeat: no-repeat;
    background-position:-34px 8px;
    background-size: 65px;
	background-color: #f5f5f5;
    height: 40px;
    width: 40px;
}
.product-block .product-details:hover .product_hover_block .action button.cart_button{background-color: #f5f5f5;}
.product-block .product-details .product_hover_block .action button.cart_button:hover{background-color: #fbb50d;}

footer h5 {
	font-size: 16px;
	line-height: 22px;
	float: left;
	width: 100%;
	color:#111111;
}
.block_newsletter h3 .title .material-icons {
    height: 25px;
    width: 25px;
    background-size: 25px;
    background-repeat: no-repeat;
}
.footer-appinner ul.list-unstyled {display: flex;}
#column-left .product-thumb, #column-right .product-thumb { padding: 15px 10px 5px;}
#column-left .box .box-heading, #column-right .box .box-heading {
    background: none;
	padding: 15px 15px;
    font-size: 14px;
    line-height: 16px;
}
.newsletter.col-sm-6 {
	display: inline-block;
	float: none;
	vertical-align: top;
	width: 80%;
	text-align: center;
}
.footer-blocks .footerleft .contact-block li ,
#footer .column li{padding: 0px 15px;}
.product-layout.product-grid { width: 24%;}
#content .box .box-heading, .box.related_prd .box-heading {
    font-size: 20px;
    line-height: 24px;
}
.box.special .box-heading { margin-bottom: 8px;}
.tab-head { margin-bottom: 8px;}
#content .webdigifyblog .box-heading { margin: 0 0 10px 0;}
.countbox.hastime { padding: 2px 2px;}
.hometab, .ProductbyCategory, .featured { padding:25px 0 25px;}	
.htabs a { padding: 10px 20px;}
.box.webdigifyblog { margin: 15px 0 25px;}
.pull-left.wd-language,.pull-left.wd-currency {top: 0px; width: 100%; padding: 0;}
.btn-group, .btn-group-vertical .btn-group.prd_page {padding: 9px 0px 0px; }
#form-currency .btn-link, #form-language .btn-link {
	border: medium none;
	color: #666;
	font-size: 13px;
	line-height: 22px;
	text-decoration: none;
	padding: 0px 20px;
	float: left;
	letter-spacing: 0.3px;
}
.wd-currency, .wd-language {
	position: relative;
	right: auto;
}
#form-currency .btn-link.dropdown-toggle, #form-language .btn-link.dropdown-toggle {
	background: no-repeat;
	padding: 0px 10px;
}
.dropdown.myaccount {margin: 9px 0 9px 0px;    float: right;}
.header_wishlist {display: none;}
.action button.cart_button {
	padding: 7px 10px 7px 32px;
	background-position:10px 8px;
}
#newslatter {
	padding: 18px 20px 30px;
	text-align: center;
}
.block_newsletter { display: block;}
#block_1 {
	width: 100%;
	padding: 0;
}
.col-sm-3.column.footerleft{margin: 0px;}
#wdfooter {
	text-align: center;
	padding: 0 0 10px 0;
}
.product-list .product-details .product_hover_block button.wishlist,
.product-layout.product-list .product-details .product_hover_block button,
.product-layout.product-list .product-details.product_hover_block button.compare_button,
.product-layout.product-list .product_hover_block .quickview-button a.quickbox {
	height: 35px;
	width: 35px;
}
#information-information #content, #account-return #content, #information-sitemap #content, 
#account-account #content, #account-voucher #content, #account-wishlist #content, #account-order #content, 
#account-edit #content, #account-newsletter #content, #account-password #content, #account-address #content, 
#account-recurring #content, #account-reward #content, #account-transaction #content, 
#checkout-checkout #content, #checkout-cart #content, #account-register #content{
	width: 100%;
	margin: 0;
}
.offer_title {
	color: #111111;
}
.offer-content {
	text-align: left;
}
.header_right {
	float: left;
	width: 100%;
	margin: 0;
	display: none;

}
.header_center {
	padding: 0;
	position: static;
	width: 40px;
	margin:9px 0;
	height: 40px;
	background: #020202;
	cursor: pointer;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-khtml-border-radius: 5px;
	margin-right: 20px;
}
nav.nav-container {
	position: static;
	background: none;
	padding: 0;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
}
.nav-responsive span {
	display: block;
	text-align: center;
	position: relative;
}
.header_top {
	padding: 10px 0;
}
.col-sm-4.header-logo {
	width: 160px;
	padding: 7px 0;
}
.header_top .account {
	padding: 0 0 0 15px;
}


}

@media (max-width: 767px) {
.offer-title{ display: none; }
#top{ background: #f5f5f5; padding: 10px 0; border-top: none; position: relative; height: 60px;}
.top-links-toggle {display: block; cursor: pointer;}
.top-links {display: none; position: absolute; background: #fff; padding: 10px; width: 200px; z-index: 999; border: 1px solid #e8e9eb; top: 100%; right: 0;}
.top-links-toggle > i, #res-menu i {font-size: 21px;}
.top-links .links-item{ padding: 5px; width: 100%; display: block; }
.top-links .links-item::before{ display: none; }
.box-category-top{ display: none; }
#newslatter::after {
    border-right: 0px;
}
.col-sm-5.product-right {width: 100%;}
.col-sm-4.product-left {width: 100%;}
.quickview-button {display: none;}
#column-left .product-items .product-details, #column-left .product-items .product-details, #column-right .product-items .product-details {
	float: left;
}
.newsletter .btn.btn-lg {padding: 0px 20px;}
.responsive-menu .nav-responsive .expandable{margin: 0; height: 20px;}
.main-navigation img {display: none;}
.res-menu-close { visibility: hidden; position: absolute; cursor: pointer; opacity: 0;}
.menu-active .res-menu-close{ visibility: visible; left: 240px; z-index: 9999; transition: all 600ms ease 0s; top: 10px; opacity: 1; height: 30px;
	width: 30px; text-align: center; line-height: 30px;}
.res-menu-close > svg {width:12px; height:12px; fill:#0a7ef0; stroke:#0a7ef0; stroke-width:5px;}
.cmsbanner-block{margin: 20px 0;}
.cms-inner-block1, .cms-inner-block2 { width: 100%; margin: 0 0 20px 0;}
.top-links-toggle > svg {width: 20px; height: 20px; fill: #0a7ef0;}
.special .product-block:hover .product-details{ margin-top: 0; }
.product-block:hover .product-block-inner { box-shadow: none; padding-bottom:0; margin-bottom: 0; z-index: 9;}
.product-block .product-block-inner { z-index: 9;}
.product-block:hover .countdown{ opacity: 1; }
.product-thumb .price{ font-size: 14px;}
.product-thumb .price-old{ font-size: 12px; }
.manufacturer_title{ display: none; }
.manufacture_block ul{ padding: 0; float: none; }
.manufacture_block{padding: 25px 0; text-align: center;}
.payment-block ul {padding: 0;}
.payment-block {width: 100%; text-align: center;}
p.powered {margin: 10px 0; width: 100%; text-align: center;}
.newsletter{ width: 100%; margin: 0; }
.social-block ul li{ float: none; }
.tab-head {
	padding: 0;
	background: none;
	border-bottom: 1px solid #eaeaea;
}
.hometab-heading.box-heading{padding: 20px 0 0 20px;}
.htabs {margin: 0; width: 100%;}
.tab-head .sub-heading{margin: 0px 0px 0 20px;}
.tab-head .coupon{margin-top: 15px;}
.featured{ padding: 40px 0; }
.tab-head span{ width: 100%; font-size: 12px; }
footer{ margin-top: 25px; }
.product-layout.product-grid{ width:32.5%; min-height: 360px; }
.pagination-right{ width: 100%; margin: 20px 0 0;}
.col-sm-12.category_list {padding: 0;}
.category_list li{margin: 0 10px 10px 0;}
.breadcrumb {width: 100%;}
.breadcrumb > li{white-space: normal;}
.col-sm-8.product-left {position: relative; top: auto; width: 410px; margin: 0 auto;}
.layout-1 #content {padding: 0;}
.col-sm-4.product-right {padding: 20px 0 0; width: 100%;}
#tabs_info.product-tab, .box.related_prd{ margin: 30px 0 0; }
.col-sm-4.search_term {padding: 0 0 10px;}
.col-sm-3.search_subcategory{ padding: 0; }
.information-contact #content{padding: 30px;}
.contact-form-design.container{margin: 0px auto 70px; padding: 15px;}
.table-responsive.full-view{ display: none; }
.table.table-bordered.shopping-cart.responsive{ display:block; }
.shopping-cart .btn-primary{ margin: 0; }
.listing { min-height:unset; padding: 80px 15px; line-height: 30px;}
.alert.alert-success.animated{width: 100%;}
.box.special .product-block .product-details { padding:10px 5px;}
.countbox.hastime { padding: 5px 5px;}
.col-sm-5.product-right {width: 100%;}
.banners-slider-carousel {margin: 0px 0 20px;}
.tablatest_default_width, .tabbestseller_default_width, .tabspecial_default_width,.featured_default_width{ width: 26% }
.productcategory0_default_width.wdproductcategory,.productcategory1_default_width.wdproductcategory,
.productcategory2_default_width.wdproductcategory,.productcategory3_default_width.wdproductcategory,
.productcategory4_default_width.wdproductcategory,.productcategory5_default_width.wdproductcategory{width: 26%;}
.special_default_width{width: 26%;}
#content .banners-slider-carousel .product-block .product-block-inner { border: 0px;}
.footer-appinner ul.list-unstyled {display: block;}
.box.special .box-heading { margin-bottom: 8px;}
.owl-buttons { top: -50px;}
.box.webdigifyblog { margin: 15px 0 25px;}
.footer-top { padding: 28px 0 28px;}
.product-list .product_hover_block { bottom: 0px;}
.product-block .product_hover_block {
	opacity: 1;
    -moz-opacity: 1;
    -khtml-opacity: 1;
    -webkit-opacity: 1;
    position: static;
    text-align: left;
    height: auto;
}
.product_hover_block button.wishlist,.product_hover_block button.compare_button {
	display: inline-block;
	vertical-align: top;
}

.product_hover_block button, .quickbox {
	background-color: #f5f5f5;
}
.product_hover_block button.wishlist {
	background-image: url(../image/webdigify/wishlist-hover.svg);
	background-repeat: no-repeat;
}
.product_hover_block button.compare_button {
	background-image: url(../image/webdigify/compare-hover.svg);
	background-repeat: no-repeat;
}
.product_hover_block button, .quickbox {
	height: 35px;
	width: 35px;
	background-size: 15px;
}
.countdown {
	float: right;
	right: 5px;
	left: auto;
	top: 5px;
	bottom: auto;
	position: absolute;
}
.countbox .timebox {
	margin: 0 0px 5px 0;
	display: block;
	height: 32px;
	min-width: 32px;
}
.count-down .countbox strong {height: 32px;}
.countbox .timebox .time {	margin: -20px 0 0 0;}

.product_hover_block button.wishlist:hover:after ,
.product_hover_block button.compare_button:hover:after{
	height: 36px;
	width: 36px;
}
.col-sm-4.header-logo {
	width: 130px;
	padding: 11px 0;
	margin: 0;
}
.header_center {
	margin-right: 10px;
}
.header_top .account {
	padding: 0 0 0 8px;
}
.col-sm-3.header_cart {
	padding: 0 0 0 8px;
}
#content .box .box-heading, .box.related_prd .box-heading,#carousel-0 .box-heading {
	background: none;
	padding: 0 0 10px;
	border-bottom: 1px solid #eaeaea;
}
.tab-head .hometab-heading.box-heading {
	border: none !important;
}
.htabs a {
	margin: 0px 0 0;
}
.product_hover_block button.wishlist,
.product_hover_block button.compare_button {
	margin: 15px 0 5px;
}
.product-layout.product-list .product_hover_block button.compare_button{margin: 0px;}
}


@media only screen and (max-width: 660px) {
.productpage .description {
	font-size: 10px;
	line-height: 20px;
}
.productpage .special-price {
	font-size: 30px;
}
.product-layout.product-list {padding: 0 0 20px;}
.module_default_width{ width: 33%; }
.banners-slider-carousel .container {
	padding: 0 10px;
}
.layout-2.left-col #content .col-sm-12 {
	padding: 0 10px;
}
.product-layout.product-list .img-wrap {
	width: 30%;
}
.dropdown.myaccount {
	margin: 3px 0;
}
#cart {
	padding: 2px 0px;
}
.col-sm-5.header_search {
	float: left;
	width: 100%;
	text-align: center;
	padding: 15px 0px 5px;
}
#searchbox {
	width: auto;
	float: none;
	margin: 0 auto;
}
.header_center {
	height: 40px;
	margin: 4px 10px 4px 0;
}
.col-sm-4.header-logo {
	padding: 6px 0;
}
#cart .dropdown-menu {
	top: 62px;
}
.search_box .form-control-select {
	font-size: 11px;
}
.header_search .form-control.input-lg {
	padding:8px 8px 8px 8px;
	font-size: 11px;
}

}
@media only screen and (max-width: 650px){
	.col-sm-4.header-logo {
		display: block;
		text-align: center;
	}
	.htabs a.selected, .htabs li a {
		padding: 10px 10px 10px 0px;
	}
	
	.etabs {
	    float: none;
    text-align: center;
    width: 100%;
	padding: 0px 0px 50px 0px;
	}
	.htabs a.selected, .htabs li.active a {
		background: transparent;
		color: #fbb50d;
	}

	#searchbox {
		float: none;
		display: inline-block;
		vertical-align: top;
	}
	.etabs li.tab {width: 86%;text-align: center;	}
	.htabs a.selected, .htabs li.active a {background: #fbb50d;	color: #fff;	}
	.hometab-heading.box-heading {text-align: center !important;float: none !important;	}
	#blog-carousel .product-block, #blog-grid .product-block {padding: 0px 10px 0px;	}
	.countdown {margin: 0 0 8px 0px;	}
	.box.special {	margin:40px 0 0;	}
	.product-block.product-thumb.transition {	padding: 10px 10px;	}
	.special-vrstk{max-width: 188px;};
	#footer {padding: 20px 0px;}
}
@media only screen and (max-width: 543px){
	.tablatest_default_width, .tabbestseller_default_width, .tabspecial_default_width, .productcategory0_default_width,
.productcategory1_default_width, .productcategory2_default_width, .productcategory3_default_width, .productcategory4_default_width, .featured_default_width{ width: 50% }
.productcategory0_default_width.wdproductcategory,.productcategory1_default_width.wdproductcategory,
.productcategory2_default_width.wdproductcategory,.productcategory3_default_width.wdproductcategory,
.productcategory4_default_width.wdproductcategory,.productcategory5_default_width.wdproductcategory,.special_default_width{width: 49%;}
.banners-slider-carousel { margin:10px 0 10px;}
.product-layout.product-grid{width: 49%; }
.module_default_width{ width: 33%; }
.box.webdigifyblog { margin: 0px 0 10px;}
#block_1 { padding:15px 0px 0px;}
.hometab, .ProductbyCategory, .featured { padding: 15px 0 10px;}
.box.special { margin:8px 0 10px;}
.social-block ul { margin: 0px 0;}
.footer-appinner .subtitle { margin: 0 0 8px;}
.block_newsletter h3 .title {font-size: 18px; line-height: 20px;}
.footer-top {padding:18px 20px;}
.footer-appinner .title {
    font-size: 16px;
    line-height: 26px;
	margin: 0px 0 4px;
}
.block_newsletter h3 {
    font-size: 16px;
    line-height: 26px;
}
#newslatter { padding: 0px;}
.newsletter.col-sm-6 {width: 100%;padding: 0;}
.product-list .product-block .product-details{width: 100%;text-align: center;}
.product-layout.product-list .img-wrap {
	float: none;
	text-align: center;
	width: 100%;
	height: auto;
}
.product-list .product-details .action {
	margin: 0 2px 0 0;
	display: inline-block;
	vertical-align: top;
	width: auto;
	text-align: center;
	float: none;
}

.product-block .product_hover_block {
	width: auto;
	float: left;
	text-align: left;
	
}
.product-layout.product-list .product-block .product_hover_block{
	display: inline-block;
	vertical-align: top;
	float: none;
}
.stock_status {
	display: none;
}
#content .product-list .product-block .image {
	float: none;
	width: auto;
}
.product-layout.product-list .img-wrap .product_hover_block {
	display: none;
}

}


@media only screen and (max-width: 479px) {

.cmsbanner-block .cms-inner-block1 .description, .cmsbanner-block .cms-inner-block2 .description{ left: 20px; top: 10%; }
.cmsbanner-block .description .title {font-size: 14px; margin: 0 0 10px 0;}
.cmsbanner-block .description .toptitle{font-size: 16px;}
.cmsbanner-block .description .banner_text {margin: 10px 0 0 0;}
#cart .dropdown-menu{width: 280px;}
.stock_status{ font-size: 12px;padding: 7px 10px; }
.social-block > ul {padding: 0; margin: 5px 0;}
.social-block ul li a{margin-right: 0;}
.module_default_width{ width: 50%; }
#content .banners-slider-carousel .product-block{ margin: 0; }
#content .banners-slider-carousel .product-block .product-block-inner{ padding:0 !important; }
.sort-by-wrapper{ width: 100%; }
.show-wrapper {margin: 15px 0 0;}
.category_filter .btn-list-grid{padding: 0;}
.category_filter .show{margin: 7px 10px 0 0;}
.pagination-wrapper .page-link, .pagination-wrapper .page-result{width: 100%; text-align: center;}
.payment-block ul img {margin-bottom: 5px;}
.col-sm-8.product-left{width: 290px;}
#content.productpage .product-title{font-size: 16px;}
.productpage .write-review, .productpage .review-count{margin: 0 5px;}
#button-search {width: 100%; margin: 10px 0 20px;}
.information-contact #content{padding: 15px; background-repeat: repeat;}
.header_search .form-control.input-lg {
    padding: 8px 10px 8px 15px;
	width: 180px;
}
#accordion h4.panel-title a.accordion-toggle {display: block;}
.checkout-cart #accordion .panel-title > a, .checkout-checkout #accordion .panel-title {
	background: #f5f5f5 none repeat scroll 0 0;
	font-weight: 500;
	padding: 10px;
	border-radius: 5px;
	margin: 0 0 10px 0;
}
#accordion .panel-title > a {color: inherit;}
#accordion .panel-heading .fa.fa-caret-down {float: right; margin-top:4px;}
#accordion .col-sm-10 {width: 75%;}
#accordion .form-horizontal .control-label {text-align:left;}
.alert-dismissable .close, .alert-dismissible .close{right: 0 !important;}
#accordion.panel-group .panel {
	width: 100%;
	background-color: #fff;
	border: none;
}
.checkout-success p a {color: #111111;}
.container{padding:0;}
.product-compare .table-bordered {
  border: 1px solid #e5e5e5;
  float: left;
  overflow: auto;
}
.swiper-viewport .swiper-button-prev::before, .swiper-viewport .swiper-button-next::before {
    background: none;
	 border: 0px;
	 color:#111;
}
.swiper-viewport .swiper-button-next {
    right: 0px;
    width: 40px;
    height: 40px;
	transition: none;
}
.swiper-viewport .swiper-button-prev {
    left: 0px;
    transition: none;
    height: 40px;
    width: 40px;
}
.main-slider .swiper-viewport:hover .swiper-button-prev{left: 0px;}
.main-slider .swiper-viewport:hover .swiper-button-next{right: 0px;}
.swiper-viewport .swiper-button-next:hover::before, .swiper-viewport .swiper-button-prev:hover::before{
	background: none;
	color: #fff;
}
.newsletter #txtemail {	padding: 10px 90px 10px 20px;}
.product-block.product-thumb.transition {	padding: 10px 7px;}
.product-thumb h4 {	font-size: 12px;	line-height: 16px;}
#tabs_info.product-tab ul.nav.nav-tabs {
    width: 100%;
    margin: 0 0 10px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
    float: none;
    text-align: center;
    display: block;
}
.nav-tabs>li {
    margin: 0 0 10px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
	float: none !important;
}
.footer-top { padding: 20px 0 15px;}
.hometab, .ProductbyCategory, .featured { padding: 10px 0 10px;}
#content .box .box-heading, .box.related_prd .box-heading { padding: 15px 0px 10px;}
#block_1 .list-unstyled li:first-child { margin: 0 0px 0 0;}
#footer .container {
	padding: 0px;
}
.pagination {
	float: none !important;
}
	
}
@media only screen and (max-width: 380px) {
	.search_box {width: 100px;	}
	.search_box .form-control-select, .search_box .form-control-select.active {width: 100px;height: 40px;	}
	.header_search .form-control.input-lg {
		width: 150px;
		padding: 8px 10px 8px 10px;
		height: 40px;
	}
	.box.special .product-block-inner .image,
	.box.special .product-block .product-details {width: 100%;	}
	.ProductbyCategory {padding: 15px 0 5px;	}
	.box.webdigifyblog {margin: 10px 0 20px;	}
	#column-left .product-items .product-details, #column-left .product-items .product-details h4, #column-right .product-items .product-details h4 {
		padding-top: 0px;
	}
	.product-info .additional-carousel {
		position: relative;
		padding: 0 20px;
		overflow: hidden;
	}
	.pagination {float: none !important;	}
	.header_search .btn.btn-default.btn-lg {padding: 10px 10px 3px;height: 40px;}
	.product-details .rating span {
		font-size: 0;
  }
	
}

/* ---------------------- Start wd - Product-Extended block  --------------------------*/
#tab-description{
	font-size: 18px!important;
	font-weight: 350!important;
	color: #000!important;
}
#tab-description p{
	color: #000!important;
}

 #tab-description {
	float: left;
	width: 100%;
	}
	#tab-description .product-description,
	#tab-description .producttabdescription {
	float: left;
	width: 100%;
	}
	#tab-description .producttabdescription .tabdescleft {
	padding-left: 0;
	float: left;
	}
	#tab-description .producttabdescription .tabdescleft .tabdescleft-img {
	float: left;
	}
	#tab-description .producttabdescription .tabdescleft .tabdescleft-img img {
	transition: all 1.2s ease-out 0s;
	-webkit-transition: all 1.2s ease-out 0s;
	display: block;
	max-width: 100%;
	}
	#tab-description .producttabdescription .tabdescright {
	float: left;
	padding-right: 0;
	}
	#tab-description .producttabdescription .tabdescright .tabdescright-details,
	#tab-description .producttabdescription .tabdescright .table-info-product {
	float: left;
	width: 100%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .maintitle {
	font: 700 18px/24px 'Rubik',Helvetica,sans-serif;
	color: #111;
	float: left;
	width: 100%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .title {
	font: 600 14px/21px 'Rubik',Helvetica,sans-serif;
	text-transform: inherit;
	color: #111111;
	width: 13%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .table-detail {
	float: left;
	width: 100%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .table-detail .table-feature {
	display: flex;
	align-items: center;
	padding:10px 0;
	}
	@media (max-width: 1200px) {
	#tab-description .producttabdescription .tabdescright .table-info-product .title {
	width: 20%;
	}
	}
	@media (max-width:991px) {
	#tab-description .producttabdescription .tabdescright .table-info-product .title {
	width: 40%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .detail {
	width: 60%;
	}
	#tab-description .producttabdescription .tabdescright {
		width: 51%;
	}
	#tab-description .producttabdescription .tabdescleft {
		width: 49%;
	}
	}
	@media (max-width:767px) {
	#tab-description .producttabdescription .tabdescleft .tabdescleft-img {
	float: none;
	display: inline-block;
	vertical-align: top;
	}
	#tab-description .producttabdescription .tabdescleft {
	text-align: center;
	margin: 0 0 15px;
	float: left;
	width: 100%;
	padding: 0;
	}
	#tab-description .producttabdescription .tabdescright {
	float: left;
	padding: 0;
	width: 100%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .title {
	width: 20%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .detail {
	width: 80%;
	}
	}
	@media (max-width:543px) {
	#tab-description .producttabdescription .tabdescright .table-info-product .title {
	width: 50%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .detail {
	width: 50%;
	}
	#tab-description .producttabdescription .tabdescright .table-info-product .table-detail .table-feature {
	align-items: baseline;
	}
	}
/* ---------------------- End wd - Product-Extended block --------------------------*/


/***********************Product page Banner*********************/
.product-detail-right {
    float: left;
    width: 100%;
    padding-left: 15px;
    overflow: hidden;
}

#wdcmsrightservices {
	float: left;
	width: 100%;
	padding: 0 0 20px;
}
#wdcmsrightservices .product-services {
	background: #f5f5f5;
	padding: 20px 15px;
	float: left;
	width: 100%;
}
#wdcmsrightservices .product-services .wdproduct-services {
	margin: 0;
	padding: 0;
}
#wdcmsrightservices .product-services .wdservice .service-title {
	font: 400 13px/24px 'Rubik',Helvetica,sans-serif;
	color: #111111;
}
#wdcmsrightservices .wdservice .service-icon {
	height: 42px;
	width: 42px;
	transition: all 600ms ease-in-out 0s;
	-webkit-transition: all 600ms ease-in-out 0s;
	-moz-transition: all 600ms ease-in-out 0s;
	-o-transition: all 600ms ease-in-out 0s;
	-ms-transition: all 600ms ease-in-out 0s;
	float: left;
	flex-shrink: 0;
}
#wdcmsrightservices .product-services .wdservice {
	display: flex;
	align-items: center;
}
#wdcmsrightservices .wdservice .wdservice-desc {
	padding: 0 0 0 10px;
}

#wdcmsrightservices .product-services .wdproduct-services > li {
	float: left;
	width: 100%;
	margin: 10px 0;
}
#wdcmsrightservices .wd-return.wdservice .service-icon {
	background: url('../image/webdigify/product-services.png') repeat scroll -25px -18px;
}
#wdcmsrightservices .wd-secure.wdservice .service-icon{
	background: url('../image/webdigify/product-services.png') repeat scroll -25px -107px;
}
#wdcmsrightservices .wd-support.wdservice .service-icon{
	background: url('../image/webdigify/product-services.png') repeat scroll -25px -188px;
}
#wdcmsrightservices .wd-shop.wdservice .service-icon{
	background: url('../image/webdigify/product-services.png') repeat scroll -25px -277px;
}
#wdcmsrightservices .wd-return.wdservice:hover .service-icon {
	background-position: -109px -18px;
}
#wdcmsrightservices .wd-secure.wdservice:hover .service-icon{
	background-position: -109px -107px;
}
#wdcmsrightservices .wd-support.wdservice:hover .service-icon{
	background-position: -109px -188px;
}
#wdcmsrightservices .wd-shop.wdservice:hover .service-icon{
	background-position: -109px -277px;
}

#wdcmsrightbanner:hover .wdcmrightbanner a img {
    transform: scale(1.04);
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -o-transform: scale(1.04);
    -ms-transform: scale(1.04);
}
#wdcmsrightbanner .wdcmrightbanner a img {
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out 0s;
    -moz-transition: all 300ms ease-in-out 0s;
    -o-transition: all 300ms ease-in-out 0s;
    -ms-transition: all 300ms ease-in-out 0s;
	max-width: 100%;
}
#wdcmsrightbanner .wdcmrightbanner a {
    overflow: hidden;
    display: block;
}


@media(max-width:1499px) {
	#wdcmsrightservices .wdservice .wdservice-desc {
		padding: 0 0 0 10px;
	}
	#wdcmsrightservices .product-sevices .wdservice .service-title{
		font-size: 13px;
		line-height: 22px;
	}
	#wdcmsrightservices .product-services {
		padding: 10px 10px;
	}
	.product-detail-right {
		padding-left: 0px;
	}

}

@media(max-width:1199px) {
	#wdcmsrightservices .product-sevices .wdservice .service-title {
		font-size: 12px;
		line-height: 20px;
	}
}

@media(max-width:991px) {
	#wdcmsrightservices {
		max-width: 280px;
		padding: 0 15px 20px 0;
	}

	.col-sm-3.product {
		width: 100%;
	}
	.product-detail-left {
		width: 100%;
	}
}
@media(max-width:543){
	#wdcmsrightbanner {
		margin: 0px;
	}
}
#wdcmsrightbanner {
    float: left;
}
@media(max-width:991px) and (min-width:544){
	#wdcmsrightbanner {
		float: left;
		width: 190px;
		margin-left: 15px;
	}
}

/***********************End Product Page Banner**************/

.product-detail-left {
    width:100% ;
    float: left;
    padding-right: 0px;
}

/* Webdigify www.templatemela.com End */
#product .form-group.qty button#button {
    color: #fff;
    background: #293544;
}
#product .form-group.qty button#button:hover {
    color: #111;
    background: #fbb50d;
}
@media(max-width:991px) {
	.product-detail-left {
		width: 100%;
		padding-top: 20px;
	}
}

/***************Start Testimonial Cms Block ***************/


#wdcmstestimonial {
	width: 100%;
	float: left;
	padding: 50px 0;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title h4 {
	color: #111 !important;
	font: 700 14px/28px 'Rubik',Helvetica,sans-serif;
	text-transform: inherit;
	margin: 0;
}
#wdcmstestimonial .testimonial-content .testimonial-desc {
	float: left;
	width: 100%;
	margin: 0 0 10px;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title .wdtestimonial-subtitle {
	font: 400 14px/28px 'Rubik',Helvetica,sans-serif;
	text-transform: inherit;
	color: #111;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
	font: 400 14px/24px 'Rubik',Helvetica,sans-serif;
	color: #111;
	text-transform: inherit;
	margin: 0 0 10px;
	text-align: left;
}
#wdcmstestimonial .wdtestimonial-content .tttestimonial-title {
	font: 700 30px/44px 'Rubik',Helvetica,sans-serif;
	color: #111;
}
#wdcmstestimonial .testimonial-content {
	width: 64%;
	padding: 0 0 0 60px;
	position: relative;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-user-title h4::after {
	content: '-';
	font-weight: 400;
	margin: 0 8px;
}
#wdcmstestimonial .testimonial-user-title {
	text-align: left;
	margin: 0;
	display: flex;
	align-items: center;
	float: left;
	width: 100%;
}
#wdcmstestimonial #wdtestimonial-carousel {
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
	float: left;
	border-right: 1px solid rgba(0,0,0,0.1);
	padding: 0 60px 0 0;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image img {
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-khtml-border-radius: 50%;
	float: left;
	width: 100%;
	height: 100%;
	border: 3px solid #fff;
	box-shadow: 0 0 4px 1px rgba(0,0,0,.05);
}
#wdtestimonial-carousel li {
	float: left;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
#wdcmstestimonial .owl-carousel .owl-pagination .owl-page.active {
	background: #fbb50d;
	height: 12px;
	width: 12px;
}
#wdcmstestimonial .owl-carousel .owl-pagination .owl-page {
	height: 8px;
	width: 8px;
	background: #293544;
	border-radius: 8px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 8px 0 0;
	transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
}
#wdcmstestimonial .owl-carousel .owl-pagination {
	position: static;
	float: left;
	width: 100%;
	text-align: center;
	margin: 15px 0 0;
}
@media (max-width: 1199px) {
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
	font-size: 14px;
	line-height: 26px;
}
#wdcmstestimonial .testimonial-content {
	padding: 0 0 0 40px;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
	padding: 0 40px 0 0;
}
}
@media (max-width: 991px) {
#wdcmstestimonial {
	padding: 30px 0;
}
#wdcmstestimonial .testimonial-content {
	width: 72%;
}
}
@media (max-width:767px) {
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p {
	font-size: 13px;
    line-height: 24px;
}
#wdcmstestimonial .testimonial-content {
	padding: 0 0 0 20px;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
	padding: 0 20px 0 0;
}
}
@media (max-width:543px) {
	#wdcmstestimonial {
		padding: 15px 0;
	}
#wdtestimonial-carousel li {
	display: block;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image {
	padding: 0 0 15px;
    float: none;
    width: 100%;
    border: none;
    text-align: center;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-image img {
	float: none;
	width: 100px;
	height: 100px;
	display: inline-block;
	vertical-align: top;
}
#wdcmstestimonial .testimonial-content {
	width: 100%;
	float: left;
	padding: 0;
}
#wdcmstestimonial .wdtestimonial-content .wdtestimonial-inner ul li .testimonial-content .testimonial-desc p{
	text-align: center;
}
#wdcmstestimonial .testimonial-user-title {
	justify-content: center;
}
}

.header-logo {
	width: 20%!important;
}


.header-cms-block span.content{
	margin-right: 10px;
}

#nav-one{
	list-style-type:none;
}
#nav-one li{
	display:block;
	float:left;
	width:100%;
}
.box-content-category {
	position: fixed;
	left:0;
	top:0;
	width:33vw;
	height: 100vh;
	overflow-y: scroll;
	/* width:auto!important; */
}

.box-content-category ul li a:before {
	display: none!important;
}

@media (max-width: 767px){
	.col-sm-4.header-logo {
		width: 200px!important;
	}
}

@media only screen and (max-width: 660px){
	.col-sm-4.header-logo {
		padding: 0px 0!important;
	}
}

.fa-tags:before {
    content: none!important;
}
.header_top.fixed {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin-top: -1px;
    animation: 1s ease 0s normal both 1 running fixedmenu;
	z-index: 100;
	background: white;
}

.header_bottom.fixed {
	margin-top: 75px!important;
}

.header_top {
    padding: 10px 0px 0px 0px!important;
}
#content.productpage .product-title {
	text-transform: inherit!important;
}

.description-right a{ 
	color: #fbb50d;
}


	/********** End Testimonial Cms BLock  **********/

.title-module span{
	margin-top: 15px;
	font-size: 30px;
	font-weight: 600;
    line-height: 35px;
	color: black;
}

.oneclick > .help-block{
font-size:0.65em;
}

#button-oneclick{
	padding: 11px 15px 11px 37px;
    border-radius: 5px;
    color: #ffffff;
    background: #fbb50d;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    background-image: url(../image/webdigify/cart-hover.svg);
    background-repeat: no-repeat;
    background-position: 9px 8px;
    background-size: 22px;
    position: relative;
    z-index: 1;
}

#button-oneclick:before{
	content: "";
    position: absolute;
    background: #fbb50d;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -ms-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    height: 0;
}

#button-oneclick:hover:before{
    height: 100%;
    background: #111111;
    color: #fff;
    border-radius: 5px;
}

#button-oneclick:hover:after{
	content: "";
    background-image: url(../image/webdigify/cart-hover.svg);
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    position: absolute;
    background-size: 22px;
    background-position: 9px -3px;
    background-repeat: no-repeat;
    z-index: 9;
    left: 0;
}

.oleclick{
	border-top: 1px solid #eaeaea;
	padding: 15px 5px 0px 0;
}

.product-description{
	margin-bottom: 20px;
}

.delivery-icon{
	margin-right: 10px;
    padding-bottom: 2px;
}
.glyphicon{
	margin-right: 10px;
}

.oleclick h3{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 40px;
	letter-spacing: 0.6px;
	color: #111111;
	margin-bottom: 0px!important;
}

table.delivery{
	margin-left: 30px;
}

.colcat{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: #838383;
}

.colval{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.87);
}

td.colright {
	padding-left: 110px;
}

@media (max-width: 767px){
	.colcat{
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: #838383;
	}
	.colval{
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: rgba(0, 0, 0, 0.87);
	}
	td.colright {
		padding-left: 9px;
	}
	table.delivery td {
		padding-bottom: 10px;
		line-height: 10px!important;
	}
	.oleclick h3{
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 0.6px;
		color: #111111;
	}
	table.delivery{
		margin-top: 15px;
		margin-left: 0px!important;
	}
	.advant{
		margin-left: 0px!important;
		max-width: 100%!important;
		margin-top: 15px!important;
	}
}
table.delivery td {
    line-height: 30px;
}

.advant{
	margin-bottom: 10px;
	margin-top: 10px;
	margin-left: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    max-width: 72%;
}

.advant p{
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	color: #000000;
}

.delivery-icon{
	width: 20px!important;
} 

.colleft{
	min-width: 170px;
}

.ass h1{
	color: #111!important;
	font-size: 30px!important;
	font-family: Rubik!important;
	font-weight: 700!important;
	line-height: 40px!important;
	letter-spacing: 0.6px!important;
}

.ass{
	margin-bottom: 40px; 
}

.mg-top{
	border-radius: 10px;
	height: 324px!important;
	background-position: center!important;
} 

.blog-desc1{
	margin-bottom: 40px;
}

.blog-manu{
	margin-top: 40px;
}

.manu{
	display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
	border-radius: 10px;
	background: #F7F7F5;
	width: 920px;
	height: 75px;
	flex-shrink: 0;
	margin-bottom: 20px;
}

.manu img{
	max-height: 55px;
	max-width: 100px;
	margin-left: 20px;
}

.manu span{
	margin-left: 25px;
	color: #000;
	font-size: 13px;
	font-family: Rubik;
	line-height: 22px;
	letter-spacing: 0.6px;
	width: 68%
}

.manu a{
	color: #FBB50D!important;
	font-size: 15px!important;
	line-height: 24px!important;
}

.navigation span{
	color: #000;
	font-size: 18px;
	line-height: 26px
}

.navigation{
	margin-top: 40px!important;
	border-bottom: 2px solid #F7F7F5 ;
	margin-bottom: 40px!important;
}

.ul-hd{
	margin-top: 13px!important;
	margin-bottom: 13px!important;
}

.h-nav{
	color: #3D3BFF;
	font-size: 18px;
	line-height: 26px;
	margin-top: 13px;
}

.blog-desc h2{
	color: #111;
	font-size: 24px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.6px;
	margin-bottom: 15px;
}

.blog-desc1 ul li{
	color: #666;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.6px;
	list-style-type: square;
}

.blog-desc1 ul li::marker{
	color: #ffab00;
	font-size: 25px;
}

.blog-desc ul li{
	color: #666;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: 0.6px;
	list-style-type: square;
}

.blog-desc ul li::marker{
	color: #ffab00;
	font-size: 25px;
}

.perelink-block{
	cursor: pointer;
	margin: 40px 0px 40px 0px;
	border-radius: 16px;
	border: 1px solid #E4E4E4;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
	display: flex;
	justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
}  

.perelink-block img{
	width: 209px;
	flex-shrink: 0;
	margin-left: 41px;
	border-radius: 5px;
	margin-bottom: 41px;
    margin-top: 41px;
}

.perelink-flex{
	display: flex;
	flex-direction: column;
	margin-left: 30px;
}

.perelink-flex span{
	color: #000;
	font-size: 16px;
	line-height: 23px;
	margin-top: 41px;
	margin-bottom: 18px;
}
.perelink-flex a{
	color: #FBB50D;
	font-size: 20px;
	line-height: 29px;
}

.read-more-blog{
	padding: 20px;
	background: #FEF5E0;
	text-align: left;
	margin-top: 40px;
}

.read-more-blog span{
	color: #000;
	font-size: 13px;
	font-family: Rubik;
	line-height: 21px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.read-more-blog ul li{
	list-style: none!important;  
	margin-top: 13px!important;
	color: #3D3BFF!important;
	font-size: 14px!important;
	font-family: Rubik!important;
	line-height: 21px!important;
}

input.form-control.input-lg{
	border-radius: 5px 0px 0px 5px;
}

.blog-desc table{
	font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
	font-size: 14px;
	border-collapse: collapse;
	text-align: center;
	margin-top: 25px;
	margin-bottom: 25px;
}

.blog-desc th, .blog-desc td:first-child {
	background: #AFCDE7;
	color: black;
	padding: 10px 20px;
}

.blog-desc th, td {
	border-style: solid;
	border-width: 0 1px 1px 0;
	border-color: white;
}

.blog-desc td {
	background: #D8E6F3;
}

.blog-desc th:first-child, td:first-child {
	text-align: left;
}

@media (max-width: 767px){
	.manu a {
		margin-left: 0; 
	}

	.manu{
		width: auto;
	}

	.manu img{
		margin-left: 0px;
	}

	.manu span{
		margin-left: 0px;
	}
	
	.perelink-block img {
		width: 300px;
		margin-top: 20px;
    	margin-left: 0px; 
    	margin-bottom: 0px;
		border-radius: 5px;
	}

	.perelink-block{
		justify-content: center;
    	flex-direction: column;
    	align-items: center; 
	}

	.perelink-flex{
		margin-left: 0px;
		padding: 0px 25px 0px 25px;
	}

	.perelink-flex span {
		margin-top: 16px;
    	margin-bottom: 18px;
	}
	.perelink-flex a {
		margin-bottom: 12px;
		word-break: break-all;
	}
	.blog-desc ul{
		column-count: 1!important;
	}
	.blog-desc h2 {
		font-size: 18px;
	}
	.blog-desc1 ul{
		column-count: 1!important;
	}
	.blog-desc1 h2 {
		font-size: 18px;
	}
} 