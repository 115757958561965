section.brands{
  margin-bottom: 40px;
  .brands_list{
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    @media(max-width: 1024px){
      grid-template-columns: 1fr;
    }
    .brand_item{
      padding: 15px;
      background-color: $cgray;
      border-radius: 5px;
      @media(max-width: 1024px){
        padding: 23px;
      }
      figure{
        width: 100%;
        img{
          width: 100%;
          height: 65px;
          @media(max-width: 1024px){
            height: 95px;
          }
        }
      }
    }
  }
}