section.custom_banners{
  margin-bottom: 40px;
  .banner_slider_wrap{
    .dl_banner_slider{

      padding-bottom: 40px;
      .banner{
        width: 100%;
        .banner_image{
          overflow: hidden;
          width: 100%;
          figure{
            width: 100%;
            min-height: 150px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              min-height: 150px;
            }
          }
          picture{
            position: absolute;
            top: -40px;
            left: 20px;
            @media(max-width: 1024px){
              left: unset;
              right: 0;
              top: -20px;
              height: 100%;
            }
          }
        }
        .banner_content{
          position: absolute;
          right: 40px;
          top: 25px;
          @media(max-width: 1024px){
            right: unset;
            left: 15px;
            top: 15px;
            width: 80%;
          }
          .banner_text{
            font-size: 30px;
            color: $cwhite;
            font-weight: 700;
            line-height: 34px;
            @media(max-width: 1024px){
              font-size: 15px;
            }
            span{
              color: $ccorp;
              font-size: 45px;
              @media(max-width: 1024px){
                font-size: 23px;
              }
            }
          }
        }
      }
      .owl-controls{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        .owl-pagination{
          bottom: 0;
          .owl-page{
            span{
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: $ccorp;
              border-radius: 50%;
              opacity: 0.7;
            }
            &.active{
              span{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}